import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        minWidth: "60%",
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: theme.palette.text.primary,
    },
    closeButton: {
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
  }),
);
