import { ElementRef, useCallback, useMemo, useRef } from "react";
import { MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import {
  GridRowParams,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowModel,
} from "@mui/x-data-grid";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { DeleteIcon } from "components/icon/delete-icon";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import {
  AttachmentType,
  AttachmentTypeId,
} from "data-access/repositories/attachment_type/attachment_type.dto";
import { attachmentTypeRepository } from "data-access/repositories/attachment_type/attachment_type.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";

export const AttachmentTypeTable = () => {
  const dispatch = useAppDispatch();

  const {
    data: attachmentTypes,
    isValidating,
    mutate,
  } = useSWR("/api/v1/attachment_types", () => attachmentTypeRepository.index());

  const columns = useMemo(() => {
    const headers = [];
    headers.push({
      field: "name",
      headerName: "名前",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "is_publish_to_mobile",
      headerName: "共有範囲",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Select
          name="publicationRange"
          value={params.row.isPublishToMobile ? "all" : "part"}
          variant="standard"
          onChange={(e) => handleChange(e, params.row.id)}
          displayEmpty
          sx={{
            "&.MuiInput-underline:before": {
              borderBottom: "none",
            },
          }}
        >
          <MenuItem value="all">全員に共有</MenuItem>
          <MenuItem value="part">一部に共有</MenuItem>
        </Select>
      ),
    });
    headers.push({
      field: "actions",
      type: "actions" as const,
      width: 80,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<DeleteIcon color={theme.palette.grayScale[700]} size={20} />}
          label="Delete"
          onClick={() => handleDelete(params.row.id as AttachmentTypeId)}
        />,
      ],
    });
    return headers;
  }, []);

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (id: AttachmentTypeId) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();

    if (res) {
      try {
        await attachmentTypeRepository.destroy(id as AttachmentTypeId);
        attachmentTypes && mutate();
        dispatch(mainOperations.updateSuccessMessage("添付ファイルタイプを削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    id: AttachmentTypeId,
  ) => {
    try {
      await attachmentTypeRepository.update(id, {
        isPublishToMobile: e.target.value === "all" ? true : false,
      });
      attachmentTypes && mutate();
      dispatch(mainOperations.updateSuccessMessage("共有範囲を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    try {
      await attachmentTypeRepository.update(params.row.id, {
        displayOrderPosition: params.targetIndex,
      });
      attachmentTypeRepository && mutate();
      dispatch(mainOperations.updateSuccessMessage("予定タイプの順番を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleEditName = useCallback(async (params: GridRowModel) => {
    const { id, name } = params;
    try {
      await attachmentTypeRepository.update(id, {
        name,
      });
      attachmentTypes &&
        mutate(
          attachmentTypes.map((attachmentType: AttachmentType) => {
            if (attachmentType.id === id) {
              attachmentType.name = name;
            }
            return attachmentType;
          }),
        );
      dispatch(mainOperations.updateSuccessMessage("添付ファイルタイプ名を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    return params;
  }, []);

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <CustomizedDataGridPro
        columnHeaderHeight={40}
        columns={columns}
        rows={attachmentTypes || []}
        loading={isValidating}
        hideFooter
        rowReordering
        disableRowSelectionOnClick
        onRowOrderChange={handleRowOrderChange}
        processRowUpdate={handleEditName}
      />
    </>
  );
};
