import { AddCircle } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  List,
  ListItem,
  Paper,
  Popper,
  TextField,
} from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  data: any[];
  value?: any;
  entityName: string;
  onChange: (e: React.SyntheticEvent, value: any, reason: AutocompleteChangeReason) => void;
  onClick?: () => void;
  isCreate?: boolean;
  entityLabel?: string;
  variant?: "outlined" | "standard" | "filled";
  disabled?: boolean;
  isClearable?: boolean;
  sx?: object;
  filterOptions?: (options: any[], state: { inputValue: string }) => any[];
}
export const CustomAutocomplete = ({
  value = undefined,
  isCreate = false,
  entityLabel = "",
  variant = "outlined",
  disabled = false,
  isClearable = false,
  filterOptions = undefined,
  ...props
}: Props) => {
  const { data, entityName, onChange, onClick, sx } = props;

  return (
    <Autocomplete
      options={data}
      value={value}
      onChange={onChange}
      filterOptions={filterOptions}
      sx={sx}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={entityLabel} variant={variant} />}
      PopperComponent={
        isCreate
          ? (props: any) => <CustomList {...props} handleCreate={onClick} entityName={entityName} />
          : undefined
      }
      noOptionsText={`${entityName}は見つかりませんでした`}
      disabled={disabled}
      disableClearable={!isClearable}
    />
  );
};

interface CustomPopperProps {
  children: React.ReactNode;
  handleCreate: () => void;
  entityName: string;
  open: boolean;
}
export const CustomList = ({ children, handleCreate, entityName, ...props }: CustomPopperProps) => {
  return (
    <Popper {...props} placement="bottom-start">
      <Paper
        sx={{
          "&.MuiPaper-root": { boxShadow: `0px 0px 4px 1px ${theme.palette.grayScale[300]}` },
        }}
      >
        <List sx={{ "& .MuiAutocomplete-paper": { boxShadow: "none" } }}>
          {children}
          <ListItem sx={{ p: 0, pl: "10px", pt: "4px" }}>
            <Button
              sx={{ width: "100%", justifyContent: "start", fontWeight: "bold" }}
              startIcon={<AddCircle />}
              // onBlurが先に発火してしまいhandleCreateが発火しないためpreventDefaultを追加
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleCreate}
            >
              {entityName}を新規作成
            </Button>
          </ListItem>
        </List>
      </Paper>
    </Popper>
  );
};
