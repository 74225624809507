import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BillingUpdateResponse,
  BillingShowResponse,
  BillingUpdateRequest,
  BillingId,
} from "data-access/repositories/billing/billing.dto";
import { billingRepository } from "data-access/repositories/billing/billing.repository";
import {
  InvoiceResponse,
  InvoiceCreateRequest,
  BillingInvoiceId,
} from "data-access/repositories/billing/invoice/invoice.dto";
import { invoiceRepository } from "data-access/repositories/billing/invoice/invoice.repository";
import {
  WarrantyShowResponse,
  WarrantyCreateRequest,
} from "data-access/repositories/billing/warranty/warranty.dto";
import { warrantyRepository } from "data-access/repositories/billing/warranty/warranty.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";
import { downloadBlob } from "utils/downloadBlob";

export const showBilling = createAsyncThunk<BillingShowResponse, BillingId, AsyncThunkConfig>(
  "billingSidebar/show",
  async (id: BillingId, thunkApi) => {
    try {
      return await billingRepository.show(id);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const downloadTemplateBilling = createAsyncThunk<
  void,
  { id: BillingId; fileName: string },
  AsyncThunkConfig
>(
  "billingSidebar/downloadTemplateBilling",
  async (thunkArg: { id: BillingId; fileName: string }, thunkApi) => {
    try {
      const blob: Blob = await billingRepository.downloadTemplate(thunkArg.id);
      downloadBlob(blob, thunkArg.fileName);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const updateBilling = createAsyncThunk<
  BillingUpdateResponse,
  { id: BillingId; body: BillingUpdateRequest },
  AsyncThunkConfig
>(
  "billingSidebar/update",
  async (thunkArg: { id: BillingId; body: BillingUpdateRequest }, thunkApi) => {
    try {
      return await billingRepository.update(thunkArg.id, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const showInvoice = createAsyncThunk<
  InvoiceResponse[],
  { billingId: BillingId },
  AsyncThunkConfig
>("billingSidebar/showInvoice", async (thunkArg: { billingId: BillingId }, thunkApi) => {
  try {
    return await invoiceRepository.show(thunkArg.billingId);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

export const createInvoice = createAsyncThunk<
  void,
  { billingId: BillingId; body: InvoiceCreateRequest },
  AsyncThunkConfig
>(
  "billingSidebar/createInvoice",
  async (thunkArg: { billingId: BillingId; body: InvoiceCreateRequest }, thunkApi) => {
    try {
      return await invoiceRepository.create(thunkArg.billingId, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const destroyInvoice = createAsyncThunk<
  void,
  { billingId: BillingId; id: BillingInvoiceId },
  AsyncThunkConfig
>(
  "billingSidebar/destroyInvoice",
  async (thunkArg: { billingId: BillingId; id: BillingInvoiceId }, thunkApi) => {
    try {
      return await invoiceRepository.destroy(thunkArg.billingId, thunkArg.id);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const showWarranty = createAsyncThunk<
  WarrantyShowResponse[],
  { billingId: BillingId },
  AsyncThunkConfig
>("billingSidebar/showWarranty", async (thunkArg: { billingId: BillingId }, thunkApi) => {
  try {
    return await warrantyRepository.show(thunkArg.billingId);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

export const createWarranty = createAsyncThunk<
  void,
  { billingId: BillingId; body: WarrantyCreateRequest },
  AsyncThunkConfig
>(
  "billingSidebar/createWarranty",
  async (thunkArg: { billingId: BillingId; body: WarrantyCreateRequest }, thunkApi) => {
    try {
      return await warrantyRepository.create(thunkArg.billingId, thunkArg.body);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const destroyWarranty = createAsyncThunk<
  void,
  { billingId: BillingId; id: number },
  AsyncThunkConfig
>(
  "billingSidebar/destroyWarranty",
  async (thunkArg: { billingId: BillingId; id: number }, thunkApi) => {
    try {
      return await warrantyRepository.destroy(thunkArg.billingId, thunkArg.id);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);
