import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    block: {
      border: `1px solid ${theme.palette.grayScale[300]}`,
      borderRadius: "5px",
      marginTop: "1rem",
      padding: "1rem",
    },
    image: {
      width: "4.8rem",
      height: "4.8rem",
      maxWidth: "4.8rem",
      maxHeight: "4.8rem",
      borderRadius: "5px",
      margin: 1,
    },
  }),
);
