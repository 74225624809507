import { Brand } from "../../brand";
import { ProjectStatusTypeId } from "../../project_status_type/project_status_type.dto";
import { UserId } from "../../user/user.dto";
import { SubscriberType } from "../index.dto";

export type NoticeProjectStatusChangeId = Brand<number, "NoticeProjectStatusChangeId">;

export type NoticeProjectStatusChangeIndexResponse = Array<NoticeProjectStatusChange>;

export type Options = "inquired_by" | "manager";
export const OPTIONS: Record<Options, { key: Options; label: string }> = {
  inquired_by: { key: "inquired_by", label: "受付担当者" },
  manager: { key: "manager", label: "現場責任者" },
};
export interface NoticeProjectStatusChange {
  id: NoticeProjectStatusChangeId;
  projectStatusIdsForFilter: ProjectStatusTypeId[];
  noticeTarget: NoticeProjectStatusChangeNoticeTarget;
  subscriberTypes: SubscriberType[];
  isEnable: boolean;
}

export interface NoticeProjectStatusChangeUpdateRequest {
  projectStatusIdsForFilter?: ProjectStatusTypeId[];
  noticeTarget?: {
    inquiredBy: boolean;
    manager: boolean;
    userIds: UserId[];
  };
  subscriberTypes?: SubscriberType[];
  isEnable?: boolean;
}

export interface NoticeProjectStatusChangeNoticeTarget {
  inquiredBy: boolean;
  manager: boolean;
  userIds: UserId[];
}
