import { Alert, AlertTitle, Dialog, Typography } from "@mui/material";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const RecurrenceUpdateInfoModal = (props: Props) => {
  const { isOpen, onClose } = props;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <Alert severity="warning" onClose={onClose} sx={{ p: "12px" }}>
        <AlertTitle sx={{ mb: "14px" }}>注意</AlertTitle>
        <Typography>
          この予定のみ変更されました。
          <br />
          すべての繰り返し予定を変更するには
          <br />
          予定編集画面から変更してください。
        </Typography>
      </Alert>
    </Dialog>
  );
};
