import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ScheduleSearchRequest,
  ScheduleSearchResponse,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";

export const search = createAsyncThunk<
  ScheduleSearchResponse,
  ScheduleSearchRequest,
  AsyncThunkConfig
>("scheduleSearchResultTable/index", async (query, thunkApi) => {
  try {
    return await scheduleRepository.search(query);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});
