import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    selectAllButton: {
      height: "100%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    deselectButton: {
      height: "100%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    checkbox: {
      position: "absolute",
      top: "8px",
      left: "8px",
      width: "20px",
      height: "20px",
      accentColor: theme.palette.primary.main,
    },
    imageBoxBlock: {
      display: "flex",
      flexWrap: "wrap",
      gap: "24px",
      marginTop: "16px",
      marginBottom: "28px",
      "& .MuiIconButton-root": {
        position: "absolute",
      },
    },
  }),
);
