import { ElementRef, useCallback, useMemo, useRef } from "react";
import { Switch, Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
} from "@mui/x-data-grid";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { DeleteIcon } from "components/icon/delete-icon";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import { theme } from "extensions/theme";
import { supplierRepository } from "features/cost/api/supplier.repository";
import { Supplier, SupplierId } from "features/cost/types/supplier.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";

interface Props {
  fetchIndexKey: string;
}

export const SupplierTable = ({ fetchIndexKey }: Props) => {
  const dispatch = useAppDispatch();

  const { data: suppliers, mutate, isValidating } = useSWR(fetchIndexKey, supplierRepository.index);

  const handleEditName = useCallback(async (params: GridRowModel) => {
    try {
      await supplierRepository.update(params.id, {
        name: params.name,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("仕入先名を更新しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    return params;
  }, []);

  const handleCheck = async (e: React.ChangeEvent<HTMLInputElement>, id: SupplierId) => {
    const { name, type, checked, value } = e.target;
    try {
      await supplierRepository.update(id, {
        [name]: type === "checkbox" ? checked : value,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("仕入先を更新しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    try {
      await supplierRepository.update(params.row.id, {
        displayOrderPosition: params.targetIndex,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("仕入先の順番を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const columns = useMemo(() => {
    const headers: GridColDef[] = [];
    headers.push({
      field: "name",
      headerName: "仕入先名",
      minWidth: 240,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "isDefaultCost",
      headerName: "デフォルト反映",
      minWidth: 140,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Switch
            name="isDefaultCost"
            checked={params.value}
            onChange={(e) => handleCheck(e, params.row.id)}
            color="primary"
          />
        );
      },
    });
    headers.push({
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params: GridRowParams) => [
        <>
          <GridActionsCellItem
            icon={<DeleteIcon color={theme.palette.grayScale[700]} size={20} />}
            label="Delete"
            onClick={() => handleDelete(params.row.id as SupplierId)}
          />
        </>,
      ],
    });
    return headers;
  }, []);

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (id: SupplierId) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();

    if (res) {
      try {
        await supplierRepository.destroy(id);
        suppliers && mutate(suppliers.filter((supplier: Supplier) => supplier.id !== id));
        dispatch(mainOperations.updateSuccessMessage("仕入先を削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <CustomizedDataGridPro
        autoHeight
        columnHeaderHeight={40}
        columns={columns}
        rows={suppliers || []}
        loading={isValidating}
        hideFooter
        rowReordering
        disableRowSelectionOnClick
        onRowOrderChange={handleRowOrderChange}
        processRowUpdate={handleEditName}
      />
    </>
  );
};
