import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { theme } from "extensions/theme";

const STabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    border-left: 1px solid ${theme.palette.primary.main};
    border-bottom: 1px solid ${theme.palette.primary.main};
    & .MuiButtonBase-root.MuiTab-root {
      color: ${theme.palette.primary.main};
      width: 10rem;
      border-top: 1px solid ${theme.palette.primary.main};
      border-right: 1px solid ${theme.palette.primary.main};
    }
    & .MuiButtonBase-root.MuiTab-root.Mui-selected {
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.grayScale[0]};
    }
  }
`;

interface Props<T extends string> {
  value: T;
  onChange: (e: React.SyntheticEvent, newValue: T) => void;
  tabs: { value: T; label: string }[];
}

export const CustomTabs = <T extends string>(props: Props<T>) => {
  return (
    <STabs value={props.value} onChange={(e, newValue) => props.onChange(e, newValue as T)}>
      {props.tabs.map((tab) => (
        <Tab key={tab.value} value={tab.value} label={tab.label} />
      ))}
    </STabs>
  );
};
