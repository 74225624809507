import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  isOpen: boolean;
  type: "delete" | "update";
  onUpdate: (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, withFutures: boolean) => void;
  onDelete: (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, withFutures: boolean) => void;
  onCancel: () => void;
  onClose: () => void;
}

export const RecurrenceRuleConfirmDialog = (props: Props) => {
  const { isOpen, onUpdate, onDelete, onCancel, onClose, type } = props;
  const [withFutures, setWithFutures] = useState<boolean>(false);

  useEffect(() => {
    setWithFutures(false);
  }, [isOpen]);

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === "true";
    setWithFutures(value);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      maxWidth="sm"
      sx={{ "& .MuiPaper-root": { p: "0 10px 10px 10px" } }}
    >
      <DialogTitle sx={{ textAlign: "right", p: "10px 0" }}>
        <IconButton onClick={handleCancel}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          sx={{ color: theme.palette.text.primary, textAlign: "center", fontWeight: "bold" }}
        >
          {`繰り返し予定の${type === "delete" ? "削除" : "変更"}`}
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <RadioGroup
          name="withFutures"
          row
          onChange={handleRadio}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <FormControlLabel
            control={<Radio size="small" checked={!withFutures} />}
            value={false}
            sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px", userSelect: "none" } }}
            label="この予定のみ"
          />
          <FormControlLabel
            control={<Radio size="small" checked={withFutures} />}
            value={true}
            sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px", userSelect: "none" } }}
            label="この予定以降のすべて"
          />
        </RadioGroup>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", gap: "20px" }}>
        <Button onClick={handleCancel} color="primary" variant="outlined" sx={{ width: "120px" }}>
          キャンセル
        </Button>
        {type === "delete" && (
          <Button
            onClick={(e) => onDelete(e, withFutures)}
            color="error"
            variant="contained"
            sx={{ width: "120px" }}
          >
            削除する
          </Button>
        )}
        {type === "update" && (
          <Button
            onClick={(e) => onUpdate(e, withFutures)}
            color="primary"
            variant="contained"
            sx={{ width: "120px" }}
          >
            決定
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
