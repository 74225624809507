import { ApiClient } from "data-access/ApiClient";

export const tokenRepository = {
  save(code: string): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post(`/api/v1/microsoft/token`, { code }, config).then((res) => res.data);
  },
  destroy(): Promise<void> {
    return ApiClient.delete("/api/v1/microsoft/token").then((res) => res.data);
  },
};
