import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    scheduleName: {
      fontSize: "20px",
      fontWeight: "bold",
      wordBreak: "break-all",
      flex: 1,
    },
    radioGroup: {
      "& .MuiFormControlLabel-root": {
        border: `1px solid ${theme.palette.grayScale[700]}`,
        borderRadius: 5,
        padding: "0.5rem 3rem 0.5rem 0",
        marginLeft: "0.1rem",
      },
    },
    blockTitle: {
      color: theme.palette.grayScale[700],
    },
    headerFixed: {
      position: "sticky",
      top: 0,
      width: "28rem",
      backgroundColor: theme.palette.grayScale[0],
      zIndex: 9,
    },
    siteManagerBadge: {
      backgroundColor: theme.palette.customPrimary[100],
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "4px",
      padding: "4px 10px",
      fontSize: "12px",
      fontWeight: "500",
    },
  }),
);
