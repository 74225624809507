import { createStyles, makeStyles } from "@mui/styles";

export const styles = makeStyles(() =>
  createStyles({
    tableSelectBox: {
      "&.MuiInput-underline:before": {
        borderBottom: "none",
      },
    },
  }),
);
