import { Close } from "@mui/icons-material";
import { IconButton, Portal, Snackbar } from "@mui/material";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";

type Props = {
  successMessage?: string;
  errorMessage?: string;
  handleCloseSuccess?: () => void;
  handleCloseError?: () => void;
};

export const ResponseSnackbar = (props: Props) => {
  const { successMessage, errorMessage } = props;
  const dispatch = useAppDispatch();

  const handleCloseSuccess = (): void => {
    if (props.handleCloseSuccess) {
      props.handleCloseSuccess();
    }
    dispatch(mainOperations.updateSuccessMessage(""));
  };
  const handleCloseError = (): void => {
    if (props.handleCloseError) {
      props.handleCloseError();
    }
    dispatch(mainOperations.updateErrorMessage(""));
  };

  return (
    <>
      {/* モーダルに隠れる時があるため<Portal />で囲む */}
      <Portal>
        <Snackbar
          open={!!successMessage}
          onClose={handleCloseSuccess}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          message={successMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSuccess}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        />
      </Portal>
      {errorMessage && (
        <Portal>
          <Snackbar
            open={!!errorMessage}
            onClose={handleCloseError}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            message={errorMessage}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseError}
              >
                <Close fontSize="small" />
              </IconButton>
            }
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: theme.palette.red[500],
              },
            }}
          />
        </Portal>
      )}
    </>
  );
};
