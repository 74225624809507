import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    content: {
      width: "fit-content",
      borderBottom: `2px solid ${theme.palette.grayScale[0]}`,
      "&:hover": {
        cursor: "pointer",
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    headingTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    formatName: {
      width: "420px",
      padding: "8px",
      fontSize: "14px",
      border: `1px solid ${theme.palette.grayScale[300]}`,
      borderRadius: "4px",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grayScale[700],
    },
  }),
);
