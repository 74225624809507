import { reportContentPrintModalSlice } from "./slice";
import { AppDispatch } from "../store";

export const reportContentPrintModalOperations = {
  updateRequestedByName: (value: string) => (dispatch: AppDispatch) => {
    dispatch(reportContentPrintModalSlice.actions.updateRequestedByName({ value }));
  },
  updateActivityType: (value: string) => (dispatch: AppDispatch) => {
    dispatch(reportContentPrintModalSlice.actions.updateActivityType({ value }));
  },
  updateBehaviorType: (value: string) => (dispatch: AppDispatch) => {
    dispatch(reportContentPrintModalSlice.actions.updateBehaviorType({ value }));
  },
  updateReportedByName: (value: string) => (dispatch: AppDispatch) => {
    dispatch(reportContentPrintModalSlice.actions.updateReportedByName({ value }));
  },
  open: () => (dispatch: AppDispatch) => {
    dispatch(reportContentPrintModalSlice.actions.open());
  },
  close: () => (dispatch: AppDispatch) => {
    dispatch(reportContentPrintModalSlice.actions.close());
  },
};
