import { useState } from "react";
import { ConfirmDialog } from "components/molecules/confirm-dialog";
import { cookieRepository } from "data-access/cookie/cookie.repository";

interface Props {
  isOpen: boolean;
  handleYes: () => void;
  handleNo: () => void;
}

export const ScheduleDeleteConfirmDialog = (props: Props) => {
  const [isDeleteConfirmNotShowAgain, setIsDeleteConfirmNotShowAgain] = useState(false);

  const handleYes = () => {
    if (isDeleteConfirmNotShowAgain) {
      cookieRepository.set("isScheduleDeleteConfirmNotShowAgain", true);
    }
    props.handleYes();
  };

  return (
    <ConfirmDialog
      content="この予定を削除しますか？"
      summary="この予定はすべての参加者の予定から削除され、復元できません。"
      maxWidth="xs"
      yesButtonText="削除する"
      handleNo={props.handleNo}
      handleYes={handleYes}
      isOpen={props.isOpen}
      isConfirmNotShowAgain={true}
      yesButtonColor="error"
      handleConfirmedNotShowAgain={setIsDeleteConfirmNotShowAgain}
    />
  );
};
