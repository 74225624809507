import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

type Props = {
  maxHeight?: string;
  minHeight?: string;
};

export const styles = makeStyles(() =>
  createStyles({
    modal: {
      "& .MuiDialogContent-root": {
        border: "none",
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grayScale[0],
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      "& .MuiSvgIcon-root": {
        color: theme.palette.grayScale[0],
      },
    },
    content: {
      minHeight: (props: Props) => props.minHeight,
      maxHeight: (props: Props) => props.maxHeight,
    },
    footer: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
  }),
);
