import { Layout } from "components/templates/layout";
import { SalesAggregation } from "features/sales-aggregation/components";

export default function SalesAggregations() {
  return (
    <Layout>
      <SalesAggregation />
    </Layout>
  );
}
