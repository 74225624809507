import React from "react";
import { Edit } from "@mui/icons-material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Expense, ExpenseId, ExpenseUpdateRequest } from "features/cost/types/expense.dto";
import { styles } from "./styles";

interface Props {
  expense: Expense;
  setEdit: (id: ExpenseId, name: string, body: ExpenseUpdateRequest) => void;
}
export const ExpenseCard = ({ expense, setEdit }: Props) => {
  const classes = styles();

  return (
    <Box className={classes.card}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Tooltip title={expense.expenseItem.name} placement="top-start" arrow>
            <Typography className={classes.name}>{expense.expenseItem.name}</Typography>
          </Tooltip>
        </div>
        <IconButton
          onClick={() =>
            setEdit(expense.id, expense.expenseItem.name, {
              amount: expense.amount,
              note: expense.note,
            })
          }
        >
          <Edit fontSize="small" />
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div>
          {expense.note && (
            <Tooltip
              title={expense.note.split("\n").map((str: string, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <Typography fontSize="11px">
                      {str}
                      <br />
                    </Typography>
                  </React.Fragment>
                );
              })}
              placement="top"
              arrow
            >
              <DescriptionOutlinedIcon fontSize="small" />
            </Tooltip>
          )}
        </div>
        <Typography>¥{expense.amount.toLocaleString()}</Typography>
      </div>
    </Box>
  );
};
