import { useRef, useCallback } from "react";

// スクロール位置を保存するグローバルなオブジェクト
const scrollPositions: Record<string, number> = {};

export const useScrollToPosition = (key: string) => {
  const savedScrollY = useRef(scrollPositions[key]);

  const saveScrollPosition = useCallback(() => {
    savedScrollY.current = window.scrollY;
    scrollPositions[key] = savedScrollY.current;
  }, [key]);

  const scrollToSavedPosition = useCallback(
    (delay = 500) => {
      setTimeout(() => {
        window.scrollTo(0, scrollPositions[key]);
      }, delay);
    },
    [key],
  );

  return { savedScrollY, saveScrollPosition, scrollToSavedPosition };
};
