import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { TodoTagTypeLabel } from "components/label/todo-tag-type-label";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { ProjectTodo, ProjectTodoFormItem } from "data-access/repositories/project/todo/todo.dto";
import { TodoTagType } from "data-access/repositories/todo_tag_type/todo/todo_tag_type.dto";
import { theme } from "extensions/theme";
import { ProjectSidebarState } from "store/project-sidebar/slice";
import { styles } from "./styles";

interface Props {
  state: ProjectSidebarState;
  projectTodoTagTypes: TodoTagType[];
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
    id?: number | null,
  ) => void;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>, todoId: number) => void;
  onClickCreate: () => void;
  onClickDelete: (todoId: number, type: string) => void;
  onClickEditModeChange: (id: number, value: boolean) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, todoId: number) => void;
}

export const ProjectSidebarTodo = (props: Props) => {
  const {
    state,
    projectTodoTagTypes,
    onChange,
    onCheck,
    onClickCreate,
    onClickDelete,
    onClickEditModeChange,
    onBlur,
  } = props;
  const classes = styles();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isEdit = state.todoForm.some((todo) => todo.is_edit_mode);

  // 更新日時の降順で表示する
  const doneTodos = state.todoForm
    .filter((todo) => todo.is_done)
    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
  const undoneTodos = state.todoForm.filter((todo) => !todo.is_done);

  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "8px",
          }}
        >
          <CustomFormLabel labelName="TODO" sx={{ mb: 0 }} />
          <Button
            variant="contained"
            onClick={onClickCreate}
            disabled={!state.isExisting || isEdit}
            sx={{ height: "34px", borderRadius: "16px", py: 1, px: 2, fontSize: "12px" }}
          >
            TODOを追加
          </Button>
        </Box>

        {/* 未完了のTodos */}
        {undoneTodos.length === 0 && (
          <Typography sx={{ fontSize: "14px" }}>TODOはありません</Typography>
        )}
        {undoneTodos.map((todo: ProjectTodoFormItem) => (
          <Box
            key={todo.id}
            sx={{
              display: "flex",
              border: `1px solid ${theme.palette.grayScale[300]}`,
              borderRadius: "4px",
              position: "relative",
              p: "12px",
              mb: "12px",
            }}
          >
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    id="isTodoDone"
                    name="isTodoDone"
                    sx={{ p: 0 }}
                    onChange={(e) => onCheck(e, todo.id)}
                    checked={todo.is_done}
                  />
                }
                label={
                  <Typography component="span" sx={{ fontSize: "11px" }}>
                    完了
                  </Typography>
                }
                labelPlacement="top"
                className={classes.checkbox}
              />
              <Box sx={{ width: "100%", ml: "11px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "40px",
                    alignItems: "center",
                    mb: "5px",
                  }}
                >
                  <Select
                    id="todoTagType"
                    name="todoTagType"
                    variant="standard"
                    className={classes.selectBox}
                    value={todo.tag_type.value}
                    onChange={(e) => onChange(e, todo.id)}
                    sx={{
                      width: "auto",
                      height: "40px",
                    }}
                  >
                    {projectTodoTagTypes.map((tagType: TodoTagType) => (
                      <MenuItem key={tagType.value} value={tagType.value}>
                        <TodoTagTypeLabel todoTagType={tagType.value_i18n} />
                      </MenuItem>
                    ))}
                  </Select>
                  <IconButton onClick={() => onClickDelete(todo.id, "projectTodo")} sx={{ p: 0 }}>
                    <DeleteIcon color={theme.palette.grayScale[700]} size={20} />
                  </IconButton>
                </Box>
                {todo.is_edit_mode ? (
                  <TextField
                    id="todoContent"
                    name="todoContent"
                    placeholder="詳細メモを入力"
                    value={todo.content}
                    autoFocus
                    onChange={(e) => onChange(e, todo.id)}
                    onBlur={(e) => onBlur(e, todo.id)}
                    multiline
                    inputProps={{ style: { fontSize: 14 } }}
                    sx={{ width: "100%", "& .MuiInputBase-root": { p: "8px" } }}
                  />
                ) : (
                  <Box onClick={() => onClickEditModeChange(todo.id, true)}>
                    {todo.content.length === 0 ? (
                      <Typography
                        fontSize={"12px"}
                        sx={{ mb: "5px", color: theme.palette.grayScale[700] }}
                      >
                        詳細メモを入力
                      </Typography>
                    ) : (
                      <Typography
                        fontSize={"12px"}
                        sx={{
                          mb: "5px",
                          cursor: "pointer",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          display: "-webkit-box",
                          "-webkit-box-orient": "vertical",
                          "-webkit-line-clamp": "2",
                        }}
                      >
                        {todo.content}
                      </Typography>
                    )}
                  </Box>
                )}
                {/* </>
                )} */}
                <Typography fontSize={"11px"} sx={{ mt: "8px", textAlign: "right" }}>
                  作成:{todo.created_by?.name}
                </Typography>
              </Box>
            </>
          </Box>
        ))}
      </div>

      {/* 完了済みのTodos */}
      <AccordionToggle
        title="完了済みのTODO"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isDisabled={doneTodos.length === 0}
        titleStyle={{ fontWeight: "500", color: theme.palette.grayScale[700] }}
      />
      {doneTodos.length === 0 && (
        <Typography sx={{ fontSize: "14px", mt: "8px" }}>完了済みのTODOはありません</Typography>
      )}
      {isExpanded &&
        doneTodos.map((todo: ProjectTodo) => (
          <Box
            key={todo.id}
            sx={{
              display: "flex",
              border: `1px solid ${theme.palette.grayScale[300]}`,
              borderRadius: "4px",
              p: "12px",
              my: "12px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  id="isTodoUndone"
                  name="isTodoUndone"
                  sx={{
                    p: 0,
                    "&.Mui-checked": {
                      color: theme.palette.grayScale[700],
                    },
                  }}
                  onChange={(e) => onCheck(e, todo.id)}
                  checked={todo.is_done}
                />
              }
              label={
                <Typography component="span" sx={{ fontSize: "11px" }}>
                  完了
                </Typography>
              }
              labelPlacement="top"
              className={classes.checkbox}
            />
            <Box sx={{ width: "100%", ml: "11px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "40px",
                  alignItems: "center",
                  mb: "5px",
                }}
              >
                <TodoTagTypeLabel
                  todoTagType={todo.tag_type.value_i18n}
                  sx={{
                    backgroundColor: theme.palette.grayScale[100],
                    color: theme.palette.grayScale[700],
                  }}
                />
              </Box>
              <Typography fontSize={"12px"} sx={{ mb: "5px" }}>
                <s>{todo.content}</s>
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Typography sx={{ fontSize: "11px", mr: "16px" }}>
                  作成：{todo.created_by?.name}
                </Typography>
                <Typography fontSize={"11px"}>完了：{todo.completed_by?.name}</Typography>
              </Box>
            </Box>
          </Box>
        ))}
    </>
  );
};
