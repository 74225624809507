import { Box } from "@mui/material";
import { styles } from "./styles";

interface Props {
  todoTagType: string;
  sx?: object;
}

export const TodoTagTypeLabel = (props: Props) => {
  const classes = styles();

  const ClassName = (statusType: string) => {
    switch (statusType) {
      case "日程調整":
        return classes.scheduleAdjustment;
      case "見積提出":
        return classes.estimateSubmission;
      case "部材発注":
        return classes.materialOrdering;
      case "協力会社発注":
        return classes.partnerOrder;
      case "図面作成":
        return classes.drawingCreation;
      case "現場調査":
        return classes.sitePreview;
      case "その他":
      default:
        return classes.default;
    }
  };
  return (
    <Box
      component="span"
      className={ClassName(props.todoTagType)}
      sx={{
        fontSize: "14px",
        borderRadius: "4px",
        p: "6px 10px",
        fontWeight: "500",
        lineHeight: "13px",
        textAlign: "center",
        height: "26px",
        ...props.sx,
      }}
    >
      {props.todoTagType}
    </Box>
  );
};
