import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Layout } from "components/templates/layout";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { ClientSidebar } from "pages/client/sidebar";
import { ClientTable } from "pages/client/table";
import useSWR from "swr";
import { ClientTableHeader } from "./table-header";

export const Client = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);

  const fetchIndexKey = `/api/v1/clients?page=${page}`;
  const { data, mutate, isValidating } = useSWR(fetchIndexKey, () =>
    clientRepository.index({
      page,
      keyword,
    }),
  );

  useEffect(() => {
    // 顧客の新規作成
    if (new RegExp(`^/clients/new$`).test(location.pathname)) {
      setIsSidebarOpen(true);
      setIsCreateNew(true);
    }
    // 顧客の新規作成 (案件作成画面から)
    if (new RegExp(`^/clients/new/project/\\d+$`).test(location.pathname)) {
      setIsSidebarOpen(true);
      setIsCreateNew(true);
    }
    // 顧客編集
    if (new RegExp(`^/clients/\\d+$`).test(location.pathname)) {
      setIsSidebarOpen(true);
      setIsCreateNew(false);
    }
  }, [location]);

  const handleSubmit = () => {
    mutate();
  };

  const handleClickCreate = () => {
    navigate("/clients/new");
  };

  const handleClose = () => {
    setIsSidebarOpen(false);
    navigate("/clients");
  };
  return (
    <Layout>
      <Box>
        <ClientTableHeader
          keyword={keyword}
          setKeyword={setKeyword}
          onClick={handleClickCreate}
          onSubmit={handleSubmit}
        />
        <ClientTable page={page} setPage={setPage} data={data} isLoading={isValidating} />

        <ClientSidebar
          isOpen={isSidebarOpen}
          onClose={handleClose}
          isCreateNew={isCreateNew}
          fetchIndexKey={fetchIndexKey}
        />
      </Box>
    </Layout>
  );
};
