import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { userPasswordRepository } from "data-access/repositories/user/password/password.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";

export const ChangePasswordTab = () => {
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      await userPasswordRepository.updateWithAuth(password);
      dispatch(mainOperations.updateSuccessMessage("パスワードを再設定しました。"));
    } catch (e) {
      dispatch(mainOperations.updateErrorMessage(e.response.data.message));
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <h2>パスワードを再設定する</h2>
      <Typography>
        推測しにくいパスワードをお奨めします。また、パスワードの使い回しはご遠慮ください。
      </Typography>
      <TextField
        id="password"
        name="password"
        label="新しいパスワード"
        variant="outlined"
        onChange={handleChange}
        value={password}
        type={showPassword ? "text" : "password"}
        sx={{ width: "600px", mt: "30px", mb: "15px" }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div>
        <Typography sx={{ fontSize: "15px", color: `${theme.palette.grayScale[700]}` }}>
          パスワードは6文字以上が必要です
        </Typography>
        <Typography sx={{ fontSize: "15px", color: `${theme.palette.grayScale[700]}` }}>
          半角英数字記号が使用可能です
        </Typography>
        <Typography sx={{ fontSize: "15px", color: `${theme.palette.grayScale[700]}` }}>
          英小文字を1字以上含める必要があります
        </Typography>
      </div>
      <Button
        onClick={handleSubmit}
        variant="contained"
        sx={{
          marginTop: "30px",
          height: "40px",
          width: "200px",
        }}
      >
        パスワードを再設定する
      </Button>
    </>
  );
};
