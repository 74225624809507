import { makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() => ({
  menuItem: {
    width: "fit-content",
    borderBottom: `2px solid ${theme.palette.grayScale[0]}`,
    "&:hover": {
      cursor: "pointer",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    "&.MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "500",
    },
  },
  content: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    marginLeft: "16px",
  },
}));
