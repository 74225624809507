import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "extensions/theme";

export const styles = makeStyles<Theme, { isClearable: boolean }>(() => ({
  box: {
    width: (props) => (props.isClearable ? "160px" : "180px"),
    "& .react-datepicker__close-icon::after": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      right: "4px",
      top: "5px",
    },
    "& .react-datepicker__close-icon": {
      marginRight: "40px",
    },
  },
  dateBlock: {
    width: (props) => (props.isClearable ? "160px" : "180px"),
    height: "46px",
    backgroundColor: theme.palette.customPrimary[50],
    border: "none",
    borderRadius: "5px",
    padding: "8px !important",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
  },
  saturday: {
    color: theme.palette.blue[500],
    "&.react-datepicker__day--selected": {
      color: theme.palette.grayScale[0],
    },
  },
  sunday: {
    color: theme.palette.red[500],
    "&.react-datepicker__day--selected": {
      color: theme.palette.grayScale[0],
    },
  },
}));
