export const MicrosoftIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14740_1586)">
        <path d="M5.70309 5.70309H0V0H5.70309V5.70309Z" fill="#F1511B" />
        <path d="M11.9999 5.70309H6.29688V0H11.9999V5.70309Z" fill="#80CC28" />
        <path d="M5.70295 12.0002H0V6.29712H5.70295V12.0002Z" fill="#00ADEF" />
        <path d="M11.9999 12.0002H6.29688V6.29712H11.9999V12.0002Z" fill="#FBBC09" />
      </g>
      <defs>
        <clipPath id="clip0_14740_1586">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
