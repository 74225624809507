import { Brand } from "data-access/repositories/brand";
import { SubscriberType } from "../index.dto";

export type NoticeConfirmedScheduleAssignmentChangeId = Brand<
  number,
  "NoticeConfirmedScheduleAssignmentChangeId"
>;

export type NoticeConfirmedScheduleAssignmentChangeIndexResponse =
  NoticeConfirmedScheduleAssignmentChange;

export interface NoticeConfirmedScheduleAssignmentChange {
  id: NoticeConfirmedScheduleAssignmentChangeId;
  subscriberTypes: SubscriberType[];
  isEnable: boolean;
}

export interface NoticeConfirmedScheduleAssignmentChangeUpdateRequest {
  subscriberTypes?: SubscriberType[];
  isEnable?: boolean;
}

export const initialNoticeConfirmedScheduleAssignmentChange: NoticeConfirmedScheduleAssignmentChange =
  {
    id: 0 as NoticeConfirmedScheduleAssignmentChangeId,
    subscriberTypes: [],
    isEnable: false,
  };
