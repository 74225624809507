import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    monthPicker: {
      "& input": {
        border: "1px solid rgba(4, 74, 88, 0.5)",
        color: theme.palette.primary.main,
        borderRadius: "0.2rem",
        padding: "0.4rem",
        fontSize: "0.857rem",
        width: "12.4rem",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        cursor: "pointer",
      },
      "& .react-datepicker__close-icon::after": {
        backgroundColor: theme.palette.primary.main,
      },
      "& .react-datepicker-popper": {
        zIndex: 9,
      },
    },
    tableHeader: {
      "& .MuiInputBase-root": {
        height: "30px",
        fontSize: "14px",
        width: "13rem",
        color: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.primary.main} `,
        opacity: ".5",
      },
    },
  }),
);
