import { WorkReport } from "components/organisms/work-report";
import { Layout } from "components/templates/layout/index";

export default function WorkReports() {
  return (
    <Layout>
      <WorkReport />
    </Layout>
  );
}
