import { Typography } from "@mui/material";

interface Props {
  bgColor: string;
  label: string;
}
export const LegendLabel = ({ bgColor, label }: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <div
        style={{ width: "16px", height: "10px", borderRadius: "4px", backgroundColor: bgColor }}
      />
      <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>{label}</Typography>
    </div>
  );
};
