import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import { SubscriberType, subscriberTypes } from "data-access/repositories/notice/index.dto";
import { NoticeNextDayScheduleSummaryIndexResponse } from "data-access/repositories/notice/schedule/next_day_schedule_summary.dto";
import { nextDayScheduleSummaryRepository } from "data-access/repositories/notice/schedule/next_day_schedule_summary.repository";
import { ScheduleTypeId } from "data-access/repositories/schedule_type/schedule_type.dto";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { styles } from "./styles";

const STypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "500",
  marginBottom: "8px",
});

interface Props {
  data: NoticeNextDayScheduleSummaryIndexResponse;
  fetchIndexKey: string;
  setIsLoading: (v: boolean) => void;
}

export const NextDayScheduleSummary = (props: Props) => {
  const { data, fetchIndexKey, setIsLoading } = props;
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const classes = styles();
  const [formState, setFormState] = useState<NoticeNextDayScheduleSummaryIndexResponse>([]);

  useEffect(() => {
    if (data) {
      setFormState(
        data.map((nextDayScheduleSummary) => {
          return {
            ...nextDayScheduleSummary,
            // 予定タイプが空配列の場合はすべての予定タイプを選択
            scheduleTypeIdsForFilter: nextDayScheduleSummary.scheduleTypeIdsForFilter.length
              ? nextDayScheduleSummary.scheduleTypeIdsForFilter
              : mainState.scheduleTypes.map((scheduleType) => scheduleType.id),
          };
        }),
      );
    }
  }, [data]);

  const handleChangeForTime = async (date: Date | null, index: number) => {
    if (!date) return;
    setIsLoading(true);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const convertTime = `${hours}:${minutes}`;

    try {
      await nextDayScheduleSummaryRepository.update(formState[index].id, {
        ...formState[index],
        noticeTime: convertTime,
      });
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("通知ルールを更新しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeForSubscriberTypeSelect = (
    e: SelectChangeEvent<SubscriberType[]>,
    index: number,
  ) => {
    const { name, value } = e.target;
    if (!value.length) return;

    setFormState((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value,
      };
      return newState;
    });
  };

  const handleChangeForScheduleTypeSelect = (
    e: SelectChangeEvent<ScheduleTypeId[]>,
    index: number,
    targetInfo: any,
  ) => {
    const { name, value } = e.target;

    setFormState((prevState) => {
      const newState = [...prevState];
      const allScheduleTypeIds = mainState.scheduleTypes.map((scheduleType) => scheduleType.id);

      if (!value.length) {
        // valueが空の場合、すべてのScheduleTypeを選択
        newState[index] = {
          ...newState[index],
          [name]: allScheduleTypeIds,
        };
      } else if (newState[index].scheduleTypeIdsForFilter.length === allScheduleTypeIds.length) {
        // 現在の選択状態がすべてなら、選択したもののみを選択状態にする
        newState[index] = {
          ...newState[index],
          [name]: [targetInfo.props.value],
        };
      } else {
        newState[index] = {
          ...newState[index],
          [name]: value,
        };
      }

      return newState;
    });
  };

  const handleBlur = async (_: React.SyntheticEvent, index: number) => {
    setIsLoading(true);
    // すべての予定タイプが選択されている場合は空配列に変換
    const convertScheduleTypeIdsForFilter =
      formState[index].scheduleTypeIdsForFilter.length === mainState.scheduleTypes.length
        ? []
        : formState[index].scheduleTypeIdsForFilter;

    try {
      await nextDayScheduleSummaryRepository.update(formState[index].id, {
        ...formState[index],
        scheduleTypeIdsForFilter: convertScheduleTypeIdsForFilter,
      });
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("通知ルールを更新しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { checked } = e.target;
    setIsLoading(true);
    try {
      await nextDayScheduleSummaryRepository.update(formState[index].id, {
        isEnable: checked,
      });
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("通知ルールを更新しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsLoading(false);
    }
  };

  const isSelectedScheduleType = (scheduleTypeId: ScheduleTypeId, index: number) => {
    return formState[index].scheduleTypeIdsForFilter.find(
      (scheduleTypeIdForFilter) => scheduleTypeIdForFilter === scheduleTypeId,
    );
  };

  const selectedScheduleTypesLabel = (selectedScheduleTypeIds: ScheduleTypeId[]) => {
    if (selectedScheduleTypeIds.length === mainState.scheduleTypes.length) return "すべて";
    return mainState.scheduleTypes
      .filter((scheduleType) => selectedScheduleTypeIds.includes(scheduleType.id))
      .map((scheduleType) => scheduleType.name)
      .join(", ");
  };

  const isSelectedSubscriberType = (subscriberType: SubscriberType, index: number) => {
    return formState[index].subscriberTypes.find(
      (noticeSubscriberType) => noticeSubscriberType === subscriberType,
    );
  };

  const selectedSubscriberTypesLabel = (selectedSubscriberTypes: SubscriberType[]) => {
    return subscriberTypes
      .filter((subscriberType) => selectedSubscriberTypes.includes(subscriberType.value))
      .map((subscriberType) => subscriberType.name)
      .join(", ");
  };

  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <Typography sx={{ fontWeight: "bold", mb: "16px" }}>前日の決まった時刻に通知</Typography>
        {formState.length ? (
          <div style={{ marginLeft: "20px" }}>
            {formState.map((notice, index) => (
              <React.Fragment key={index}>
                <div style={{ display: "flex", alignItems: "flex-end", gap: "24px" }}>
                  <div>
                    <STypography>予定タイプ</STypography>
                    <Select
                      name="scheduleTypeIdsForFilter"
                      value={notice.scheduleTypeIdsForFilter}
                      onChange={(e, targetInfo) =>
                        handleChangeForScheduleTypeSelect(e, index, targetInfo)
                      }
                      onClose={(_) => handleBlur(_, index)}
                      multiple
                      sx={{ width: "200px", height: "40px" }}
                      renderValue={(values) => selectedScheduleTypesLabel(values)}
                    >
                      {mainState.scheduleTypes.map((scheduleType) => (
                        <MenuItem key={scheduleType.id} value={scheduleType.id}>
                          {isSelectedScheduleType(scheduleType.id, index) ? (
                            <CheckIcon color="primary" sx={{ mr: 1 }} />
                          ) : (
                            <Box sx={{ px: 1.5, mr: 1 }} />
                          )}
                          {scheduleType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <STypography>タイミング（前日）</STypography>
                    <DatePicker
                      // 時刻だけ関係しているため年月日は固定
                      selected={new Date(`2024-01-01T${notice.noticeTime}`)}
                      onChange={(date) => handleChangeForTime(date, index)}
                      className={classes.date}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="時刻"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div>
                    <STypography>通知方法</STypography>
                    <Select
                      name="subscriberTypes"
                      value={notice.subscriberTypes}
                      onChange={(e) => handleChangeForSubscriberTypeSelect(e, index)}
                      onClose={(_) => handleBlur(_, index)}
                      multiple
                      sx={{ width: "200px", height: "40px" }}
                      renderValue={(values) => selectedSubscriberTypesLabel(values)}
                    >
                      {subscriberTypes.map((subscriberType) => (
                        <MenuItem key={subscriberType.value} value={subscriberType.value}>
                          {isSelectedSubscriberType(subscriberType.value, index) ? (
                            <CheckIcon color="primary" sx={{ mr: 1 }} />
                          ) : (
                            <Box sx={{ px: 1.5, mr: 1 }} />
                          )}
                          {subscriberType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <Switch
                      name="isEnable"
                      checked={notice.isEnable}
                      onChange={(e) => handleToggle(e, index)}
                      color="primary"
                    />
                  </div>
                </div>
                <Divider sx={{ my: "12px" }} />
              </React.Fragment>
            ))}
          </div>
        ) : (
          <Typography fontSize="14px">通知ルールが設定されていません。</Typography>
        )}
      </div>
    </>
  );
};
