import { ApiClient } from "data-access/ApiClient";

export const iCalRepository = {
  show(): Promise<{ url: string }> {
    return ApiClient.get("/api/v1/schedules.ical/url").then((res) => res.data);
  },
  reset(): Promise<void> {
    return ApiClient.delete("/api/v1/schedules.ical/url").then((res) => res.data);
  },
};
