import { ApiClient } from "data-access/ApiClient";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  ExpenseCreateRequest,
  ExpenseId,
  ExpenseIndexResponse,
  ExpenseUpdateRequest,
} from "../types/expense.dto";

export const expenseRepository = {
  index(projectId: ProjectId): Promise<ExpenseIndexResponse> {
    return ApiClient.get(`/api/v1/projects/${projectId}/expenses`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(projectId: ProjectId, body: ExpenseCreateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post(
      `/api/v1/projects/${projectId}/expenses`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  update(projectId: ProjectId, id: ExpenseId, body: ExpenseUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(
      `/api/v1/projects/${projectId}/expenses/${id}`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  destroy(projectId: ProjectId, id: ExpenseId): Promise<void> {
    return ApiClient.delete(`/api/v1/projects/${projectId}/expenses/${id}`).then((res) => res.data);
  },
};
