import { useState } from "react";
import { Box } from "@mui/material";
import { CustomTabs } from "components/molecules/custom-tabs";
import { Calendar } from "components/organisms/user-settings/calendar";
import { Project } from "components/organisms/user-settings/project";
import { ChangePasswordTab } from "components/organisms/user-settings/reset-password";
import { Layout } from "components/templates/layout";

type TabType = "calendar" | "project" | "changePassword";

const UserSettings = () => {
  const [tab, setTab] = useState<TabType>("calendar");

  return (
    <Layout>
      <Box>
        <CustomTabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: TabType) => setTab(newValue)}
          tabs={[
            { value: "calendar", label: "予定" },
            { value: "project", label: "案件" },
            { value: "changePassword", label: "パスワード再設定" },
          ]}
        />
      </Box>

      {tab === "calendar" && <Calendar />}
      {tab === "project" && <Project />}
      {tab === "changePassword" && <ChangePasswordTab />}
    </Layout>
  );
};

export default UserSettings;
