import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { theme } from "extensions/theme";
import Logo from "images/gembahub.svg";
export const ErrorPage500 = () => {
  const navigate = useNavigate();
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <img src={Logo} alt="logo" style={{ width: 320, marginBottom: 40 }} />
      <Typography
        fontSize={32}
        sx={{ fontWeight: "700", color: theme.palette.primary.main, mb: "40px" }}
      >
        ページが表示できません
      </Typography>
      <Typography sx={{ textAlign: "center", mb: "40px" }}>
        申し訳ありません。現在このページを表示することができません。
        <br />
        しばらく時間を置いてもご覧いただけない場合、お手数ですが
        <a href="mailto:support@artisan.inc">現場Hubカスタマーサポート</a>までお問い合わせください。
        <br />
        エラーコード：500
      </Typography>
      <Button sx={{ width: "213px" }} onClick={() => navigate("/projects")} variant="contained">
        案件一覧に戻る
      </Button>
    </Box>
  );
};
