import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { SupplierRequest, SupplierIndexResponse, SupplierId } from "../types/supplier.dto";

export const supplierRepository = {
  index(): Promise<SupplierIndexResponse> {
    return ApiClient.get("/api/v1/suppliers").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(body: SupplierRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/suppliers", createBody(body), config).then((res) => res.data);
  },
  update(id: SupplierId, body: SupplierRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/suppliers/${id}`, createBody(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: SupplierId): Promise<void> {
    return ApiClient.delete(`/api/v1/suppliers/${id}`).then((res) => res.data);
  },
};

const createBody = (body: SupplierRequest) => {
  return {
    name: body.name,
    is_default_cost: body.isDefaultCost,
    display_order_position: body.displayOrderPosition,
  };
};
