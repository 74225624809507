import { PostalCodeFromAddressResponse } from "./postal_code_from_address.dto";
import { ApiClient } from "../../ApiClient";

export const postalCodeRepository = {
  index(address: string): Promise<PostalCodeFromAddressResponse> {
    const params = {
      address,
    };
    return ApiClient.get("/api/v1/postal_codes/postal_code_from_address", {
      params,
    }).then((res) => {
      return res.data;
    });
  },
};
