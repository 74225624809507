import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ClientServiceReportFormatsResponse } from "../types/client_service_report_format.dto";

export const clientServiceReportFormatRepository = {
  index(): Promise<ClientServiceReportFormatsResponse> {
    return ApiClient.get("/api/v1/companies/client_service_report_formats").then((res) => {
      return convertKeysToCamelCase(res.data) as ClientServiceReportFormatsResponse;
    });
  },
};
