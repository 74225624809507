import { useMemo, useState } from "react";
import { Close, ContentCopy } from "@mui/icons-material";
import {
  Button,
  TextField,
  Typography,
  Box,
  InputAdornment,
  Tooltip,
  AutocompleteChangeReason,
} from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { DeleteIcon } from "components/icon/delete-icon";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { BuildingId, BuildingRequest } from "data-access/repositories/building/building.dto";
import { buildingRepository } from "data-access/repositories/building/building.repository";
import { Client } from "data-access/repositories/client/client.dto";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { openURLInNewTab } from "utils/openURLInNewTab";

interface Props {
  onClose: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onDelete: (id: BuildingId) => void;
  isCreateNew: boolean;
  formState: BuildingRequest;
  setFormState: React.Dispatch<React.SetStateAction<BuildingRequest>>;
  clients: Client[];
  buildingId: BuildingId;
  fetchIndexKey: string;
}

export const BuildingSidebarHeader = (props: Props) => {
  const {
    onClose,
    onChange,
    onBlur,
    onDelete,
    isCreateNew,
    formState,
    setFormState,
    clients,
    buildingId,
    fetchIndexKey,
  } = props;
  const dispatch = useAppDispatch();
  const [buildingNameTip, setBuildingNameTip] = useState<string>("物件名をコピーします");

  const handleOpenNameTip = () => {
    setBuildingNameTip("物件名をコピーします");
  };
  const copyBuildingNameToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`${formState.name}`);
      setBuildingNameTip("コピーしました");
    } catch (error: any) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  const selectedClient = useMemo(() => {
    if (clients.length === 0) return [];
    return clients.filter((client) => client.id === formState.clientId);
  }, [formState.clientId, clients]);

  const handleChange = async (
    e: React.SyntheticEvent,
    value: Client,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === "selectOption") {
      try {
        await buildingRepository.update(buildingId, { clientId: value.id });
        setFormState({ ...formState, clientId: value.id });
        mutate(fetchIndexKey);
        dispatch(mainOperations.updateSuccessMessage("顧客を変更しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
    if (reason === "clear") {
      try {
        await buildingRepository.update(buildingId, { clientId: null });
        setFormState({ ...formState, clientId: null });
        mutate(fetchIndexKey);
        dispatch(mainOperations.updateSuccessMessage("顧客を変更しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const handleCreate = () => {
    openURLInNewTab("clients/new");
  };

  const customFilterOptions = (options: Client[], { inputValue }: { inputValue: string }) => {
    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phone_number.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phone_number_second.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        pt: "70px",
        backgroundColor: theme.palette.grayScale[0],
        zIndex: "2",
        boxShadow: "0px 5px 5px -5px rgba(0,0,0,0.5)",
        mb: "20px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <Button
          startIcon={<Close />}
          onClick={onClose}
          sx={{ color: theme.palette.grayScale[700] }}
        >
          とじる
        </Button>
        <Button
          startIcon={
            <DeleteIcon size={20} color={isCreateNew ? theme.palette.grayScale[700] : ""} />
          }
          onClick={() => onDelete(buildingId)}
          color="error"
          disabled={isCreateNew}
        >
          削除する
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          gap: "24px",
        }}
      >
        <div style={{ width: "100%" }}>
          <CustomFormLabel labelName="物件名" />
          <TextField
            id="name"
            name="name"
            sx={{ width: "100%" }}
            value={formState.name || ""}
            onChange={onChange}
            onBlur={onBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={buildingNameTip} onOpen={handleOpenNameTip} placement="top" arrow>
                    <Button
                      startIcon={<ContentCopy />}
                      onClick={copyBuildingNameToClipboard}
                      color="primary"
                      disabled={isCreateNew}
                      sx={{ p: 0, justifyContent: "end" }}
                    />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </div>
        <div>
          <CustomFormLabel labelName="顧客" />
          {isCreateNew ? (
            <Typography sx={{ mt: "16px" }}>新規作成時は選択できません</Typography>
          ) : (
            <CustomAutocomplete
              data={clients}
              value={selectedClient[0] || null}
              entityName="顧客"
              onChange={handleChange}
              onClick={handleCreate}
              isCreate
              isClearable
              entityLabel="顧客を検索または選択"
              filterOptions={customFilterOptions}
              sx={{ width: "300px" }}
            />
          )}
        </div>
      </div>
    </Box>
  );
};
