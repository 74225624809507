import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    dateRangePicker: {
      boxShadow: "0 10px 25px 0 rgba(0, 0, 0, .5)",
      "& .rdrInputRanges": {
        display: "none",
      },
      "& .rdrInRange, & .rdrStartEdge, & .rdrEndEdge": {
        backgroundColor: theme.palette.primary.main,
      },
      "& .rdrStaticRangeLabel": {
        color: theme.palette.text.primary,
      },
    },
  }),
);
