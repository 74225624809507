import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    scheduleBlock: {
      cursor: "pointer",
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "5px",
      marginTop: "16px",
      padding: "12px 10px",
    },
    scheduleName: {
      flex: 1,
      fontSize: "16px",
      fontWeight: "bold",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "2",
    },
    note: {
      fontSize: "12px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "1",
    },
  }),
);
