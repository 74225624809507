import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "../../../extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    notYet: {
      backgroundColor: theme.palette.red[500],
    },
    done: {
      backgroundColor: theme.palette.primary.main,
    },
    part: {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
);
