import { scheduleDeleteConfirmDialogSlice } from "./slice";
import { AppDispatch } from "../store";

export const scheduleDeleteConfirmDialogOperations = {
  setObject: (value: { id: number; name: string }) => (dispatch: AppDispatch) => {
    dispatch(scheduleDeleteConfirmDialogSlice.actions.setObject({ value }));
  },
  updateIsDeleteConfirmNotShowAgain: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(scheduleDeleteConfirmDialogSlice.actions.updateIsDeleteConfirmNotShowAgain({ value }));
  },
  open: () => (dispatch: AppDispatch) => {
    dispatch(scheduleDeleteConfirmDialogSlice.actions.open());
  },
  close: () => (dispatch: AppDispatch) => {
    dispatch(scheduleDeleteConfirmDialogSlice.actions.close());
  },
};
