import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SearchTextField } from "components/molecules/search-text-field";
import { theme } from "extensions/theme";

const CustomizedTableHeader = styled(Box)({
  "& .MuiInputBase-root": {
    height: "1.928rem",
    fontSize: "0.857rem",
    width: "13rem",
    color: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.primary.main} `,
    opacity: ".5",
  },
});

interface Props {
  keyword: string;
  setKeyword: (e: string) => void;
  onClick: () => void;
  onSubmit: () => void;
}
export const ClientTableHeader = (props: Props) => {
  const { keyword, setKeyword, onClick, onSubmit } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setKeyword(e.target.value as string);
  };

  return (
    <CustomizedTableHeader
      sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
    >
      <form
        onSubmit={(e) => {
          onSubmit();
          e.preventDefault();
        }}
      >
        <SearchTextField
          id="keyword"
          name="keyword"
          value={keyword}
          onChange={handleChange}
          onSubmit={onSubmit}
        />
      </form>
      <Button
        variant="contained"
        startIcon={<AddCircleIcon />}
        onClick={onClick}
        sx={{ minWidth: "12rem" }}
      >
        顧客を新規作成
      </Button>
    </CustomizedTableHeader>
  );
};
