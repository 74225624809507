import { useState } from "react";
import { PrimaryCategoryId } from "data-access/repositories/project/primary_category/primary_category.dto";
import { primaryCategoryRepository } from "data-access/repositories/project/primary_category/primary_category.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { SecondaryCategoryId } from "data-access/repositories/project/secondary_category/secondary_category.dto";
import { secondaryCategoryRepository } from "data-access/repositories/project/secondary_category/secondary_category.repository";
import { TertiaryCategoryId } from "data-access/repositories/project/tertiary_category/tertiary_category.dto";
import { tertiaryCategoryRepository } from "data-access/repositories/project/tertiary_category/tertiary_category.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { handleError } from "utils/errorHandler";

export const useCategoryUpdate = (
  projectId: ProjectId,
  selectedPrimaryCategoryId: PrimaryCategoryId,
  primaryCategoryMutate: () => void,
  secondaryCategoryMutate: () => void,
) => {
  const dispatch = useAppDispatch();

  const [editingTargetPrimaryCategoryId, setEditingPrimaryCategoryId] =
    useState<PrimaryCategoryId | null>(null);
  const [editingPrimaryCategoryName, setEditingPrimaryCategoryName] = useState<string>("");
  const [editingTargetSecondaryCategoryId, setEditingSecondaryCategoryId] =
    useState<SecondaryCategoryId | null>(null);
  const [editingSecondaryCategoryName, setEditingSecondaryCategoryName] = useState<string>("");
  const [editingTargetTertiaryCategoryId, setEditingTertiaryCategoryId] =
    useState<TertiaryCategoryId | null>(null);
  const [editingTertiaryCategoryName, setEditingTertiaryCategoryName] = useState<string>("");

  const handleEditPrimaryCategory = (primaryCategoryId: PrimaryCategoryId, name: string) => {
    setEditingPrimaryCategoryId(primaryCategoryId);
    setEditingPrimaryCategoryName(name);
  };

  const handleEditSecondaryCategory = (secondaryCategoryId: SecondaryCategoryId, name: string) => {
    setEditingSecondaryCategoryId(secondaryCategoryId);
    setEditingSecondaryCategoryName(name);
  };

  const handleEditTertiaryCategory = (tertiaryCategoryId: TertiaryCategoryId, name: string) => {
    setEditingTertiaryCategoryId(tertiaryCategoryId);
    setEditingTertiaryCategoryName(name);
  };

  const handleBlurPrimaryCategory = async () => {
    if (!editingTargetPrimaryCategoryId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await primaryCategoryRepository.update(projectId, editingTargetPrimaryCategoryId, {
        name: editingPrimaryCategoryName,
      });
      primaryCategoryMutate();
      dispatch(mainOperations.updateSuccessMessage("大項目を更新しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "大項目の更新に失敗しました",
      );
    } finally {
      setEditingPrimaryCategoryId(null);
      setEditingPrimaryCategoryName("");
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleBlurSecondaryCategory = async (
    _: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ) => {
    if (!editingTargetSecondaryCategoryId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await secondaryCategoryRepository.update(projectId, primaryCategoryId, secondaryCategoryId, {
        name: editingSecondaryCategoryName,
      });
      primaryCategoryMutate();
      secondaryCategoryMutate();
      dispatch(mainOperations.updateSuccessMessage("中項目を更新しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "中項目の更新に失敗しました",
      );
    } finally {
      setEditingSecondaryCategoryId(null);
      setEditingSecondaryCategoryName("");
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleBlurTertiaryCategory = async (
    _: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    secondaryCategoryId: SecondaryCategoryId,
  ) => {
    if (!editingTargetTertiaryCategoryId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await tertiaryCategoryRepository.update(
        projectId,
        selectedPrimaryCategoryId,
        secondaryCategoryId,
        editingTargetTertiaryCategoryId,
        { name: editingTertiaryCategoryName },
      );
      secondaryCategoryMutate();
      dispatch(mainOperations.updateSuccessMessage("小項目を更新しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "小項目の更新に失敗しました",
      );
    } finally {
      setEditingTertiaryCategoryId(null);
      setEditingTertiaryCategoryName("");
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return {
    editingTargetPrimaryCategoryId,
    editingPrimaryCategoryName,
    editingTargetSecondaryCategoryId,
    editingSecondaryCategoryName,
    editingTargetTertiaryCategoryId,
    editingTertiaryCategoryName,
    handleEditPrimaryCategory,
    handleEditSecondaryCategory,
    handleEditTertiaryCategory,
    handleBlurPrimaryCategory,
    handleBlurSecondaryCategory,
    handleBlurTertiaryCategory,
    setEditingPrimaryCategoryName,
    setEditingSecondaryCategoryName,
    setEditingTertiaryCategoryName,
  };
};
