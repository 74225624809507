import { ChangeEvent } from "react";
import { Box, IconButton, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AmountTextField } from "components/atoms/amount-text-field";
import { DeleteIcon } from "components/icon/delete-icon";
import { DepositHistoryResponse } from "data-access/repositories/billing/deposit_history/deposit_history.dto";
import { theme } from "extensions/theme";
import { formatDateUtil } from "utils/formatDateUtil";
import { CustomFormLabel } from "../custom-form-label";

const CustomMenuItem = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "bold",
  borderRadius: "4px",
  padding: "4px 10px",
  backgroundColor: theme.palette.blue[200],
  border: `1px solid ${theme.palette.blue[500]}`,
}));

interface Props {
  depositHistory: DepositHistoryResponse;
  index: number;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
  onChangeDate: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
  onChangeDepositMethodType: (e: SelectChangeEvent<string>, index: number) => void;
  onDelete: (index: number) => void;
  onBlur: any;
  displayDepositAmount: (string | number)[];
  setDisplayDepositAmount: any;
}

export const DepositBlock = (props: Props) => {
  const {
    depositHistory,
    index,
    onChange,
    onDelete,
    onBlur,
    onChangeDate,
    onChangeDepositMethodType,
  } = props;

  return (
    <Box
      sx={{
        mb: "12px",
        p: "12px",
        border: `1px solid ${theme.palette.grayScale[300]}`,
        borderRadius: "5px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomFormLabel labelName="入金金額" small />
        <IconButton onClick={() => onDelete(index)}>
          <DeleteIcon color={theme.palette.grayScale[700]} size={20} />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <AmountTextField
          name="depositAmount"
          value={depositHistory.deposit_amount}
          width="100%"
          onChange={(e) => onChange(e, index)}
          blurFunc={(e: any) => onBlur(e, index)}
        />
      </Box>
      <div style={{ display: "flex", gap: "12px" }}>
        <div>
          <CustomFormLabel labelName="入金日" small />
          <TextField
            id="depositDate"
            name="depositDate"
            type="date"
            value={formatDateUtil(new Date(depositHistory.deposit_date), "yyyy-MM-dd")}
            onChange={(e) => onChangeDate(e, index)}
            sx={{
              width: "140px",
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
            }}
          />
        </div>
        <div>
          <CustomFormLabel labelName="入金方法" small />
          <Select
            id="depositMethodType"
            name="depositMethodType"
            variant="standard"
            value={depositHistory.deposit_method_type}
            onChange={(e) => onChangeDepositMethodType(e, index)}
            sx={{
              "&.MuiInputBase-root:before": {
                borderBottom: "none",
              },
            }}
          >
            <MenuItem value="not_yet_set">
              <CustomMenuItem>未設定</CustomMenuItem>
            </MenuItem>
            <MenuItem value="bank_transfer">
              <CustomMenuItem>銀行振込</CustomMenuItem>
            </MenuItem>
            <MenuItem value="promissory_note">
              <CustomMenuItem>手形</CustomMenuItem>
            </MenuItem>
            <MenuItem value="cash">
              <CustomMenuItem>現金</CustomMenuItem>
            </MenuItem>
            <MenuItem value="half_cash_half_note">
              <CustomMenuItem>半金半手</CustomMenuItem>
            </MenuItem>
            <MenuItem value="credit_card">
              <CustomMenuItem>クレジットカード</CustomMenuItem>
            </MenuItem>
          </Select>
        </div>
      </div>
    </Box>
  );
};
