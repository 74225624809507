import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    tableHeader: {
      "& .MuiInputBase-root": {
        height: "1.928rem",
        fontSize: "0.857rem",
        width: "13rem",
        color: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.primary.main} `,
        opacity: ".5",
      },
    },
  }),
);
