import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Add, AddCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { TertiaryCategoryId } from "data-access/repositories/project/tertiary_category/tertiary_category.dto";
import { theme } from "extensions/theme";
import { resizeImage } from "utils/resizeImage";
import { styles } from "./styles";

interface Props {
  tertiaryCategoryId: TertiaryCategoryId;
  onDrop: (files: File[] | FileList | null, tertiaryCategoryId: TertiaryCategoryId) => void;
  sx?: object;
}

export const PhotoDropzone = (props: Props) => {
  const classes = styles();
  const [errors, setErrors] = useState<string[]>([]);
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const resizedImages = await Promise.all(acceptedFiles.map((file) => resizeImage(file)));
        props.onDrop(resizedImages, props.tertiaryCategoryId);
      } catch {
        setErrors([...errors, "file-resize-failed"]);
      }
    },
    [props],
  );

  const { fileRejections, getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/gif": [".gif"],
      "image/avif": [".avif"],
      "image/svg": [".svg"],
    },
    maxSize: 1024 * 1024 * 500,
  });

  // エラーの種類を取得
  useEffect(() => {
    if (fileRejections.length === 0) {
      setErrors([]);
      return;
    }
    const rejectionErrorCodes = fileRejections.map((rejection) => {
      return rejection.errors.map((error) => {
        return error.code;
      });
    });
    // デバッグ用
    console.log("errors", rejectionErrorCodes.flat());
    setErrors(rejectionErrorCodes.flat());
  }, [fileRejections]);

  return (
    <>
      <Box className={classes.addPhotoBox}>
        <div {...getRootProps({ className: classes.dropzone })}>
          {isDragActive ? (
            <div style={{ padding: "42px 0" }}>
              <AddCircle />
              <Typography sx={{ fontWeight: "bold" }}>
                ドラッグ＆ドロップ
                <br />
                でファイル追加
              </Typography>
            </div>
          ) : (
            <AddPhotoZone />
          )}
        </div>
      </Box>
      <ErrorContainer errors={errors} />
    </>
  );
};

const AddPhotoZone = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "215px",
        height: "137px",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      <Add />
      写真を追加
    </div>
  );
};

const ErrorMessage = styled(Typography)({
  color: theme.palette.error.main,
  fontWeight: "bold",
  fontSize: "11px",
});

const ErrorContainer = ({ errors }: { errors: string[] }) => {
  return (
    <>
      {errors.some((error) => error === "file-too-large") && (
        <ErrorMessage>ファイルサイズが500MBを超えています</ErrorMessage>
      )}
      {errors.some((error) => error === "file-invalid-type") && (
        <ErrorMessage>無効なファイル形式です</ErrorMessage>
      )}
      {errors.some((error) => error === "file-resize-failed") && (
        <ErrorMessage>画像のリサイズに失敗しました</ErrorMessage>
      )}
    </>
  );
};
