import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    label: {
      fontWeight: "bold",
      color: theme.palette.grayScale[700],
    },
    card: {
      backgroundColor: theme.palette.customPrimary[50],
      width: "420px",
      height: "100px",
      borderRadius: "4px",
      padding: "4px 8px",
      marginRight: "12px",
      marginBottom: "12px",
    },
    badge: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grayScale[0],
      gap: "4px",
      width: "80px",
      height: "28px",
      borderRadius: "14px",
    },
  }),
);
