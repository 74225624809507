import { useState } from "react";
import {
  Button,
  TextField,
  SelectChangeEvent,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled, css } from "@mui/material/styles";
import { Flash } from "components/atoms/flash";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { attachmentTypeRepository } from "data-access/repositories/attachment_type/attachment_type.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";

interface Props {
  open: (e: boolean) => void;
  isOpen: boolean;
}

interface State {
  name: string;
  displayOrderPosition: number;
  isPublishToMobile: boolean;
}

const CustomRadioGroup = styled(RadioGroup)({
  "& .MuiFormControlLabel-root": {
    border: `1px solid ${theme.palette.grayScale[700]}`,
    borderRadius: 5,
    padding: "0.5rem 3rem 0.5rem 0",
    marginLeft: "0.1rem",
  },
  "& .MuiRadio-root.Mui-checked": {
    color: theme.palette.grayScale[0],
  },
});

const CustomFormControlLabel = styled(FormControlLabel)<{ active: number }>`
  ${(props) =>
    props.active
      ? css`
          color: ${theme.palette.grayScale[0]};
          background-color: ${theme.palette.primary.main};
        `
      : css`
          color: ${theme.palette.primary.main};
          background-color: ${theme.palette.grayScale[0]};
          & .MuiRadio-root {
            color: ${theme.palette.primary.main};
          }
        `}
`;

export const AttachmentTypeFormModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<State>({
    name: "",
    displayOrderPosition: 0,
    isPublishToMobile: true,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const { mutate: attachmentTypesMutate } = useSWR("/api/v1/attachment_types", () =>
    attachmentTypeRepository.index(),
  );

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string | boolean>,
  ) => {
    switch (e.target.name) {
      case "name":
        return setState({ ...state, name: e.target.value as string });
      case "isPublishToMobile":
        return setState({ ...state, isPublishToMobile: e.target.value as boolean });
    }
  };

  const handleClickIsPublishToMobileRadioButton = (value: boolean) => {
    setState({ ...state, isPublishToMobile: value });
  };

  const handleClick = async () => {
    // 登録処理
    try {
      await attachmentTypeRepository.create(state);
      attachmentTypesMutate();
      handleClose();
      dispatch(mainOperations.updateSuccessMessage("添付ファイルタイプを作成しました"));
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const handleClose = () => {
    props.open(false);
    setState({ name: "", isPublishToMobile: true, displayOrderPosition: 0 });
  };

  const isValid = (): boolean => {
    if (!state.name) return false;
    return true;
  };

  return (
    <CustomModal
      open={props.isOpen}
      onClose={() => handleClose()}
      title="添付ファイルタイプの新規作成"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid()}
            onClick={handleClick}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            保存
          </Button>
        </>
      }
    >
      <Box>
        <Box sx={{ mt: "1rem" }}>
          <CustomFormLabel labelName="名前" />
          <TextField
            id="name"
            name="name"
            sx={{ width: "50%", mt: "0.5rem" }}
            value={state.name}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ mt: "2rem" }}>
          <CustomFormLabel labelName="共有範囲" />
          <FormControl>
            <CustomRadioGroup
              aria-labelledby="radio-is-publish-to-mobile"
              value={state.isPublishToMobile}
              name="isPublishToMobile"
              row
            >
              <CustomFormControlLabel
                active={+state.isPublishToMobile}
                value="true"
                control={<Radio />}
                label="全員に共有"
                onClick={() => handleClickIsPublishToMobileRadioButton(true)}
              />
              <CustomFormControlLabel
                active={+!state.isPublishToMobile}
                value="false"
                control={<Radio />}
                label="一部に共有"
                onClick={() => handleClickIsPublishToMobileRadioButton(false)}
              />
            </CustomRadioGroup>
          </FormControl>
        </Box>
      </Box>

      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}
    </CustomModal>
  );
};
