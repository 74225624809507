import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "../../../extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    optionButton: {
      width: "102px",
      height: "36px",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      gap: "8px",
      borderRadius: "36px",
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: "pointer",
      userSelect: "none",
    },
  }),
);
