import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TIFFViewer } from "components/atoms/tiff-preview";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { imagePreviewModalOperations } from "store/image-preview-modal/operations";
import { selectImagePreviewModal } from "store/image-preview-modal/slice";
import { isContainExtensions } from "utils/isContainExtensions";
import { styles } from "./styles";

const CustomTIFFViewer = styled(TIFFViewer)({
  canvas: {
    "max-width": "100%",
    "max-height": "inherit",
  },
  div: {
    margin: "0 auto",
  },
});

export const ImagePreviewModal = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectImagePreviewModal);
  const classes = styles();

  const handleClose = () => {
    dispatch(imagePreviewModalOperations.close());
  };

  return (
    <Dialog open={state.isOpen} onClose={() => handleClose()} className={classes.dialog}>
      <DialogTitle className={classes.title}>
        <Typography>{state.image.name}</Typography>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <Box sx={{ textAlign: "center" }}>
          {isContainExtensions(state.image.name, [".tif", ".tiff"]) ? (
            <CustomTIFFViewer tiff={state.image.url} />
          ) : (
            <img
              alt={state.image.name}
              src={state.image.url}
              style={{ maxHeight: "600px", minWidth: "450px" }}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
