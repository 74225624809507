import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GroupLabel } from "components/label/group-label";
import { PhotoPreviewModal } from "components/organisms/photo-preview-modal";
import { sharableWorkReportRepository } from "data-access/repositories/share/work_report/sharable_work_report.repository";
import { theme } from "extensions/theme";
import { usePhotoPreview } from "hooks/usePhotoPreview";
import { usePhotoSelect } from "hooks/usePhotoSelect";
import FileDownload from "images/file_download_icon.svg";
import Logo from "images/logo.svg";
import { ErrorPage404 } from "pages/error/error-page-404";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { downloadImagesV2 } from "utils/downloadImages";
import { handleError } from "utils/errorHandler";
import { timeRange } from "utils/timeRange";
import { usersName } from "utils/usersName";
import { styles } from "./styles";

const CustomBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  lineHeight: "14px",
  letterSpacing: "0.2px",
  fontWeight: "500",
  padding: "1rem 0",
});

const CustomHeaderBox = styled(Box)({
  position: "sticky",
  top: 0,
  backgroundColor: theme.palette.grayScale[0],
  zIndex: 9,
});

export const ShareWorkReport = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const pathParts = window.location.pathname.split("/");
  const { data, error } = useSWR(`/api/v1/sharable_work_reports/${pathParts[2]}`, () =>
    sharableWorkReportRepository.index(pathParts[2]),
  );
  // リンクが不正な場合は404ページを表示
  if (error) {
    return <ErrorPage404 />;
  }

  const { data: photos } = useSWR(`/api/v1/sharable_work_reports/${pathParts[2]}/photos`, () =>
    sharableWorkReportRepository.getPhotos(pathParts[2]),
  );

  const { photoPreviewState, setPhotoPreviewState, handleClickPhotoPreview } =
    usePhotoPreview(photos);

  const {
    selectedPhotoIds,
    setSelectedPhotoIds,
    handleSelectPhoto,
    handleSelectPhotoAll,
    handleDeselectPhotoAll,
  } = usePhotoSelect(photos);

  const handleDownload = async () => {
    if (!photos) return;
    dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしています"));
    try {
      const selectedPhotos = photos.filter((photo) => selectedPhotoIds.has(photo.id));
      await downloadImagesV2(selectedPhotos, `作業日報写真_(${data?.siteName})`);
      dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしました"));
      setSelectedPhotoIds(new Set());
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "写真のダウンロードに失敗しました",
      );
    }
  };

  return (
    <>
      <PhotoPreviewModal state={photoPreviewState} setState={setPhotoPreviewState} />

      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "4rem",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <img src={Logo} alt="logo" style={{ marginLeft: "24px", width: 150 }} />
      </Box>
      <CustomHeaderBox>
        <Box sx={{ display: "flex", alignItems: "center", p: "24px" }}>
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              wordBreak: "break-all",
              flex: 1,
            }}
          >
            {data?.siteName}
          </Typography>
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontWeight: "bold" }}>作成企業:</Typography>
            <Typography sx={{ pl: "0.5rem" }}>{data?.companyName}</Typography>
          </div>
        </Box>

        <Divider />
      </CustomHeaderBox>

      <Box sx={{ p: "24px" }}>
        <CustomBox>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.grayScale[700],
              fontWeight: "500",
            }}
          >
            作業日時
          </Typography>
          <Typography
            sx={{
              width: "75%",
              fontSize: "14px",
              color: theme.palette.grayScale[900],
            }}
          >
            {timeRange(
              "yyyy年MM月dd日 HH:mm",
              data?.startTime.toString(),
              data?.endTime.toString(),
            )}
          </Typography>
        </CustomBox>

        <CustomBox>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.grayScale[700],
              fontWeight: "500",
            }}
          >
            現場責任者
          </Typography>
          <Typography
            sx={{
              width: "75%",
              fontSize: "14px",
              color: theme.palette.grayScale[900],
            }}
          >
            {data?.siteManager ? data?.siteManager.name : "未設定"}
          </Typography>
        </CustomBox>

        <CustomBox>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.grayScale[700],
              fontWeight: "500",
            }}
          >
            参加者
          </Typography>
          <Typography
            sx={{
              width: "75%",
              fontSize: "14px",
              color: theme.palette.grayScale[900],
            }}
          >
            {usersName(data?.users || [])}
          </Typography>
        </CustomBox>

        <CustomBox>
          <Typography
            sx={{ fontSize: "14px", color: theme.palette.grayScale[700], fontWeight: "500" }}
          >
            作業内容・連絡事項
          </Typography>
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                fontSize: "14px",
                color: theme.palette.grayScale[900],
                wordBreak: "break-all",
              }}
            >
              {data?.content.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Box>
        </CustomBox>

        <div>
          <Box sx={{ display: "flex" }}>
            <Typography
              fontWeight="500"
              sx={{
                fontSize: "14px",
                color: theme.palette.grayScale[700],
                height: "35px",
                lineHeight: "30px",
              }}
            >
              現場写真
            </Typography>
            {!!photos && (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "16px", mb: "20px", ml: "20px" }}
              >
                <Typography
                  onClick={handleSelectPhotoAll}
                  className={classes.selectAllButton}
                  fontSize={14}
                  color={theme.palette.primary.main}
                >
                  すべて選択
                </Typography>
                <Typography
                  onClick={handleDeselectPhotoAll}
                  className={classes.deselectButton}
                  fontSize={14}
                >
                  選択を解除
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    onClick={handleDownload}
                    disabled={selectedPhotoIds.size === 0}
                    sx={{
                      fontSize: "12px",
                      height: "30px",
                      borderRadius: "20px",
                      fontWeight: "500",
                    }}
                    startIcon={
                      <Box
                        sx={{ color: theme.palette.grayScale[0] }}
                        component="img"
                        src={FileDownload}
                      />
                    }
                  >
                    {selectedPhotoIds.size}件をダウンロード
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            {data?.categoriesHavePhotos.map((primaryCategory, index) => (
              <React.Fragment key={index}>
                <Typography fontSize={20} fontWeight="bold" sx={{ mb: "12px" }}>
                  {primaryCategory.name}
                </Typography>
                <Box>
                  {primaryCategory.secondaryCategories.map((secondaryCategory, index) => (
                    <React.Fragment key={index}>
                      <Typography fontWeight="bold" sx={{ mb: "16px" }}>
                        {secondaryCategory.name}
                      </Typography>
                      {secondaryCategory.tertiaryCategories.map((tertiaryCategory) => {
                        const filteredPhotos = photos?.filter((photo) => {
                          return photo.tertiaryCategory.id === tertiaryCategory.id;
                        });

                        return (
                          <React.Fragment key={tertiaryCategory.id}>
                            <GroupLabel
                              name={tertiaryCategory.name}
                              colorNumber={tertiaryCategory.colorNumber}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "24px",
                                mt: "12px",
                                mb: "28px",
                              }}
                            >
                              {filteredPhotos && filteredPhotos.length > 0 ? (
                                filteredPhotos.map((photo) => (
                                  <Box
                                    key={photo.id}
                                    sx={{
                                      position: "relative",
                                      overflow: "hidden",
                                      borderRadius: "4px",
                                      border: `1px solid ${theme.palette.grayScale[300]}`,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
                                        height: "137px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        alt={`photo-${photo.id}`}
                                        src={photo.thumbnailUrl}
                                        loading="lazy"
                                        style={{
                                          width: "215px",
                                          height: "137px",
                                          objectFit: "cover",
                                        }}
                                        onClick={() => handleClickPhotoPreview(photo)}
                                      />
                                      {/* MUIのCheckBoxではレンダリングが間に合わないためinputを使用 */}
                                      <input
                                        type="checkbox"
                                        checked={selectedPhotoIds.has(photo.id)}
                                        onChange={() => handleSelectPhoto(photo.id)}
                                        className={classes.checkbox}
                                      />
                                    </Box>
                                  </Box>
                                ))
                              ) : (
                                <Typography>写真はありません</Typography>
                              )}
                            </Box>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  ))}
                </Box>
              </React.Fragment>
            ))}
          </Box>
        </div>

        <CustomBox>
          <Typography
            sx={{ fontSize: "14px", color: theme.palette.grayScale[700], fontWeight: "500" }}
          >
            作成日
          </Typography>
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                width: "75%",
                fontSize: "14px",
                color: theme.palette.grayScale[900],
              }}
            >
              {data?.createdDate}
            </Typography>
          </Box>
        </CustomBox>

        <CustomBox>
          <Typography
            sx={{ fontSize: "14px", color: theme.palette.grayScale[700], fontWeight: "500" }}
          >
            作成者
          </Typography>
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                width: "75%",
                fontSize: "14px",
                color: theme.palette.grayScale[900],
              }}
            >
              {data?.createdBy.name}
            </Typography>
          </Box>
        </CustomBox>
      </Box>
    </>
  );
};
