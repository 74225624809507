import { forwardRef, useState } from "react";
import { Box } from "@mui/material";
import { GroupFormModal } from "./form-modal";
import GroupHeader from "./header";
import { GroupTable } from "./table";

const Group = forwardRef<HTMLDivElement>((_, ref) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <GroupFormModal open={setModalOpen} isOpen={isModalOpen} />

      <GroupHeader isOpen={isModalOpen} setOpen={setModalOpen} ref={ref} />
      <Box>
        <GroupTable />
      </Box>
    </>
  );
});

Group.displayName = "Group";

export default Group;
