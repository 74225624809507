import { ApiClient } from "../../../ApiClient";
import { ActivityIndexResponse } from "../../activity/activity.dto";
import { BuildingId } from "../building.dto";

export const buildingActivityRepository = {
  index(id: BuildingId, limit: number): Promise<ActivityIndexResponse> {
    return ApiClient.get(`/api/v1/buildings/${id}/activities?limit=${limit}`).then(
      (res) => res.data,
    );
  },
};
