import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  NoticeProjectStatusChangeId,
  NoticeProjectStatusChangeIndexResponse,
  NoticeProjectStatusChangeUpdateRequest,
} from "./project_status_change.dto";

export const noticeProjectStatusChangeRepository = {
  index(): Promise<NoticeProjectStatusChangeIndexResponse> {
    return ApiClient.get("/api/v1/notice/project_status_changes/settings").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(): Promise<void> {
    return ApiClient.post("/api/v1/notice/project_status_changes/settings").then((res) => res.data);
  },
  update(
    id: NoticeProjectStatusChangeId,
    body: NoticeProjectStatusChangeUpdateRequest,
  ): Promise<void> {
    return ApiClient.put(
      `/api/v1/notice/project_status_changes/settings/${id}`,
      updateBody(body),
    ).then((res) => res.data);
  },
  destroy(id: NoticeProjectStatusChangeId): Promise<void> {
    return ApiClient.delete(`/api/v1/notice/project_status_changes/settings/${id}`).then(
      (res) => res.data,
    );
  },
};

const updateBody = (body: NoticeProjectStatusChangeUpdateRequest) => {
  return {
    project_status_ids_for_filter: body.projectStatusIdsForFilter,
    notice_target: convertKeysToSnakeCase(body.noticeTarget),
    subscriber_types: body.subscriberTypes,
    is_enable: body.isEnable,
  };
};
