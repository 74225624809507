import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, IconButton, Typography } from "@mui/material";

interface Props {
  title: string;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  isDisabled?: boolean;
  boxStyle?: object;
  titleStyle?: object;
}
export const AccordionToggle = (props: Props) => {
  const { title, isExpanded, setIsExpanded, isDisabled, boxStyle, titleStyle } = props;
  return (
    <>
      <Box
        sx={{
          display: "inline-flex",
          userSelect: "none",
          gap: "16px",
          cursor: "pointer",
          mb: "8px",
          ...boxStyle,
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Typography sx={{ ...titleStyle }}>{title}</Typography>
        {!isDisabled && (
          <IconButton sx={{ p: 0 }}>
            <ExpandMoreIcon
              sx={{
                transition: "transform 0.2s ease-in-out",
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        )}
      </Box>
    </>
  );
};
