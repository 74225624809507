import { Box, Select, TablePagination, MenuItem, SelectChangeEvent } from "@mui/material";
import { styled, css } from "@mui/material/styles";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import { PageInfo } from "data-access/repositories/page_info/page_info.dto";

type Props = {
  columns: GridColDef[];
  rows: readonly { [p: string]: any }[];
  loading: boolean;
  pageInfo: PageInfo;
  currentPage: number;
  handleSelect: (event: SelectChangeEvent<unknown>) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleRowClick?: (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails,
  ) => void;
  handleCheck?: any;
  initialState?: GridInitialStateCommunity;
  isCheckbox?: boolean;
  isCheckAllNoneStyle?: boolean;
  tableHeight?: string;
};

const CustomStyleDataGrid = styled(DataGrid)<{ isCheckAllNoneStyle: number }>`
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-columnHeader:focus {
    outline: inherit !important;
  }
  .MuiDataGrid-row {
    cursor: pointer;
  }
  .MuiTypography-root {
    line-height: inherit;
  }

  ${(props) =>
    props.isCheckAllNoneStyle
      ? css`
          & .muidatagrid-columnheadercheckbox .muidatagrid-columnheadertitlecontainer: {
            display: "none";
          }
        `
      : css``}
`;

export const CustomDataGrid = ({ isCheckAllNoneStyle = false, ...props }: Props) => {
  const pageItemOptions = Array.from(Array(props.pageInfo.pages).keys(), (x) => x + 1).map(
    (pageItem) => {
      return (
        <MenuItem key={pageItem} value={pageItem}>
          {pageItem}
        </MenuItem>
      );
    },
  );

  // ページ変更後は、スクロール位置をトップに戻す
  const scrollToTop = () => {
    const dataGridScroller = document.querySelector(".MuiDataGrid-virtualScroller");
    if (dataGridScroller) dataGridScroller.scrollTop = 0;
  };

  const footer = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Select
          value={props.currentPage}
          onChange={(event) => {
            props.handleSelect(event);
            scrollToTop();
          }}
          variant="standard"
        >
          {pageItemOptions}
        </Select>
        <Box marginX={1}>ページ</Box>
        <TablePagination
          component="div"
          count={props.pageInfo.count}
          page={props.currentPage - 1}
          rowsPerPage={props.pageInfo.items}
          rowsPerPageOptions={[]}
          onPageChange={(event, page) => {
            props.handleChangePage(event, page);
            scrollToTop();
          }}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ width: "100%", ...(props.tableHeight ? { height: props.tableHeight } : {}) }}>
      <CustomStyleDataGrid
        autoHeight={!props.tableHeight}
        loading={props.loading}
        slots={{ footer }}
        rows={props.rows}
        columns={props.columns}
        hideFooterSelectedRowCount={true}
        onRowClick={props.handleRowClick}
        disableColumnMenu
        initialState={props.initialState}
        checkboxSelection={props.isCheckbox}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(ids) => {
          const rows = props.rows;
          const selectedIds = new Set(ids);
          const selectedRowData = rows.filter((row) => selectedIds.has(row.id));
          if (props.handleCheck) {
            props.handleCheck(selectedRowData);
          }
        }}
        isCheckAllNoneStyle={isCheckAllNoneStyle ? 1 : 0}
      />
    </Box>
  );
};
