import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  SelectChangeEvent,
  FormControlLabel,
  Typography,
  AutocompleteChangeReason,
  Autocomplete,
  CircularProgress,
  AutocompleteInputChangeReason,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AmountTextField } from "components/atoms/amount-text-field";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { addressRepository } from "data-access/repositories/Address/postal_code.repository";
import { Building, BuildingId } from "data-access/repositories/building/building.dto";
import { buildingRepository } from "data-access/repositories/building/building.repository";
import { Client, ClientId } from "data-access/repositories/client/client.dto";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { postalCodeRepository } from "data-access/repositories/postal_code/postal_code.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { ProjectTypeId } from "data-access/repositories/project_type/project_type.dto";
import { ProjectInfo } from "data-access/repositories/schedule/schedule.dto";
import { theme } from "extensions/theme";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import useSWR from "swr";
import { DEFAULT_ID } from "utils/constant";
import { openURLInNewTab } from "utils/openURLInNewTab";

const STypography = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;
const STextField = styled(TextField)`
  width: 330px;
  & .MuiOutlinedInput-root {
    height: 46px;
  }
`;

interface Props {
  isOpen: boolean;
  scheduleName: string;
  onClose: () => void;
  /**
   * 新規ならprojectInfo, 既存ならprojectId
   */
  onClickSubmit: (projectId: ProjectId, projectInfo: ProjectInfo) => void;
}

export const LinkProjectFormModal = (props: Props) => {
  const { isOpen, scheduleName, onClose, onClickSubmit } = props;
  const [projectInfo, setProjectInfo] = useState<ProjectInfo>({});
  const [projectId, setProjectId] = useState<ProjectId>(DEFAULT_ID as ProjectId);
  const onChangeProjectInfo = (projectInfo: ProjectInfo) => {
    setProjectInfo(projectInfo);
  };
  const [isValid, setIsValid] = useState<boolean>(false);

  return (
    <CustomModal
      title="予定に案件を紐づける"
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      footer={
        <>
          <Button variant="outlined" onClick={onClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid}
            onClick={() => onClickSubmit(projectId, projectInfo)}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            保存
          </Button>
        </>
      }
    >
      <LinkProjectForm
        isOpen={isOpen}
        scheduleName={scheduleName}
        setIsValid={setIsValid}
        onSetExistingProjectId={setProjectId}
        onChangeProjectInfo={onChangeProjectInfo}
      />
    </CustomModal>
  );
};

interface LinkProjectFormProps {
  isOpen: boolean;
  scheduleName: string;
  setIsValid: (isValid: boolean) => void;
  onSetExistingProjectId: (projectId: ProjectId) => void;
  onChangeProjectInfo: (projectInfo: ProjectInfo) => void;
}

const LinkProjectForm = (props: LinkProjectFormProps) => {
  const { isOpen, scheduleName, setIsValid, onSetExistingProjectId, onChangeProjectInfo } = props;

  const mainState = useAppSelector(selectMain);
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string>("");
  const [addressErrorMessage, setAddressErrorMessage] = useState<string>("");
  const [searchedAddress, setSearchedAddress] = useState<string>("");
  const [isCreateNewProject, setIsCreateNewProject] = useState<boolean>(false);
  const [isCreateNewClient, setIsCreateNewClient] = useState<boolean>(false);
  // 案件のcomboBoxに表示する案件名
  const [project, setProject] = useState<any>({ id: DEFAULT_ID, name: "" });
  // 案件のcomboBoxで検索時に入力された値
  const [inputProjectValue, setInputProjectValue] = useState<string>("");
  const [formState, setFormState] = useState<ProjectInfo>({});

  const { data: rawProjectsData, isValidating } = useSWR(
    isOpen ? `/api/v1/projects?query=${inputProjectValue}` : null,
    () =>
      projectRepository.index({
        keyword: inputProjectValue,
        sort: "updated_at",
        direction: "desc",
      }),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: clients } = useSWR(isOpen ? "/api/v1/clients/all" : null, clientRepository.all, {
    revalidateOnFocus: false,
  });

  const { data: rawBuildingsData } = useSWR(isOpen ? "/api/v1/buildings/all" : null, () =>
    buildingRepository.all({ orderBy: "recently_created" }),
  );

  const projects = rawProjectsData
    ? rawProjectsData.data.map((project) => {
        return {
          id: project.id,
          name: project.name,
          code: project.code,
        };
      })
    : [];

  const buildings = rawBuildingsData
    ? rawBuildingsData.map((building) => {
        return {
          id: building.id,
          name: building.name,
          createOption: false,
        };
      })
    : [];

  /**
   *  useEffect使用箇所は主に、状態変化時の親コンポーネントへの伝達、検知としている
   *  ゆえに、useEffect内で使用している関数は、親からのpropsで渡された関数のみ使用している
   */

  // フォームの状態変化時
  useEffect(() => {
    onChangeProjectInfo(formState);
  }, [formState]);

  // 既存案件選択時
  useEffect(() => {
    onSetExistingProjectId(project.id);
  }, [project]);

  // 案件新規作成の可否
  useEffect(() => {
    onChangeProjectInfo(isCreateNewProject ? formState : {});
    onSetExistingProjectId(isCreateNewProject ? (DEFAULT_ID as ProjectId) : project.id);
  }, [isCreateNewProject]);

  // バリデーション
  useEffect(() => {
    // 顧客の設定が正しいかによって新規案件作成の可否を判断
    const isValidToCreateNewProject = isCreateNewClient ? !!formState.client?.name : true;
    // 新規案件作成か、もしくは既存案件が選択されているåかによって判断
    setIsValid(isCreateNewProject ? isValidToCreateNewProject : !!project.id);
  }, [isCreateNewProject, project.id, isCreateNewClient, formState.client?.name]);

  const handleSelectProject = (e: React.SyntheticEvent, value: any) => {
    const projectId = value ? value.id : DEFAULT_ID;
    const projectName = value ? value.name : "";
    setProject({ id: projectId, name: projectName });
    setInputProjectValue("");
  };
  // 顧客のcomboBoxに表示する顧客名
  const [clientName, setClientName] = useState<string>("");

  const handleSelectClient = (e: React.SyntheticEvent, value: any) => {
    const clientId = value ? value.id : 0;
    const clientName = value ? value.name : "";
    setClientName(clientName);
    setFormState({ ...formState, clientId: clientId });
  };

  const handleCheckCreateNewProject = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setIsCreateNewProject(!isCreateNewProject);
    if (checked) {
      setFormState({
        ...formState,
        projectTypeId: mainState.projectTypes[0].id,
        name: scheduleName,
      });
    } else {
      setFormState({ ...formState });
    }
  };

  const handleChangeProjectSearchInputValue = (
    _: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    switch (reason) {
      case "clear":
        setProject({ id: DEFAULT_ID, name: "" });
        setInputProjectValue("");
        break;
      case "input":
        setInputProjectValue(value);
        break;
    }
  };

  const handleCheckCreateNewClient = () => {
    setIsCreateNewClient(!isCreateNewClient);
    setClientName("");
    setFormState({
      ...formState,
      clientId: DEFAULT_ID as ClientId,
      client: undefined,
    });
  };

  const handleChangeProjectForm = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => {
    const value = e.target.value;
    setFormState({ ...formState, [e.target.name]: value });
  };

  const handleChangeClientForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setFormState({ ...formState, client: { name: value } });
  };

  const handleClickSearchAddress = async () => {
    const postalCode = formState.postalCode;
    if (!postalCode) return;
    try {
      const res = await addressRepository.index(postalCode);
      const address = res.data[0].prefecture + res.data[0].city + res.data[0].town;
      setFormState({ ...formState, address });
      setPostalCodeErrorMessage("");
    } catch {
      setPostalCodeErrorMessage("入力した郵便番号から住所が見つかりませんでした");
    }
  };

  const handleClickSearchPostalCode = async () => {
    const address = formState.address;
    if (!address) return;
    try {
      const res = await postalCodeRepository.index(address);
      setFormState({
        ...formState,
        postalCode: res.data[0].postal_code,
      });
      setSearchedAddress(
        res.data.length ? res.data[0].prefecture + res.data[0].city + res.data[0].town : "",
      );
      setAddressErrorMessage("");
    } catch {
      setSearchedAddress("");
      setAddressErrorMessage("入力した住所から郵便番号が見つかりませんでした");
    }
  };

  const handleCreateBuilding = () => {
    openURLInNewTab("buildings/new");
  };

  const handleChangeBuilding = (
    _: React.SyntheticEvent,
    value: any,
    reason: AutocompleteChangeReason,
  ) => {
    if (!rawBuildingsData) return;
    if (reason === "selectOption") {
      const selectBuilding: Building[] = rawBuildingsData.filter((building) => {
        return building.id === value.id;
      });
      setFormState({
        ...formState,
        name: formState.name || selectBuilding[0].name,
        postalCode: selectBuilding[0].postal_code,
        address: selectBuilding[0].address,
        buildingId: value.id,
        clientId: selectBuilding[0].client
          ? selectBuilding[0].client?.id
          : (DEFAULT_ID as ClientId),
        client: { name: selectBuilding[0].client ? selectBuilding[DEFAULT_ID].client.name : "" },
      });
    }
    if (reason === "clear") {
      setFormState({
        ...formState,
        buildingId: DEFAULT_ID as BuildingId,
      });
    }
  };

  const projectFilterOptions = (
    options: {
      id: ProjectId;
      name: string;
      code: string;
    }[],
  ) => {
    return options;
  };

  const clientFilterOptions = (options: Client[], { inputValue }: { inputValue: string }) => {
    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phone_number.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phone_number_second.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  return (
    <>
      <div style={{ backgroundColor: theme.palette.customPrimary[50], padding: "8px" }}>
        <Autocomplete
          id="project"
          options={projects}
          onChange={handleSelectProject}
          onBlur={() => setInputProjectValue("")}
          sx={{
            width: 330,
            mb: "14px",
            "& .MuiAutocomplete-popupIndicator": {
              display: "none",
            },
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="案件名を入力して検索"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isValidating ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
          filterOptions={projectFilterOptions}
          onInputChange={handleChangeProjectSearchInputValue}
          inputValue={inputProjectValue || project.name}
          value={{ id: project.id || DEFAULT_ID, name: project.name || "", code: "" }}
          loading={isValidating}
          disabled={isCreateNewProject}
          noOptionsText={
            inputProjectValue ? "案件は見つかりませんでした" : "検索結果が表示されます"
          }
        />
        <div>
          <FormControlLabel
            sx={{ ml: 0, mb: "12px" }}
            labelPlacement="end"
            label={
              <Typography sx={{ fontWeight: "500", fontSize: "14px", userSelect: "none" }}>
                案件を新規作成して紐づける
              </Typography>
            }
            control={
              <Checkbox
                id="createNewProjectCheckbox"
                name="createNewProject"
                sx={{ p: 0, pr: 1 }}
                onChange={handleCheckCreateNewProject}
                checked={isCreateNewProject}
              />
            }
          />
        </div>
        {isCreateNewProject && (
          <>
            <div style={{ marginBottom: "24px" }}>
              <CustomAutocomplete
                data={buildings}
                entityName="物件"
                entityLabel="案件に紐づける物件を選択"
                onChange={handleChangeBuilding}
                onClick={handleCreateBuilding}
                isCreate
                isClearable
                variant="standard"
                sx={{ width: "300px" }}
              />
            </div>
            <div style={{ marginBottom: "24px" }}>
              <div style={{ marginBottom: "20px" }}>
                <STypography>案件タイプ</STypography>
                <Select
                  id="projectTypeId"
                  name="projectTypeId"
                  value={
                    formState.projectTypeId === (DEFAULT_ID as ProjectTypeId)
                      ? ""
                      : formState.projectTypeId?.toString()
                  }
                  onChange={handleChangeProjectForm}
                  sx={{
                    width: "150px",
                    "&.MuiInputBase-root": {
                      height: "46px",
                    },
                  }}
                >
                  {mainState.projectTypes.map((projectType) => (
                    <MenuItem key={projectType.id} value={projectType.id}>
                      {projectType.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <STypography>案件名</STypography>
                <STextField
                  id="name"
                  name="name"
                  value={formState.name}
                  onChange={handleChangeProjectForm}
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <STypography>売上（税抜）</STypography>
                <AmountTextField
                  name="salesAmount"
                  width="330px"
                  height="46px"
                  value={formState.salesAmount || 0}
                  onChange={handleChangeProjectForm}
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <STypography>郵便番号</STypography>
                  <Button
                    onClick={handleClickSearchAddress}
                    disabled={formState.postalCode?.length !== 7}
                    sx={{ py: 0, ml: 2, mb: "8px" }}
                  >
                    住所を検索
                  </Button>
                </div>
                <STextField
                  id="postalCode"
                  name="postalCode"
                  value={formState.postalCode}
                  error={addressErrorMessage.length > 0}
                  helperText={addressErrorMessage}
                  placeholder="半角数字7ケタ,ハイフン無し"
                  onChange={handleChangeProjectForm}
                />
                {searchedAddress && (
                  <Typography sx={{ position: "relative", bottom: "0.25rem" }}>
                    検索結果: {searchedAddress}
                  </Typography>
                )}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <STypography>住所</STypography>
                  <Button
                    onClick={handleClickSearchPostalCode}
                    disabled={formState.address === undefined || formState.address?.length === 0}
                    sx={{ py: 0, ml: 2, mb: "8px" }}
                  >
                    郵便番号を検索
                  </Button>
                </div>
                <STextField
                  id="address"
                  name="address"
                  value={formState.address}
                  error={postalCodeErrorMessage.length > 0}
                  helperText={postalCodeErrorMessage}
                  onChange={handleChangeProjectForm}
                  placeholder="東京都渋谷区神泉町１１ー１０"
                />
              </div>
              <div>
                <STypography>建物名・階・号室</STypography>
                <STextField
                  id="addressSecond"
                  name="addressSecond"
                  value={formState.addressSecond}
                  onChange={handleChangeProjectForm}
                  placeholder="現場Hubビル1階"
                />
              </div>
            </div>

            <Typography color="primary">顧客情報</Typography>
            <CustomAutocomplete
              data={clients || []}
              value={{
                id: formState.clientId || (DEFAULT_ID as ClientId),
                name: clientName || "",
              }}
              onChange={handleSelectClient}
              entityName="顧客"
              entityLabel="顧客"
              variant="standard"
              disabled={isCreateNewClient}
              isClearable
              filterOptions={clientFilterOptions}
              sx={{ width: 330, mb: "14px" }}
            />
            <div style={{ marginBottom: "20px" }}>
              <FormControlLabel
                sx={{ ml: 0 }}
                labelPlacement="end"
                label={
                  <Typography sx={{ fontWeight: "500", fontSize: "14px", userSelect: "none" }}>
                    顧客を新規作成して案件に紐づける
                  </Typography>
                }
                control={
                  <Checkbox
                    id="createNewClientCheckbox"
                    name="createNewClient"
                    sx={{ p: 0, pr: 1 }}
                    onChange={handleCheckCreateNewClient}
                    checked={isCreateNewClient}
                  />
                }
              />
            </div>
            {isCreateNewClient && (
              <>
                <CustomFormLabel
                  labelName="顧客名"
                  small
                  labelColor="primary"
                  required
                  requiredSize="14px"
                />
                <STextField
                  id="clientName"
                  name="clientName"
                  value={formState.client ? formState.client.name : ""}
                  onChange={handleChangeClientForm}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
