import { search } from "./actions";
import { scheduleSearchResultTableSlice } from "./slice";
import {
  ScheduleIndexRequest,
  ScheduleSearchRequest,
} from "../../data-access/repositories/schedule/schedule.dto";
import { AppDispatch } from "../store";

export const scheduleSearchResultTableOperations = {
  index: (query: ScheduleIndexRequest) => (dispatch: AppDispatch) => {
    dispatch(search(query));
  },
  search: (query: ScheduleSearchRequest) => (dispatch: AppDispatch) => {
    dispatch(search(query));
  },
  submit: () => (dispatch: AppDispatch) => {
    dispatch(scheduleSearchResultTableSlice.actions.submit());
  },
  updateCurrentPage: (value: number) => (dispatch: AppDispatch) => {
    dispatch(scheduleSearchResultTableSlice.actions.updateCurrentPage({ value }));
  },
  updateRowsPerPage: (value: number) => (dispatch: AppDispatch) => {
    dispatch(scheduleSearchResultTableSlice.actions.updateRowsPerPage({ value }));
  },
};
