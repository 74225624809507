import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Project,
  initialProjectResponse,
  ProjectUpdateRequest,
  initialProjectRequest,
} from "data-access/repositories/project/project.dto";
import { ProjectTodo, ProjectTodoFormItem } from "data-access/repositories/project/todo/todo.dto";
import { ProjectStatusTypeId } from "data-access/repositories/project_status_type/project_status_type.dto";
import { ObjectKeys } from "types/objectKeys";
import {
  createProject,
  showProject,
  updateProject,
  getPostalCode,
  createProjectAttachment,
  updateProjectAttachment,
  destroyProjectAttachment,
  createProjectEstimate,
  duplicateProject,
  getAddress,
  showProjectTodo,
  createProjectTodo,
  updateTodoDone,
  updateTodoUndone,
  deleteProjectTodo,
  updateProjectTodo,
} from "./actions";
import type { RootState } from "../store";

export interface ProjectSidebarState extends ObjectKeys {
  isOpen: boolean;
  isLoading: boolean;
  isFileUploading: boolean;
  isSubmitted: boolean;
  isExisting: boolean;
  isCreated: boolean;
  isSearched: boolean;
  isTodoSubmitted: boolean;
  isEstimateSubmitted: boolean;
  isAttachmentSubmitted: boolean;
  successMessage: string;
  errorMessage: string;
  postalCodeErrorMessage: string;
  addressErrorMessage: string;
  searchedAddress: string;
  id: number | null;
  form: ProjectUpdateRequest;
  todoForm: ProjectTodoFormItem[];
  project: Project;
  todos: ProjectTodo[];
  uploadedEstimates: File[];
  uploadedAttachments: {
    files: File[];
    attachmentTypeId: string;
    isPublishToMobile: boolean;
  } | null;
}

const initialState: ProjectSidebarState = {
  isOpen: false,
  isLoading: false,
  isFileUploading: false,
  isSubmitted: false,
  isExisting: false,
  isCreated: false,
  isSearched: false,
  isTodoSubmitted: false,
  isEstimateSubmitted: false,
  isAttachmentSubmitted: false,
  successMessage: "",
  errorMessage: "",
  postalCodeErrorMessage: "",
  addressErrorMessage: "",
  searchedAddress: "",
  id: null,
  form: initialProjectRequest,
  todoForm: [],
  project: initialProjectResponse,
  todos: [],
  uploadedEstimates: [],
  uploadedAttachments: null,
};

const initForm = (state: ProjectSidebarState, action?: PayloadAction<Project>) => {
  state.isLoading = false;
  state.uploadedEstimates = [];
  state.uploadedAttachments = null;
  const project = action?.payload || state.project;

  state.id = project.id;
  state.project = project;
  state.form.managerId = project.manager?.id;
  state.form.name = project.name;
  state.form.code = project.code;
  state.form.requester = project.requester;
  state.form.requestedByPersonName = project.requested_by_person_name;
  state.form.billingPrecaution = project.billing_precaution;
  state.form.postalCode = project.postal_code;
  state.form.address = project.address;
  state.form.addressSecond = project.address_second;
  state.form.phoneNumber = project.phone_number;
  state.form.phoneNumberSecond = project.phone_number_second;
  state.form.faxNumber = project.fax_number;
  state.form.email = project.email;
  state.form.projectTypeId = project.project_type.id;
  state.form.projectStatusTypeId = project.project_status_type.id;
  state.form.clientId = project.client?.id;
  state.form.buildingId = project.building?.id;
  state.form.inquiredDate = project.inquired_date;
  state.form.orderedDate = project.ordered_date;
  state.form.expectedCompleteDate = project.expected_complete_date;
  state.form.completedDate = project.completed_date;
  state.form.inquiryNumber = project.inquiry_number;
  state.form.isManageBillingOnAnotherSystem = project.is_manage_billing_on_another_system;
  state.form.isNoBill = project.is_no_bill;
  state.form.note = project.note;
  state.form.reportContent = project.report_content;
  state.form.inquiredById = project.inquired_by?.id;
  state.form.isSupportedByMaker = project.is_supported_by_maker;
  state.form.salesAmount = project.sales_amount;
  state.form.tax = project.sales_tax;
  state.form.totalCostAmount = project.total_cost_amount;
  state.form.personName = project.person_name;
};

export const projectSidebarSlice = createSlice({
  name: "projectSidebar",
  initialState,
  reducers: {
    updateSalesAmount: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      form: {
        ...state.form,
        salesAmount: action.payload.value,
      },
    }),
    updateSalesTax: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      form: {
        ...state.form,
        tax: action.payload.value,
      },
    }),
    updateName: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        name: action.payload.value,
      },
    }),
    updatePersonName: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        personName: action.payload.value,
      },
    }),
    updateRequester: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        requester: action.payload.value,
      },
    }),
    updateRequestedByPersonName: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        requestedByPersonName: action.payload.value,
      },
    }),
    updateBillingPrecaution: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        billingPrecaution: action.payload.value,
      },
    }),
    updatePostalCode: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        postalCode: action.payload.value,
      },
    }),
    updateAddress: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        address: action.payload.value,
      },
    }),
    updateAddressSecond: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        addressSecond: action.payload.value,
      },
    }),
    updatePhoneNumber: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        phoneNumber: action.payload.value,
      },
    }),
    updatePhoneNumberSecond: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        phoneNumberSecond: action.payload.value,
      },
    }),
    updateFaxNumber: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        faxNumber: action.payload.value,
      },
    }),
    updateEmail: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        email: action.payload.value,
      },
    }),
    updateManagerId: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      form: {
        ...state.form,
        managerId: action.payload.value,
      },
    }),
    updateNote: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        note: action.payload.value,
      },
    }),
    updateReportContent: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        reportContent: action.payload.value,
      },
    }),
    updateIsEditMode: (state, action: PayloadAction<{ id: number; value: boolean }>) => ({
      ...state,
      todoForm: state.todoForm.map((todo) => {
        return todo.id === action.payload.id
          ? { ...todo, is_edit_mode: action.payload.value }
          : todo;
      }),
    }),
    updateIsCreated: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isCreated: action.payload.value,
    }),
    updateTodoTagType: (state, action: PayloadAction<{ id: number; value: string }>) => {
      return {
        ...state,
        todoForm: state.todoForm.map((todo) => {
          return todo.id === action.payload.id
            ? {
                ...todo,
                tag_type: { value: action.payload.value, value_i18n: todo.tag_type.value_i18n },
              }
            : todo;
        }),
      };
    },
    updateTodoContent: (state, action: PayloadAction<{ id: number; value: string }>) => {
      return {
        ...state,
        todoForm: state.todoForm.map((todo) => {
          return todo.id === action.payload.id ? { ...todo, content: action.payload.value } : todo;
        }),
      };
    },
    updateProjectStatusTypeId: (state, action: PayloadAction<{ value: ProjectStatusTypeId }>) => ({
      ...state,
      form: {
        ...state.form,
        projectStatusTypeId: action.payload.value,
      },
    }),
    updateCode: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        code: action.payload.value,
      },
    }),
    updateInquiryNumber: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        inquiryNumber: action.payload.value,
      },
    }),
    updateIsManageBillingOnAnotherSystem: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      form: {
        ...state.form,
        isManageBillingOnAnotherSystem: action.payload.value,
      },
    }),
    updateIsNoBill: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      form: {
        ...state.form,
        isNoBill: action.payload.value,
      },
    }),
    updateIsSupportedByMaker: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      form: {
        ...state.form,
        isSupportedByMaker: action.payload.value,
      },
    }),
    updateInquiredDate: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        inquiredDate: action.payload.value,
      },
    }),
    updateOrderedDate: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        orderedDate: action.payload.value,
      },
    }),
    updateExpectedCompleteDate: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        expectedCompleteDate: action.payload.value,
      },
    }),
    updateCompletedDate: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        completedDate: action.payload.value,
      },
    }),
    updateProjectTypeId: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      form: {
        ...state.form,
        projectTypeId: action.payload.value,
      },
    }),
    updateInquiredById: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      form: {
        ...state.form,
        inquiredById: action.payload.value,
      },
    }),
    setUploadedEstimates: (state, action: PayloadAction<{ files: File[] }>) => {
      if (action.payload.files) {
        for (let i = 0; i < action.payload.files.length; i++) {
          state.uploadedEstimates.push(action.payload.files[i]);
        }
      }
      return state;
    },
    setUploadedAttachments: (
      state,
      action: PayloadAction<{ files: File[]; attachmentTypeId: string }>,
    ) => {
      if (action.payload.files) {
        state.uploadedAttachments = {
          files: action.payload.files,
          attachmentTypeId: action.payload.attachmentTypeId,
          isPublishToMobile: false,
        };
      }
      return state;
    },
    updateSuccessMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      successMessage: action.payload.value,
    }),
    updateErrorMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      errorMessage: action.payload.value,
    }),
    open: (state) => ({
      ...state,
      isOpen: true,
    }),
    close: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createProject.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.isExisting = true;
      state.isCreated = true;
      initForm(state, action);
      state.successMessage = "案件を作成しました";
    });
    builder.addCase(createProject.rejected, (state, action) => {
      state.isLoading = false;
      state.isSubmitted = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(showProject.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(showProject.fulfilled, (state, action) => {
      state.isExisting = true;
      initForm(state, action);
    });
    builder.addCase(showProject.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(showProjectTodo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(showProjectTodo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.todos = action.payload;
      state.todoForm = action.payload.map((todo) => {
        return { ...todo, is_edit_mode: false };
      });
    });
    builder.addCase(showProjectTodo.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(createProjectTodo.pending, (state) => {
      state.isLoading = true;
      state.isTodoSubmitted = false;
    });
    builder.addCase(createProjectTodo.fulfilled, (state) => {
      state.isLoading = false;
      state.isTodoSubmitted = true;
      state.successMessage = "TODOを作成しました";
    });
    builder.addCase(createProjectTodo.rejected, (state, action) => {
      state.isLoading = false;
      state.isTodoSubmitted = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(updateProjectTodo.pending, (state) => {
      state.isLoading = true;
      state.isTodoSubmitted = false;
    });
    builder.addCase(updateProjectTodo.fulfilled, (state) => {
      state.isLoading = false;
      state.isTodoSubmitted = true;
      state.successMessage = "TODOを更新しました";
    });
    builder.addCase(updateProjectTodo.rejected, (state, action) => {
      state.isLoading = false;
      state.isTodoSubmitted = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(deleteProjectTodo.pending, (state) => {
      state.isLoading = true;
      state.isTodoSubmitted = false;
    });
    builder.addCase(deleteProjectTodo.fulfilled, (state) => {
      state.isLoading = false;
      state.isTodoSubmitted = true;
      state.successMessage = "TODOを削除しました";
    });
    builder.addCase(deleteProjectTodo.rejected, (state, action) => {
      state.isLoading = false;
      state.isTodoSubmitted = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(updateTodoDone.pending, (state) => {
      state.isLoading = true;
      state.isTodoSubmitted = false;
    });
    builder.addCase(updateTodoDone.fulfilled, (state) => {
      state.isLoading = false;
      state.isTodoSubmitted = true;
      state.successMessage = "TODOを完了済みにしました";
    });
    builder.addCase(updateTodoDone.rejected, (state, action) => {
      state.isLoading = false;
      state.isTodoSubmitted = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(updateTodoUndone.pending, (state) => {
      state.isLoading = true;
      state.isTodoSubmitted = false;
    });
    builder.addCase(updateTodoUndone.fulfilled, (state) => {
      state.isLoading = false;
      state.isTodoSubmitted = true;
      state.successMessage = "TODOを未完了に戻しました";
    });
    builder.addCase(updateTodoUndone.rejected, (state, action) => {
      state.isLoading = false;
      state.isTodoSubmitted = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(duplicateProject.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
    });
    builder.addCase(duplicateProject.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.isExisting = true;
      state.isCreated = true;
      initForm(state, action);
      state.successMessage = "案件を複製しました";
    });
    builder.addCase(duplicateProject.rejected, (state, action) => {
      state.isLoading = false;
      state.isSubmitted = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getPostalCode.pending, (state) => {
      state.isLoading = true;
      state.isSearched = false;
      state.errorMessage = "";
    });
    builder.addCase(getPostalCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSearched = true;
      state.postalCodeErrorMessage = "";
      state.form.postalCode = action.payload.data[0].postal_code;
      state.searchedAddress =
        action.payload.data[0].prefecture +
        action.payload.data[0].city +
        action.payload.data[0].town;
    });
    builder.addCase(getPostalCode.rejected, (state) => {
      state.isLoading = false;
      state.searchedAddress = "";
      state.postalCodeErrorMessage = "入力した住所から郵便番号が見つかりませんでした";
    });
    builder.addCase(getAddress.pending, (state) => {
      state.isLoading = true;
      state.isSearched = false;
      state.errorMessage = "";
    });
    builder.addCase(getAddress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSearched = true;
      state.addressErrorMessage = "";
      state.form.address =
        action.payload.data[0].prefecture +
        action.payload.data[0].city +
        action.payload.data[0].town;
    });
    builder.addCase(getAddress.rejected, (state) => {
      state.isLoading = false;
      state.addressErrorMessage = "入力した郵便番号から住所が見つかりませんでした";
    });
    builder.addCase(updateProject.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.project = action.payload;
      initForm(state);
      state.successMessage = "案件を更新しました";
    });
    builder.addCase(updateProject.rejected, (state, action) => {
      state.isLoading = false;
      state.uploadedEstimates = [];
      state.uploadedAttachments = null;
      initForm(state);
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(createProjectAttachment.pending, (state) => {
      state.isLoading = true;
      state.isFileUploading = true;
      state.isSubmitted = false;
      state.isAttachmentSubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(createProjectAttachment.fulfilled, (state) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.isFileUploading = false;
      state.isAttachmentSubmitted = true;
      state.successMessage = "添付ファイルをアップロードしました";
    });
    builder.addCase(createProjectAttachment.rejected, (state, action) => {
      state.isLoading = false;
      state.isFileUploading = false;
      state.uploadedEstimates = [];
      state.uploadedAttachments = null;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(updateProjectAttachment.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
      state.isAttachmentSubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(updateProjectAttachment.fulfilled, (state) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.isAttachmentSubmitted = true;
      state.successMessage = "添付ファイルを更新しました";
    });
    builder.addCase(updateProjectAttachment.rejected, (state, action) => {
      state.isLoading = false;
      state.uploadedEstimates = [];
      state.uploadedAttachments = null;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(destroyProjectAttachment.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
      state.isAttachmentSubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(destroyProjectAttachment.fulfilled, (state) => {
      state.isSubmitted = true;
      state.successMessage = "添付ファイルを削除しました";
      state.isAttachmentSubmitted = true;
      state.isLoading = false;
      state.uploadedEstimates = [];
      state.uploadedAttachments = null;
    });
    builder.addCase(destroyProjectAttachment.rejected, (state, action) => {
      state.isLoading = false;
      state.uploadedEstimates = [];
      state.uploadedAttachments = null;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(createProjectEstimate.pending, (state) => {
      state.isLoading = true;
      state.isEstimateSubmitted = false;
      state.isFileUploading = true;
    });
    builder.addCase(createProjectEstimate.fulfilled, (state) => {
      state.isEstimateSubmitted = true;
      state.isFileUploading = false;
      state.successMessage = "見積をアップロードしました";
      state.isLoading = false;
      state.uploadedEstimates = [];
      state.uploadedAttachments = null;
    });
    builder.addCase(createProjectEstimate.rejected, (state, action) => {
      state.isLoading = false;
      state.isFileUploading = false;
      state.uploadedEstimates = [];
      state.uploadedAttachments = null;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
  },
});

export const selectProjectSidebar = (state: RootState) => state.projectSidebar;
export default projectSidebarSlice.reducer;
