import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import JSZip from "jszip";

// 画像の一括ダウンロード
export const downloadImages = async (
  sources: { url: string; name: string }[],
  folderName: string,
) => {
  const zip = new JSZip();
  const folder = zip.folder(folderName) || zip;
  const fileNamesCount: { [key: string]: number } = {};

  for (const { url, name } of sources) {
    const blob = await fetchImageAsBlob(url);
    if (blob) {
      const fileName = generateFileName(name, fileNamesCount[name] || 0);
      fileNamesCount[name] = (fileNamesCount[name] || 0) + 1;
      folder.file(fileName, blob);
    }
  }

  await generateAndDownloadZip(zip, folderName);
};

const sanitizeFileName = (fileName: string): string => {
  return fileName.replace(/[<>:"/\\|?*]/g, "_");
};

export const downloadImagesV2 = async (photos: PhotoResponse[], fileName: string) => {
  const zip = new JSZip();
  const fileNamesCount: { [key: string]: number } = {};

  const topLevelFolder = zip.folder(sanitizeFileName(fileName));
  if (!topLevelFolder) return;

  for (const photo of photos) {
    const { tertiaryCategory, fileUrl, fileName: photoFileName } = photo;

    const tertiaryFolderName = sanitizeFileName(tertiaryCategory.name);

    const folderPath = tertiaryFolderName;
    const folder = topLevelFolder.folder(folderPath);

    if (folder) {
      const blob = await fetchImageAsBlob(fileUrl);
      if (blob) {
        const safeFileName = sanitizeFileName(photoFileName);
        const count = fileNamesCount[safeFileName] || 0;
        const uniqueFileName = count > 0 ? `${safeFileName}_${count}` : safeFileName;

        folder.file(uniqueFileName, blob);
        fileNamesCount[safeFileName] = count + 1;
      }
    }
  }

  await generateAndDownloadZip(zip, sanitizeFileName(fileName));
};
export const downloadImageAll = async (photos: PhotoResponse[], fileName: string) => {
  const zip = new JSZip();
  const fileNamesCount: { [key: string]: number } = {};

  const topLevelFolder = zip.folder(sanitizeFileName(fileName));
  if (!topLevelFolder) return;

  for (const photo of photos) {
    const {
      primaryCategory,
      secondaryCategory,
      tertiaryCategory,
      fileUrl,
      fileName: photoFileName,
    } = photo;

    const primaryFolderName = sanitizeFileName(primaryCategory.name);
    const secondaryFolderName = sanitizeFileName(secondaryCategory.name);
    const tertiaryFolderName = sanitizeFileName(tertiaryCategory.name);

    const folderPath = `${primaryFolderName}/${secondaryFolderName}/${tertiaryFolderName}`;
    const folder = topLevelFolder.folder(folderPath);

    if (folder) {
      const blob = await fetchImageAsBlob(fileUrl);
      if (blob) {
        const safeFileName = sanitizeFileName(photoFileName);
        const count = fileNamesCount[safeFileName] || 0;
        const uniqueFileName = count > 0 ? `${safeFileName}_${count}` : safeFileName;

        folder.file(uniqueFileName, blob);
        fileNamesCount[safeFileName] = count + 1;
      }
    }
  }

  await generateAndDownloadZip(zip, sanitizeFileName(fileName));
};

// 画像をBlobとして取得する共通関数
const fetchImageAsBlob = async (url: string): Promise<Blob | null> => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Network response was not ok");
    return await response.blob();
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
};

// ファイル名の重複を回避するためのファイル名を生成する関数
const generateFileName = (originalName: string, count: number): string => {
  if (count === 0) return originalName;
  const dotIndex = originalName.lastIndexOf(".");
  if (dotIndex === -1) return `${originalName}(${count})`;
  return `${originalName.substring(0, dotIndex)}(${count})${originalName.substring(dotIndex)}`;
};

// ZIP ファイルの生成とダウンロード
const generateAndDownloadZip = async (zip: JSZip, folderName: string) => {
  const blob = await zip.generateAsync({ type: "blob" });
  const dataUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = dataUrl;
  link.download = `${folderName}.zip`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => URL.revokeObjectURL(dataUrl), 1000);
};
