import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  NoticeConfirmedScheduleCreationIndexResponse,
  NoticeConfirmedScheduleCreationUpdateRequest,
} from "./confirmed-schedule-creation.dto";

export const confirmedScheduleCreationRepository = {
  show(): Promise<NoticeConfirmedScheduleCreationIndexResponse> {
    return ApiClient.get("/api/v1/notice/confirmed_schedule_creations/setting").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(body: NoticeConfirmedScheduleCreationUpdateRequest): Promise<void> {
    return ApiClient.put(
      "/api/v1/notice/confirmed_schedule_creations/setting",
      updateBody(body),
    ).then((res) => res.data);
  },
};

const updateBody = (body: NoticeConfirmedScheduleCreationUpdateRequest) => {
  return {
    subscriber_types: body.subscriberTypes,
    is_enable: body.isEnable,
  };
};
