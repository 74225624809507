import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    badge: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grayScale[0],
      gap: "4px",
      width: "80px",
      height: "28px",
      borderRadius: "14px",
    },
    card: {
      backgroundColor: theme.palette.customPrimary[50],
      width: "248px",
      height: "72px",
      borderRadius: "4px",
      padding: "4px 8px",
      marginRight: "12px",
      marginBottom: "12px",
    },
    name: {
      width: "180px",
      fontSize: "12px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }),
);
