import { useState } from "react";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";

export const usePhotoPreview = (photos: PhotoResponse[] | undefined) => {
  const [photoPreviewState, setPhotoPreviewState] = useState<{
    isOpen: boolean;
    photos: PhotoResponse[];
    targetIndex: number;
  }>({ isOpen: false, photos: [], targetIndex: 0 });

  const handleClickPhotoPreview = (targetPhoto: PhotoResponse) => {
    if (!photos) return;
    const clickedPhotoInfo = photos.find(
      (photo) =>
        photo.id === targetPhoto.id &&
        photo.projectAttributes === targetPhoto.projectAttributes &&
        photo.workReportAttributes === targetPhoto.workReportAttributes,
    );

    if (!clickedPhotoInfo) return;

    const clickedPhotoIndex = photos.indexOf(clickedPhotoInfo);
    setPhotoPreviewState({ isOpen: true, photos, targetIndex: clickedPhotoIndex });
  };

  return { photoPreviewState, setPhotoPreviewState, handleClickPhotoPreview };
};
