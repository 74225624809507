import { Box, Typography, Divider } from "@mui/material";
import { WorkReport, WorkReportId } from "data-access/repositories/work_report/work_report.dto";
import { theme } from "extensions/theme";
import { timeRange } from "utils/timeRange";
import { usersName } from "utils/usersName";
import { styles } from "./styles";

interface Props {
  workReport: WorkReport;
  setShow: (e: { isOpen: boolean; id: WorkReportId | undefined }) => void;
}

export const WorkReportBlock = (props: Props) => {
  const { workReport } = props;
  const classes = styles();

  const handleShow = () => {
    props.setShow({ isOpen: true, id: workReport.id });
  };

  return (
    <Box
      className={classes.block}
      key={workReport.id}
      sx={{ width: "100%", flexDirection: "column", display: "flex", cursor: "pointer" }}
      onClick={handleShow}
    >
      <Box sx={{ width: "100%", flexDirection: "column", display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              height: "auto",
              lineHeight: "auto",
              mb: "6px",
            }}
          >
            {workReport.site_name}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", flexDirection: "row", display: "flex" }}>
          <Box sx={{ width: "70%" }}>
            <Typography fontSize="0.8rem" sx={{ mb: "0.375rem" }}>
              {timeRange(
                "yyyy年MM月dd日 HH:mm",
                workReport.start_time.toString(),
                workReport.end_time.toString(),
              )}
            </Typography>
            <Typography fontSize="0.8rem" sx={{ mb: "0.375rem" }}>
              現場責任者：{workReport.site_manager?.name}
            </Typography>
            <Typography
              fontSize="0.8rem"
              sx={{
                mb: "0.375rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": "2",
              }}
            >
              参加者({workReport.users.length}名)：{usersName(workReport.users)}
            </Typography>
            <Typography fontSize="0.8rem">
              作業の進捗：{workReport.work_status_type_i18n}
            </Typography>
          </Box>
          {workReport.header_photos.length > 0 && (
            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Box
                component="img"
                className={classes.image}
                alt={`photo-${workReport.header_photos[0].id}`}
                src={workReport.header_photos[0].thumbnail_url}
                sx={{ ml: "1rem" }}
              />
            </Box>
          )}
        </Box>
      </Box>
      {workReport.content !== "" && workReport.content.length < 100 && (
        <>
          <Divider sx={{ my: "0.75rem" }} />
          <Box>
            <Typography fontSize="0.8rem">{workReport.content}</Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
