import { ConfirmDialog } from "components/molecules/confirm-dialog";
import { discardChangesConfirmDialogOperations } from "store/discard-changes-confirm-dialog/operations";
import { selectDiscardChangesConfirmDialog } from "store/discard-changes-confirm-dialog/slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

export const DiscardChangesConfirmDialog = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectDiscardChangesConfirmDialog);

  const handleNo = () => {
    dispatch(discardChangesConfirmDialogOperations.close());
  };

  const handleYes = () => {
    dispatch(discardChangesConfirmDialogOperations.close());
    dispatch(discardChangesConfirmDialogOperations.updateIsConfirm(true));
  };

  return (
    <ConfirmDialog
      content="保存されていない変更を破棄しますか？"
      yesButtonText="保存せず破棄"
      handleNo={handleNo}
      handleYes={handleYes}
      isOpen={state.isOpen}
    />
  );
};
