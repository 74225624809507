import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { CustomTabs } from "components/molecules/custom-tabs";
import { Layout } from "components/templates/layout";
import { OvertimeRecord } from "./overtime-record";
import { Project } from "./project";
import { Schedule } from "./schedule";

type TabType = "schedule" | "project" | "overtimeRecord";

export const NoticeSetting = () => {
  const [tab, setTab] = useState<TabType>("schedule");

  return (
    <Layout>
      <Typography fontWeight="bold" fontSize="24px" mb="20px">
        通知設定
      </Typography>

      <Box sx={{ mb: 2 }}>
        <CustomTabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: TabType) => setTab(newValue)}
          tabs={[
            { value: "schedule", label: "予定" },
            { value: "project", label: "案件" },
            { value: "overtimeRecord", label: "残業時間アラート" },
          ]}
        />
      </Box>

      {tab === "schedule" && <Schedule />}
      {tab === "project" && <Project />}
      {tab === "overtimeRecord" && <OvertimeRecord />}
    </Layout>
  );
};
