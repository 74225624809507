import { createStyles, makeStyles } from "@mui/styles";

export const styles = makeStyles(() =>
  createStyles({
    none: {
      display: "none",
    },
    noDateEvents: {
      padding: "0 0.5rem",
    },
  }),
);
