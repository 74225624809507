import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { formatDateUtil } from "utils/formatDateUtil";
import { ApiClient } from "../../../../data-access/ApiClient";
import { AttendanceIndexResponse } from "../../types/daily_record/attendance.employee.dto";

export const attendanceEmployeeRepository = {
  index(date: Date): Promise<AttendanceIndexResponse> {
    const formatDate = formatDateUtil(date.toString(), "yyyy-MM-dd");
    return ApiClient.get(`/api/v1/attendances/daily_records/${formatDate}/employees`).then(
      (res) => {
        return convertKeysToCamelCase(res.data);
      },
    );
  },
};
