import { Button } from "@mui/material";

interface Props {
  name: string;
  onClick: () => void;
  disabled?: boolean;
  sx?: object;
}

export const PrimaryButton = (props: Props) => {
  return (
    <Button
      variant="contained"
      onClick={props.onClick}
      disabled={props.disabled}
      sx={{
        height: "34px",
        borderRadius: "16px",
        py: 1,
        px: 2,
        fontSize: "12px",
        lineHeight: "34px",
        textAlign: "center",
        display: "flex",
        ml: "16px",
        ...props.sx,
      }}
    >
      {props.name}
    </Button>
  );
};
