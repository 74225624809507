import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiPaper-root": {
        padding: "1rem",
      },
    },
    title: {
      display: "flex",
      justifyContent: "flex-end",
      padding: 0,
      color: theme.palette.grayScale[900],
    },
  }),
);
