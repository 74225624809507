import { Box, Typography } from "@mui/material";
import { GroupLabel } from "components/label/group-label";
import { theme } from "extensions/theme";
import { AttendanceShowResponse } from "features/attendance/types/monthly_record/attendance.employee.dto";

interface Props {
  attendanceEmployee: AttendanceShowResponse;
}

export const Infos = (props: Props) => {
  const { attendanceEmployee } = props;

  return (
    <Box sx={{ mb: "16px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "16px", mb: "24px" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
          {attendanceEmployee.name}
        </Typography>
        {attendanceEmployee.group && (
          <GroupLabel
            name={attendanceEmployee.group.name}
            colorNumber={attendanceEmployee.group.colorNumber}
          />
        )}
      </Box>
      <div>
        <Typography color="primary" sx={{ fontSize: "14px", fontWeight: "500", mb: "16px" }}>
          基本情報
        </Typography>
        <div style={{ display: "flex", gap: "16px" }}>
          <div
            style={{
              display: "flex",
              height: "42px",
              width: "280px",
              border: `1px solid ${theme.palette.grayScale[300]}`,
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                width: "40%",
                height: "100%",
                lineHeight: "42px",
                pl: "12px",
                fontSize: "14px",
                fontWeight: "500",
                bgcolor: theme.palette.customPrimary[50],
                borderRight: `1px solid ${theme.palette.grayScale[300]}`,
              }}
            >
              労働時間
            </Typography>
            <Typography
              sx={{
                lineHeight: "42px",
                fontSize: "14px",
                pl: "12px",
              }}
            >
              {attendanceEmployee.summary.laborHours}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              height: "42px",
              width: "280px",
              border: `1px solid ${theme.palette.grayScale[300]}`,
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                width: "40%",
                height: "100%",
                lineHeight: "42px",
                pl: "12px",
                fontSize: "14px",
                fontWeight: "500",
                bgcolor: theme.palette.customPrimary[50],
                borderRight: `1px solid ${theme.palette.grayScale[300]}`,
              }}
            >
              出勤日数
            </Typography>
            <Typography
              sx={{
                lineHeight: "42px",
                fontSize: "14px",
                pl: "12px",
              }}
            >
              {attendanceEmployee.summary.numberOfWorkingDays}日
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  );
};
