interface ClientInfo {
  postal_code: string;
  address: string;
  address_second: string;
}

export const mailingAddress = (client: ClientInfo): string => {
  return `〒${client.postal_code}
${client.address}
${client.address_second}`;
};
