import { useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { noticeProjectStatusChangeRepository } from "data-access/repositories/notice/project-status/project_status_change.repository";
import { theme } from "extensions/theme";
import useSWR from "swr";
import { ProjectStatus } from "./project-status-change";

export const Project = () => {
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);
  const fetchIndexKey = "/api/v1/notice/project_status_types/settings";

  const { data, isValidating } = useSWR(fetchIndexKey, noticeProjectStatusChangeRepository.index, {
    revalidateOnFocus: false,
  });

  return (
    <>
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
        open={isFetchLoading || isValidating}
        invisible
      >
        <CircularProgress />
      </Backdrop>

      <ProjectStatus
        data={data || []}
        fetchIndexKey={fetchIndexKey}
        setIsLoading={setIsFetchLoading}
      />
    </>
  );
};
