import { imagePreviewModalSlice } from "./slice";
import { AppDispatch } from "../store";

export const imagePreviewModalOperations = {
  setImage: (image: { name: string; url: string }) => (dispatch: AppDispatch) => {
    dispatch(imagePreviewModalSlice.actions.setImage({ image }));
  },
  open: () => (dispatch: AppDispatch) => {
    dispatch(imagePreviewModalSlice.actions.open());
  },
  close: () => (dispatch: AppDispatch) => {
    dispatch(imagePreviewModalSlice.actions.close());
  },
};
