import { useNavigate } from "react-router-dom";
import { Edit, GroupAdd } from "@mui/icons-material";
import {
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { ConfirmSchedule } from "components/icon/confirm-schedule-icon";
import { DeleteIcon } from "components/icon/delete-icon";
import { ScheduleDuplicate } from "components/icon/duplicate-schedule-icon";
import { UnconfirmSchedule } from "components/icon/unconfirm-schedule-icon";
import { ScheduleId } from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { User } from "data-access/repositories/user/user.dto";
import { theme } from "extensions/theme";
import { useScrollToPosition } from "hooks/useScrollToPosition";
import { calendarOperations } from "store/calendar/operations";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { scheduleDeleteConfirmDialogOperations } from "store/schedule-delete-confirm-dialog/operations";
import { scheduleRightClickMenuOperations } from "store/schedule-right-click-menu/operations";
import { selectScheduleRightClickMenu } from "store/schedule-right-click-menu/slice";
import useSWR from "swr";
import Cookies from "universal-cookie";

interface Props {
  viewType: "dayGridMonth" | "resourceTimelineWeek" | "resourceTimelineDay";
  setSelectAssignmentsModal: (isOpen: boolean, scheduleId: ScheduleId, assignments: User[]) => void;
  setIsEdit: (isEdit: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  onDelete: (isOpen: boolean, id: ScheduleId) => void;
  setRecurrenceDialog: (
    isOpen: boolean,
    id: ScheduleId,
    type: "delete" | "update",
    onUpdateFunc?: (withFutures: boolean) => Promise<void>,
  ) => void;
}

export const ScheduleRightClickMenu = (props: Props) => {
  const {
    viewType,
    setSelectAssignmentsModal,
    setIsEdit,
    setIsLoading,
    onDelete,
    setRecurrenceDialog,
  } = props;
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectScheduleRightClickMenu);
  const navigate = useNavigate();
  const { scrollToSavedPosition } = useScrollToPosition("calendar");

  const { data: schedule } = useSWR(
    `/api/v1/schedule/${state.object.id}`,
    state.isOpen ? () => scheduleRepository.show(state.object.id as ScheduleId) : null,
  );

  if (!schedule) return null;

  const isMemberDuplicateAndDeleteDisplay =
    (viewType === "resourceTimelineDay" || viewType === "resourceTimelineWeek") &&
    state.object.userId !== 0;

  const handleClick = async (e: any) => {
    const { value } = e.currentTarget.dataset;
    switch (value) {
      case "createAssignment":
        setSelectAssignmentsModal(true, schedule.id, schedule.users);
        break;
      case "duplicateDate":
        switch (viewType) {
          case "dayGridMonth":
            dispatch(scheduleRightClickMenuOperations.duplicateGridSchedule(schedule.id));
            break;
          default:
            dispatch(scheduleRightClickMenuOperations.duplicateTimelineSchedule(schedule.id));
        }
        break;
      case "confirmSchedule":
      case "unconfirmSchedule":
        if (schedule.recurrence_rule) {
          const updateFunc = async (withFutures: boolean) => {
            setIsLoading(true);
            try {
              await scheduleRepository.update(schedule.id, withFutures, {
                isConfirmed: value === "confirmSchedule",
              });
              dispatch(mainOperations.updateSuccessMessage("予定を変更しました"));
            } catch {
              dispatch(mainOperations.updateErrorMessage("予定を変更できませんでした"));
            } finally {
              setIsLoading(false);
              scrollToSavedPosition();
            }
          };
          setRecurrenceDialog(true, schedule.id as ScheduleId, "update", updateFunc);
        } else {
          dispatch(
            scheduleRightClickMenuOperations.updateGridSchedule(schedule.id, false, {
              isConfirmed: value === "confirmSchedule",
            }),
          );
        }
        break;
      case "scheduleEdit":
        setIsEdit(true);
        navigate(`/calendar/${schedule.id}`, { state: { isToRightClick: true } });
        break;
      case "scheduleDelete": {
        if (schedule.recurrence_rule) {
          setRecurrenceDialog(true, schedule.id as ScheduleId, "delete");
          return;
        }
        const cookies = new Cookies();
        const isDeleteConfirmNotShowAgain = cookies.get("isScheduleDeleteConfirmNotShowAgain");
        if (isDeleteConfirmNotShowAgain) {
          dispatch(calendarOperations.destroy(schedule.id as ScheduleId, false));
        } else {
          onDelete(true, schedule.id);
          dispatch(
            scheduleDeleteConfirmDialogOperations.setObject({
              id: schedule.id,
              name: schedule.name,
            }),
          );
          dispatch(scheduleDeleteConfirmDialogOperations.open());
        }
        break;
      }
    }
    dispatch(scheduleRightClickMenuOperations.close());
  };

  const handleClose = () => {
    dispatch(scheduleRightClickMenuOperations.close());
  };

  return (
    <Popper
      open={state.isOpen}
      anchorEl={state.anchorEl}
      style={{ zIndex: 1001 }}
      placement="right"
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList>
            {isMemberDuplicateAndDeleteDisplay && (
              <MenuItem data-value={"createAssignment"} onClick={handleClick}>
                <ListItemIcon>
                  <GroupAdd />
                </ListItemIcon>
                <ListItemText primary="参加者を編集" />
              </MenuItem>
            )}

            {(viewType === "dayGridMonth" || viewType === "resourceTimelineWeek") && (
              <MenuItem data-value={"duplicateDate"} onClick={handleClick}>
                <ListItemIcon>
                  <ScheduleDuplicate />
                </ListItemIcon>
                <ListItemText primary="別の日に複製" />
              </MenuItem>
            )}

            {schedule.is_confirmed ? (
              <MenuItem data-value={"unconfirmSchedule"} onClick={handleClick}>
                <ListItemIcon>
                  <UnconfirmSchedule />
                </ListItemIcon>
                <ListItemText primary="予定を未確定" />
              </MenuItem>
            ) : (
              <MenuItem data-value={"confirmSchedule"} onClick={handleClick}>
                <ListItemIcon>
                  <ConfirmSchedule />
                </ListItemIcon>
                <ListItemText primary="予定を確定" />
              </MenuItem>
            )}

            <MenuItem data-value={"scheduleEdit"} onClick={handleClick}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="予定を編集" />
            </MenuItem>

            <MenuItem data-value={"scheduleDelete"} onClick={handleClick}>
              <ListItemIcon sx={{ color: theme.palette.red[500] }}>
                <DeleteIcon size={22} />
              </ListItemIcon>
              <ListItemText
                primary="予定を削除"
                primaryTypographyProps={{
                  color: theme.palette.red[500],
                }}
              />
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};
