import { Box, Typography } from "@mui/material";
import { StatusTypeLabel } from "components/label/status-type-label";
import { theme } from "extensions/theme";
import { AttendanceShowResponse } from "features/attendance/types/monthly_record/attendance.employee.dto";
import { DaysOfWeek } from "utils/constant";
import { formatDateUtil } from "utils/formatDateUtil";

interface Props {
  attendanceEmployee: AttendanceShowResponse;
}

export const List = (props: Props) => {
  const { attendanceEmployee } = props;

  return (
    <div style={{ paddingBottom: "16px" }}>
      <Typography color="primary" sx={{ fontSize: "14px", fontWeight: "500", mb: "16px" }}>
        出勤日一覧
      </Typography>
      {attendanceEmployee.attendances.map((attendance, index) => (
        <Box
          key={index}
          sx={{
            border: `1px solid ${theme.palette.grayScale[300]}`,
            borderRadius: "8px",
            p: "12px 16px",
            mb: "8px",
            bgcolor: attendance.timestamps.length > 0 ? "inherit" : theme.palette.customPrimary[50],
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
            <div style={{ display: "flex", alignItems: "end" }}>
              <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px", mr: "2px" }}>
                {new Date(attendance.date).getMonth() + 1}/
              </Typography>
              <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                {new Date(attendance.date).getDate()}
              </Typography>
            </div>
            <Typography>{DaysOfWeek[new Date(attendance.date).getDay()]}</Typography>

            {attendance.timestamps.length > 0 ? (
              <div style={{ display: "flex", alignItems: "end", gap: "8px" }}>
                <Typography
                  sx={{ color: theme.palette.grayScale[700], fontSize: "14px", fontWeight: "500" }}
                >
                  労働時間
                </Typography>
                <Typography>{attendance.laborHours}</Typography>
              </div>
            ) : (
              <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                打刻記録がありません
              </Typography>
            )}
          </div>
          {attendance.timestamps.length > 0 && (
            <>
              <div
                style={{
                  border: `1px solid ${theme.palette.grayScale[300]}`,
                  borderRadius: "8px",
                  marginTop: "16px",
                  marginBottom: "12px",
                }}
              >
                {attendance.timestamps.map((timestamp, index, array) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom:
                        index === array.length - 1
                          ? "none"
                          : `1px solid ${theme.palette.grayScale[300]}`,
                      height: "60px",
                    }}
                  >
                    <Box
                      sx={{
                        lineHeight: "60px",
                        width: "100px",
                        textAlign: "center",
                        height: "100%",
                        borderRight: `1px solid ${theme.palette.grayScale[300]}`,
                      }}
                    >
                      <StatusTypeLabel
                        statusType={timestamp.stampType.valueI18n}
                        sx={{ display: "inline" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "70px",
                        textAlign: "center",
                        height: "100%",
                        borderRight: `1px solid ${theme.palette.grayScale[300]}`,
                      }}
                    >
                      <Typography>
                        {new Date(attendance.date).getDate() ===
                        new Date(timestamp.datetime).getDate()
                          ? "当日"
                          : "翌日"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "70px",
                        textAlign: "center",
                        height: "100%",
                        borderRight: `1px solid ${theme.palette.grayScale[300]}`,
                      }}
                    >
                      <Typography>{formatDateUtil(timestamp.datetime, "HH:mm")}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: "10px",
                        width: "250px",
                        height: "100%",
                        borderRight: `1px solid ${theme.palette.grayScale[300]}`,
                        whiteSpace: "nowrap",
                        flex: 1,
                        overflowX: "scroll",
                      }}
                    >
                      <Typography fontSize="14px">{timestamp.address || "-"}</Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          width: "50px",
                          fontSize: "12px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        {timestamp.isEdited && "編集済"}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </div>
              <Typography color="primary" sx={{ fontSize: "14px", fontWeight: "500", mb: "8px" }}>
                連絡事項
              </Typography>
              <Typography fontSize="14px">{attendance.note}</Typography>
            </>
          )}
        </Box>
      ))}
    </div>
  );
};
