import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    scheduleAdjustment: {
      backgroundColor: theme.palette.green[200],
    },
    estimateSubmission: {
      backgroundColor: theme.palette.customPrimary[100],
    },
    materialOrdering: {
      backgroundColor: theme.palette.blue[200],
    },
    partnerOrder: {
      backgroundColor: theme.palette.primary.light,
    },
    drawingCreation: {
      backgroundColor: theme.palette.brown[200],
    },
    sitePreview: {
      backgroundColor: theme.palette.red[200],
    },
    default: {
      backgroundColor: theme.palette.grayScale[200],
    },
  }),
);
