import React from "react";

export const UserSettingsIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.61127 1.3335C9.64267 1.3335 10.6318 1.75053 11.3611 2.49286C12.0904 3.2352 12.5002 4.24201 12.5002 5.29183C12.5002 6.34164 12.0904 7.34846 11.3611 8.09079C10.6318 8.83313 9.64267 9.25016 8.61127 9.25016C7.57988 9.25016 6.59072 8.83313 5.86141 8.09079C5.13211 7.34846 4.72239 6.34164 4.72239 5.29183C4.72239 4.24201 5.13211 3.2352 5.86141 2.49286C6.59072 1.75053 7.57988 1.3335 8.61127 1.3335ZM8.61127 3.31266C8.09558 3.31266 7.601 3.52118 7.23635 3.89235C6.87169 4.26351 6.66683 4.76692 6.66683 5.29183C6.66683 5.81674 6.87169 6.32015 7.23635 6.69131C7.601 7.06248 8.09558 7.271 8.61127 7.271C9.12697 7.271 9.62155 7.06248 9.9862 6.69131C10.3509 6.32015 10.5557 5.81674 10.5557 5.29183C10.5557 4.76692 10.3509 4.26351 9.9862 3.89235C9.62155 3.52118 9.12697 3.31266 8.61127 3.31266ZM8.61127 10.2397C9.27239 10.2397 10.0696 10.3288 10.9543 10.497L9.33072 12.1496L8.61127 12.12C5.72377 12.12 2.68072 13.5647 2.68072 14.1981V15.2866H8.7085L10.5557 17.1668H0.833496V14.1981C0.833496 11.5658 6.01544 10.2397 8.61127 10.2397Z"
        fill="currentColor"
      />
      <path
        d="M14.903 10.5C14.852 10.4991 14.8024 10.5161 14.7628 10.5481C14.7232 10.5801 14.6961 10.6251 14.6863 10.675L14.528 11.775C14.278 11.8834 14.0363 12.0167 13.8196 12.1667L12.7863 11.75C12.6946 11.75 12.5863 11.75 12.528 11.8584L11.6946 13.3C11.6446 13.3917 11.6613 13.5 11.7446 13.5667L12.628 14.25C12.5947 14.5268 12.5947 14.8066 12.628 15.0834L11.7446 15.7667C11.7072 15.7988 11.6818 15.8428 11.6727 15.8912C11.6636 15.9397 11.6714 15.9899 11.6946 16.0334L12.528 17.475C12.578 17.5834 12.6863 17.5834 12.7863 17.5834L13.8196 17.1667C14.0363 17.3167 14.2696 17.4584 14.528 17.5584L14.6863 18.6584C14.703 18.7584 14.7863 18.8334 14.903 18.8334H16.5696C16.6613 18.8334 16.753 18.7584 16.7696 18.6584L16.928 17.5584C17.178 17.45 17.403 17.3167 17.628 17.1667L18.653 17.5834C18.7613 17.5834 18.8696 17.5834 18.928 17.475L19.7613 16.0334C19.7846 15.9899 19.7923 15.9397 19.7832 15.8912C19.7741 15.8428 19.7487 15.7988 19.7113 15.7667L18.8196 15.0834C18.8363 14.9417 18.853 14.8084 18.853 14.6667C18.853 14.525 18.8446 14.3917 18.8196 14.25L19.703 13.5667C19.7404 13.5346 19.7658 13.4907 19.7749 13.4422C19.784 13.3937 19.7762 13.3435 19.753 13.3L18.9196 11.8584C18.8696 11.75 18.7613 11.75 18.653 11.75L17.628 12.1667C17.403 12.0167 17.178 11.875 16.9196 11.775L16.7613 10.675C16.756 10.6276 16.7337 10.5836 16.6984 10.5514C16.6632 10.5192 16.6174 10.501 16.5696 10.5H14.903ZM15.7363 13.4167C16.428 13.4167 16.9863 13.975 16.9863 14.6667C16.9863 15.3584 16.428 15.9167 15.7363 15.9167C15.0363 15.9167 14.4863 15.3584 14.4863 14.6667C14.4863 13.975 15.0446 13.4167 15.7363 13.4167Z"
        fill="currentColor"
      />
    </svg>
  );
};
