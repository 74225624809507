import { Button, Typography } from "@mui/material";
import { PageState } from ".";

interface Props {
  setPage: (page: PageState) => void;
}
export const ConfirmPasswordReset = (props: Props) => {
  const { setPage } = props;

  const handleClick = () => {
    setPage("signInForm");
  };
  return (
    <>
      <Typography sx={{ fontSize: "28px", fontWeight: "bold", mb: "16px" }}>
        パスワードの再設定完了
      </Typography>
      <Typography sx={{ fontSize: "14px", mb: "60px" }}>
        パスワードの再設定が完了しました。
      </Typography>
      <Button onClick={handleClick} variant="contained" fullWidth>
        ログイン画面へ
      </Button>
    </>
  );
};
