import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface scheduleDeleteConfirmDialogState {
  isOpen: boolean;
  object: {
    id: number;
    name: string;
  };
  isDeleteConfirmNotShowAgain: boolean;
}

const initialState: scheduleDeleteConfirmDialogState = {
  isOpen: false,
  object: {
    id: 0,
    name: "",
  },
  isDeleteConfirmNotShowAgain: false,
};

export const scheduleDeleteConfirmDialogSlice = createSlice({
  name: "scheduleDeleteConfirmDialog",
  initialState,
  reducers: {
    setObject: (state, action: PayloadAction<{ value: { id: number; name: string } }>) => ({
      ...state,
      object: action.payload.value,
    }),
    updateIsDeleteConfirmNotShowAgain: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isDeleteConfirmNotShowAgain: action.payload.value,
    }),
    open: (state) => ({
      ...state,
      isOpen: true,
    }),
    close: (state) => ({
      ...state,
      isOpen: false,
      isDeleteConfirmNotShowAgain: false,
    }),
  },
  extraReducers: () => {},
});

export const selectScheduleDeleteConfirmDialog = (state: RootState) =>
  state.scheduleDeleteConfirmDialog;
export default scheduleDeleteConfirmDialogSlice.reducer;
