import { Layout } from "components/templates/layout";
import { Attendance } from "features/attendance/components";

export default function Attendances() {
  return (
    <Layout>
      <Attendance />
    </Layout>
  );
}
