import { theme } from "extensions/theme";

type Props = {
  size?: number;
  color?: string;
};

export const ScheduleDuplicate = (props: Props) => {
  return (
    <svg
      width={props.size || 22}
      height={props.size || 22}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5526 16V13.6H9.18421V12H11.5526V9.6H13.1316V12H15.5V13.6H13.1316V16H11.5526ZM2.07895 14.4C1.64474 14.4 1.27289 14.2435 0.963421 13.9304C0.654474 13.6168 0.5 13.24 0.5 12.8V3.2C0.5 2.76 0.654474 2.38347 0.963421 2.0704C1.27289 1.7568 1.64474 1.6 2.07895 1.6H2.86842V0H4.44737V1.6H9.18421V0H10.7632V1.6H11.5526C11.9868 1.6 12.3587 1.7568 12.6682 2.0704C12.9771 2.38347 13.1316 2.76 13.1316 3.2V8.08C12.8684 8.04 12.6053 8.02 12.3421 8.02C12.0789 8.02 11.8158 8.04 11.5526 8.08V6.4H2.07895V12.8H7.60526C7.60526 13.0667 7.625 13.3333 7.66447 13.6C7.70395 13.8667 7.77632 14.1333 7.88158 14.4H2.07895ZM2.07895 4.8H11.5526V3.2H2.07895V4.8ZM2.07895 4.8V3.2V4.8Z"
        fill={props.color || theme.palette.grayScale[700]}
      />
    </svg>
  );
};
