import { makeStyles } from "@mui/styles";
import { theme } from "../../../extensions/theme";

interface StyleProps {
  isRead: boolean;
  noticeType: string;
}

export const useStyles = makeStyles(() => ({
  icon: {
    minWidth: "26px",
    height: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginRight: "8px",
    backgroundColor: (props: StyleProps) => {
      if (props.noticeType === "overtime_records") {
        return props.isRead ? theme.palette.red[100] : theme.palette.red[500];
      }
      return props.isRead ? theme.palette.customPrimary[100] : theme.palette.primary.main;
    },
  },
  title: {
    fontSize: "12px !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: (props: StyleProps) => (props.isRead ? "bold !important" : "500 !important"),
    color: (props: StyleProps) => {
      if (props.noticeType === "overtime_records") {
        return theme.palette.red[500];
      }
      return props.isRead ? theme.palette.primary.main : "inherit";
    },
  },
}));
