export const downloadBlob = (blob: Blob, fileName: string): void => {
  // 利用しているOSやブラウザ情報を取得
  const ua = window.navigator.userAgent.toLowerCase();

  // macOSの場合は、ファイル名をNFDで正規化する
  const normalizedFileName = ua.includes("mac os x") ? fileName.normalize("NFD") : fileName;
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", normalizedFileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
