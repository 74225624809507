import { formatDateUtil } from "./formatDateUtil";

export const timeRange = (format: string, startTimeStr?: string, endTimeStr?: string): string => {
  if (!startTimeStr || !endTimeStr) {
    return "未設定";
  }
  const startTime = new Date(startTimeStr);
  const endTime = new Date(endTimeStr);
  const resultStartTime = formatDateUtil(new Date(startTime), format);

  if (
    startTime.getFullYear() === endTime.getFullYear() &&
    startTime.getMonth() === endTime.getMonth() &&
    startTime.getDate() === endTime.getDate()
  ) {
    return resultStartTime + "~" + formatDateUtil(endTime, "HH:mm");
  } else {
    if (format.includes("yyyy")) {
      format = format.replace(/yyyy.?/, "");
    }
    return resultStartTime + "~" + formatDateUtil(endTime, format);
  }
};
