import { RecurrenceRuleParams } from "data-access/repositories/recurrence_rule/recurrence_rule.dto";

export const isRecurrenceRuleChanged = (
  response: RecurrenceRuleParams | undefined,
  request: RecurrenceRuleParams | undefined,
): boolean => {
  if (!response && request) return true;
  if (response && !request) return true;
  if (!response && !request) return false;

  if (response?.interval !== request?.interval) return true;
  if (response?.frequencyType !== request?.frequencyType) return true;
  if (response?.endType !== request?.endType) return true;
  if (response?.daysOfWeek?.join(",") !== request?.daysOfWeek?.join(",")) return true;
  if (request?.endType === "count_specify") {
    if (response?.count !== request?.count) return true;
  }
  if (request?.endType === "end_date_specify") {
    if (response?.endDate !== request?.endDate) return true;
  }

  return false;
};
