import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { theme } from "extensions/theme";
import {
  ClientServiceReportFormatId,
  ClientServiceReportFormatsResponse,
} from "features/client-service-report/types/client_service_report_format.dto";

const SelectedRadioButton = (isSelected: boolean) => ({
  padding: "12px 8px",
  border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.grayScale[300]}`,
  borderRadius: "8px",
  backgroundColor: isSelected ? theme.palette.customPrimary[50] : theme.palette.grayScale[0],
  color: theme.palette.primary.main,
  "& .MuiFormControlLabel-label": { fontWeight: "500", fontSize: "14px" },
});

interface Props {
  formats: ClientServiceReportFormatsResponse;
  isLoading: boolean;
  selectedLayoutId: ClientServiceReportFormatId;
  selectedLayoutIdSetter: (id: ClientServiceReportFormatId) => void;
  stepsSetter: (steps: string[]) => void;
}

export const FormatSelect = (props: Props) => {
  const { formats, isLoading, selectedLayoutId, selectedLayoutIdSetter, stepsSetter } = props;

  const handleRadio = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    selectedLayoutIdSetter(Number(value) as ClientServiceReportFormatId);
    stepsSetter([
      "書式",
      ...formats
        .filter((format) => format.id === Number(value))[0]
        .pages.map((page) => {
          return page.excelSheetName;
        }),
    ]);
  };
  return (
    <>
      <Typography sx={{ fontSize: "12px", fontWeight: "500", textAlign: "center", mb: "16px" }}>
        {isLoading ? "書式を読み込み中です。" : "報告書の書式を選択してください。"}
      </Typography>
      <RadioGroup
        name="selectLayout"
        row
        onChange={handleRadio}
        sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
      >
        {formats?.map((format, index) => (
          <FormControlLabel
            key={index}
            control={<Radio size="small" checked={selectedLayoutId === format.id} />}
            value={format.id}
            sx={SelectedRadioButton(selectedLayoutId === format.id)}
            label={format.name}
          />
        ))}
      </RadioGroup>
    </>
  );
};
