import { Brand } from "data-access/repositories/brand";
import { SubscriberType } from "../index.dto";

export type NoticeConfirmedScheduleDeletionId = Brand<number, "NoticeConfirmedScheduleDeletionId">;

export type NoticeConfirmedScheduleDeletionIndexResponse = NoticeConfirmedScheduleDeletion;

export interface NoticeConfirmedScheduleDeletion {
  id: NoticeConfirmedScheduleDeletionId;
  subscriberTypes: SubscriberType[];
  isEnable: boolean;
}

export interface NoticeConfirmedScheduleDeletionUpdateRequest {
  subscriberTypes?: SubscriberType[];
  isEnable?: boolean;
}

export const initialNoticeConfirmedScheduleDeletion: NoticeConfirmedScheduleDeletion = {
  id: 0 as NoticeConfirmedScheduleDeletionId,
  subscriberTypes: [],
  isEnable: false,
};
