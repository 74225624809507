import { Divider, TextField } from "@mui/material";
import { TitleLabel } from "components/label/title-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { BillingSidebarClientBlock } from "components/organisms/billing-sidebar-client-block";
import { ProjectBilling } from "data-access/repositories/billing/project_billing/project_billing.dto";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { BillingSidebarState } from "store/billing-sidebar/slice";
import { useAppDispatch } from "store/hooks";

interface Props {
  state: BillingSidebarState;
  projectBillings: ProjectBilling[];
}

export const BillingSidebarClientInfo = (props: Props) => {
  const { state, projectBillings } = props;
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case "mailingName":
        return dispatch(billingSidebarOperations.updateMailingName(e.target.value as string));
      case "mailingAddress":
        return dispatch(billingSidebarOperations.updateMailingAddress(e.target.value as string));
      case "note":
        return dispatch(billingSidebarOperations.updateNote(e.target.value as string));
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const formKey = e.target.name;
    const billingKey = formKey
      .split(/(?=[A-Z])/)
      .join("_")
      .toLowerCase();

    if (state.billing[billingKey] !== state.form[formKey]) {
      dispatch(
        billingSidebarOperations.updateBilling(state.billing.id, {
          [formKey]: state.form[formKey],
        }),
      );
    }
  };

  return (
    <>
      <TitleLabel sx={{ mb: "16px" }} title="顧客情報" />
      <BillingSidebarClientBlock client={projectBillings[0].project.client} />

      <div style={{ marginTop: "16px" }}>
        <CustomFormLabel labelName="請求メモ" small />
        <TextField
          id="note"
          name="note"
          value={state.form.note}
          onChange={handleChange}
          onBlur={handleBlur}
          multiline
          maxRows={4}
          sx={{ width: "100%" }}
        />
      </div>

      <Divider sx={{ my: "24px" }} />
      <TitleLabel sx={{ my: "16px" }} title="請求書宛先情報" />

      <div style={{ marginTop: "16px" }}>
        <div>
          <CustomFormLabel labelName="請求書宛名" small />
          <TextField
            id="mailingName"
            name="mailingName"
            value={state.form.mailingName}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            maxRows={4}
            sx={{ width: "60%" }}
          />
        </div>

        <div style={{ marginTop: "16px" }}>
          <CustomFormLabel labelName="請求書宛先住所" small />
          <TextField
            id="mailingAddress"
            name="mailingAddress"
            value={state.form.mailingAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            maxRows={3}
            sx={{ width: "60%" }}
          />
        </div>
      </div>
    </>
  );
};
