import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { styles } from "./styles";

interface Props {
  sx?: object;
  items: { label: string | React.ReactNode; value: React.ReactNode }[];
}

export const DetailTable = (props: Props) => {
  const classes = styles();

  return (
    <Table sx={{ ...props.sx }} className={classes.detailTable}>
      <TableBody>
        {props.items.map((item, index) => {
          return (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {item.label}
              </TableCell>
              <TableCell component="td" scope="row">
                {item.value}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
