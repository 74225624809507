import { HOUR } from "./constant";

type Period = {
  startDateTime: Date;
  endDateTime: Date;
};

export const calculatePeriodFromDate = (
  enteredStartDateTime: Date, // 入力値
  currentStartDateTime: Date | null, // 既存の開始日時
  currentEndDateTime: Date | null, // 既存の終了日時
): Period => {
  if (!currentStartDateTime || !currentEndDateTime) {
    return allDayPeriod(enteredStartDateTime);
  }

  if (currentStartDateTime < currentEndDateTime) {
    const diffDays = getDiffDays(currentStartDateTime, enteredStartDateTime);
    const newEndDate = addDays(currentEndDateTime, diffDays);
    return {
      startDateTime: enteredStartDateTime,
      endDateTime: newEndDate,
    };
  } else {
    return allDayPeriod(enteredStartDateTime);
  }
};

const getDiffDays = (currentDateTime: Date, enteredDateTime: Date): number => {
  const enteredMilliSecond: number = enteredDateTime.getTime();
  const currentMilliSecond: number = currentDateTime.getTime();

  const diffInTimeStamp = enteredMilliSecond - currentMilliSecond;
  const diffInDays = diffInTimeStamp / (1000 * 3600 * 24);
  if (Math.sign(diffInDays) === 1) {
    return Math.floor(diffInDays);
  } else {
    return Math.ceil(diffInDays);
  }
};

const addDays = (currentDateTime: Date, diffDays: number): Date => {
  currentDateTime.setDate(currentDateTime.getDate() + diffDays);
  const hour = currentDateTime.getHours();
  const minute = currentDateTime.getMinutes();
  currentDateTime.setHours(hour, minute);

  return currentDateTime;
};

const allDayPeriod = (date: Date) => {
  date.setHours(HOUR.START_OF_ALL_DAY);
  const startDateTime = new Date(date);
  date.setHours(HOUR.END_OF_ALL_DAY);
  const endDateTime = new Date(date);

  return {
    startDateTime,
    endDateTime,
  };
};

export const calculatePeriodFromTime = (
  enteredStartDateTime: Date, // 入力値
  currentStartDateTime: Date | null, // 既存の開始日時
  currentEndDateTime: Date | null, // 既存の終了日時
): Period => {
  let endDateTime: Date;

  if (!currentStartDateTime || !currentEndDateTime) {
    endDateTime = new Date(enteredStartDateTime.getTime() + 60 * 60 * 1000);
  } else if (
    // 既存の開始日時 > 入力値 または 入力値 < 既存の終了日時
    currentStartDateTime > enteredStartDateTime ||
    enteredStartDateTime < currentEndDateTime
  ) {
    endDateTime = currentEndDateTime;
  } else {
    endDateTime = new Date(enteredStartDateTime.getTime() + 60 * 60 * 1000);
  }

  return {
    startDateTime: enteredStartDateTime,
    endDateTime: endDateTime,
  };
};
