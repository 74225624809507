import { ApiClient } from "data-access/ApiClient";
import { convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import { ClientServiceReportItemRequest } from "../types/client_service_report_item.dto";

export const clientServiceReportItemRepository = {
  bulk(body: ClientServiceReportItemRequest): Promise<void> {
    return ApiClient.put(
      `/api/v1/client_service_report_items/bulk`,
      convertKeysToSnakeCase(body),
    ).then((res) => res.data);
  },
};
