import { ApiClient } from "data-access/ApiClient";
import { AuthClient } from "data-access/AuthClient";
import { AuthCreateRequest, AuthResponse } from "./auth.dto";

export const authRepository = {
  create(body: AuthCreateRequest): Promise<AuthResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return AuthClient.post("/api/v1/auths", body, config).then((res) => res.data);
  },
  show(): Promise<AuthResponse> {
    return ApiClient.get("/api/v1/auths").then((res) => res.data);
  },
  update(): Promise<AuthResponse> {
    return AuthClient.put("/api/v1/auths").then((res) => res.data);
  },
  destroy(): Promise<void> {
    return AuthClient.delete("/api/v1/auths").then((res) => res.data);
  },
};
