import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    modal: {
      "& .MuiDialog-paper": {
        width: "80%",
        height: "90%",
        maxWidth: "none",
        borderRadius: "8px",
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: theme.palette.text.primary,
      paddingLeft: "70px",
      paddingRight: "20px",
      marginTop: "22px",
    },
    closeButton: {
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    name: {
      fontWeight: "500",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "1",
    },
  }),
);
