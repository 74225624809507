import { forwardRef } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Typography } from "@mui/material";

interface Props {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
}
const ExpenseItemHeader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: "16px",
      }}
    >
      <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>経費項目</Typography>
      <Button
        variant="contained"
        startIcon={<AddCircleIcon />}
        onClick={() => props.setOpen(true)}
        sx={{ minWidth: "12rem" }}
      >
        経費項目を新規作成
      </Button>
    </Box>
  );
});

ExpenseItemHeader.displayName = "ExpenseItemHeader";

export default ExpenseItemHeader;
