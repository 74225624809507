import { useEffect, useState } from "react";
import { Button, Divider, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { theme } from "extensions/theme";
import { SalesAggregationResponse, SortKey } from "features/sales-aggregation/types/common.dto";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { LegendLabel } from "../legend_label";

interface Data {
  targetName: string;
  salesAmount: number;
  salesAmountByChart: number;
  grossProfit: number;
  grossProfitByChart: number;
  grossProfitRate: number;
  grossProfitRateByChart: number;
}
interface Props {
  data: SalesAggregationResponse;
  entityName: string;
}
export const GenericChart = ({ data, entityName }: Props) => {
  const [displayData, setDisplayData] = useState<Data[]>([]);
  const [selectedSortKey, setSelectedSortKey] = useState<SortKey>("salesAmount");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 12;
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const formatData: Data[] = data.map((v) => {
    const CHART_VALUE_DIVISOR = 1000;
    const NEGATIVE_VALUE = -1;
    return {
      targetName: v.targetName,
      salesAmount: v.salesAmount,
      salesAmountByChart:
        Math.sign(v.grossProfit) === NEGATIVE_VALUE
          ? v.salesAmount / CHART_VALUE_DIVISOR
          : (v.salesAmount - v.grossProfit) / CHART_VALUE_DIVISOR,
      grossProfit: v.grossProfit,
      grossProfitByChart:
        Math.sign(v.grossProfit) === NEGATIVE_VALUE ? 0 : v.grossProfit / CHART_VALUE_DIVISOR,
      grossProfitRate: v.grossProfitRate,
      grossProfitRateByChart:
        Math.sign(v.grossProfitRate) === NEGATIVE_VALUE ? 0 : v.grossProfitRate,
    };
  });

  useEffect(() => {
    setDisplayData(formatData.slice(0, itemsPerPage));
    setSelectedSortKey("salesAmount");
    setCurrentPage(0);
  }, [data]);

  const handleSelect = (e: SelectChangeEvent<string>) => {
    setSelectedSortKey(e.target.value as SortKey);
    setCurrentPage(0);
    switch (e.target.value) {
      case "salesAmount":
        setDisplayData(
          formatData
            .slice()
            .sort((a, b) => b.salesAmount - a.salesAmount)
            .slice(0, itemsPerPage),
        );
        break;
      case "grossProfit":
        setDisplayData(
          formatData
            .slice()
            .sort((a, b) => b.grossProfit - a.grossProfit)
            .slice(0, itemsPerPage),
        );
        break;
      case "grossProfitRate":
        setDisplayData(
          formatData
            .slice()
            .sort((a, b) => b.grossProfitRate - a.grossProfitRate)
            .slice(0, itemsPerPage),
        );
        break;
    }
  };

  const sortByKey = (data: Data[], key: keyof Data, descending = true) => {
    return data.sort((a, b) => {
      if (descending) {
        return (b[key] as number) - (a[key] as number);
      } else {
        return (a[key] as number) - (b[key] as number);
      }
    });
  };

  const updateDisplayData = (pageIndex: number) => {
    const startIndex = pageIndex * itemsPerPage;
    // データをコピーしてからソート
    let sortedData = [...formatData];

    // ソート順に応じてデータをソート
    if (selectedSortKey === "salesAmount") {
      sortedData = sortByKey(sortedData, "salesAmount");
    } else if (selectedSortKey === "grossProfitRate") {
      sortedData = sortByKey(sortedData, "grossProfitRate");
    }

    // 更新されたソート順でページに対応するデータを設定
    const pageData = sortedData.slice(startIndex, startIndex + itemsPerPage);
    setDisplayData(pageData);
  };

  const handleNext = () => {
    const nextPage = currentPage + 1;
    if (nextPage * itemsPerPage < formatData.length) {
      setCurrentPage(nextPage);
      updateDisplayData(nextPage);
    }
  };

  const handlePrev = () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 0) {
      setCurrentPage(prevPage);
      updateDisplayData(prevPage);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>{entityName}</Typography>
        <div style={{ display: "flex", gap: "40px" }}>
          <div style={{ display: "flex", gap: "20px" }}>
            <LegendLabel bgColor={theme.palette.blue[400]} label="売上" />
            <LegendLabel bgColor={theme.palette.teal[400]} label="粗利" />
          </div>
          <Select value={selectedSortKey} onChange={handleSelect} sx={{ mr: "60px" }}>
            <MenuItem value="salesAmount">売上順</MenuItem>
            <MenuItem value="grossProfit">粗利</MenuItem>
            <MenuItem value="grossProfitRate">粗利率順</MenuItem>
          </Select>
        </div>
      </div>
      <Divider sx={{ my: "12px" }} />

      <div style={{ height: "340px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={displayData}
            margin={{
              top: 50,
              right: 30,
              left: 20,
              bottom: 15,
            }}
          >
            <CartesianGrid strokeDasharray="" vertical={false} />
            <XAxis dataKey="targetName" interval={0} tick={<CustomXAxisTick />} />
            <YAxis label={{ value: "売上(千円)", position: "top", offset: 30 }} />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="grossProfitByChart"
              stackId="a"
              fill={theme.palette.secondary.light}
              activeBar={<Rectangle fill={theme.palette.teal[400]} />}
              barSize={30}
            />
            <Bar
              dataKey="salesAmountByChart"
              stackId="a"
              label={(props) => renderCustomBarLabel(props, displayData)}
              fill={theme.palette.primary.light}
              activeBar={<Rectangle fill={theme.palette.blue[400]} />}
              radius={[5, 5, 0, 0]}
              barSize={30}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div style={{ display: "flex" }}>
        <Button onClick={handlePrev} disabled={currentPage === 0}>
          前の12件
        </Button>
        <Typography variant="body2" sx={{ mt: "8px" }}>
          {currentPage + 1} / {totalPages}
        </Typography>
        <Button
          onClick={handleNext}
          disabled={(currentPage + 1) * itemsPerPage >= formatData.length}
        >
          次の12件
        </Button>
      </div>
    </div>
  );
};

const CustomXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const companyName = payload.value;
  const maxLengthSingleLine = 4;
  const maxLineLength = 3;

  const formatTextForDisplay = (value: string) => {
    if (value.length <= maxLengthSingleLine) {
      return <tspan dy="12px">{value}</tspan>;
    } else {
      const firstLine = value.substring(0, maxLengthSingleLine);
      let secondLine = value.substring(maxLineLength, maxLineLength * 2);
      if (value.length > maxLineLength * 2) {
        secondLine += "...";
      }
      return (
        <>
          <tspan x={0} dy="12px">
            {firstLine}
          </tspan>
          <tspan x={0} dy="16px">
            {secondLine}
          </tspan>
        </>
      );
    }
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} textAnchor="middle" fontSize="11px" fontWeight="bold">
        {formatTextForDisplay(companyName)}
      </text>
    </g>
  );
};

const renderCustomBarLabel = (props: any, data: Data[]) => {
  const { x, y, width, index } = props;
  const labelValue = data[index].grossProfitRate;
  return (
    <>
      {index === 0 && (
        <text
          x={x + width / 2}
          y={y}
          textAnchor="middle"
          dy={-20}
          style={{ fontSize: "11px", fontWeight: "bold" }}
        >
          粗利率
        </text>
      )}
      <text
        x={x + width / 2}
        y={y}
        fill={theme.palette.grayScale[700]}
        textAnchor="middle"
        dy={-6}
        style={{ fontSize: "11px", fontWeight: "bold" }}
      >{`${labelValue}%`}</text>
    </>
  );
};

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const data: Data = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: theme.palette.customPrimary[50],
          borderRadius: "16px",
          padding: "12px 16px",
          boxShadow: `0px 4px 8px 0px ${theme.palette.grayScale[900]}`,
        }}
      >
        <Typography sx={{ fontSize: "14px", mb: "8px" }}>{data.targetName}</Typography>
        <div style={{ display: "flex", gap: "16px" }}>
          <div>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>売上</Typography>
            <Typography sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
              ¥{data.salesAmount?.toLocaleString()}
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>粗利</Typography>
            <Typography sx={{ fontWeight: "bold", color: theme.palette.secondary.main }}>
              ¥{data.grossProfit?.toLocaleString()}
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>粗利率</Typography>
            <Typography sx={{ fontWeight: "bold", color: theme.palette.secondary.main }}>
              {data.grossProfitRate}%
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
