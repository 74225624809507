import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  NoticeNextDayScheduleSummaryId,
  NoticeNextDayScheduleSummaryIndexResponse,
  NoticeNextDayScheduleSummaryUpdateRequest,
} from "./next_day_schedule_summary.dto";

export const nextDayScheduleSummaryRepository = {
  index(): Promise<NoticeNextDayScheduleSummaryIndexResponse> {
    return ApiClient.get("/api/v1/notice/next_day_schedule_summaries/settings").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(
    id: NoticeNextDayScheduleSummaryId,
    body: NoticeNextDayScheduleSummaryUpdateRequest,
  ): Promise<void> {
    return ApiClient.put(
      `/api/v1/notice/next_day_schedule_summaries/settings/${id}`,
      updateBody(body),
    ).then((res) => res.data);
  },
};

const updateBody = (body: NoticeNextDayScheduleSummaryUpdateRequest) => {
  return {
    schedule_type_ids_for_filter: body.scheduleTypeIdsForFilter,
    notice_time: body.noticeTime,
    subscriber_types: body.subscriberTypes,
    is_enable: body.isEnable,
  };
};
