import { Box } from "@mui/material";
import { theme } from "extensions/theme";
import { convertBilledStatusToJP } from "utils/convertToJP";
import { BilledStatusType } from "utils/convertToJP/type";
import { styles } from "./styles";

interface Props {
  type: string;
  sx?: object;
}

export const BillingTableColumnLabel = ({ type, sx = {} }: Props) => {
  const classes = styles();

  const className = (type: BilledStatusType) => {
    switch (type) {
      case "before_billing_file_created":
        return classes.notYet;
      case "billing_file_created":
        return classes.done;
      case "before_warranty_created":
        return classes.notYet;
      case "warranty_created":
        return classes.done;
      case "before_billed":
        return classes.notYet;
      case "billed":
        return classes.done;
      case "before_deposited":
        return classes.notYet;
      case "partial_deposited":
        return classes.part;
      case "all_deposited":
        return classes.done;
    }
  };

  return (
    <Box
      component="span"
      className={className(type as BilledStatusType)}
      sx={{
        fontSize: "12px",
        p: "4px 11px",
        borderRadius: "16px",
        verticalAlign: "text-bottom",
        fontWeight: "bold",
        textAlign: "center",
        color: theme.palette.grayScale[0],
        ...sx,
      }}
    >
      {convertBilledStatusToJP(type as BilledStatusType)}
    </Box>
  );
};
