import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ApiClient } from "../../../../data-access/ApiClient";
import { EmployeeId } from "../../types/index.dto";
import { AttendanceIndexResponse } from "../../types/monthly_record/attendance.employee.dto";
import { AttendanceShowResponse } from "../../types/monthly_record/attendance.employee.dto";

export const attendanceEmployeeRepository = {
  index(yearMonth: string): Promise<AttendanceIndexResponse> {
    return ApiClient.get(`/api/v1/attendances/monthly_records/${yearMonth}/employees.json`).then(
      (res) => {
        return convertKeysToCamelCase(res.data);
      },
    );
  },
  show(yearMonth: string, employeeId: EmployeeId): Promise<AttendanceShowResponse> {
    return ApiClient.get(
      `/api/v1/attendances/monthly_records/${yearMonth}/employees/${employeeId}`,
    ).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  csvDownload(yearMonth: string): Promise<Blob> {
    return ApiClient.get(`/api/v1/attendances/monthly_records/${yearMonth}/employees.csv`, {
      responseType: "blob",
    }).then((res) => res.data);
  },
  csvDownloadByDay(yearMonth: string): Promise<Blob> {
    return ApiClient.get(`/api/v1/attendances/monthly_records/${yearMonth}/employees/by_day.csv`, {
      responseType: "blob",
    }).then((res) => res.data);
  },
};
