import { useMemo, useState } from "react";
import {
  AutocompleteChangeReason,
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { PrimaryButton } from "components/atoms/primary-button";
import { ExpandToggleLabel } from "components/label/expand-toggle-label";
import { TitleLabel } from "components/label/title-label";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { BillingBlock } from "components/molecules/billing-block";
import { BillingSummaryBlock } from "components/molecules/billing-summary-block";
import { ClientBlock } from "components/molecules/client-block";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { BillingFormModal } from "components/organisms/billing-form-modal";
import { Client, ClientId } from "data-access/repositories/client/client.dto";
import { ProjectUpdateRequest } from "data-access/repositories/project/project.dto";
import { ProjectBilling } from "data-access/repositories/project/project_billing/project_billing.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { ProjectSidebarState } from "store/project-sidebar/slice";
import { ProjectBillingInitialDisplayNumber } from "utils/constant";
import { openURLInNewTab } from "utils/openURLInNewTab";

interface Props {
  state: ProjectSidebarState;
  projectBillings: ProjectBilling[];
  projectForm: ProjectUpdateRequest;
  rawClientsData: Client[];
  selectedClient: Client | undefined;
  fetchKey: string;
  onClick: (name: string) => void;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
}
export const BillingsInfo = (props: Props) => {
  const { state, projectBillings, fetchKey, rawClientsData, selectedClient } = props;
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isExpandedSetting, setIsExpandedSetting] = useState<boolean>(false);

  const clients = rawClientsData.map((client) => {
    return {
      id: client.id,
      name: client.name,
      phoneNumber: client.phone_number,
      phoneNumberSecond: client.phone_number_second,
      createOption: false,
    };
  });

  const displayNumber = useMemo(() => {
    if (expanded) {
      return projectBillings.length || 0;
    }
    return ProjectBillingInitialDisplayNumber;
  }, [expanded]);

  const handleClickBillingCreate = () => {
    setIsOpen(true);
  };

  const handleChange = (e: React.SyntheticEvent, value: any, reason: AutocompleteChangeReason) => {
    if (reason !== "selectOption") return;

    dispatch(projectSidebarOperations.updateProject(state.project.id, { clientId: value.id }));
  };

  const handleCreate = () => {
    openURLInNewTab(`clients/new/project/${state.project.id}`);
  };

  const customFilterOptions = (
    options: {
      id: ClientId;
      name: string;
      phoneNumber: string;
      phoneNumberSecond: string;
      createOption: boolean;
    }[],
    { inputValue }: { inputValue: string },
  ) => {
    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phoneNumber.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phoneNumberSecond.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  return (
    <>
      <BillingFormModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fetchKey={fetchKey}
        project={state.project}
      />

      <div style={{ display: "flex", marginBottom: "16px" }}>
        <TitleLabel title="請求情報" />
        <Tooltip
          title="顧客を選択してください"
          placement="top"
          arrow
          disableFocusListener={!!state.project.client}
          disableHoverListener={!!state.project.client}
        >
          <span>
            <PrimaryButton
              name="請求を作成"
              onClick={handleClickBillingCreate}
              disabled={!state.project.client}
            />
          </span>
        </Tooltip>
      </div>

      <div style={{ marginBottom: "16px" }}>
        <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700], mb: "8px" }}>
          請求概要
        </Typography>
        {projectBillings && (
          <BillingSummaryBlock
            projectBillings={projectBillings}
            salesAmount={state.project.sales_amount}
          />
        )}
      </div>

      <div style={{ marginBottom: "16px" }}>
        <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700], mb: "8px" }}>
          この案件に紐づく請求一覧
        </Typography>
        {projectBillings && projectBillings.length > 0 ? (
          <>
            {projectBillings.slice(0, displayNumber).map((project_billing) => {
              return (
                <BillingBlock
                  key={project_billing.id}
                  billing={project_billing}
                  isBillingStatusTypeUse={
                    mainState.company.company_setting.is_billing_status_type_use
                  }
                />
              );
            })}
            {projectBillings.length > ProjectBillingInitialDisplayNumber && (
              <ExpandToggleLabel onClick={() => setExpanded(!expanded)} expanded={expanded} />
            )}
          </>
        ) : (
          <Typography sx={{ fontWeight: "bold", ml: "8px" }}>請求はありません</Typography>
        )}
      </div>

      <div style={{ marginBottom: "24px" }}>
        <CustomFormLabel labelName="顧客" small />
        {!selectedClient ? (
          <CustomAutocomplete
            data={clients}
            entityName="顧客"
            onChange={handleChange}
            onClick={handleCreate}
            isCreate
            entityLabel="顧客を検索または選択"
            filterOptions={customFilterOptions}
          />
        ) : (
          <ClientBlock
            client={selectedClient}
            isDeselectButtonVisible={projectBillings.length === 0}
          />
        )}
      </div>

      <div>
        <AccordionToggle
          title="詳細設定"
          isExpanded={isExpandedSetting}
          setIsExpanded={setIsExpandedSetting}
          titleStyle={{ fontSize: "14px", fontWeight: "bold", color: theme.palette.grayScale[700] }}
        />
        {isExpandedSetting && (
          <Box sx={{ display: "flex", flexDirection: "column", width: "40%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="isSupportedByMaker"
                  name="isSupportedByMaker"
                  onChange={(e) => props.onCheck(e, !props.projectForm.isSupportedByMaker)}
                  checked={props.projectForm.isSupportedByMaker}
                  size="small"
                />
              }
              label={
                <Typography component="span" fontSize={12}>
                  メーカー対応
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="isManageBillingOnAnotherSystem"
                  name="isManageBillingOnAnotherSystem"
                  onChange={(e) =>
                    props.onCheck(e, !props.projectForm.isManageBillingOnAnotherSystem)
                  }
                  checked={props.projectForm.isManageBillingOnAnotherSystem}
                  size="small"
                />
              }
              label={
                <Typography component="span" fontSize={12}>
                  請求は別システムで管理する
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="isNoBill"
                  name="isNoBill"
                  onChange={(e) => props.onCheck(e, !props.projectForm.isNoBill)}
                  checked={props.projectForm.isNoBill}
                  size="small"
                />
              }
              label={
                <Typography component="span" fontSize={12}>
                  請求しない
                </Typography>
              }
            />
          </Box>
        )}
      </div>
    </>
  );
};
