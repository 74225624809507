import { AttendanceOvertimeIndexResponse } from "features/attendance/types/overtime_record/attendance.employee.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ApiClient } from "../../../../data-access/ApiClient";

export const attendanceOvertimeEmployeeRepository = {
  index(year: string): Promise<AttendanceOvertimeIndexResponse> {
    return ApiClient.get(`/api/v1/attendances/overtime_records/${year}/employees`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
};
