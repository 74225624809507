import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  initialScheduleRightClickObject,
  ScheduleRightClickObject,
} from "data-access/repositories/schedule/schedule.dto";
import { duplicateGridSchedule, duplicateTimelineSchedule, updateGridSchedule } from "./actions";
import type { RootState } from "../store";

interface scheduleRightClickMenuState {
  isLoading: boolean;
  isSubmitted: boolean;
  successMessage: string;
  errorMessage: string;
  isOpen: boolean;
  object: ScheduleRightClickObject;
  anchorEl: HTMLElement | null;
}

const initialState: scheduleRightClickMenuState = {
  isLoading: false,
  isSubmitted: false,
  successMessage: "",
  errorMessage: "",
  isOpen: false,
  object: initialScheduleRightClickObject,
  anchorEl: null,
};

export const scheduleRightClickMenuSlice = createSlice({
  name: "scheduleRightClickMenu",
  initialState,
  reducers: {
    setObject: (state, action: PayloadAction<{ value: ScheduleRightClickObject }>) => ({
      ...state,
      object: action.payload.value,
    }),
    setAnchorEl: (state, action: PayloadAction<{ value: HTMLButtonElement | null }>) => ({
      ...state,
      anchorEl: action.payload.value,
    }),
    deleteSnackbarMessage: (state) => ({
      ...state,
      successMessage: "",
      errorMessage: "",
    }),
    open: (state) => ({
      ...state,
      isOpen: true,
    }),
    close: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(updateGridSchedule.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
    });
    builder.addCase(updateGridSchedule.fulfilled, (state) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.successMessage = "予定を更新しました";
    });
    builder.addCase(updateGridSchedule.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(duplicateGridSchedule.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
    });
    builder.addCase(duplicateGridSchedule.fulfilled, (state) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.successMessage = "予定を次の日に複製しました";
    });
    builder.addCase(duplicateGridSchedule.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(duplicateTimelineSchedule.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
    });
    builder.addCase(duplicateTimelineSchedule.fulfilled, (state) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.successMessage = "予定を次の日に複製しました";
    });
    builder.addCase(duplicateTimelineSchedule.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
  },
});

export const selectScheduleRightClickMenu = (state: RootState) => state.scheduleRightClickMenu;
export default scheduleRightClickMenuSlice.reducer;
