import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    selectAllButton: {
      height: "100%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    deselectButton: {
      height: "100%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    categoryTreeName: {
      flex: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      wordBreak: "break-all",
    },
    checkbox: {
      position: "absolute",
      top: "8px",
      left: "8px",
      width: "20px",
      height: "20px",
      accentColor: theme.palette.primary.main,
    },
    imageBoxBlock: {
      display: "flex",
      flexWrap: "wrap",
      gap: "20px",
      marginTop: "16px",
      marginBottom: "28px",
      backgroundColor: theme.palette.customPrimary[50],
      border: `1px solid ${theme.palette.grayScale[300]}`,
      borderRadius: "4px",
      padding: "16px",
      "& .MuiIconButton-root": {
        position: "absolute",
      },
    },
    modal: {
      "& .MuiDialogContent-root": {
        minHeight: "80vh",
        border: "none",
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grayScale[0],
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      "& .MuiSvgIcon-root": {
        color: theme.palette.grayScale[0],
      },
    },
  }),
);
