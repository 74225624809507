import { RefObject, useRef } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { Layout } from "components/templates/layout";
import Employee from "./employee";
import Group from "./group";
import MemberOrder from "./member-order";
import OutsourcedMember from "./outsourced-member";
import { styles } from "./styles";

export const MemberSetting = () => {
  const classes = styles();

  const employeeHeaderRef = useRef<HTMLDivElement>(null);
  const outsourcedMemberHeaderRef = useRef<HTMLDivElement>(null);
  const memberOrderHeaderRef = useRef<HTMLDivElement>(null);
  const groupHeaderRef = useRef<HTMLDivElement>(null);

  // スクロールを実行する関数
  const scrollToSection = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Layout>
      <div style={{ display: "flex" }}>
        <div style={{ width: "150px" }}>
          <Stack spacing={2} sx={{ mr: "20px" }}>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(employeeHeaderRef)}
              fontWeight="500"
            >
              自社メンバー
            </Typography>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(outsourcedMemberHeaderRef)}
              fontWeight="500"
            >
              協力会社
            </Typography>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(memberOrderHeaderRef)}
              fontWeight="500"
            >
              表示順
            </Typography>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(groupHeaderRef)}
              fontWeight="500"
            >
              グループ
            </Typography>
          </Stack>
        </div>
        <div style={{ overflow: "scroll", height: "85vh", flex: 1 }}>
          <Employee ref={employeeHeaderRef} />
          <Divider sx={{ my: 6 }} />
          <OutsourcedMember ref={outsourcedMemberHeaderRef} />
          <Divider sx={{ my: 6 }} />
          <MemberOrder ref={memberOrderHeaderRef} />
          <Divider sx={{ my: 6 }} />
          <Group ref={groupHeaderRef} />
        </div>
      </div>
    </Layout>
  );
};
