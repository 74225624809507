import { ProjectTypeIndexResponse, ProjectTypeRequest, ProjectTypeId } from "./project_type.dto";
import { ApiClient } from "../../ApiClient";

export const projectTypeRepository = {
  index(): Promise<ProjectTypeIndexResponse> {
    return ApiClient.get("/api/v1/project_types").then((res) => res.data);
  },
  create(body: ProjectTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/project_types", createBody(body), config).then(
      (res) => res.data,
    );
  },
  update(id: ProjectTypeId, body: ProjectTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/project_types/${id}`, createBody(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: ProjectTypeId): Promise<void> {
    return ApiClient.delete(`/api/v1/project_types/${id}`).then((res) => res.data);
  },
};

const createBody = (body: ProjectTypeRequest) => {
  return {
    name: body.name,
    display_order_position: body.displayOrderPosition,
  };
};
