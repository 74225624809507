import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    link: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    listItemIcon: {
      "&.MuiListItemIcon-root": {
        minWidth: "44px",
      },
    },
  }),
);
