import { Box, Button, SelectChangeEvent, TextField } from "@mui/material";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { ProjectUpdateRequest } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";

interface Props {
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClick: (name: string) => void;
  projectForm: ProjectUpdateRequest;
}

export const ProjectSidebarPrintableReportBlock = (props: Props) => {
  const { onChange, onBlur, onClick, projectForm } = props;

  return (
    <Box sx={{ my: "1.7rem" }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: "16px" }}>
        <CustomFormLabel labelName="受注物件報告書" />
        <Button
          sx={{ ml: "0.5rem" }}
          variant="contained"
          onClick={() => onClick("printableReport")}
        >
          報告内容プレビュー
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.customPrimary[50],
          border: `1px solid ${theme.palette.grayScale[300]}`,
          borderRadius: "5px",
          py: "1rem",
          px: "1rem",
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "11rem", mr: "2rem" }}>
            <CustomFormLabel labelName="依頼元" />
            <TextField
              id="requester"
              name="requester"
              value={projectForm.requester}
              onChange={onChange}
              onBlur={onBlur}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ width: "11rem" }}>
            <CustomFormLabel labelName="依頼担当者名" />
            <TextField
              id="requestedByPersonName"
              name="requestedByPersonName"
              value={projectForm.requestedByPersonName}
              onChange={onChange}
              onBlur={onBlur}
              sx={{ width: "100%" }}
            />
          </Box>
        </Box>
        <Box sx={{ mt: "1rem" }}>
          <CustomFormLabel labelName="報告内容" toolTipTitle="受注物件報告書に表示されます" />
          <TextField
            id="reportContent"
            name="reportContent"
            value={projectForm.reportContent}
            onChange={onChange}
            onBlur={onBlur}
            sx={{ width: "100%" }}
            multiline
            maxRows={4}
          />
        </Box>
      </Box>
    </Box>
  );
};
