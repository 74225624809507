import { deleteConfirmDialogSlice } from "./slice";
import { AppDispatch } from "../store";

export const deleteConfirmDialogOperations = {
  setObject:
    (value: { id: number | string; type: string; recordType?: string }) =>
    (dispatch: AppDispatch) => {
      dispatch(deleteConfirmDialogSlice.actions.setObject({ value }));
    },
  open: () => (dispatch: AppDispatch) => {
    dispatch(deleteConfirmDialogSlice.actions.open());
  },
  close: () => (dispatch: AppDispatch) => {
    dispatch(deleteConfirmDialogSlice.actions.close());
  },
};
