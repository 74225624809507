import { Close } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  onClose: () => void;
}
export const Header = (props: Props) => {
  const { onClose } = props;

  return (
    <Box
      sx={{
        pt: "70px",
        mb: "20px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <Button
          startIcon={<Close />}
          onClick={onClose}
          sx={{ color: theme.palette.grayScale[700] }}
        >
          とじる
        </Button>
      </div>
    </Box>
  );
};
