import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  NoticeOvertimeRecordIndexResponse,
  NoticeOvertimeRecordUpdateRequest,
} from "./overtime_record.dto";

export const noticeOvertimeRecordRepository = {
  index(): Promise<NoticeOvertimeRecordIndexResponse> {
    return ApiClient.get("/api/v1/notice/overtime_records/setting").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(body: NoticeOvertimeRecordUpdateRequest): Promise<void> {
    return ApiClient.put("/api/v1/notice/overtime_records/setting", updateBody(body)).then(
      (res) => res.data,
    );
  },
};

const updateBody = (body: NoticeOvertimeRecordUpdateRequest) => {
  return {
    is_enable_monthly_overtime_hours_principle_warning:
      body.isEnableMonthlyOvertimeHoursPrincipleWarning,
    is_enable_monthly_overtime_hours_principle_violation:
      body.isEnableMonthlyOvertimeHoursPrincipleViolation,
    is_enable_special_clause_occurrences_exception_warning:
      body.isEnableSpecialClauseOccurrencesExceptionWarning,
    is_enable_special_clause_occurrences_exception_violation:
      body.isEnableSpecialClauseOccurrencesExceptionViolation,
    is_enable_overtime_and_holiday_hours_exception_warning:
      body.isEnableOvertimeAndHolidayHoursExceptionWarning,
    is_enable_overtime_and_holiday_hours_exception_violation:
      body.isEnableOvertimeAndHolidayHoursExceptionViolation,
    is_enable_some_months_average_exception_warning: body.isEnableSomeMonthsAverageExceptionWarning,
    is_enable_some_months_average_exception_violation:
      body.isEnableSomeMonthsAverageExceptionViolation,
    is_enable_annual_overtime_hours_principle_warning:
      body.isEnableAnnualOvertimeHoursPrincipleWarning,
    is_enable_annual_overtime_hours_principle_violation:
      body.isEnableAnnualOvertimeHoursPrincipleViolation,
    is_enable_annual_overtime_hours_exception_warning:
      body.isEnableAnnualOvertimeHoursExceptionWarning,
    is_enable_annual_overtime_hours_exception_violation:
      body.isEnableAnnualOvertimeHoursExceptionViolation,
  };
};
