import { downloadBlob } from "./downloadBlob";

export const fileDownload = (url: string, name: string) => {
  fetch(url, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then((buffer) => {
        downloadBlob(new Blob([buffer]), name);
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
