import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  ExpenseItemId,
  ExpenseItemIndexResponse,
  ExpenseItemRequest,
} from "../types/expense_item.dto";

export const expenseItemRepository = {
  index(): Promise<ExpenseItemIndexResponse> {
    return ApiClient.get("/api/v1/expense_items").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(body: ExpenseItemRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/expense_items", convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
  update(id: ExpenseItemId, body: ExpenseItemRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/expense_items/${id}`, convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: ExpenseItemId): Promise<void> {
    return ApiClient.delete(`/api/v1/expense_items/${id}`).then((res) => res.data);
  },
};
