import { PersonalSettingShowResponse, PersonalSettingUpdateRequest } from "./personal_setting.dto";
import { ApiClient } from "../../ApiClient";

export const personalSettingRepository = {
  show(): Promise<PersonalSettingShowResponse> {
    return ApiClient.get("/api/v1/personal_setting").then((res) => res.data);
  },
  update(body: PersonalSettingUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put("/api/v1/personal_setting", settingBody(body), config).then(
      (res) => res.data,
    );
  },
};

const settingBody = (body: PersonalSettingUpdateRequest) => {
  return {
    calendar_start_day_of_week: body.calendarStartDayOfWeek,
    number_of_project_note_lines_to_display: body.numberOfProjectNoteLinesToDisplay,
  };
};
