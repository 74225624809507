import { ClientIndexRequest } from "data-access/repositories/client/client.dto";
import { getClients } from "./actions";
import { billingTableHeaderSlice } from "./slice";
import { AppDispatch } from "../store";

export const billingTableHeaderOperations = {
  getClients: (query: ClientIndexRequest) => (dispatch: AppDispatch) => {
    dispatch(getClients(query));
  },
  addCountChanges: () => (dispatch: AppDispatch) => {
    dispatch(billingTableHeaderSlice.actions.addCountChanges());
  },
};
