import { useState } from "react";
import { Box, Button, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { Flash } from "components/atoms/flash";
import { CustomModal } from "components/molecules/custom-modal";
import { projectTypeRepository } from "data-access/repositories/project_type/project_type.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";

interface FormState {
  name: string;
  displayOrderPosition: number;
}

const initialState = {
  name: "",
  displayOrderPosition: 0,
};

interface Props {
  open: (e: boolean) => void;
  isOpen: boolean;
}

export const ProjectTypeModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formState, setFormState] = useState<FormState>(initialState);

  const { mutate: projectTypesMutate } = useSWR(
    "/api/v1/project_types",
    projectTypeRepository.index,
  );

  const handleClose = () => {
    setFormState(initialState);
    props.open(false);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => {
    switch (e.target.name) {
      case "name":
        setFormState({ ...formState, name: e.target.value as string });
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      await projectTypeRepository.create(formState);
      projectTypesMutate();
      dispatch(mainOperations.getProjectTypes());
      handleClose();
      dispatch(mainOperations.updateSuccessMessage("案件タイプを作成しました"));
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const isValid = (): boolean => {
    if (!formState.name) return false;
    return true;
  };

  return (
    <>
      <CustomModal
        title="案件タイプの新規作成"
        open={props.isOpen}
        onClose={handleClose}
        maxWidth="sm"
        footer={
          <>
            <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
              キャンセル
            </Button>
            <Button
              disabled={!isValid()}
              onClick={handleSubmit}
              variant="contained"
              sx={{ width: "10rem", ml: "1rem" }}
            >
              保存
            </Button>
          </>
        }
      >
        {errorMessage && (
          <Flash title="エラー" message={errorMessage} severity="error" sx={{ mt: "1rem" }} />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", my: "24px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px", mb: "16px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>案件タイプ名</Typography>
            <Typography component="span" sx={{ color: theme.palette.red[500], fontSize: "12px" }}>
              必須
            </Typography>
          </Box>
          <TextField
            id="name"
            name="name"
            placeholder="案件タイプ名を入力"
            value={formState.name}
            onChange={handleChange}
            sx={{ width: "50%" }}
          />
        </Box>
      </CustomModal>
    </>
  );
};
