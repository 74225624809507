import { theme } from "extensions/theme";

type Props = {
  size?: number;
  color?: string;
};

export const UnconfirmSchedule = (props: Props) => {
  return (
    <svg
      width={props.size || 22}
      height={props.size || 22}
      viewBox="1 0 18 18"
      x="0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.59188 16.677L2.59203 16.6772C2.95901 17.0484 3.40403 17.2361 3.9204 17.2361H10.3678H10.5515L10.484 17.0652C10.3704 16.7772 10.2926 16.4902 10.2502 16.2039C10.2072 15.9136 10.1858 15.6234 10.1858 15.3333V15.2083H10.0608H4.0454V8.3472H14.3217V10.0889V10.2343L14.4655 10.2124C14.7518 10.1689 15.0379 10.1472 15.3239 10.1472C15.6099 10.1472 15.8961 10.1689 16.1823 10.2124L16.3261 10.2343V10.0889V4.66664C16.3261 4.1449 16.1415 3.69498 15.7752 3.32373C15.4082 2.95188 14.9632 2.76386 14.4467 2.76386H13.6945V1.11108V0.986084H13.5695H11.8151H11.6901V1.11108V2.76386H6.67698V1.11108V0.986084H6.55198H4.79759H4.67259V1.11108V2.76386H3.9204C3.40396 2.76386 2.95892 2.95188 2.59196 3.32373C2.22559 3.69498 2.04102 4.1449 2.04102 4.66664V15.3333C2.04102 15.855 2.22558 16.3052 2.59188 16.677ZM14.3217 4.79164V6.31942H4.0454V4.79164H14.3217Z"
        fill={props.color || theme.palette.grayScale[700]}
      />
      <path
        d="M14.0827 17.5001H15.6452C16.2253 17.5001 16.7817 17.2696 17.192 16.8594C17.6022 16.4491 17.8327 15.8927 17.8327 15.3126C17.8327 14.7324 17.6022 14.176 17.192 13.7658C16.7817 13.3555 16.2253 13.1251 15.6452 13.1251H11.5827M12.6243 11.6667L11.166 13.1251L12.6243 14.5834"
        stroke={props.color || theme.palette.grayScale[700]}
        strokeWidth="2"
      />
    </svg>
  );
};
