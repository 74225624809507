import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    blockTitle: {
      color: theme.palette.grayScale[700],
    },
    block: {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.customPrimary[50],
      borderRadius: "5px",
      marginTop: "1rem",
      padding: "1rem",
    },
    button: {
      display: "flex",
      gap: "8px",
      width: "100%",
    },
  }),
);
