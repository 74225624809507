import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface ReportContentPrintModalState {
  isLoading: boolean;
  errorMessage: string;
  isOpen: boolean;
  requestedByName: string;
  activityType: string;
  behaviorType: string;
  reportedByName: string;
}

const initialState: ReportContentPrintModalState = {
  isLoading: false,
  errorMessage: "",
  isOpen: false,
  requestedByName: "",
  activityType: "",
  behaviorType: "",
  reportedByName: "",
};

export const reportContentPrintModalSlice = createSlice({
  name: "reportContentPrintModal",
  initialState,
  reducers: {
    updateRequestedByName: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      requestedByName: action.payload.value,
    }),
    updateActivityType: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      activityType: action.payload.value,
    }),
    updateBehaviorType: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      behaviorType: action.payload.value,
    }),
    updateReportedByName: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      reportedByName: action.payload.value,
    }),
    open: (state) => ({
      ...state,
      isOpen: true,
    }),
    close: () => initialState,
  },
  extraReducers: () => {},
});

export const selectReportContentPrintModal = (state: RootState) => state.reportContentPrintModal;
export default reportContentPrintModalSlice.reducer;
