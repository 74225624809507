import { useState } from "react";
import { Box, Button, Collapse } from "@mui/material";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { ActivityItem } from "components/molecules/activity-block";
import { Activity } from "data-access/repositories/activity/activity.dto";
import { theme } from "extensions/theme";
import { ACTIVITY_PER_PAGE, ActivityInitialDisplayCount } from "utils/constant";

interface Props {
  activities: Activity[];
  totalCount?: number;
  limit: number;
  setLimit: (limit: number) => void;
}

export const ActivityBlock = (props: Props) => {
  const { activities, totalCount, limit, setLimit } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleMoreData = () => {
    setLimit(limit + ACTIVITY_PER_PAGE);
  };

  return (
    <Box
      sx={{
        mt: 2,
        p: "8px",
        backgroundColor: theme.palette.customPrimary[100],
      }}
    >
      <AccordionToggle
        title="更新履歴"
        isExpanded={expanded}
        setIsExpanded={() => setExpanded(!expanded)}
      />
      {activities &&
        activities
          .slice(0, ActivityInitialDisplayCount)
          .map((activity) => <ActivityItem key={activity.id} activity={activity} />)}
      <Collapse in={expanded} timeout="auto">
        {activities &&
          activities
            .slice(ActivityInitialDisplayCount)
            .map((activity) => <ActivityItem key={activity.id} activity={activity} />)}
        {totalCount !== activities.length && (
          <div style={{ textAlign: "center" }}>
            <Button onClick={handleMoreData}>さらに表示</Button>
          </div>
        )}
      </Collapse>
    </Box>
  );
};
