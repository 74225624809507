import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    menuItem: {
      width: "fit-content",
      borderBottom: `2px solid ${theme.palette.grayScale[0]}`,
      "&:hover": {
        cursor: "pointer",
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }),
);
