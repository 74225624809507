export const getBaseUrl = () => {
  if (import.meta.env.VITE_APP_ENV === "local") {
    return import.meta.env.VITE_APP_LOCAL_API_URL;
  } else {
    if (import.meta.env.VITE_APP_ENV === "production") {
      return import.meta.env.VITE_APP_PROD_API_URL;
    } else {
      return import.meta.env.VITE_APP_STAGING_API_URL;
    }
  }
};
