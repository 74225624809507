import { ApiClient } from "data-access/ApiClient";
import { AttendanceShowResponse } from "features/attendance/types/monthly_record/attendance.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";

// 打刻の締め
export const attendanceRepository = {
  show(yearMonth: string): Promise<AttendanceShowResponse> {
    return ApiClient.get(`/api/v1/attendances/monthly_records/${yearMonth}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(yearMonth: string, isClosed: boolean): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(
      `/api/v1/attendances/monthly_records/${yearMonth}`,
      { is_closed: isClosed },
      config,
    ).then((res) => res.data);
  },
};
