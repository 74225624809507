// オブジェクトのvalueは動的で型が多数あるためanyにしている

// オブジェクトのキーをキャメルケースからスネークケースに変換する関数
export const convertKeysToSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    // 配列の各要素に対して変換を適用
    return obj.map(convertKeysToSnakeCase);
  } else if (obj !== null && typeof obj === "object") {
    // オブジェクトのキーを変換
    const newObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
      const newKey = camelToSnakeCase(key);
      newObj[newKey] = convertKeysToSnakeCase(obj[key]);
    });
    return newObj;
  } else {
    // 配列でもオブジェクトでもない場合はそのまま返す
    return obj;
  }
};

// オブジェクトのキーをスネークケースからキャメルケースに変換する関数
export const convertKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    // 配列の各要素に対して変換を適用
    return obj.map(convertKeysToCamelCase);
  } else if (obj !== null && typeof obj === "object") {
    // オブジェクトのキーを変換
    const newObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
      const newKey = snakeToCamelCase(key);
      newObj[newKey] = convertKeysToCamelCase(obj[key]);
    });
    return newObj;
  } else {
    // 配列でもオブジェクトでもない場合はそのまま返す
    return obj;
  }
};

export const camelToSnakeCase = (key: string): string => {
  return key.replace(/[A-Z]/g, (uppercaseChar) => `_${uppercaseChar.toLowerCase()}`);
};

export const snakeToCamelCase = (key: string): string => {
  return key.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
};
