import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HelpOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { Flash } from "components/atoms/flash";
import { ProjectDetailBlockForTotalBillingForm } from "components/molecules/project-detail-block-for-total-billing-form";
import {
  BillingCreateRequest,
  initialBillingCreateRequest,
} from "data-access/repositories/billing/billing.dto";
import { billingRepository } from "data-access/repositories/billing/billing.repository";
import { ProjectCollection, ProjectId } from "data-access/repositories/project/project.dto";
import { ProjectBilling } from "data-access/repositories/project/project_billing/project_billing.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { downloadBlob } from "utils/downloadBlob";
import { handleError } from "utils/errorHandler";
import { mailingAddress } from "utils/mailingAddress";
import { mailingName } from "utils/mailingName";
import { salesTaxCalculator } from "utils/salesTaxCalculator";
import { selectMain } from "../../../store/main/slice";
import { ClientBlock } from "../../molecules/client-block";
import { CustomModal } from "../../molecules/custom-modal";

const CustomTypographyTitle = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  projects: ProjectCollection[];
}

export const TotalBillingFormModal = (props: Props) => {
  const { isOpen, setIsOpen, projects } = props;
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const navigate = useNavigate();
  const [formState, setFormState] = useState<BillingCreateRequest>(initialBillingCreateRequest);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // モーダルが開かれたらフォームを初期化する
  useEffect(() => {
    if (!isOpen) return;
    const calculateAmount = (projectBillings: any, salesAmount: number) => {
      if (!projectBillings) return salesAmount;
      const totalBilled = projectBillings.reduce(
        (total: number, item: ProjectBilling) => total + item.amount,
        0,
      );
      return salesAmount - totalBilled;
    };

    const calculatedTax = async (amount: number, project: ProjectCollection): Promise<number> => {
      // 請求書作成時に案件の消費税が変更されていた場合、変更後の消費税を使用する
      const res = await salesTaxCalculator(amount);
      return amount === project.sales_amount ? project.sales_tax : res.tax;
    };

    const fetchData = async () => {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        const projectBillings = await Promise.all(
          projects.map(async (project) => {
            const amount = calculateAmount(project.project_billings, project.sales_amount);
            const tax: number = await calculatedTax(amount, project);
            return {
              projectId: project.id,
              amount: calculateAmount(project.project_billings, project.sales_amount),
              tax,
            };
          }),
        );

        setFormState({
          ...formState,
          projectBillings,
          billingDate: new Date().toString(),
          subject:
            projects.length > 1
              ? `${projects[0].client?.name}${projects[0].client?.honorific_title}`
              : projects[0].name,
          mailingName: mailingName({
            name: projects[0].client?.name,
            billing_person_name: projects[0].client?.billing_person_name,
            honorific_title: projects[0].client?.honorific_title,
            billing_person_section: projects[0].client?.billing_person_section,
            billing_person_position: projects[0].client?.billing_person_position,
          }),
          mailingAddress: mailingAddress({
            postal_code: projects[0].client?.postal_code,
            address: projects[0].client?.address,
            address_second: projects[0].client?.address_second,
          }),
          statusType: "before_billed",
        });
      } catch (error) {
        handleError(error, setErrorMessage, "案件情報の取得に失敗しました");
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    };

    fetchData();
  }, [isOpen]);

  const handleChangeAmount = (projectBillingToUpdate: {
    projectId: ProjectId;
    amount: number;
    tax: number;
  }) => {
    setFormState((prevState) => ({
      ...prevState,
      projectBillings: prevState.projectBillings.map((projectBilling) =>
        projectBilling.projectId === projectBillingToUpdate.projectId
          ? {
              ...projectBilling,
              amount: projectBillingToUpdate.amount,
              tax: projectBillingToUpdate.tax,
            }
          : projectBilling,
      ),
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isWarrantyAttach = e.target.value === "true";
    setFormState({ ...formState, isWarrantyAttach });
  };

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked: boolean,
  ) => {
    switch (checked) {
      case true:
        return setFormState({ ...formState, statusType: "billed" });
      case false:
        return setFormState({ ...formState, statusType: "before_billed" });
    }
  };

  const handleDateChange = (date: Date) => {
    setFormState({ ...formState, billingDate: date.toString() });
  };

  const handleClickEndOfMonth = () => {
    const date = new Date(formState.billingDate);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setFormState({ ...formState, billingDate: lastDay.toString() });
  };

  const handleClose = () => {
    setIsOpen(false);
    setFormState(initialBillingCreateRequest);
    setErrorMessage("");
  };

  const findProjectBillingAmount = (currentProject: ProjectCollection) => {
    const foundProjectBilling = formState.projectBillings.find(
      (projectBilling) => projectBilling.projectId === currentProject.id,
    );
    return foundProjectBilling || { id: 0, amount: 0, tax: 0 };
  };

  const sumAmount = useMemo((): number => {
    return formState.projectBillings.reduce((total, item) => total + item.amount, 0);
  }, [formState.projectBillings]);

  const createBilling = async () => {
    try {
      const res = await billingRepository.create(formState);
      navigate(`/billings/${res.id}`);
      dispatch(mainOperations.updateSuccessMessage("請求書を作成しました"));
      handleClose();
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const createBillingAndDownload = async () => {
    try {
      const createdBilling = await billingRepository.create(formState);
      const blob: Blob = await billingRepository.downloadTemplate(createdBilling.id);
      downloadBlob(blob, `【請求書】${formState.code} ${formState.subject}.xlsx`);
      dispatch(mainOperations.updateSuccessMessage("請求書を作成しました"));
      handleClose();
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const scrollTopRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (errorMessage !== "") {
      scrollTopRef?.current?.scrollIntoView();
    }
  }, [errorMessage]);

  return (
    <CustomModal
      open={isOpen}
      onClose={handleClose}
      title="請求を新規作成"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button onClick={createBilling} variant="contained" sx={{ width: "10rem", ml: "1rem" }}>
            作成する
          </Button>
          <Button
            onClick={createBillingAndDownload}
            variant="contained"
            sx={{ width: "15rem", ml: "1rem", textTransform: "none" }}
          >
            作成＋請求書 Excelダウンロード
          </Button>
        </>
      }
    >
      <div ref={scrollTopRef} />
      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "end",
          backgroundColor: theme.palette.customPrimary[50],
          padding: "12px",
          marginBottom: "12px",
          borderRadius: "4px",
          gap: "8px",
        }}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>請求金額合計</Typography>
        <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
          ¥{sumAmount.toLocaleString()}
        </Typography>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>（税別）</Typography>
      </div>
      <div>
        <CustomTypographyTitle sx={{ mb: "12px" }}>案件</CustomTypographyTitle>
        {projects.map((project) => (
          <ProjectDetailBlockForTotalBillingForm
            key={project.id}
            project={project}
            fetchKey={`/api/v1/projects/${project.id}/project_billings`}
            stateAmount={findProjectBillingAmount(project).amount}
            stateTax={findProjectBillingAmount(project).tax}
            onChange={handleChangeAmount}
          />
        ))}
      </div>
      <Divider sx={{ my: "20px" }} />
      <div>
        <CustomTypographyTitle sx={{ mb: "12px" }}>顧客</CustomTypographyTitle>
        <ClientBlock
          client={projects[0].client}
          isEditable={false}
          isDeselectButtonVisible={false}
        />
      </div>
      <Divider sx={{ my: "20px" }} />
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>顧客宛名</CustomTypographyTitle>
        <TextField
          id="mailingName"
          name="mailingName"
          placeholder="顧客宛名を記載"
          value={formState.mailingName}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ width: "100%" }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ display: "flex" }}>
          <CustomTypographyTitle sx={{ mb: "12px" }}>顧客宛先住所</CustomTypographyTitle>
          <Tooltip
            title="住所などの追記・修正ができます。会社住所が変わる場合は顧客を編集してください。"
            placement="right"
          >
            <HelpOutlined sx={{ ml: "1rem", verticalAlign: "middle" }} color="primary" />
          </Tooltip>
        </div>
        <TextField
          id="mailingAddress"
          name="mailingAddress"
          placeholder="顧客住所を記載"
          value={formState.mailingAddress}
          onChange={handleChange}
          multiline
          maxRows={3}
          sx={{ width: "100%" }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>請求番号</CustomTypographyTitle>
        <TextField
          id="code"
          name="code"
          value={formState.code}
          onChange={handleChange}
          sx={{ width: "40%" }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>請求書件名</CustomTypographyTitle>
        <TextField
          id="subject"
          name="subject"
          value={formState.subject}
          onChange={handleChange}
          sx={{ width: "40%" }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>請求日</CustomTypographyTitle>
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <CustomDatePicker
            id="billingDate"
            name="billingDate"
            date={formState.billingDate ? new Date(formState.billingDate) : null}
            onChange={handleDateChange}
            popperPlacement="right"
          />
          <Button onClick={handleClickEndOfMonth} variant="outlined" sx={{ height: "32px" }}>
            月末
          </Button>
        </div>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>請求メモ</CustomTypographyTitle>
        <TextField
          id="note"
          name="note"
          value={formState.note}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ width: "100%" }}
        />
      </div>
      {mainState.company.company_setting.is_warranty_use && (
        <div>
          <CustomTypographyTitle sx={{ mb: "12px" }}>保証書</CustomTypographyTitle>
          <RadioGroup name="isWarrantyAttach" row onChange={handleRadio}>
            <FormControlLabel
              control={<Radio size="small" checked={formState.isWarrantyAttach} />}
              value={true}
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
              label="付与する"
            />
            <FormControlLabel
              control={<Radio size="small" checked={!formState.isWarrantyAttach} />}
              value={false}
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
              label="付与しない"
            />
          </RadioGroup>
        </div>
      )}
      {mainState.company.company_setting.is_billing_status_type_use && (
        <>
          <Divider sx={{ my: "20px" }} />
          <div style={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isBilled"
                  onChange={handleCheck}
                  size="small"
                  checked={formState.statusType === "billed"}
                />
              }
              label="送付ステータスを「送付済み」にする"
              labelPlacement="start"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px", userSelect: "none" } }}
            />
          </div>
        </>
      )}
    </CustomModal>
  );
};
