import { forwardRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Typography } from "@mui/material";
import { MemberFormModal } from "./form-modal";
import { OutsourcedMemberTable } from "./table";

const OutsourcedMember = forwardRef<HTMLDivElement>((_, ref) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <MemberFormModal open={setModalOpen} isOpen={isModalOpen} />

      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>協力会社</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setModalOpen(true)}
        >
          協力会社を新規作成
        </Button>
      </Box>
      <Box>
        <OutsourcedMemberTable />
      </Box>
    </>
  );
});

OutsourcedMember.displayName = "OutsourcedMember";

export default OutsourcedMember;
