import { theme } from "extensions/theme";

interface Props {
  value: string;
}

export const CheckCircledIcon = ({ value }: Props) => {
  const targetValue = ["billing_file_created"];
  return (
    <>
      {targetValue.includes(value) && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon / check-circled">
            <path
              id="Vector"
              d="M17.5 9.31429V10.0043C17.4991 11.6216 16.9754 13.1953 16.007 14.4907C15.0386 15.786 13.6775 16.7336 12.1265 17.1922C10.5756 17.6508 8.91794 17.5957 7.40085 17.0352C5.88376 16.4747 4.58849 15.4389 3.70822 14.0821C2.82795 12.7253 2.40984 11.1203 2.51626 9.50653C2.62267 7.89271 3.2479 6.35654 4.29871 5.1271C5.34951 3.89766 6.76959 3.04083 8.34714 2.6844C9.92469 2.32798 11.5752 2.49105 13.0525 3.14929"
              stroke={theme.palette.primary.main}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M16.9444 4.44434L10 11.3957L7.91666 9.31239"
              stroke={theme.palette.primary.main}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      )}
    </>
  );
};
