import { Brand } from "data-access/repositories/brand";

export type ExpenseItemId = Brand<number, "ExpenseItemId">;

export interface ExpenseItem {
  id: ExpenseItemId;
  name: string;
  isDefaultCost: boolean;
}

export type ExpenseItemIndexResponse = Array<ExpenseItem>;

export interface ExpenseItemRequest {
  name?: string;
  isDefaultCost?: boolean;
  displayOrderPosition?: number;
}

export const initialExpenseItemRequest: ExpenseItemRequest = {
  name: "",
  isDefaultCost: false,
  displayOrderPosition: 0,
};
