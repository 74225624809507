import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { cookieRepository } from "data-access/cookie/cookie.repository";
import { theme } from "extensions/theme";
import Logo from "images/gembahub.svg";

export const ErrorPage404 = () => {
  const navigate = useNavigate();
  const cookie = cookieRepository.get();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <img src={Logo} alt="logo" style={{ width: 320, marginBottom: 40 }} />
      <Typography
        fontSize={32}
        sx={{ fontWeight: "700", color: theme.palette.primary.main, mb: "40px" }}
      >
        お探しのページが見つかりませんでした
      </Typography>
      <Typography sx={{ textAlign: "center", mb: "40px" }}>
        申し訳ありません。お探しのページが見つかりませんでした。
        <br />
        URLをご確認いただき、もう一度お試しください。
        <br />
        エラーコード：404
      </Typography>
      {/* ログインしていないユーザには表示する必要がないため */}
      {cookie && (
        <Button sx={{ width: "213px" }} onClick={() => navigate("/projects")} variant="contained">
          案件一覧に戻る
        </Button>
      )}
    </Box>
  );
};
