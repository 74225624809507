import { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Flash } from "components/atoms/flash";
import { CustomModal } from "components/molecules/custom-modal";
import { groupRepository } from "data-access/repositories/group/group.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { GroupColorPatterns } from "utils/constant";

interface FormState {
  name: string;
  colorNumber: string;
  displayOrderPosition: number;
}

interface Props {
  open: (e: boolean) => void;
  isOpen: boolean;
}

export const GroupFormModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const { mutate: groupsMutate, data: groups } = useSWR("/api/v1/groups", groupRepository.index);

  const initialState = {
    name: "",
    colorNumber: "CCD2DE",
    displayOrderPosition: groups?.length || 0,
  };
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formState, setFormState] = useState<FormState>(initialState);

  const handleClose = () => {
    setFormState(initialState);
    props.open(false);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => {
    switch (e.target.name) {
      case "name":
        setFormState({ ...formState, name: e.target.value as string });
        break;
      case "color":
        setFormState({ ...formState, colorNumber: e.target.value as string });
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      await groupRepository.create(formState);
      groups && groupsMutate();
      handleClose();
      dispatch(mainOperations.updateSuccessMessage("グループを作成しました"));
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };
  const isValid = (): boolean => {
    if (!formState.name) return false;
    return true;
  };

  return (
    <>
      <CustomModal
        open={props.isOpen}
        onClose={handleClose}
        title="グループの新規作成"
        maxWidth="sm"
        footer={
          <>
            <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
              キャンセル
            </Button>
            <Button
              disabled={!isValid()}
              onClick={handleSubmit}
              variant="contained"
              sx={{ width: "10rem", ml: "1rem" }}
            >
              保存
            </Button>
          </>
        }
      >
        {errorMessage && (
          <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", my: "24px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px", mb: "16px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>グループ名</Typography>
            <Typography component="span" sx={{ color: theme.palette.red[500], fontSize: "12px" }}>
              必須
            </Typography>
          </Box>
          <TextField
            id="name"
            name="name"
            placeholder="グループ名を入力"
            value={formState.name}
            onChange={handleChange}
            sx={{ width: "50%" }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "700", mb: "16px" }}>カラー</Typography>
          <Select
            id="color"
            name="color"
            value={`${formState.colorNumber}`}
            onChange={handleChange}
            displayEmpty
            sx={{ width: "170px" }}
          >
            {GroupColorPatterns.map((color, index) => (
              <MenuItem value={color} key={index}>
                <Box
                  sx={{
                    width: 90,
                    height: 24,
                    backgroundColor: `#${color}`,
                    borderRadius: "4px",
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </Box>
      </CustomModal>
    </>
  );
};
