import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "380px",
      backgroundColor: theme.palette.grayScale[0],
      boxShadow: "24",
      borderRadius: "8px",
      padding: "16px",
    },
  }),
);
