import { useMemo, useState } from "react";
import {
  AutocompleteChangeReason,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { Flash } from "components/atoms/flash";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import {
  CompanyUser,
  PermissionSetKeyName,
} from "data-access/repositories/company_user/company_user.dto";
import { companyUserRepository } from "data-access/repositories/company_user/company_user.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { outsourcingCostRepository } from "features/cost/api/outsourcing_cost.repository";
import {
  OutsourcingCostCreateRequest,
  initialOutSourcingCostCreateRequest,
} from "features/cost/types/outsourcing_cost.dto";
import useSWR, { mutate } from "swr";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: ProjectId;
  fetchIndexKey: string;
}

export const OutsourcingCostFormModal = (props: Props) => {
  const { isOpen, onClose, projectId, fetchIndexKey } = props;
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isNewCreate, setIsNewCreate] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [formState, setFormState] = useState<OutsourcingCostCreateRequest>({
    employee: {
      name: "",
      permissionSetKeyName: PermissionSetKeyName.OutsourcedMember,
      userEmail: "",
      unitPricePerDay: 0,
    },
  });

  const { data } = useSWR(isOpen ? "/api/v1/company_users" : null, companyUserRepository.index);
  const activeOutsourcedMembers = useMemo(() => {
    return data?.filter((user) => {
      return !user.isDeactivate && user.isOutsourcedMember;
    });
  }, [data]);

  const handleCheckNewCreate = () => {
    setIsNewCreate(!isNewCreate);
    setFormState({
      userId: formState.userId,
      employee: {
        ...formState.employee,
        permissionSetKeyName: PermissionSetKeyName.OutsourcedMember,
      },
    });
  };

  // 「アサインのみ可能にする」チェックボックスの設定
  const [isAssignMemberOnly, setIsAssignMemberOnly] = useState(false);
  const handleAssignMemberOnlyCheckbox = (_: any, checked: boolean) => {
    setIsAssignMemberOnly(checked);
    setFormState({
      ...formState,
      employee: {
        ...formState.employee,
        permissionSetKeyName: checked
          ? PermissionSetKeyName.Other
          : PermissionSetKeyName.OutsourcedMember,
      },
    });
  };

  const handleSelectOutsourcedMember = async (
    _: React.SyntheticEvent,
    value: CompanyUser,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason !== "selectOption") return;
    setFormState({
      userId: value.userId,
      employee: {
        ...formState.employee,
      },
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({
      ...formState,
      employee: {
        ...formState.employee,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    const convertedFormState = isNewCreate
      ? { employee: formState.employee }
      : { userId: formState.userId };

    try {
      await outsourcingCostRepository.create(projectId, convertedFormState);
      handleClose();
      mutate(fetchIndexKey);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    } finally {
      setIsSubmitted(false);
    }
  };

  const handleClose = () => {
    onClose();
    setIsNewCreate(false);
    setFormState(initialOutSourcingCostCreateRequest);
    setIsAssignMemberOnly(false);
    setErrorMessage("");
  };

  const isValid = (): boolean => {
    if (!isNewCreate && !formState.userId) return false;
    if (isNewCreate && !formState.employee?.name) return false;
    if (isNewCreate && !formState.employee?.unitPricePerDay) return false;
    if (isNewCreate && !isAssignMemberOnly && !formState.employee?.userEmail) return false;
    return true;
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={handleClose}
      title="協力会社の追加"
      maxWidth="sm"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid() || isSubmitted}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            追加
          </Button>
        </>
      }
    >
      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}

      <CustomAutocomplete
        variant="standard"
        data={activeOutsourcedMembers || []}
        value={formState.userId}
        onChange={handleSelectOutsourcedMember}
        entityName="協力会社"
        entityLabel="協力会社を検索または選択"
        disabled={isNewCreate}
      />

      <FormControlLabel
        sx={{ ml: 0, my: "12px" }}
        labelPlacement="end"
        label={
          <Typography sx={{ fontWeight: "500", fontSize: "14px", userSelect: "none" }}>
            協力会社を新規作成して追加する
          </Typography>
        }
        control={
          <Checkbox
            name="newCreateOutsourcing"
            sx={{ p: 0, pr: 1 }}
            onChange={handleCheckNewCreate}
            checked={isNewCreate}
          />
        }
      />

      <Collapse in={isNewCreate} timeout="auto">
        <div style={{ marginBottom: "8px" }}>
          <CustomFormLabel
            labelName="名前"
            labelColor={theme.palette.grayScale[900]}
            labelWeight="bold"
            required
            requiredSize="14px"
          />
          <TextField
            name="name"
            value={formState.employee?.name}
            onChange={handleChange}
            sx={{ width: "100%" }}
            placeholder="名前を入力"
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
          <Checkbox checked={isAssignMemberOnly} onChange={handleAssignMemberOnlyCheckbox} />
          <Typography sx={{ fontSize: "14px" }}>アサインのみ可能（ログイン不可）にする</Typography>
        </div>
        <div style={{ marginBottom: "8px" }}>
          <CustomFormLabel
            labelName="メールアドレス"
            labelColor={theme.palette.grayScale[900]}
            labelWeight="bold"
            required={!isAssignMemberOnly}
            requiredSize="14px"
          />
          <TextField
            name="userEmail"
            type="email"
            value={formState.employee?.userEmail}
            onChange={handleChange}
            sx={{
              width: "100%",
              backgroundColor: isAssignMemberOnly ? theme.palette.primary.light : "",
            }}
            required={!isAssignMemberOnly}
            disabled={isAssignMemberOnly}
            placeholder="メールアドレスを入力"
          />
        </div>
        <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "16px" }}>
          ユーザーIDとパスワードを記載したメールが送信されます。
        </Typography>
        <div>
          <CustomFormLabel
            labelName="人工単価"
            labelColor={theme.palette.grayScale[900]}
            labelWeight="bold"
            required
            requiredSize="14px"
          />
          <TextField
            name="unitPricePerDay"
            type="number"
            value={formState.employee?.unitPricePerDay}
            onChange={handleChange}
            sx={{ width: "100%" }}
            placeholder="人工単価を入力"
            InputProps={{
              startAdornment: <InputAdornment position="start">¥</InputAdornment>,
            }}
          />
        </div>
      </Collapse>
    </CustomModal>
  );
};
