import { Divider, Typography } from "@mui/material";
import { theme } from "extensions/theme";
import { SalesAggregationResponse } from "features/sales-aggregation/types/common.dto";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { LegendLabel } from "../legend_label";

interface Data {
  targetName: string;
  salesAmount: number;
  salesAmountByChart: number;
  grossProfit: number;
  grossProfitByChart: number;
  grossProfitRate: number;
  grossProfitRateByChart: number;
}
interface Props {
  data: SalesAggregationResponse;
}
export const TotalChart = ({ data }: Props) => {
  const formatData: Data[] = data.map((v) => {
    const CHART_VALUE_DIVISOR = 1000;
    const NEGATIVE_VALUE = -1;
    return {
      targetName: v.targetName,
      salesAmount: v.salesAmount,
      salesAmountByChart:
        Math.sign(v.grossProfit) === NEGATIVE_VALUE
          ? v.salesAmount / CHART_VALUE_DIVISOR
          : (v.salesAmount - v.grossProfit) / CHART_VALUE_DIVISOR,
      grossProfit: v.grossProfit,
      grossProfitByChart:
        Math.sign(v.grossProfit) === NEGATIVE_VALUE ? 0 : v.grossProfit / CHART_VALUE_DIVISOR,
      grossProfitRate: v.grossProfitRate,
      grossProfitRateByChart:
        Math.sign(v.grossProfitRate) === NEGATIVE_VALUE ? 0 : v.grossProfitRate,
    };
  });

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>全体</Typography>
        <div style={{ display: "flex", gap: "20px" }}>
          <LegendLabel bgColor={theme.palette.blue[400]} label="売上" />
          <LegendLabel bgColor={theme.palette.teal[400]} label="粗利" />
          <LegendLabel bgColor={theme.palette.mustard[400]} label="粗利率" />
        </div>
      </div>
      <Divider sx={{ my: "12px" }} />

      <div style={{ height: "340px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={formatData}
            margin={{
              top: 50,
              right: 30,
              left: 20,
              bottom: 15,
            }}
          >
            <CartesianGrid strokeDasharray="" vertical={false} />
            <XAxis dataKey="targetName" interval={0} tick={<CustomXAxisTick />} />
            <YAxis label={{ value: "売上(千円)", position: "top", offset: 30 }} />
            <YAxis
              yAxisId="right"
              orientation="right"
              type="number"
              tickFormatter={(value) => `${value}%`}
              label={{ value: "粗利率", position: "top", offset: 30 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="grossProfitByChart"
              stackId="a"
              fill={theme.palette.secondary.light}
              activeBar={<Rectangle fill={theme.palette.teal[400]} />}
              barSize={30}
            />
            <Bar
              dataKey="salesAmountByChart"
              stackId="a"
              label={(props) => renderCustomBarLabel(props, formatData)}
              fill={theme.palette.primary.light}
              activeBar={<Rectangle fill={theme.palette.blue[400]} />}
              radius={[5, 5, 0, 0]}
              barSize={30}
            />
            <Line
              dataKey="grossProfitRateByChart"
              yAxisId="right"
              type="linear"
              strokeDasharray="5 5"
              strokeWidth={2}
              stroke={theme.palette.mustard[400]}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const CustomXAxisTick = (props: any) => {
  const { x, y, payload, index } = props;

  const dateParts = payload.value.split("-");
  const year = `${dateParts[0]}年`;
  const month = `${dateParts[1].replace(/^0+/, "")}月`;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fontWeight="bold">
        {month}
      </text>
      {(month === "1月" || index === 0) && (
        <text x={0} y={0} dy={36} textAnchor="middle">
          {year}
        </text>
      )}
    </g>
  );
};

const renderCustomBarLabel = (props: any, data: Data[]) => {
  const { x, y, width, index } = props;
  const labelValue = data[index].grossProfitRate;
  return (
    <text
      x={x + width / 2}
      y={y}
      fill={theme.palette.grayScale[700]}
      textAnchor="middle"
      dy={-6}
      style={{ fontSize: "11px", fontWeight: "bold" }}
    >{`${labelValue}%`}</text>
  );
};

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const data: Data = payload[0].payload;
    const dateParts = data.targetName.split("-");
    const year = `${dateParts[0]}年`;
    const month = `${dateParts[1].replace(/^0+/, "")}月`;
    return (
      <div
        style={{
          backgroundColor: theme.palette.customPrimary[50],
          borderRadius: "16px",
          padding: "12px 16px",
          boxShadow: `0px 4px 8px 0px ${theme.palette.grayScale[900]}`,
        }}
      >
        <Typography sx={{ fontSize: "14px", mb: "8px" }}>
          {year} {month}
        </Typography>
        <div style={{ display: "flex", gap: "16px" }}>
          <div>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>売上</Typography>
            <Typography sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
              ¥{data.salesAmount?.toLocaleString()}
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>粗利</Typography>
            <Typography sx={{ fontWeight: "bold", color: theme.palette.secondary.main }}>
              ¥{data.grossProfit?.toLocaleString()}
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>粗利率</Typography>
            <Typography sx={{ fontWeight: "bold", color: theme.palette.mustard[500] }}>
              {data.grossProfitRate}%
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
