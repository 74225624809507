import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ClientIndexRequest,
  ClientIndexResponse,
} from "data-access/repositories/client/client.dto";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";

export const getClients = createAsyncThunk<
  ClientIndexResponse,
  ClientIndexRequest,
  AsyncThunkConfig
>("billingTableHeader/clients", async (query, thunkApi) => {
  try {
    return await clientRepository.index(query);
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});
