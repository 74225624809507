import { ApiClient } from "data-access/ApiClient";
import { ExternalScheduleList, ExternalScheduleRequest } from "./external_schedule.dto";

export const externalScheduleRepository = {
  index(query: ExternalScheduleRequest): Promise<ExternalScheduleList> {
    const params = {
      schedule_range_start: query.scheduleRangeStart,
      schedule_range_end: query.scheduleRangeEnd,
      user_ids: query.userIds,
      group_ids: query.groupIds,
    };
    return ApiClient.get("/api/v1/microsoft/schedules", { params }).then((res) => res.data);
  },
};
