import { forwardRef } from "react";
import { HelpOutlined } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { MemberOrderTable } from "./table";

const MemberOrder = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <div style={{ display: "flex", gap: "8px" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>表示順</Typography>
          <Tooltip title="並び順は予定やプルダウンへ反映されます" placement="right">
            <HelpOutlined fontSize="small" color="primary" />
          </Tooltip>
        </div>
      </Box>
      <Box>
        <MemberOrderTable />
      </Box>
    </>
  );
});

MemberOrder.displayName = "MemberOrder";

export default MemberOrder;
