import { useRef } from "react";
import { RefObject } from "@fullcalendar/core/preact";
import { Divider, Stack, Typography } from "@mui/material";
import { Layout } from "components/templates/layout";
import ExpenseItem from "./expense-item";
import { styles } from "./styles";
import Supplier from "./supplier";

export const CostSetting = () => {
  const classes = styles();

  const supplierHeaderRef = useRef<HTMLDivElement>(null);
  const expenseItemHeaderRef = useRef<HTMLDivElement>(null);

  // スクロールを実行する関数
  const scrollToSection = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Layout>
      <div style={{ display: "flex" }}>
        <div style={{ width: "150px" }}>
          <Stack spacing={2} sx={{ mr: "20px" }}>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(supplierHeaderRef)}
              fontWeight="500"
            >
              仕入先
            </Typography>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(expenseItemHeaderRef)}
              fontWeight="500"
            >
              経費項目
            </Typography>
          </Stack>
        </div>
        <div style={{ overflow: "scroll", height: "85vh", flex: 1 }}>
          <Supplier ref={supplierHeaderRef} />
          <Divider sx={{ my: 6 }} />
          <ExpenseItem ref={expenseItemHeaderRef} />
        </div>
      </div>
    </Layout>
  );
};
