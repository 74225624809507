import { useEffect, useState } from "react";
import { ActivityBlock } from "components/InfoBlock/activity";
import { projectActivityRepository } from "data-access/repositories/project/activity/project_activity.repository";
import { ProjectSidebarState } from "store/project-sidebar/slice";
import useSWR from "swr";
import { ACTIVITY_PER_PAGE } from "utils/constant";

interface Props {
  state: ProjectSidebarState;
}

export const ProjectSidebarActivity = (props: Props) => {
  const { state } = props;

  const [limit, setLimit] = useState<number>(ACTIVITY_PER_PAGE);
  const { data: activities, mutate } = useSWR(
    state.project.id ? `/api/v1/projects/${state.project.id}/activities?limit=${limit}` : null,
    () => projectActivityRepository.index(state.project.id, limit),
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (
      state.project.id > 0 &&
      (state.isSubmitted || state.isTodoSubmitted || state.isEstimateSubmitted)
    ) {
      mutate();
    }
  }, [state.isSubmitted, state.isTodoSubmitted, state.isEstimateSubmitted]);

  return (
    <ActivityBlock
      activities={activities?.data || []}
      totalCount={activities?.pagination.count || 0}
      limit={limit}
      setLimit={setLimit}
    />
  );
};
