import { useMemo } from "react";
import FaxIcon from "@mui/icons-material/Fax";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  AutocompleteChangeReason,
  Button,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { TitleLabel } from "components/label/title-label";
import { Building, BuildingId } from "data-access/repositories/building/building.dto";
import { ProjectUpdateRequest } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { ProjectSidebarState } from "store/project-sidebar/slice";

const STextField = styled(TextField)({
  width: "130px",
  "& .MuiInputBase-input": {
    padding: "10px 8px",
  },
});

const STypography = styled(Typography)({
  color: theme.palette.grayScale[700],
  fontSize: "14px",
  display: "flex",
  gap: "8px",
});

interface Props {
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChangeBuilding: (
    event: React.SyntheticEvent<Element, Event>,
    value: {
      id: BuildingId;
      name: string;
      createOption: boolean;
    } | null,
    reason: AutocompleteChangeReason,
  ) => void;
  onCreate: () => void;
  state: ProjectSidebarState;
  projectForm: ProjectUpdateRequest;
  rawBuildingsData: Building[];
}

export const ProjectSidebarSiteInfo = (props: Props) => {
  const { onChange, onBlur, onChangeBuilding, onCreate, state, projectForm, rawBuildingsData } =
    props;
  const dispatch = useAppDispatch();
  const postalCode = projectForm.postalCode
    ? projectForm.postalCode.replace("-", "").replace("ー", "")
    : "";

  const buildings = rawBuildingsData.map((building) => {
    return {
      id: building.id,
      name: building.name,
      createOption: false,
    };
  });

  const selectedBuilding = useMemo(() => {
    if (buildings.length === 0) return [];
    return buildings.filter((building) => building.id === projectForm.buildingId);
  }, [projectForm.buildingId, buildings]);

  const handleClickSearchAddress = () => {
    if (projectForm.postalCode) {
      const postalCode: string = projectForm.postalCode.replace(/-/g, "");
      dispatch(projectSidebarOperations.getAddress(postalCode));
    }
  };

  const handleClickSearchPostalCode = () => {
    dispatch(projectSidebarOperations.getPostalCode(projectForm.address as string));
  };

  return (
    <>
      <TitleLabel title="現場情報" sx={{ mb: "8px" }} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CustomAutocomplete
          data={buildings}
          value={selectedBuilding[0] || null}
          onChange={onChangeBuilding}
          onClick={onCreate}
          isCreate
          entityName="物件"
          entityLabel="物件"
          variant="standard"
          isClearable
          sx={{ width: "250px", mb: "20px" }}
        />
        <TextField
          id="personName"
          name="personName"
          variant="standard"
          label="先方担当者"
          value={projectForm.personName || ""}
          onChange={onChange}
          onBlur={onBlur}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
          gap: "16px",
        }}
      >
        <div>
          <div style={{ display: "flex" }}>
            <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px" }}>
              郵便番号
            </Typography>
            <Button
              onClick={handleClickSearchAddress}
              disabled={postalCode.length !== 7}
              sx={{ py: 0, ml: "8px", fontSize: "14px" }}
            >
              住所を検索
            </Button>
          </div>
          <STextField
            id="postalCode"
            name="postalCode"
            value={projectForm.postalCode}
            error={state.postalCodeErrorMessage.length > 0}
            helperText={state.postalCodeErrorMessage}
            onChange={onChange}
            onBlur={onBlur}
            InputProps={{
              startAdornment: <InputAdornment position="start">〒</InputAdornment>,
            }}
            sx={{ width: "150px" }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px" }}>
              都道府県・市区町村・番地
            </Typography>
            <Button
              sx={{ py: 0 }}
              onClick={handleClickSearchPostalCode}
              disabled={projectForm.address?.length === 0}
            >
              郵便番号を検索
            </Button>
          </div>
          <STextField
            id="address"
            name="address"
            value={projectForm.address}
            error={state.addressErrorMessage.length > 0}
            helperText={state.addressErrorMessage}
            onChange={onChange}
            onBlur={onBlur}
            sx={{ width: "100%" }}
            placeholder="東京都渋谷区神泉町１１ー１０"
          />
        </div>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px" }}>
          建物名・階・号室
        </Typography>
        <STextField
          sx={{ width: "100%" }}
          id="addressSecond"
          name="addressSecond"
          value={projectForm.addressSecond}
          onChange={onChange}
          onBlur={onBlur}
          placeholder="現場Hubビル１階"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <div>
          <STypography>
            <PhoneIcon sx={{ width: "18px" }} />
            電話番号1
          </STypography>
          <STextField
            id="phoneNumber"
            name="phoneNumber"
            value={projectForm.phoneNumber}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="000-0000-0000"
          />
        </div>
        <div>
          <STypography>
            <PhoneIcon sx={{ width: "18px" }} />
            電話番号2
          </STypography>
          <STextField
            id="phoneNumberSecond"
            name="phoneNumberSecond"
            value={projectForm.phoneNumberSecond}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="000-0000-0000"
          />
        </div>
        <div>
          <STypography>
            <FaxIcon sx={{ width: "18px" }} />
            FAX番号
          </STypography>
          <STextField
            id="faxNumber"
            name="faxNumber"
            value={projectForm.faxNumber}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="000-0000-0000"
          />
        </div>
        <div>
          <STypography>
            <MailOutlineIcon sx={{ width: "18px" }} />
            メールアドレス
          </STypography>
          <STextField
            id="email"
            name="email"
            value={projectForm.email}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="***@gembahub.jp"
            sx={{ width: "230px" }}
          />
        </div>
      </div>
    </>
  );
};
