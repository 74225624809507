import { Box, Tooltip, Typography } from "@mui/material";
import { GroupLabel } from "components/label/group-label";
import { StatusTypeLabel } from "components/label/status-type-label";
import { theme } from "extensions/theme";
import { AttendanceEmployee } from "features/attendance/types/daily_record/attendance.employee.dto";
import { formatDateUtil } from "utils/formatDateUtil";

interface Props {
  currentDate: Date;
  attendanceEmployee: AttendanceEmployee;
}

export const Card = (props: Props) => {
  const { currentDate, attendanceEmployee } = props;
  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grayScale[300]}`,
        borderRadius: "8px",
        mb: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
          bgcolor: theme.palette.customPrimary[50],
          borderRadius: "8px 8px 0 0",
          p: "16px 12px",
          gap: "20px",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{attendanceEmployee.name}</Typography>
        <StatusTypeLabel statusType={attendanceEmployee.attendance.statusType.valueI18n} />
        {attendanceEmployee.group && (
          <GroupLabel
            name={attendanceEmployee.group.name}
            colorNumber={attendanceEmployee.group.colorNumber}
          />
        )}
        {attendanceEmployee.attendance.note && (
          <Typography
            sx={{
              flex: 1,
              fontSize: "14px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "1",
            }}
          >
            {attendanceEmployee.attendance.note}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderRight: `1px solid ${theme.palette.grayScale[300]}`,
            p: "8px 16px",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold", mb: "20px" }}>
              労働時間
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>休憩時間</Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "16px", mb: "16px" }}>
              {attendanceEmployee.summary.laborHours}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {attendanceEmployee.summary.breakHours}
            </Typography>
          </div>
        </Box>
        {attendanceEmployee.attendance.timestamps.length ? (
          <Box sx={{ display: "flex,", flexDirection: "column", flex: 1 }}>
            {attendanceEmployee.attendance.timestamps.map((timestamp, index, array) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom:
                    index === array.length - 1
                      ? "none"
                      : `1px solid ${theme.palette.grayScale[300]}`,
                  height: array.length === 1 ? "77px" : "60px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100px",
                    height: "100%",
                    borderRight: `1px solid ${theme.palette.grayScale[300]}`,
                  }}
                >
                  <StatusTypeLabel statusType={timestamp.stampType.valueI18n} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "70px",
                    textAlign: "center",
                    height: "100%",
                    borderRight: `1px solid ${theme.palette.grayScale[300]}`,
                  }}
                >
                  <Typography>
                    {currentDate.getDate() === new Date(timestamp.datetime).getDate()
                      ? "当日"
                      : "翌日"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "70px",
                    textAlign: "center",
                    height: "100%",
                    borderRight: `1px solid ${theme.palette.grayScale[300]}`,
                  }}
                >
                  <Typography>{formatDateUtil(timestamp.datetime, "HH:mm")}</Typography>
                </Box>
                <Tooltip
                  title={timestamp.address && "最大で100mの誤差が出ることがあります"}
                  placement="top-start"
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: "10px",
                      height: "100%",
                      borderRight: `1px solid ${theme.palette.grayScale[300]}`,
                      flex: 1,
                    }}
                  >
                    <Typography fontSize="14px">{timestamp.address || "-"}</Typography>
                  </Box>
                </Tooltip>
                <Box>
                  <Typography
                    sx={{
                      width: "50px",
                      fontSize: "12px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    {timestamp.isEdited && "編集済"}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography sx={{ display: "flex", alignItems: "center", fontWeight: "500", ml: "16px" }}>
            打刻記録がありません
          </Typography>
        )}
      </Box>
    </Box>
  );
};
