import { Range } from "react-date-range";
import { scheduleSearchResultHeaderSlice } from "./slice";
import { AppDispatch } from "../store";

export const searchResultHeaderOperations = {
  updateScheduleDateRange: (value: Range) => (dispatch: AppDispatch) => {
    dispatch(scheduleSearchResultHeaderSlice.actions.updateScheduleDateRange({ value }));
  },
  updateScheduleDateRangeLabel: (value: string) => (dispatch: AppDispatch) => {
    dispatch(scheduleSearchResultHeaderSlice.actions.updateScheduleDateRangeLabel({ value }));
  },
  updateKeyword: (value: string) => (dispatch: AppDispatch) => {
    dispatch(scheduleSearchResultHeaderSlice.actions.updateKeyword({ value }));
  },
};
