import { createStyles, makeStyles } from "@mui/styles";

export const styles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiPaper-root": {
        padding: "1rem",
      },
    },
  }),
);
