import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface DiscardChangesConfirmDialogState {
  isConfirm: boolean;
  isOpen: boolean;
}

const initialState: DiscardChangesConfirmDialogState = {
  isConfirm: false,
  isOpen: false,
};

export const discardChangesConfirmDialogSlice = createSlice({
  name: "discardChangesConfirmDialog",
  initialState,
  reducers: {
    updateIsConfirm: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isConfirm: action.payload.value,
    }),
    open: (state) => ({
      ...state,
      isOpen: true,
      isConfirm: false,
    }),
    close: () => initialState,
  },
  extraReducers: () => {},
});

export const selectDiscardChangesConfirmDialog = (state: RootState) =>
  state.discardChangesConfirmDialog;
export default discardChangesConfirmDialogSlice.reducer;
