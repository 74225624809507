import { createSlice } from "@reduxjs/toolkit";
import { Client } from "data-access/repositories/client/client.dto";
import { getClients } from "./actions";
import type { RootState } from "../store";

export type BillingTableHeaderState = {
  isLoading: boolean;
  clients: Client[];
  countChanges: number;
};

const initialState: BillingTableHeaderState = {
  isLoading: false,
  clients: [],
  countChanges: 0,
};

export const billingTableHeaderSlice = createSlice({
  name: "billingTableHeader",
  initialState,
  reducers: {
    addCountChanges: (state) => ({
      ...state,
      countChanges: state.countChanges + 1,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getClients.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clients = action.payload.data;
    });
    builder.addCase(getClients.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const selectBillingTableHeader = (state: RootState) => state.billingTableHeader;
export default billingTableHeaderSlice.reducer;
