import { useState } from "react";
import { Typography, Button, Box, CircularProgress } from "@mui/material";
import { PrimaryButton } from "components/atoms/primary-button";
import { TagLabel } from "components/label/tag-label";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { ProjectScheduleResponse } from "data-access/repositories/project/schedule/schedule.dto";
import { projectScheduleRepository } from "data-access/repositories/project/schedule/schedule.repository";
import { ScheduleList } from "data-access/repositories/schedule/schedule.dto";
import { theme } from "extensions/theme";
import { ProjectSidebarState } from "store/project-sidebar/slice";
import useSWRInfinite from "swr/infinite";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { timeRange } from "utils/timeRange";
import { styles } from "./styles";
import { ProjectScheduleFormModal } from "../project-schedule-form-modal";

interface Props {
  state: ProjectSidebarState;
  projectId: ProjectId;
  projectName: string;
}

export const ProjectSidebarScheduleBlock = (props: Props) => {
  const { state, projectId, projectName } = props;

  const getKey = (pageIndex: number, previousPageData: ProjectScheduleResponse | null) => {
    if (!projectId && !previousPageData) return null;
    return [`/api/v2/projects/${projectId}/schedules`, pageIndex];
  };

  const fetcher = (v: [string, number]) => {
    return projectScheduleRepository.index({
      id: projectId,
      page: v[1] + 1,
      limit: 10,
    });
  };

  const { data, isValidating, size, setSize } = useSWRInfinite(getKey, fetcher, {
    initialSize: 1,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <ProjectScheduleFormModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        projectId={projectId}
        projectName={projectName}
        fetchKey={getKey}
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.grayScale[700],
            fontSize: "16px",
            height: "34px",
            lineHeight: "34px",
          }}
        >
          予定
        </Typography>
        <PrimaryButton
          name="予定を作成"
          onClick={() => setIsOpen(true)}
          disabled={!state.isExisting}
        />
      </Box>
      <Box sx={{ maxHeight: "440px", overflow: "scroll" }}>
        {data &&
          data.map((list: ProjectScheduleResponse) =>
            list.data.map((schedule: ScheduleList) => (
              <ScheduleBlock key={schedule.id} schedule={schedule} />
            )),
          )}
        {!data?.[0].data.length && <Typography>予定がありません</Typography>}

        {data && size < data?.[0].pagination.pages && (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
            {!isValidating ? (
              <Button
                onClick={() => {
                  setSize(size + 1);
                }}
              >
                さらに取得
              </Button>
            ) : (
              <CircularProgress />
            )}
          </div>
        )}
      </Box>
    </>
  );
};

const ScheduleBlock = ({ schedule }: { schedule: ScheduleList }) => {
  const classes = styles();

  const handleClick = () => {
    openURLInNewTab(`calendar/${schedule.id}`);
  };

  return (
    <Box key={schedule.id} onClick={handleClick} className={classes.scheduleBlock}>
      <Box sx={{ display: "flex", alignItems: "center", mb: "8px", gap: "12px" }}>
        <TagLabel
          tagName={schedule.schedule_type.name}
          colorNumber={schedule.schedule_type.color_number}
        />
        <Typography className={classes.scheduleName}>{schedule.name}</Typography>
      </Box>
      {schedule.start_time && schedule.end_time ? (
        <>
          <Typography sx={{ fontSize: "16px", fontWeight: "500", mb: "8px" }}>
            {timeRange("yyyy年MM月dd日 HH:mm", schedule.start_time, schedule.end_time)}
          </Typography>
        </>
      ) : (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: theme.palette.grayScale[700],
            mb: "8px",
          }}
        >
          日付未設定
        </Typography>
      )}
      <Typography sx={{ fontSize: "14px", mb: "8px" }}>
        {schedule.users?.map((user) => user.name).join(",")}
      </Typography>
      <Typography className={classes.note}>{schedule.note}</Typography>
    </Box>
  );
};
