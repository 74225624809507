import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    date: {
      height: "40px",
      border: `1px solid ${theme.palette.grayScale[300]}`,
      borderRadius: "4px",
      fontSize: "16px",
      width: "130px",
    },
  }),
);
