import { useEffect } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { FileDropzone } from "components/molecules/file-dropzone";
import { PdfFileBlock } from "components/molecules/pdf-file-block";
import { warrantyRepository } from "data-access/repositories/billing/warranty/warranty.repository";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { selectBillingSidebar } from "store/billing-sidebar/slice";
import { deleteConfirmDialogOperations } from "store/delete-confirm-dialog/operations";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { PREVIEWABLE_EXCEL_EXTENSION, PDF_EXTENSION } from "utils/constant";
import { isContainExtensions } from "utils/isContainExtensions";

export const BillingSidebarWarrantiesBlock = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectBillingSidebar);

  useEffect(() => {
    state.uploadedWarranties.length > 0 &&
      dispatch(
        billingSidebarOperations.createWarranty(state.billing.id, {
          files: state.uploadedWarranties,
        }),
      );
  }, [state.uploadedWarranties]);

  useEffect(() => {
    if (state.billing.id > 0) {
      dispatch(billingSidebarOperations.showWarranty(state.billing.id));
    }
  }, [state.billing.id, state.isWarrantySubmitted]);

  const handleWarrantyUpload = (files: File[]) => {
    dispatch(billingSidebarOperations.setUploadedWarranties(files));
  };

  const handleDeleteHeaderWarrantyClick = (id: number) => {
    dispatch(
      deleteConfirmDialogOperations.setObject({
        id,
        type: "billingWarranty",
      }),
    );
    dispatch(deleteConfirmDialogOperations.open());
  };

  const handleClickPreview = async (file: { id: number; name: string; url: string }) => {
    if (isContainExtensions(file.name, [PDF_EXTENSION])) {
      window.open(file.url);
      return;
    }
    if (isContainExtensions(file.name, PREVIEWABLE_EXCEL_EXTENSION)) {
      dispatch(mainOperations.updateIsLoading(true));
      const result = await warrantyRepository.excelFilePreview(state.billing.id, file.id);
      window.open(result.url, "_blank");
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as string;
    const parseBoolean: boolean = JSON.parse(value.toLowerCase());
    dispatch(billingSidebarOperations.updateIsWarrantyAttach(parseBoolean));
    dispatch(
      billingSidebarOperations.updateBilling(state.billing.id, {
        isWarrantyAttach: parseBoolean,
      }),
    );
  };

  return (
    <>
      <CustomFormLabel labelName="保証書" />

      <RadioGroup name="isWarrantyAttach" row onChange={handleRadio}>
        <FormControlLabel
          control={<Radio size="small" checked={state.form.isWarrantyAttach} />}
          value={true}
          sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
          label="付与する"
        />
        <FormControlLabel
          control={<Radio size="small" checked={!state.form.isWarrantyAttach} />}
          value={false}
          sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
          label="付与しない"
        />
      </RadioGroup>

      {state.warranties.map((warranty) => {
        return (
          <PdfFileBlock
            key={warranty.id}
            pdfFile={{
              id: warranty.id,
              name: warranty.file_name,
              url: warranty.file_url,
              uploadedDate: warranty.created_date,
            }}
            onDelete={handleDeleteHeaderWarrantyClick}
            handleClickPreview={handleClickPreview}
            availableExtensions={[PDF_EXTENSION, ...PREVIEWABLE_EXCEL_EXTENSION]}
          />
        );
      })}
      <FileDropzone onDrop={handleWarrantyUpload} />
    </>
  );
};
