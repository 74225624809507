import React from "react";
import { Box, Typography } from "@mui/material";
import { Activity } from "data-access/repositories/activity/activity.dto";
import { theme } from "extensions/theme";
import { formatDateUtil } from "utils/formatDateUtil";

interface Props {
  sx?: object;
  activity: Activity;
}

export const ActivityItem = (props: Props) => {
  return (
    <Box sx={{ mb: "1rem" }}>
      <Box>
        <Typography component="span">{props.activity.created_by.name}</Typography>
        {props.activity.activity_type !== "commented" && (
          <Typography component="span" sx={{ ml: "1rem" }}>
            {props.activity.activity_type_i18n}
          </Typography>
        )}
        <Typography
          component="span"
          sx={{ fontSize: "0.75rem", color: theme.palette.grayScale[700], ml: "1rem" }}
        >
          {formatDateUtil(props.activity.created_at, "yyyy年MM月dd日 HH:mm")}
        </Typography>
      </Box>
      {props.activity.activity_type === "edited" && (
        <Box>
          {props.activity.activity_details.map((detail) => {
            return (
              <Box key={detail.id}>
                {detail.change_type === "add" && (
                  <>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.child_record_name}(追加)：
                    </Typography>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.attachment_name}
                    </Typography>
                  </>
                )}
                {detail.change_type === "remove" && (
                  <>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.child_record_name}(削除)：
                    </Typography>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.attachment_name}
                    </Typography>
                  </>
                )}
                {detail.change_type === "edit" && (
                  <>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.child_record_name ? detail.child_record_name : detail.field_name}
                      {detail.child_record_name &&
                        `(${detail.attachment_name}) ${detail.field_name}`}
                      ：
                    </Typography>
                    <Typography
                      component="span"
                      sx={{ fontSize: "0.75rem", mt: "0.5rem", wordWrap: "break-word" }}
                    >
                      {detail.old_value ? detail.old_value : "空白"}→
                      {detail.new_value ? detail.new_value : "空白"}
                    </Typography>
                  </>
                )}
              </Box>
            );
          })}
        </Box>
      )}
      {props.activity.activity_type === "commented" && (
        <Box
          sx={{
            border: `1px solid ${theme.palette.grayScale[700]}`,
            backgroundColor: theme.palette.grayScale[0],
            p: "1rem",
            mt: "0.5rem",
          }}
        >
          {props.activity.comment.split("\n").map((str: string, index: number) => {
            return (
              <React.Fragment key={index}>
                {str}
                <br />
              </React.Fragment>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
