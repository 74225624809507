import { ApiClient } from "data-access/ApiClient";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  OutsourcingCostCreateRequest,
  OutsourcingCostId,
  OutsourcingCostIndexResponse,
  OutsourcingCostUpdateRequest,
} from "../types/outsourcing_cost.dto";

export const outsourcingCostRepository = {
  index(projectId: ProjectId): Promise<OutsourcingCostIndexResponse> {
    return ApiClient.get(`/api/v1/projects/${projectId}/outsourcing_costs`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(projectId: ProjectId, body: OutsourcingCostCreateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post(
      `/api/v1/projects/${projectId}/outsourcing_costs`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  update(
    projectId: ProjectId,
    id: OutsourcingCostId,
    body: OutsourcingCostUpdateRequest,
  ): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(
      `/api/v1/projects/${projectId}/outsourcing_costs/${id}`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  destroy(projectId: ProjectId, id: OutsourcingCostId): Promise<void> {
    return ApiClient.delete(`/api/v1/projects/${projectId}/outsourcing_costs/${id}`).then(
      (res) => res.data,
    );
  },
};
