import { BillingId, BillingIndexRequest } from "data-access/repositories/billing/billing.dto";
import { index, destroy } from "./actions";
import { billingTableSlice } from "./slice";
import { AppDispatch } from "../store";

export const billingTableOperations = {
  index: (query: BillingIndexRequest) => (dispatch: AppDispatch) => {
    dispatch(index(query));
  },
  destroy: (id: BillingId) => (dispatch: AppDispatch) => {
    dispatch(destroy(id));
  },
  setIsLoading: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(billingTableSlice.actions.setIsLoading({ value }));
  },
  updateCurrentPage: (value: number) => (dispatch: AppDispatch) => {
    dispatch(billingTableSlice.actions.updateCurrentPage({ value }));
  },
  updateRowsPerPage: (value: number) => (dispatch: AppDispatch) => {
    dispatch(billingTableSlice.actions.updateRowsPerPage({ value }));
  },
  updateSuccessMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(billingTableSlice.actions.updateSuccessMessage({ value }));
  },
  updateErrorMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(billingTableSlice.actions.updateErrorMessage({ value }));
  },
};
