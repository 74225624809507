import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import {
  EndType,
  FrequencyType,
  KeyName,
  RequestRecurrenceRule,
} from "data-access/repositories/recurrence_rule/recurrence_rule.dto";
import { RecurrenceRuleLabelRepository } from "data-access/repositories/recurrence_rule_label/recurrence_rule_label.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { DaysOfWeek } from "utils/constant";
import { styles } from "./styles";

interface RecurrenceRuleProps {
  frequencyType: FrequencyType;
  interval: number;
  daysOfWeek: number[];
  endType: EndType;
  endDate: Date;
  count: number;
}
const dowStyle = (formState: RecurrenceRuleProps, index: number) => ({
  border: `1px solid ${theme.palette.grayScale[200]}`,
  borderRadius: "4px",
  p: "8px",
  backgroundColor: formState.daysOfWeek.includes(index)
    ? theme.palette.primary.main
    : "transparent",
  cursor: "pointer",
  color: formState.daysOfWeek.includes(index)
    ? theme.palette.grayScale[0]
    : theme.palette.text.primary,
});

interface Props {
  isOpen: boolean;
  recurrenceRule: RequestRecurrenceRule | undefined;
  setRecurrenceSettingModalState: (
    isOpen: boolean,
    recurrenceRule: { keyName: KeyName; label: string; params: RequestRecurrenceRule },
  ) => void;
  onClose: () => void;
  startDate: string;
}

export const RecurrenceRuleSettingModal = (props: Props) => {
  const { isOpen, recurrenceRule, setRecurrenceSettingModalState, onClose, startDate } = props;
  const dispatch = useAppDispatch();
  const classes = styles();
  const [formState, setFormState] = useState<RecurrenceRuleProps>({
    frequencyType: "day",
    interval: 1,
    daysOfWeek: [],
    endType: "none_specify",
    endDate: new Date(),
    count: 1,
  });
  const [isShowDowSelector, setIsShowDowSelector] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) return;
    if (recurrenceRule !== undefined) {
      setFormState({
        interval: recurrenceRule.interval,
        endType: recurrenceRule.endType || "none_specify",
        endDate: recurrenceRule.endDate ? new Date(recurrenceRule.endDate) : new Date(),
        count: recurrenceRule.count || 1,
        daysOfWeek: recurrenceRule.daysOfWeek,
        frequencyType:
          (recurrenceRule.frequencyType as RecurrenceRuleProps["frequencyType"]) || "day",
      });
      if (recurrenceRule.frequencyType === "week") {
        setIsShowDowSelector(true);
      } else {
        setIsShowDowSelector(false);
      }
    }
  }, [isOpen]);

  const handleSelect = (e: SelectChangeEvent<string>) => {
    switch (e.target.value) {
      case "week":
        setIsShowDowSelector(true);
        break;
      default:
        setIsShowDowSelector(false);
        break;
    }
    const currentDateDow = new Date(startDate).getDay();
    setFormState({
      ...formState,
      daysOfWeek: [currentDateDow],
      frequencyType: e.target.value as RecurrenceRuleProps["frequencyType"],
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    switch (e.target.name) {
      case "interval":
        setFormState({ ...formState, interval: value });
        break;
      case "count":
        setFormState({ ...formState, count: value });
        break;
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value === 0) {
      switch (e.target.name) {
        case "interval":
          setFormState({ ...formState, interval: 1 });
          break;
        case "count":
          setFormState({ ...formState, count: 1 });
          break;
      }
    }
  };

  const handleDowClick = (dowNum: number) => {
    const dowIndex = formState.daysOfWeek.indexOf(dowNum);
    const newDowArray = [...formState.daysOfWeek];

    if (dowIndex === -1) {
      newDowArray.push(dowNum);
    } else {
      newDowArray.splice(dowIndex, 1);
    }
    const currentDateDow = new Date(startDate).getDay();
    const uniqDowArray = [...new Set([...newDowArray, currentDateDow])];
    setFormState({ ...formState, daysOfWeek: uniqDowArray });
  };

  const handleClick = () => {
    setFormState({ ...formState, daysOfWeek: [1, 2, 3, 4, 5] });
  };

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, endType: e.target.value as RecurrenceRuleProps["endType"] });
  };

  const handleDateChange = (date: Date) => {
    setFormState({ ...formState, endDate: date });
  };

  const handleSubmit = async () => {
    try {
      const res = await RecurrenceRuleLabelRepository.index({
        ...formState,
        originalStartTime: startDate,
        endDate: formState.endDate.toString(),
      });
      setRecurrenceSettingModalState(false, {
        keyName: res.keyName,
        label: res.label,
        params: { ...res.params, endDate: res.params.endDate?.toString() || null },
      });
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage("繰り返し設定を取得できませんでした"));
      console.error(error);
    }
  };

  const handleClose = () => {
    setIsShowDowSelector(false);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className={classes.modal}>
        <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <Typography sx={{ fontWeight: "500" }}>繰り返し設定</Typography>

          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography sx={{ fontSize: "11px", fontWeight: "500", marginRight: "8px" }}>
              頻度
            </Typography>
            <TextField
              name="interval"
              variant="standard"
              type="number"
              value={formState.interval}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={(e) => e.target.select()}
              inputProps={{
                min: 1,
              }}
              sx={{ width: "64px" }}
            />
            <Select
              variant="standard"
              value={formState.frequencyType}
              onChange={handleSelect}
              sx={{ width: "80px" }}
            >
              <MenuItem value="day">日</MenuItem>
              <MenuItem value="week">週間</MenuItem>
              <MenuItem value="month">ヶ月</MenuItem>
              <MenuItem value="year">年</MenuItem>
            </Select>
            <Typography fontSize={12} fontWeight={500}>
              ごと
            </Typography>
          </div>

          {isShowDowSelector && (
            <div style={{ display: "flex", gap: "24px" }}>
              <Typography sx={{ fontSize: "11px", fontWeight: "500" }}>曜日</Typography>
              <div>
                <div style={{ display: "flex", gap: "8px", marginBottom: "12px" }}>
                  {DaysOfWeek.map((day, index) => (
                    <Box
                      key={index}
                      sx={dowStyle(formState, index)}
                      onClick={() => handleDowClick(index)}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>{day}</Typography>
                    </Box>
                  ))}
                </div>
                <Button onClick={handleClick} sx={{ fontSize: "11px" }}>
                  平日を選択
                </Button>
              </div>
            </div>
          )}

          <div style={{ display: "flex", gap: "24px" }}>
            <Typography sx={{ fontSize: "11px", fontWeight: "500" }}>期限</Typography>
            <RadioGroup
              name="deadline"
              row
              onChange={handleRadio}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <FormControlLabel
                control={<Radio size="small" checked={formState.endType === "none_specify"} />}
                value="none_specify"
                sx={{
                  width: "100px",
                  "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" },
                }}
                label="なし"
              />
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Radio size="small" checked={formState.endType === "end_date_specify"} />
                  }
                  value="end_date_specify"
                  sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
                  label="日付を指定"
                />
                <CustomDatePicker
                  id="endDate"
                  name="endDate"
                  onChange={handleDateChange}
                  date={formState.endDate}
                  popperPlacement="right"
                  disabled={formState.endType !== "end_date_specify"}
                />
              </div>
              <div style={{ display: "flex", alignItems: "end", gap: "8px" }}>
                <FormControlLabel
                  control={<Radio size="small" checked={formState.endType === "count_specify"} />}
                  value="count_specify"
                  sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
                  label="回数を指定"
                />
                <TextField
                  name="count"
                  variant="standard"
                  type="number"
                  value={formState.count}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={(e) => e.target.select()}
                  inputProps={{
                    min: 1,
                  }}
                  sx={{ width: "64px" }}
                  disabled={formState.endType !== "count_specify"}
                />
                <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>回後に終了</Typography>
              </div>
            </RadioGroup>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              sx={{ mr: "16px", width: "150px", height: "40px" }}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ width: "150px", height: "40px" }}
            >
              完了
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
