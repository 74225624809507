import { useEffect, useMemo, useState } from "react";
import { Edit, OpenInNew } from "@mui/icons-material";
import { CircularProgress, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AmountTextField } from "components/atoms/amount-text-field";
import { Project, ProjectId } from "data-access/repositories/project/project.dto";
import { ProjectBilling } from "data-access/repositories/project/project_billing/project_billing.dto";
import { theme } from "extensions/theme";
import { useSWRConfig } from "swr";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { salesTaxCalculator } from "utils/salesTaxCalculator";
import { TaxChangeDialog } from "../tax-change-dialog";

const CustomTypographyTitle = styled(Typography)`
  font-size: 12px;
  color: ${theme.palette.grayScale[700]};
`;

const CustomTypographyValue = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;

interface Props {
  project: Project;
  fetchKey: string;
  stateAmount: number;
  stateTax: number;
  onChange: (projectBillingToUpdate: { projectId: ProjectId; amount: number; tax: number }) => void;
}

export const ProjectDetailBlockForBillingForm = (props: Props) => {
  const { project, fetchKey, onChange } = props;
  const { cache } = useSWRConfig();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isTaxDisplayed, setIsTaxDisplayed] = useState<boolean>(true);

  const projectBillings = useMemo(() => {
    return cache.get(fetchKey)?.data;
  }, [cache]);

  const handleClick = () => {
    openURLInNewTab(`projects/${project.id}`);
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = Number(e.target.value);
    setIsTaxDisplayed(false);
    switch (e.target.name) {
      case "amount": {
        return onChange({
          projectId: project.id,
          amount: value,
          tax: props.stateTax,
        });
      }
      case "tax":
        return onChange({
          projectId: project.id,
          amount: Number(props.stateAmount),
          tax: value,
        });
    }
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    try {
      const res = await salesTaxCalculator(value);
      return onChange({
        projectId: project.id,
        amount: value,
        tax: res.tax,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsTaxDisplayed(true);
    }
  };

  const totalBilled = useMemo(() => {
    return projectBillings.reduce((acc: number, cur: ProjectBilling) => acc + cur.amount, 0);
  }, []);

  const balanceAmount = useMemo(() => {
    return project.sales_amount - totalBilled - props.stateAmount;
  }, [props.stateAmount]);

  const [balanceStatus, setBalanceStatus] = useState<string>("");

  useEffect(() => {
    if (balanceAmount > 0) {
      setBalanceStatus("positive");
    } else if (balanceAmount < 0) {
      setBalanceStatus("negative");
    } else {
      setBalanceStatus("zero");
    }
  }, [props.stateAmount]);

  return (
    <>
      {/* 消費税変更ダイアログ */}
      <TaxChangeDialog
        onOpen={setOpen}
        open={open}
        tax={props.stateTax}
        onChange={handleChange}
        anchorEl={anchorEl}
      />
      <div
        style={{
          backgroundColor: theme.palette.customPrimary[50],
          border: `1px solid ${theme.palette.grayScale[300]}`,
          borderRadius: "5px",
          padding: "12px",
          width: "100%",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            {project.code}：{project.name}
          </Typography>
          <IconButton onClick={handleClick} sx={{ p: 0 }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: theme.palette.primary.main,
                mr: "4px",
              }}
            >
              案件詳細
            </Typography>
            <OpenInNew fontSize="small" color="primary" />
          </IconButton>
        </div>

        <Divider sx={{ my: "12px" }} />

        <div style={{ display: "flex" }}>
          <div style={{ width: "33%" }}>
            <CustomTypographyTitle sx={{ mb: "8px" }}>請求金額</CustomTypographyTitle>
            <AmountTextField
              name="amount"
              width="150px"
              value={props.stateAmount}
              onChange={handleChange}
              blurFunc={handleBlur}
            />
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <p style={{ fontSize: "11px" }}>
                税込: ¥
                {isTaxDisplayed ? (
                  ` ${(props.stateAmount + props.stateTax).toLocaleString()}`
                ) : (
                  <CircularProgress size={12} sx={{ ml: "8px" }} />
                )}
              </p>
              <IconButton
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setOpen(true);
                }}
              >
                <Edit color="primary" sx={{ width: "14px" }} />
              </IconButton>
            </div>
            {balanceStatus === "negative" && (
              <Typography sx={{ fontSize: "11px", color: theme.palette.red[500] }}>
                請求金額が請求残を上回っています
              </Typography>
            )}
          </div>

          <div
            style={{
              width: "33%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", gap: "8px" }}>
              <CustomTypographyTitle sx={{ width: "48px" }}>売上</CustomTypographyTitle>
              <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                ¥{project.sales_amount.toLocaleString()}
              </Typography>
            </div>
            <Divider sx={{ my: "12px" }} />
            <div>
              <div style={{ display: "flex", gap: "8px", marginBottom: "8px" }}>
                <Typography
                  sx={{ width: "48px", fontSize: "11px", color: theme.palette.grayScale[700] }}
                >
                  請求済み
                </Typography>
                <Typography sx={{ fontSize: "11px", fontWeight: "500" }}>
                  ¥ {totalBilled.toLocaleString()}
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "8px", marginBottom: "8px" }}>
                <Typography
                  sx={{ width: "48px", fontSize: "11px", color: theme.palette.grayScale[700] }}
                >
                  今回請求
                </Typography>
                <Typography sx={{ fontSize: "11px", fontWeight: "500" }}>
                  ¥ {props.stateAmount?.toLocaleString()}
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <Typography
                  sx={{ width: "48px", fontSize: "11px", color: theme.palette.grayScale[700] }}
                >
                  請求残
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "500",
                    color:
                      balanceStatus === "zero"
                        ? theme.palette.secondary.main
                        : balanceStatus === "negative"
                          ? theme.palette.red[500]
                          : theme.palette.primary.main,
                  }}
                >
                  ¥{balanceAmount.toLocaleString()}
                </Typography>
              </div>
            </div>
          </div>

          <Divider sx={{ mx: "12px" }} orientation="vertical" flexItem />

          <div style={{ width: "33%" }}>
            <div style={{ display: "flex", gap: "12px", marginBottom: "12px" }}>
              <div>
                <CustomTypographyTitle sx={{ mb: "4px" }}>受付担当者</CustomTypographyTitle>
                <CustomTypographyValue>{project.inquired_by?.name}</CustomTypographyValue>
              </div>
              <div>
                <CustomTypographyTitle sx={{ mb: "4px" }}>現場責任者</CustomTypographyTitle>
                <CustomTypographyValue>{project.manager?.name}</CustomTypographyValue>
              </div>
              <div>
                <CustomTypographyTitle sx={{ mb: "4px" }}>完了日</CustomTypographyTitle>
                <CustomTypographyValue>{project.completed_date?.toString()}</CustomTypographyValue>
              </div>
            </div>
            <div>
              <CustomTypographyTitle sx={{ mb: "4px" }}>請求に関する連絡事項</CustomTypographyTitle>
              <Tooltip title={project.billing_precaution} arrow>
                <CustomTypographyValue
                  sx={{
                    width: "223px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "-webkit-box",
                    "-webkit-box-orient": "vertical",
                    "-webkit-line-clamp": "2",
                  }}
                >
                  {project.billing_precaution}
                </CustomTypographyValue>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
