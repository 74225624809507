import React from "react";
import { Edit } from "@mui/icons-material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  MaterialCost,
  MaterialCostId,
  MaterialCostUpdateRequest,
} from "features/cost/types/material_cost.dto";
import { styles } from "./styles";

interface Props {
  materialCost: MaterialCost;
  setEdit: (id: MaterialCostId, name: string, body: MaterialCostUpdateRequest) => void;
}
export const MaterialCostCard = ({ materialCost, setEdit }: Props) => {
  const classes = styles();

  return (
    <Box className={classes.card}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title={materialCost.supplier.name} placement="top-start" arrow>
          <Typography className={classes.name}>{materialCost.supplier.name}</Typography>
        </Tooltip>
        <IconButton
          onClick={() =>
            setEdit(materialCost.id, materialCost.supplier.name, {
              amount: materialCost.amount,
              note: materialCost.note,
            })
          }
        >
          <Edit fontSize="small" />
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div>
          {materialCost.note && (
            <Tooltip
              title={materialCost.note.split("\n").map((str: string, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <Typography fontSize="11px">
                      {str}
                      <br />
                    </Typography>
                  </React.Fragment>
                );
              })}
              placement="top"
              arrow
            >
              <DescriptionOutlinedIcon fontSize="small" />
            </Tooltip>
          )}
        </div>
        <Typography>¥{materialCost.amount.toLocaleString()}</Typography>
      </div>
    </Box>
  );
};
