import { ALL_USERS_VALUE } from "./constant";

// LocalStorageRepository実装後に削除する
export const setProjectTableFilter = () => {
  const projectTableFilter = localStorage.getItem("projectTableFilter");
  if (projectTableFilter) {
    const projectTableFilterObj = JSON.parse(projectTableFilter);
    // 案件一覧の案件フィルタのローカルストレージに`billing_conditions`がない場合、`billing_condition`を削除して、`billing_conditions`を追加する
    if (!projectTableFilterObj.projectStatusTypeIds) {
      delete projectTableFilterObj.statusTypes;
      projectTableFilterObj.projectStatusTypeIds = [];
      localStorage.setItem("projectTableFilter", JSON.stringify(projectTableFilterObj));
    }
    if (!projectTableFilterObj.billingConditions) {
      delete projectTableFilterObj.billingCondition;
      projectTableFilterObj.billingConditions = [
        "no_bill",
        "partially_not_yet_bill",
        "all_billed",
        "managing_billing_on_another_system",
        "all_not_yet_bill",
      ];
      localStorage.setItem("projectTableFilter", JSON.stringify(projectTableFilterObj));
    }
  }
};

export const setBillingTableFilter = () => {
  const billingTableFilter = localStorage.getItem("billingTableFilter");
  if (billingTableFilter) {
    const billingTableFilterObj = JSON.parse(billingTableFilter);
    // 請求一覧の請求フィルタのローカルストレージに`statusTypes`がある場合、`statusTypes`を削除して、`statusType`を追加する
    if (billingTableFilterObj.statusTypes) {
      delete billingTableFilterObj.statusTypes;
      billingTableFilterObj.statusType = "all";
      localStorage.setItem("billingTableFilter", JSON.stringify(billingTableFilterObj));
    }
  }
};

export const setWorkReportTableFilter = () => {
  const workReportTableFilter = localStorage.getItem("workReportTableFilter");
  if (workReportTableFilter) {
    const workReportTableFilterObj = JSON.parse(workReportTableFilter);
    if (!workReportTableFilterObj.userIds) {
      workReportTableFilterObj.userIds = [ALL_USERS_VALUE];
      localStorage.setItem("workReportTableFilter", JSON.stringify(workReportTableFilterObj));
    }
  }
};
