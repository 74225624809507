import React, { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { autoPlacement, offset } from "@floating-ui/dom";
import { Clear, Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { Flash } from "components/atoms/flash";
import { MemberAutocomplete } from "components/atoms/member-autocomplete";
import { DeleteIcon } from "components/icon/delete-icon";
import { GroupLabel } from "components/label/group-label";
import { CustomModal } from "components/molecules/custom-modal";
import { PhotoDropzone } from "components/molecules/photo-dropzone";
import { DisplayPhoto } from "data-access/repositories/project/photo/photo.dto";
import {
  PrimaryCategory,
  PrimaryCategoryId,
} from "data-access/repositories/project/primary_category/primary_category.dto";
import { primaryCategoryRepository } from "data-access/repositories/project/primary_category/primary_category.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { SecondaryCategoryId } from "data-access/repositories/project/secondary_category/secondary_category.dto";
import { secondaryCategoryRepository } from "data-access/repositories/project/secondary_category/secondary_category.repository";
import { TertiaryCategoryId } from "data-access/repositories/project/tertiary_category/tertiary_category.dto";
import { User } from "data-access/repositories/user/user.dto";
import { Photo, WorkReportPhotoId } from "data-access/repositories/work_report/photo/photo.dto";
import { theme } from "extensions/theme";
import { useCategoryCreate } from "hooks/useCategoryCreate";
import { useCategoryDelete } from "hooks/useCategoryDelete";
import { useCategoryUpdate } from "hooks/useCategoryUpdate";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import useSWR, { useSWRConfig } from "swr";
import { handleError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT } from "utils/formatDateUtil";
import { CustomTextTitleBox } from "./custom-components";
import { styles } from "./styles";
import { workReportPhotoRepository } from "../../../data-access/repositories/work_report/photo/photo.repository";
import {
  initialState,
  WorkReport,
  WorkReportId,
  WorkReportState,
} from "../../../data-access/repositories/work_report/work_report.dto";
import { generateRandom12DigitNumber } from "../../../utils/generateRandomNumber";
import { ConfirmDialog } from "../../molecules/confirm-dialog";

const PopperPosition = [
  autoPlacement({
    alignment: "start",
    autoAlignment: false,
  }),
  offset({ mainAxis: -200, crossAxis: 250 }),
];

interface Props {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  handleSubmit: (body: WorkReportState) => Promise<WorkReport>;
  projectId: ProjectId;
  fetchIndexKeys: string[];
}

export const WorkReportCreateModal = (props: Props) => {
  const isFirstRender = useRef<boolean>(true);
  const classes = styles();
  const mainState = useAppSelector(selectMain);
  const projectState = useAppSelector(selectProjectSidebar);
  const activeUsers = mainState.users.filter((user) => !user.is_deactivate);

  const { mutate: IndexMutate } = useSWRConfig();
  const initialStartTime = useMemo(() => {
    const now = new Date();
    now.setHours(8);
    now.setMinutes(0);
    return now;
  }, []);
  const initialEndTime = useMemo(() => {
    const now = new Date();
    now.setHours(17);
    now.setMinutes(0);
    return now;
  }, []);
  const [state, setState] = useState<WorkReportState>(
    initialState(initialStartTime, initialEndTime),
  );
  const [displayPhotos, setDisplayPhotos] = useState<DisplayPhoto[]>([]);
  const [uploadPhotos, setUploadPhotos] = useState<Photo[]>([]);
  const [deletePhotoIds, setDeletePhotoIds] = useState<number[]>([]);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const [selectedPrimaryCategoryId, setSelectedPrimaryCategoryId] = useState<PrimaryCategoryId>(
    0 as PrimaryCategoryId,
  );
  const [selectedSecondaryCategoryId, setSelectedSecondaryCategoryId] =
    useState<SecondaryCategoryId>(0 as SecondaryCategoryId);
  const [hoveredPrimaryCategoryId, setHoveredPrimaryCategoryId] =
    useState<PrimaryCategoryId | null>(null);
  const [hoveredSecondaryCategoryId, setHoveredSecondaryCategoryId] =
    useState<SecondaryCategoryId | null>(null);

  const { data: primaryCategories, mutate: primaryCategoryMutate } = useSWR(
    props.isOpen ? `/api/v1/projects/${props.projectId}/primary_categories` : null,
    () => primaryCategoryRepository.index(props.projectId),
  );

  const { data: secondaryCategory, mutate: secondaryCategoryMutate } = useSWR(
    selectedPrimaryCategoryId &&
      selectedSecondaryCategoryId &&
      `/api/v1/projects/${props.projectId}/secondary_categories/${selectedSecondaryCategoryId}`,
    () =>
      secondaryCategoryRepository.show(
        props.projectId,
        selectedPrimaryCategoryId,
        selectedSecondaryCategoryId,
      ),
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    setState({
      ...state,
      siteName: projectState.project.name,
      content: mainState.company.company_setting.work_report_content_template,
    });
  }, [props.isOpen]);

  useEffect(() => {
    if (isFirstRender.current && primaryCategories) {
      setSelectedPrimaryCategoryId(primaryCategories[0].id);
      setSelectedSecondaryCategoryId(primaryCategories[0].secondaryCategories[0].id);
      isFirstRender.current = false;
    }
  }, [primaryCategories]);

  const { handleAddPrimaryCategory, handleAddSecondaryCategory, handleAddTertiaryCategory } =
    useCategoryCreate(props.projectId, primaryCategoryMutate, secondaryCategoryMutate);

  const {
    handleDeletePrimaryCategory,
    handleDeleteSecondaryCategory,
    handleDeleteTertiaryCategory,
  } = useCategoryDelete(
    props.projectId,
    primaryCategories || [],
    selectedPrimaryCategoryId,
    selectedSecondaryCategoryId,
    setSelectedPrimaryCategoryId,
    setSelectedSecondaryCategoryId,
    primaryCategoryMutate,
    secondaryCategoryMutate,
  );

  const {
    editingTargetPrimaryCategoryId,
    editingPrimaryCategoryName,
    editingTargetSecondaryCategoryId,
    editingSecondaryCategoryName,
    editingTargetTertiaryCategoryId,
    editingTertiaryCategoryName,
    handleEditPrimaryCategory,
    handleEditSecondaryCategory,
    handleEditTertiaryCategory,
    handleBlurPrimaryCategory,
    handleBlurSecondaryCategory,
    handleBlurTertiaryCategory,
    setEditingPrimaryCategoryName,
    setEditingSecondaryCategoryName,
    setEditingTertiaryCategoryName,
  } = useCategoryUpdate(
    props.projectId,
    selectedPrimaryCategoryId,
    primaryCategoryMutate,
    secondaryCategoryMutate,
  );

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => {
    setIsChange(true);
    switch (e.target.name) {
      case "siteName":
        setState({ ...state, siteName: e.target.value as string });
        break;
      case "siteManagerId":
        setState({ ...state, siteManagerId: e.target.value as number });
        break;
      case "content":
        setState({ ...state, content: e.target.value as string });
        break;
    }
  };

  const handleChangeUsers = (e: React.SyntheticEvent<Element, Event>, value: User[]) => {
    const userIds = value.map((user: User) => user.id);
    setState({ ...state, users: value, userIds });
  };

  const handleChangeDateTime = (
    date: Date,
    e: React.SyntheticEvent<any> | undefined,
    name: string,
  ) => {
    if (date === null) return;
    setIsChange(true);
    switch (name) {
      case "startTime":
        // datePickerは日付を選択した時にeventが存在する、時間を選択したときはeventが存在しない
        // ミニカレンダーの時間を選択 ||  ミニカレンダーの日付を選択（キーボード入力の時は更新させないため）
        if (e === undefined || e.type === "click") {
          setState({ ...state, startTime: date.toString() });
        }
        break;
      case "endTime":
        if (e === undefined || e.type === "click") {
          setState({ ...state, endTime: date.toString() });
        }
        break;
    }
  };

  const handleBlurDateTime = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsChange(true);
    switch (e.target.name) {
      case "startTime":
        setState({ ...state, startTime: e.target.value.toString() });
        break;
      case "endTime":
        setState({ ...state, endTime: e.target.value.toString() });
        break;
    }
  };

  const handleSelectPrimaryCategory = (primaryCategory: PrimaryCategory) => {
    setSelectedPrimaryCategoryId(primaryCategory.id);
    setSelectedSecondaryCategoryId(primaryCategory.secondaryCategories[0].id);
  };

  const handleSelectSecondaryCategory = (
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ) => {
    setSelectedPrimaryCategoryId(primaryCategoryId);
    setSelectedSecondaryCategoryId(secondaryCategoryId);
  };

  const handleClickPhotoDelete = (id: number) => {
    setIsChange(true);
    const isPhotoAdded = uploadPhotos.filter((v) => v.id === id).length > 0;
    if (isPhotoAdded) {
      displayPhotos.map((v) => v.id === id && window.URL.revokeObjectURL(v.file_url));
    } else {
      setDeletePhotoIds((currentIds) => [...currentIds, id]);
    }
    setDisplayPhotos((currentPhotos) => currentPhotos.filter((v) => v.id !== id));
    setUploadPhotos(uploadPhotos.filter((v) => v.id !== id));
  };

  const handlePhotoUpload = async (
    files: File[] | FileList | null,
    tertiaryCategoryId: TertiaryCategoryId,
  ) => {
    if (!files) return;

    const photosData: DisplayPhoto[] = [];
    const newUploadPhotos: Photo[] = [];
    for (let i = 0; i < files.length; i++) {
      const photo = buildPhoto(files[i], tertiaryCategoryId);
      photosData.push(photo);
      newUploadPhotos.push({
        id: photo.id,
        file: files[i],
        tertiary_category_id: tertiaryCategoryId,
        local_url: photo.file_url,
      });
    }
    setUploadPhotos((currentPhotos) => [...currentPhotos, ...newUploadPhotos]);
    setDisplayPhotos((currentPhotos) => [...currentPhotos, ...photosData]);
  };

  const buildPhoto = (file: File, tertiaryCategoryId: TertiaryCategoryId): DisplayPhoto => {
    const localFilePath = window.URL.createObjectURL(file);
    const date = new Date();
    return {
      id: generateRandom12DigitNumber() as WorkReportPhotoId,
      file_name: file.name,
      file_url: localFilePath,
      thumbnail_url: localFilePath,
      is_sharable: true,
      tertiary_category_id: tertiaryCategoryId,
      created_at: date.toISOString(),
      project_attributes: null,
      work_report_attributes: null,
    };
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    try {
      const res = await props.handleSubmit(state);
      if (deletePhotoIds.length > 0 && res.id) {
        await workReportPhotoRepository.bulkDestroy(
          res.id as WorkReportId,
          deletePhotoIds as WorkReportPhotoId[],
        );
        setDeletePhotoIds([]);
      }
      if (uploadPhotos.length > 0 && res.id) {
        await workReportPhotoRepository.create(res.id, uploadPhotos);
        // メモリ上のオブジェクトURLを解放するため
        uploadPhotos.map((v) => window.URL.revokeObjectURL(v.local_url));
        setUploadPhotos([]);
      }
      for (let i = 0; i < props.fetchIndexKeys.length; i++) {
        IndexMutate(props.fetchIndexKeys[i]);
      }
      handleClose();
    } catch (error) {
      handleError(error, setErrorMessage, "保存に失敗しました");
    } finally {
      setIsSubmit(false);
    }
  };

  const handleClear = () => {
    setIsChange(true);
    setState({ ...state, siteName: "" });
  };

  const handleClose = () => {
    initializeState();
    setErrorMessage("");
  };

  const handleYes = () => {
    initializeState();
  };

  const initializeState = () => {
    setDisplayPhotos([]);
    setUploadPhotos([]);
    setDeletePhotoIds([]);
    setIsChange(false);
    setIsDeleteModalOpen(false);
    setState(initialState(initialStartTime, initialEndTime));
    props.setIsOpen(false);
  };

  const scrollBottomRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (errorMessage !== "") {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [errorMessage]);

  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteModalOpen}
        handleYes={handleYes}
        handleNo={() => setIsDeleteModalOpen(false)}
        content={"作成をキャンセルします。よろしいですか？"}
        yesButtonText={"作成をキャンセル"}
        noButtonText="戻る"
        yesButtonColor="error"
      />

      <CustomModal
        title={"作業日報の作成"}
        open={props.isOpen}
        onClose={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
        footer={
          <Fragment>
            <Button
              variant="outlined"
              onClick={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
              sx={{ width: "10rem" }}
            >
              キャンセル
            </Button>
            <Button
              disabled={isSubmit}
              onClick={handleSubmit}
              variant="contained"
              sx={{ width: "10rem", ml: "1rem" }}
            >
              保存する
            </Button>
          </Fragment>
        }
      >
        <Box>
          {/*タイトル*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>作業日報名</Typography>
            </CustomTextTitleBox>
            <TextField
              id="siteName"
              name="siteName"
              value={state.siteName}
              sx={{ width: "100%" }}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      startIcon={<Clear fontSize="small" />}
                      onClick={handleClear}
                      color="primary"
                      sx={{ p: 0, minWidth: "auto" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/*参加者*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>参加者</Typography>
            </CustomTextTitleBox>
            <MemberAutocomplete
              users={state.users || []}
              activeUsers={activeUsers || []}
              siteManagerId={state.siteManagerId}
              onChange={handleChangeUsers}
            />
          </Box>

          {/* 現場責任者*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>現場責任者</Typography>
            </CustomTextTitleBox>
            <Select
              id="siteManagerId"
              name="siteManagerId"
              value={state.siteManagerId.toString()}
              onChange={handleChange}
            >
              <MenuItem value={0}>未設定</MenuItem>
              {state.users?.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/*開始日時*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>開始日時</Typography>
            </CustomTextTitleBox>
            <CustomDatePicker
              id="startTime"
              name="startTime"
              date={state.startTime ? new Date(state.startTime) : null}
              onChange={handleChangeDateTime}
              onBlur={handleBlurDateTime}
              dateFormat={DATE_TIME_SLASH_FORMAT}
              showTimeSelect
              timeIntervals={15}
              popperPlacement="right-start"
              popperModifiers={PopperPosition}
            />
          </Box>

          {/*終了日時*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>終了日時</Typography>
            </CustomTextTitleBox>
            <CustomDatePicker
              id="endTime"
              name="endTime"
              date={state.endTime ? new Date(state.endTime) : null}
              onChange={handleChangeDateTime}
              onBlur={handleBlurDateTime}
              dateFormat={DATE_TIME_SLASH_FORMAT}
              showTimeSelect
              timeIntervals={15}
              popperPlacement="right-start"
              popperModifiers={PopperPosition}
            />
          </Box>

          <Divider sx={{ mt: 8, mb: 8 }} />

          {/*作業内容・連絡事項*/}
          <Box sx={{ mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>作業内容・連絡事項</Typography>
            </CustomTextTitleBox>
            <TextField
              id="content"
              name="content"
              multiline
              fullWidth
              rows={4}
              value={state.content}
              onChange={handleChange}
            />
          </Box>

          {/*写真*/}
          <Box sx={{ mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>写真</Typography>
            </CustomTextTitleBox>
            <Box sx={{ display: "flex", width: "100%" }}>
              {/* サイドバー */}
              <Box sx={{ width: "180px", mr: "8px", overflow: "scroll", height: "75vh" }}>
                {primaryCategories?.map((primaryCategory) => (
                  <Box key={primaryCategory.id} sx={{ mb: "16px" }}>
                    <Box
                      onMouseEnter={() => setHoveredPrimaryCategoryId(primaryCategory.id)}
                      onMouseLeave={() => setHoveredPrimaryCategoryId(null)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: "18px",
                        fontWeight: "bold",
                        mb: "12px",
                        ":hover": { cursor: "pointer" },
                        color:
                          selectedPrimaryCategoryId === primaryCategory.id
                            ? theme.palette.primary.main
                            : "inherit",
                      }}
                    >
                      {editingTargetPrimaryCategoryId === primaryCategory.id ? (
                        <TextField
                          value={editingPrimaryCategoryName}
                          onChange={(e) => setEditingPrimaryCategoryName(e.target.value)}
                          onBlur={handleBlurPrimaryCategory}
                          autoFocus
                          size="small"
                          sx={{ width: "120px" }}
                        />
                      ) : (
                        <Typography
                          onClick={() => handleSelectPrimaryCategory(primaryCategory)}
                          fontWeight="bold"
                          className={classes.categoryTreeName}
                        >
                          {primaryCategory.name}
                        </Typography>
                      )}
                      {!primaryCategory.isCommon &&
                        hoveredPrimaryCategoryId === primaryCategory.id && (
                          <div style={{ marginRight: "8px", height: "24px" }}>
                            <IconButton
                              onClick={() =>
                                handleEditPrimaryCategory(primaryCategory.id, primaryCategory.name)
                              }
                              size="small"
                              sx={{ p: 0, pr: "8px" }}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeletePrimaryCategory(primaryCategory.id)}
                              size="small"
                              sx={{ p: 0 }}
                            >
                              <DeleteIcon size={16} color={theme.palette.grayScale[700]} />
                            </IconButton>
                          </div>
                        )}
                    </Box>
                    {primaryCategory.secondaryCategories.map((secondaryCategory) => (
                      <div key={secondaryCategory.id} style={{ marginLeft: "8px" }}>
                        <Box
                          onMouseEnter={() => setHoveredSecondaryCategoryId(secondaryCategory.id)}
                          onMouseLeave={() => setHoveredSecondaryCategoryId(null)}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "bold",
                            mb: "12px",
                            ":hover": { cursor: "pointer" },
                            color:
                              selectedSecondaryCategoryId === secondaryCategory.id
                                ? theme.palette.primary.main
                                : theme.palette.grayScale[700],
                          }}
                        >
                          {editingTargetSecondaryCategoryId === secondaryCategory.id ? (
                            <TextField
                              value={editingSecondaryCategoryName}
                              onChange={(e) => setEditingSecondaryCategoryName(e.target.value)}
                              onBlur={(e) =>
                                handleBlurSecondaryCategory(
                                  e,
                                  primaryCategory.id,
                                  secondaryCategory.id,
                                )
                              }
                              autoFocus
                              size="small"
                              sx={{ width: "120px" }}
                            />
                          ) : (
                            <Typography
                              onClick={() =>
                                handleSelectSecondaryCategory(
                                  primaryCategory.id,
                                  secondaryCategory.id,
                                )
                              }
                              fontWeight="bold"
                              fontSize={14}
                              className={classes.categoryTreeName}
                            >
                              {secondaryCategory.name}
                            </Typography>
                          )}
                          {!secondaryCategory.isCommon &&
                            hoveredSecondaryCategoryId === secondaryCategory.id && (
                              <div style={{ marginRight: "8px", height: "21px" }}>
                                <IconButton
                                  onClick={() =>
                                    handleEditSecondaryCategory(
                                      secondaryCategory.id,
                                      secondaryCategory.name,
                                    )
                                  }
                                  size="small"
                                  sx={{ p: 0, pr: "8px" }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleDeleteSecondaryCategory(
                                      primaryCategory.id,
                                      secondaryCategory.id,
                                    )
                                  }
                                  size="small"
                                  sx={{ p: 0 }}
                                >
                                  <DeleteIcon size={16} color={theme.palette.grayScale[700]} />
                                </IconButton>
                              </div>
                            )}
                        </Box>
                      </div>
                    ))}
                    <div style={{ display: "flex", marginLeft: "8px" }}>
                      <Button
                        onClick={() => handleAddSecondaryCategory(primaryCategory.id)}
                        sx={{ p: 0 }}
                      >
                        <AddIcon fontSize="small" color="disabled" />
                        <Typography fontSize={14} fontWeight="bold" color="GrayText">
                          中項目を追加
                        </Typography>
                      </Button>
                    </div>
                  </Box>
                ))}
                <div style={{ display: "flex" }}>
                  <Button onClick={handleAddPrimaryCategory} sx={{ p: 0 }}>
                    <AddIcon fontSize="small" color="disabled" />
                    <Typography fontWeight="bold" color="GrayText">
                      大項目を追加
                    </Typography>
                  </Button>
                </div>
              </Box>

              {/* 写真メイン */}
              {secondaryCategory && (
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: "20px" }}>
                    {secondaryCategory.name}
                  </Typography>
                  {secondaryCategory.tertiaryCategories.map((tertiaryCategory, index) => {
                    const filteredPhotos = displayPhotos?.filter((photo) => {
                      return photo.tertiary_category_id === tertiaryCategory.id;
                    });

                    return (
                      <React.Fragment key={tertiaryCategory.id}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {editingTargetTertiaryCategoryId === tertiaryCategory.id ? (
                            <TextField
                              value={editingTertiaryCategoryName}
                              onChange={(e) => setEditingTertiaryCategoryName(e.target.value)}
                              onBlur={(e) => handleBlurTertiaryCategory(e, secondaryCategory.id)}
                              autoFocus
                              size="small"
                              sx={{ mr: 1 }}
                            />
                          ) : (
                            <GroupLabel
                              name={tertiaryCategory.name}
                              colorNumber={tertiaryCategory.colorNumber}
                            />
                          )}
                          <div style={{ marginLeft: "8px" }}>
                            <IconButton
                              onClick={() =>
                                handleEditTertiaryCategory(
                                  tertiaryCategory.id,
                                  tertiaryCategory.name,
                                )
                              }
                              size="small"
                              sx={{ p: 0, pr: "8px" }}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                            {index !== 0 && (
                              <IconButton
                                onClick={() =>
                                  handleDeleteTertiaryCategory(
                                    selectedPrimaryCategoryId,
                                    secondaryCategory.id,
                                    tertiaryCategory.id,
                                  )
                                }
                                size="small"
                                sx={{ p: 0 }}
                              >
                                <DeleteIcon size={16} color={theme.palette.grayScale[700]} />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        <Box className={classes.imageBoxBlock}>
                          {filteredPhotos &&
                            filteredPhotos.map((photo) => (
                              <Box
                                key={photo.id}
                                sx={{
                                  position: "relative",
                                  overflow: "hidden",
                                  borderRadius: "4px",
                                  border: `1px solid ${theme.palette.grayScale[300]}`,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "215px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    alt={`photo-${photo.id}`}
                                    src={photo.thumbnail_url}
                                    loading="lazy"
                                    style={{
                                      width: "215px",
                                      height: "137px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => handleClickPhotoDelete(photo.id)}
                                    sx={{
                                      top: "8px",
                                      right: "8px",
                                      position: "absolute",
                                      width: "24px",
                                      height: "24px",
                                      backgroundColor: theme.palette.grayScale[0],
                                      color: theme.palette.text.primary,
                                      border: `1px solid ${theme.palette.grayScale[300]}`,
                                    }}
                                  >
                                    <Clear
                                      fontSize="small"
                                      sx={{ color: theme.palette.grayScale[700] }}
                                    />
                                  </IconButton>
                                </Box>
                              </Box>
                            ))}
                          <PhotoDropzone
                            tertiaryCategoryId={tertiaryCategory.id}
                            onDrop={handlePhotoUpload}
                          />
                        </Box>
                      </React.Fragment>
                    );
                  })}
                  <Button
                    variant="outlined"
                    onClick={() =>
                      handleAddTertiaryCategory(
                        selectedPrimaryCategoryId,
                        selectedSecondaryCategoryId,
                      )
                    }
                    sx={{ width: "100%", fontWeight: "bold" }}
                  >
                    <AddCircleOutlinedIcon fontSize="small" sx={{ mr: "8px" }} />
                    小項目を追加
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Divider sx={{ mt: 8, mb: 8 }} />

          {/*作業の進捗*/}
          <Box sx={{ mt: "1rem", mb: "1.7rem" }}>
            <CustomTextTitleBox>
              <Typography sx={{ fontSize: "18px" }}>作業の進捗</Typography>
            </CustomTextTitleBox>
            <FormControl>
              <RadioGroup
                name="workStatusType"
                row
                sx={{
                  "& .MuiFormControlLabel-root": {
                    border: `1px solid ${theme.palette.grayScale[700]}`,
                    borderRadius: "5px",
                    padding: "0.5rem 3rem 0.5rem 0",
                    marginLeft: "0.1rem",
                  },
                }}
              >
                <FormControlLabel
                  control={<Radio checked={state.workStatusType === "in_progress"} />}
                  label="継続"
                  onClick={() => {
                    setIsChange(true);
                    setState({ ...state, workStatusType: "in_progress" });
                  }}
                />
                <FormControlLabel
                  control={<Radio checked={state.workStatusType === "done"} />}
                  label="完了"
                  onClick={() => {
                    setIsChange(true);
                    setState({ ...state, workStatusType: "done" });
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {errorMessage && (
          <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
        )}
        <div ref={scrollBottomRef} />
      </CustomModal>
    </>
  );
};
