import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AddCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { theme } from "extensions/theme";
import { styles } from "./styles";

interface Props {
  onDrop: (files: File[], attachmentTypeId?: string) => void;
  attachmentTypeId?: string;
  isDisable?: boolean;
  sx?: object;
}

export const FileDropzone = ({ onDrop, attachmentTypeId, isDisable = false, sx }: Props) => {
  const classes = styles();
  const [errors, setErrors] = useState<string[]>([]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles, attachmentTypeId);
    },
    [attachmentTypeId],
  );

  const { fileRejections, getRootProps, isDragActive } = useDropzone({
    disabled: isDisable,
    onDrop: handleDrop,
    maxSize: 1024 * 1024 * 500,
  });

  // エラーの種類を取得
  useEffect(() => {
    if (fileRejections.length === 0) {
      setErrors([]);
      return;
    }
    const rejectionErrorCodes = fileRejections.map((rejection) => {
      return rejection.errors.map((error) => {
        return error.code;
      });
    });
    // デバッグ用
    console.log("errors", rejectionErrorCodes.flat());
    setErrors(rejectionErrorCodes.flat());
  }, [fileRejections]);

  return (
    <Box sx={{ my: "16px", ...sx }}>
      <div {...getRootProps({ className: classes.dropzone })}>
        {isDragActive && (
          <Box className={classes.draggingOverlayContainer}>
            <AddCircle />
            <Typography sx={{ fontWeight: "bold" }}>ドラッグ＆ドロップでファイル追加</Typography>
          </Box>
        )}
        <AddFileZone isDisable={isDisable} />
      </div>

      <ErrorContainer errors={errors} />
    </Box>
  );
};

const AddFileZone = ({ isDisable }: { isDisable?: boolean }) => {
  return (
    <div style={{ padding: "28px 0" }}>
      <Box
        sx={{
          textAlign: "center",
          cursor: isDisable ? "not-allowed" : "pointer",
        }}
      >
        {!isDisable ? (
          <>
            <Typography sx={{ fontWeight: "bold" }}>クリックしてファイルを選択</Typography>
            <Typography sx={{ fontWeight: "bold" }}>ドラッグ＆ドロップでアップロード</Typography>
          </>
        ) : (
          <Typography sx={{ fontWeight: "bold", color: theme.palette.grey[400] }}>
            新規作成時には、利用できません
          </Typography>
        )}
      </Box>
    </div>
  );
};

const ErrorContainer = ({ errors }: { errors: string[] }) => {
  return (
    <>
      {errors.some((error) => error === "file-too-large") && (
        <Typography sx={{ color: theme.palette.error.main, fontWeight: "bold" }}>
          ファイルサイズが500MBを超えています
        </Typography>
      )}
    </>
  );
};
