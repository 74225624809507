import { ApiClient } from "data-access/ApiClient";
import {
  AttendanceRuleId,
  AttendanceRuleIndexResponse,
  AttendanceRuleUpdateRequest,
} from "features/company-settings/types/attendance/rule/attendance_rule.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";

export const attendanceRuleRepository = {
  index(): Promise<AttendanceRuleIndexResponse> {
    return ApiClient.get("/api/v1/attendances/rules").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(): Promise<void> {
    return ApiClient.post("/api/v1/attendances/rules").then((res) => res.data);
  },
  update(id: AttendanceRuleId, body: AttendanceRuleUpdateRequest): Promise<void> {
    return ApiClient.put(`/api/v1/attendances/rules/${id}`, updateBody(body)).then(
      (res) => res.data,
    );
  },
  delete(id: AttendanceRuleId): Promise<void> {
    return ApiClient.delete(`/api/v1/attendances/rules/${id}`).then((res) => res.data);
  },
};

const updateBody = (body: AttendanceRuleUpdateRequest) => {
  return {
    name: body.name,
    starting_wday_of_week: body.startingWdayOfWeek,
    legal_holiday_wday: body.legalHolidayWday,
    is_break_minutes_auto_applied: body.isBreakMinutesAutoApplied,
    break_minutes_when_work_more_than_six_hours: body.breakMinutesWhenWorkMoreThanSixHours,
    break_minutes_when_work_more_than_eight_hours: body.breakMinutesWhenWorkMoreThanEightHours,
    is_default: body.isDefault,
  };
};
