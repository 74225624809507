import { ApiClient } from "data-access/ApiClient";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  MaterialCostCreateRequest,
  MaterialCostId,
  MaterialCostIndexResponse,
  MaterialCostUpdateRequest,
} from "../types/material_cost.dto";

export const materialCostRepository = {
  index(projectId: ProjectId): Promise<MaterialCostIndexResponse> {
    return ApiClient.get(`/api/v1/projects/${projectId}/material_costs`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(projectId: ProjectId, body: MaterialCostCreateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post(
      `/api/v1/projects/${projectId}/material_costs`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  update(projectId: ProjectId, id: MaterialCostId, body: MaterialCostUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(
      `/api/v1/projects/${projectId}/material_costs/${id}`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  destroy(projectId: ProjectId, id: MaterialCostId): Promise<void> {
    return ApiClient.delete(`/api/v1/projects/${projectId}/material_costs/${id}`).then(
      (res) => res.data,
    );
  },
};
