import { discardChangesConfirmDialogSlice } from "./slice";
import { AppDispatch } from "../store";

export const discardChangesConfirmDialogOperations = {
  updateIsConfirm: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(discardChangesConfirmDialogSlice.actions.updateIsConfirm({ value }));
  },
  open: () => (dispatch: AppDispatch) => {
    dispatch(discardChangesConfirmDialogSlice.actions.open());
  },
  close: () => (dispatch: AppDispatch) => {
    dispatch(discardChangesConfirmDialogSlice.actions.close());
  },
};
