import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    detailTable: {
      "& th": {
        color: theme.palette.grayScale[700],
        width: "25%",
        verticalAlign: "top",
      },
      "& .MuiTableCell-root": {
        padding: " 1rem 0",
        fontSize: "1rem",
        border: "none",
      },
      "& .MuiFormControl-root": {
        marginTop: 0,
      },
    },
  }),
);
