import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BillingIndexRequest,
  BillingIndexResponse,
  BillingId,
} from "data-access/repositories/billing/billing.dto";
import { billingRepository } from "data-access/repositories/billing/billing.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";

export const index = createAsyncThunk<BillingIndexResponse, BillingIndexRequest, AsyncThunkConfig>(
  "billingTable/index",
  async (query, thunkApi) => {
    try {
      return await billingRepository.index(query);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const destroy = createAsyncThunk<void, BillingId, AsyncThunkConfig>(
  "billingTable/destroy",
  async (id: BillingId, thunkApi) => {
    try {
      return await billingRepository.destroy(id);
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);
