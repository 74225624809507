import { Box } from "@mui/material";
import ExcelIcon from "images/excel_icon.svg";
import ImageIcon from "images/image_icon.svg";
import OtherFileIcon from "images/other_file_icon.svg";
import PdfIcon from "images/pdf_icon.svg";
import PptIcon from "images/ppt_icon.svg";
import TxtIcon from "images/txt_icon.svg";
import WordIcon from "images/word_icon.svg";

interface Props {
  fileName: string;
  sx?: object;
}

export const FileIcon = ({ fileName, sx = {} }: Props) => {
  const getFileIcon = (fileName: string) => {
    const ext = fileName.slice(-5);

    if (ext.includes(".xls")) {
      return ExcelIcon;
    } else if (ext.includes(".pdf")) {
      return PdfIcon;
    } else if (ext.includes(".ppt")) {
      return PptIcon;
    } else if (ext.includes(".png")) {
      return ImageIcon;
    } else if (ext.includes(".jp")) {
      return ImageIcon;
    } else if (ext.includes(".gif")) {
      return ImageIcon;
    } else if (ext.includes(".doc")) {
      return WordIcon;
    } else if (ext.includes(".txt")) {
      return TxtIcon;
    } else {
      return OtherFileIcon;
    }
  };

  return (
    <Box>
      <Box sx={sx} component="img" src={getFileIcon(fileName)} alt={fileName} />
    </Box>
  );
};
