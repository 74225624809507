import { useState } from "react";
import { Box, Button, Switch, TextField, Typography } from "@mui/material";
import { Flash } from "components/atoms/flash";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { theme } from "extensions/theme";
import { expenseItemRepository } from "features/cost/api/expense_item.repository";
import {
  ExpenseItemRequest,
  initialExpenseItemRequest,
} from "features/cost/types/expense_item.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  fetchIndexKey: string;
}

export const ExpenseItemFormModal = ({ isOpen, onClose, fetchIndexKey }: Props) => {
  const dispatch = useAppDispatch();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formState, setFormState] = useState<ExpenseItemRequest>(initialExpenseItemRequest);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await expenseItemRepository.create(formState);
      handleClose();
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("経費項目を作成しました"));
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const handleClose = () => {
    setFormState({ name: "" });
    onClose();
  };

  const isValid = (): boolean => {
    if (!formState.name) return false;
    return true;
  };

  return (
    <>
      <CustomModal
        open={isOpen}
        onClose={handleClose}
        title="経費項目の新規作成"
        maxWidth="sm"
        footer={
          <>
            <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
              キャンセル
            </Button>
            <Button
              disabled={!isValid()}
              onClick={handleSubmit}
              variant="contained"
              sx={{ width: "10rem", ml: "1rem" }}
            >
              保存
            </Button>
          </>
        }
      >
        {errorMessage && (
          <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", my: "24px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px", mb: "16px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>経費項目名</Typography>
            <Typography component="span" sx={{ color: theme.palette.red[500], fontSize: "12px" }}>
              必須
            </Typography>
          </Box>
          <TextField
            id="name"
            name="name"
            placeholder="経費項目名を入力"
            value={formState.name}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <CustomFormLabel
            labelName="デフォルト反映"
            labelColor={theme.palette.grayScale[900]}
            labelWeight="bold"
            small
            toolTipTitle="ONにすると、原価内訳作成時に自動的に表示されます"
          />
          <Switch
            name="isDefaultCost"
            checked={formState.isDefaultCost}
            onChange={handleChange}
            color="primary"
          />
        </Box>
      </CustomModal>
    </>
  );
};
