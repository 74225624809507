import { useState } from "react";
import { Close, ContentCopy } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { CheckCircledIcon } from "components/icon/check-circled-icon";
import { DeleteIcon } from "components/icon/delete-icon";
import { BillingTableColumnLabel } from "components/label/billing-table-column-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { BillingUpdateRequest } from "data-access/repositories/billing/billing.dto";
import { theme } from "extensions/theme";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { selectBillingSidebar } from "store/billing-sidebar/slice";
import { deleteConfirmDialogOperations } from "store/delete-confirm-dialog/operations";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { styles } from "./styles";

interface Props {
  billingForm: BillingUpdateRequest;
  isBillingStatusTypeUse: boolean;
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClose: () => void;
}

export const BillingSidebarHeader = (props: Props) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectBillingSidebar);
  const [billingNameTitleTip, setBillingNameTileTip] = useState("請求名・請求番号をコピーします");

  const handleOpenNameTitleTip = () => {
    setBillingNameTileTip("請求名・請求番号をコピーします");
  };

  const copyBillingNameTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(billingInfoText());
      setBillingNameTileTip("コピーしました");
    } catch (error: any) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  const handleClickDelete = () => {
    dispatch(
      deleteConfirmDialogOperations.setObject({
        id: state.billing.id,
        type: "billing",
      }),
    );
    dispatch(deleteConfirmDialogOperations.open());
  };

  const billingSubjectText = `請求名：${props.billingForm.subject}\n`;
  const billingCodeText = `請求番号：${state.billing.code}\n`;

  const billingInfoText = () => {
    let text: string = "";
    if (props.billingForm.subject) {
      text += billingSubjectText;
    }
    if (state.billing.code) {
      text += billingCodeText;
    }
    return text;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => {
    switch (e.target.name) {
      case "subject":
        return dispatch(billingSidebarOperations.updateSubject(e.target.value as string));
      case "code":
        return dispatch(billingSidebarOperations.updateCode(e.target.value as string));
      case "statusType":
        dispatch(billingSidebarOperations.updateStatusType(e.target.value));
        dispatch(
          billingSidebarOperations.updateBilling(state.billing.id, {
            statusType: e.target.value,
          }),
        );
        break;
      case "billingDate":
        dispatch(billingSidebarOperations.updateBillingDate(e.target.value as string));
        dispatch(
          billingSidebarOperations.updateBilling(state.billing.id, {
            billingDate: e.target.value as string,
          }),
        );
        break;
    }
  };

  const handleChangeBillingDate = (date: Date, e: React.SyntheticEvent<any> | undefined) => {
    // 直接日付を選択したら更新する。キーボード入力の時は更新しない
    if (e && e.type === "click") {
      dispatch(
        billingSidebarOperations.updateBilling(state.billing.id, {
          billingDate: date ? date.toString() : "",
        }),
      );
    }
    dispatch(billingSidebarOperations.updateBillingDate(date ? date.toString() : ""));
  };

  const handleBlurBillingDate = () => {
    if (state.billing.billing_date === state.form.billingDate) return;
    dispatch(
      billingSidebarOperations.updateBilling(state.billing.id, {
        billingDate: state.form.billingDate,
      }),
    );
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const formKey = e.target.name;
    const billingKey = formKey
      .split(/(?=[A-Z])/)
      .join("_")
      .toLowerCase();

    if (state.billing[billingKey] !== state.form[formKey]) {
      dispatch(
        billingSidebarOperations.updateBilling(state.billing.id, {
          [formKey]: state.form[formKey],
        }),
      );
    }
  };

  return (
    <Box className={classes.whole}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          startIcon={<Close />}
          onClick={props.onClose}
          sx={{ color: theme.palette.grayScale[700] }}
        >
          とじる
        </Button>
        <Box>
          <Button
            startIcon={<DeleteIcon size={20} />}
            onClick={handleClickDelete}
            color="error"
            disabled={state.isLoading}
          >
            削除
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: "16px", display: "flex", gap: "24px" }}>
        <div>
          <CustomFormLabel labelName="請求名" small />
          <TextField
            id="subject"
            name="subject"
            className={classes.subjectField}
            value={props.billingForm.subject}
            onChange={handleChange}
            onBlur={handleBlur}
            sx={{ mb: "1rem" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={billingNameTitleTip}
                    onOpen={handleOpenNameTitleTip}
                    placement="top"
                    arrow
                  >
                    <Button
                      startIcon={<ContentCopy />}
                      onClick={copyBillingNameTextToClipboard}
                      color="primary"
                      disabled={state.isLoading}
                      sx={{ p: 0, justifyContent: "end" }}
                    />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </div>

        {props.isBillingStatusTypeUse && (
          <FormControl size="small" sx={{ justifyContent: "center" }}>
            <CustomFormLabel labelName="送付ステータス" small sx={{ mb: "12px" }} />
            <Select
              id="statusType"
              name="statusType"
              variant="standard"
              value={props.billingForm.statusType}
              onChange={handleChange}
              sx={{
                width: "120px",
                "&.MuiInputBase-root:before": {
                  borderBottom: "none",
                },
              }}
            >
              <MenuItem value="before_billed">
                <BillingTableColumnLabel type="before_billed" />
              </MenuItem>
              <MenuItem value="billed">
                <BillingTableColumnLabel type="billed" />
              </MenuItem>
            </Select>
          </FormControl>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CustomFormLabel labelName="入金ステータス" small sx={{ mb: "16px" }} />
          <BillingTableColumnLabel sx={{ mb: "8px" }} type={state.billing.deposit_status_type} />
        </div>
      </Box>

      <Box sx={{ display: "flex", gap: "24px" }}>
        <Box className={classes.codeBox}>
          <CustomFormLabel labelName="請求番号" small />
          <TextField
            id="code"
            name="code"
            className={classes.codeField}
            value={props.billingForm.code}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>

        <Box className={classes.dateBox}>
          <CustomFormLabel labelName="請求日" small sx={{ mb: 0 }} />
          <CustomDatePicker
            id="billingDate"
            name="billingDate"
            date={props.billingForm.billingDate ? new Date(props.billingForm.billingDate) : null}
            onChange={handleChangeBillingDate}
            onBlur={handleBlurBillingDate}
            isClearable
          />
        </Box>

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <CustomFormLabel labelName="請求書アップロード" small />
          {state.billing.billing_file_create_status_type === "billing_file_created" ? (
            <div style={{ display: "flex" }}>
              <CheckCircledIcon value={state.billing.billing_file_create_status_type} />
              <Typography
                sx={{ color: theme.palette.primary.main, fontSize: "12px", fontWeight: "bold" }}
              >
                アップロード済み
              </Typography>
            </div>
          ) : (
            <Typography color="error" sx={{ margin: "auto" }}>
              未アップロード
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  );
};
