import { Clear } from "@mui/icons-material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { theme } from "extensions/theme";
import { InputType } from "features/client-service-report/types/client_service_report.dto";
import { ClientServiceReportItemResponse } from "features/client-service-report/types/client_service_report_item.dto";

const CustomPhotoBox = styled(Box)({
  position: "relative",
  borderRadius: "4px",
  height: "160px",
  width: "160px",
  textAlign: "center",
  border: `1px solid ${theme.palette.grayScale[300]}`,
  "& .MuiIconButton-root": {
    position: "absolute",
    top: 3,
    right: 3,
  },
});

const CustomIconButton = styled(IconButton)({
  backgroundColor: `${theme.palette.grayScale[0]} !important`,
  color: `${theme.palette.text.primary} !important`,
  border: `1px solid ${theme.palette.grayScale[300]} !important`,
  width: "24px",
  height: "24px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});

interface Props {
  elementType: InputType;
  name: string;
  state: ClientServiceReportItemResponse | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, date?: Date, name?: string) => void;
  onClick: () => void;
  onPhotoDelete: () => void;
}

export const InputFieldSwitcher = (props: Props) => {
  const { elementType, name, state, onChange, onClick, onPhotoDelete } = props;
  if (!state) return null;

  return (
    <div>
      {elementType === "text_field" && (
        <>
          <TextField
            name={name}
            value={state.value}
            onChange={onChange}
            sx={{
              width: "370px",
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
            }}
          />
        </>
      )}
      {elementType === "text_area" && (
        <TextField
          name={name}
          value={state.value}
          onChange={onChange}
          multiline
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              minHeight: "80px",
            },
          }}
          minRows={2}
        />
      )}
      {elementType === "date" && (
        <CustomDatePicker
          id={name}
          name={name}
          date={state.value ? new Date(state.value) : null}
          onChange={(date, e) => {
            const syntheticEvent = e as unknown as React.ChangeEvent<HTMLInputElement>;
            onChange(syntheticEvent, date, name);
          }}
        />
      )}
      {elementType === "image" && (
        <>
          {state.itemPhoto || state.photo || state.workReportPhoto || state.value ? (
            <CustomPhotoBox>
              <img
                src={
                  state.itemPhoto?.thumbnailUrl ||
                  state.photo?.thumbnailUrl ||
                  state.workReportPhoto?.thumbnailUrl ||
                  state.value
                }
                style={{ height: "100%", maxHeight: "160px", maxWidth: "160px" }}
                loading="lazy"
              />
              <CustomIconButton onClick={onPhotoDelete}>
                <Clear fontSize="small" />
              </CustomIconButton>
            </CustomPhotoBox>
          ) : (
            <Button variant="outlined" onClick={onClick} sx={{ width: "200px" }}>
              <AddCircleOutlinedIcon fontSize="small" sx={{ mr: "8px" }} />
              写真を選択
            </Button>
          )}
        </>
      )}
    </div>
  );
};
