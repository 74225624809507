import { useState } from "react";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "images/logo.svg";
import { ConfirmPasswordReset } from "./confirm-password-reset";
import { ResetPassword } from "./reset-password";
import { SendResetCode } from "./send-reset-code";
import { SignInForm } from "./sign-in-form";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100vw",
  height: "100vh",
  "& .p-left": {
    width: "50%",
    background: `linear-gradient(
      180deg,
      ${theme.palette.primary.main} 0%,
      ${theme.palette.primary.light} 240%
    )`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export type PageState = "signInForm" | "sendResetCode" | "resetPassword" | "confirmPasswordReset";

export default function SignIn() {
  const [page, setPage] = useState<PageState>("signInForm");
  const [sendEmail, setSendEmail] = useState<string>("");

  return (
    <Container>
      <div className="p-left">
        <img src={Logo} alt="logo" style={{ width: 400 }} />
      </div>
      <div style={{ width: "50%", position: "relative" }}>
        {page !== "signInForm" && page !== "confirmPasswordReset" && (
          <Button onClick={() => setPage("signInForm")} sx={{ m: "8px 0 0 8px" }}>
            <ArrowCircleLeftOutlinedIcon fontSize="small" sx={{ mr: "4px" }} />
            ログインにもどる
          </Button>
        )}
        <div
          style={{
            width: "60%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {page === "signInForm" && <SignInForm setPage={setPage} />}
          {page === "sendResetCode" && (
            <SendResetCode setPage={setPage} setSendEmail={setSendEmail} />
          )}
          {page === "resetPassword" && <ResetPassword setPage={setPage} sendEmail={sendEmail} />}
          {page === "confirmPasswordReset" && <ConfirmPasswordReset setPage={setPage} />}
        </div>
      </div>
    </Container>
  );
}
