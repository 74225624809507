import { useMemo } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Close, ArrowForwardIos, FileDownloadOutlined } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ReplayIcon from "@mui/icons-material/Replay";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Dialog, Box, IconButton, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { fileDownload } from "utils/fileDownload";
import { styles } from "./styles";
import { theme } from "../../../extensions/theme";

const SIconButton = styled(IconButton)({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grayScale[0],
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});

interface Props {
  state: {
    isOpen: boolean;
    targetIndex: number;
    photos: PhotoResponse[];
  };
  setState: (state: { isOpen: boolean; targetIndex: number; photos: PhotoResponse[] }) => void;
}

export const PhotoPreviewModal = (props: Props) => {
  if (!props.state.photos) return null;
  const classes = styles();

  const targetPhoto: PhotoResponse = useMemo(() => {
    return props.state.photos[props.state.targetIndex];
  }, [props.state.isOpen, props.state.targetIndex]);

  const handleClose = () => {
    props.setState({ ...props.state, isOpen: false });
  };

  const handleClickArrow = (num: -1 | 1) => {
    props.setState({ ...props.state, targetIndex: props.state.targetIndex + num });
  };

  return (
    <Dialog open={props.state.isOpen} onClose={() => handleClose()} className={classes.modal}>
      {targetPhoto && (
        <>
          <Box className={classes.title}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mr: "10px",
              }}
            >
              <Typography sx={{ fontWeight: "500" }}>
                {targetPhoto.tertiaryCategory.name}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  startIcon={<FileDownloadOutlined />}
                  variant="contained"
                  onClick={() => fileDownload(targetPhoto.fileUrl, targetPhoto.fileName)}
                  sx={{ height: "34px", borderRadius: "16px", py: 1, px: 2, fontSize: "12px" }}
                >
                  写真をダウンロード
                </Button>
              </Box>
            </Box>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              mt: "8px",
            }}
          >
            <Box sx={{ width: "71px", textAlign: "center" }}>
              {props.state.targetIndex !== 0 && (
                <IconButton onClick={() => handleClickArrow(-1)}>
                  <ArrowBackIosNewIcon
                    style={{ color: theme.palette.text.primary }}
                    fontSize="large"
                  />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                "& .react-transform-wrapper": { margin: "0 auto" },
              }}
            >
              {targetPhoto && (
                <>
                  {/* https://www.npmjs.com/package/react-zoom-pan-pinch */}
                  <TransformWrapper wheel={{ smoothStep: 0.002 }}>
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            position: "absolute",
                            zIndex: 1,
                            marginTop: "4px",
                            marginLeft: "4px",
                            gap: "8px",
                          }}
                        >
                          <SIconButton onClick={() => zoomIn()}>
                            <ZoomInIcon />
                          </SIconButton>
                          <SIconButton onClick={() => zoomOut()}>
                            <ZoomOutIcon />
                          </SIconButton>
                          <SIconButton onClick={() => resetTransform()}>
                            <ReplayIcon />
                          </SIconButton>
                        </div>
                        <TransformComponent>
                          <img
                            alt="currentPhoto"
                            src={targetPhoto.fileUrl}
                            style={{
                              maxWidth: "100%",
                              height: "75vh",
                              userSelect: "none",
                              borderRadius: "4px",
                              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
                              objectFit: "contain",
                            }}
                          />
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                </>
              )}
            </Box>
            <Box sx={{ width: "71px", textAlign: "center" }}>
              {props.state.targetIndex !== props.state.photos.length - 1 && (
                <IconButton onClick={() => handleClickArrow(1)}>
                  <ArrowForwardIos style={{ color: theme.palette.text.primary }} fontSize="large" />
                </IconButton>
              )}
            </Box>
          </Box>
        </>
      )}
    </Dialog>
  );
};
