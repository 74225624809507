import React, { useState, useEffect } from "react";
import {
  Backdrop,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AddButton } from "components/button/add-button";
import { InfoToolTip } from "components/button/info-tooltip";
import { DeleteIcon } from "components/icon/delete-icon";
import { Layout } from "components/templates/layout";
import { DayOfWeek } from "data-access/repositories/company_user/company_user.dto";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { handleError } from "utils/errorHandler";
import { formatDateUtil } from "utils/formatDateUtil";
import { attendanceRuleRepository } from "../api/attendance/rule/attendance-rule.repository";
import { attendanceSettingRepository } from "../api/attendance/setting/attendance_setting.repository";
import {
  AttendanceRuleIndexResponse,
  BreakMinutesCandidateWhenWorkMoreThanEightHours,
  BreakMinutesCandidateWhenWorkMoreThanSixHours,
} from "../types/attendance/rule/attendance_rule.dto";
import { AttendanceSettingIndexResponse } from "../types/attendance/setting/attendance_setting.dto";

const STypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: "bold",
  marginBottom: "8px",
});
export const AttendanceSetting = () => {
  const dispatch = useAppDispatch();
  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [daysInMonth, setDaysInMonth] = useState<number>(0);
  const [formState, setFormState] = useState<AttendanceRuleIndexResponse>([]);

  const { data: attendanceSetting, isValidating } = useSWR<AttendanceSettingIndexResponse>(
    "/api/v1/attendances/settings",
    attendanceSettingRepository.index,
    {
      revalidateOnFocus: false,
    },
  );
  const { data: attendanceRules, mutate } = useSWR<AttendanceRuleIndexResponse>(
    "/api/v1/attendances/rules",
    attendanceRuleRepository.index,
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (attendanceSetting) {
      const formatDate = new Date(attendanceSetting.startingDateOfYear);
      const month = formatDate.getMonth() + 1;
      const day = formatDate.getDate();

      setSelectedMonth(month);
      setSelectedDay(day);

      // 選択された月の日数を計算して設定
      const daysInSelectedMonth = new Date(new Date().getFullYear(), month, 0).getDate();
      setDaysInMonth(daysInSelectedMonth);
    }
  }, [attendanceSetting]);

  useEffect(() => {
    if (attendanceRules) {
      setFormState(structuredClone(attendanceRules));
    }
  }, [attendanceRules]);

  const handleMonthChange = async (e: SelectChangeEvent<number>) => {
    dispatch(mainOperations.updateIsLoading(true));
    const newMonth = Number(e.target.value);
    // 新しい月の日数を計算
    const daysInNewMonth = new Date(new Date().getFullYear(), newMonth, 0).getDate();
    // 現在選択されている日が新しい月の日数を超えていないかチェック
    const newDay = Math.min(selectedDay, daysInNewMonth);

    try {
      const newDate = new Date(new Date().getFullYear(), newMonth - 1, newDay);
      await attendanceSettingRepository.update({
        startingDateOfYear: formatDateUtil(newDate, "yyyy-MM-dd"),
      });
      setSelectedMonth(newMonth);
      setSelectedDay(newDay);
      setDaysInMonth(daysInNewMonth);
      dispatch(mainOperations.updateSuccessMessage("起算日を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "起算日の変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleAdd = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attendanceRuleRepository.create();
      dispatch(mainOperations.updateSuccessMessage("勤怠ルールを追加しました"));
      mutate();
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "勤怠ルールの追加に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChangeName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const newFormState = [...formState];
    newFormState[index].name = e.target.value;
    setFormState(newFormState);
  };

  const handleBlurName = async (
    _: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    index: number,
  ) => {
    if (!attendanceRules || attendanceRules[index].name === formState[index].name) {
      return;
    }
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attendanceRuleRepository.update(formState[index].id, {
        name: formState[index].name,
      });
      dispatch(mainOperations.updateSuccessMessage("ルール名を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "ルール名の変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDayChange = async (e: SelectChangeEvent<number>) => {
    dispatch(mainOperations.updateIsLoading(true));
    const newDay = Number(e.target.value);

    try {
      const newDate = new Date(new Date().getFullYear(), selectedMonth - 1, newDay);
      await attendanceSettingRepository.update({
        startingDateOfYear: formatDateUtil(newDate, "yyyy-MM-dd"),
      });
      setSelectedDay(newDay);
      dispatch(mainOperations.updateSuccessMessage("起算日を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "起算日の変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleStartingWdayChange = async (e: SelectChangeEvent<DayOfWeek>, index: number) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attendanceRuleRepository.update(formState[index].id, {
        startingWdayOfWeek: e.target.value as DayOfWeek,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("起算曜日を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "起算曜日の変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleLegalHolidayChange = async (e: SelectChangeEvent<DayOfWeek>, index: number) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attendanceRuleRepository.update(formState[index].id, {
        legalHolidayWday: e.target.value as DayOfWeek,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("法定休日を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "法定休日の変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleToggleBreakTime = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { checked } = e.target;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attendanceRuleRepository.update(formState[index].id, {
        isBreakMinutesAutoApplied: checked,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("休憩時間自動適用を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "休憩時間自動適用の変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChangeBreakMinutesWhenWorkMoreThanSixHours = async (
    e: SelectChangeEvent<BreakMinutesCandidateWhenWorkMoreThanSixHours>,
    index: number,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attendanceRuleRepository.update(formState[index].id, {
        breakMinutesWhenWorkMoreThanSixHours: e.target
          .value as BreakMinutesCandidateWhenWorkMoreThanSixHours,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("休憩時間を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "休憩時間(6時間を超える勤務)の変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChangeBreakMinutesWhenWorkMoreThanEightHours = async (
    e: SelectChangeEvent<BreakMinutesCandidateWhenWorkMoreThanEightHours>,
    index: number,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attendanceRuleRepository.update(formState[index].id, {
        breakMinutesWhenWorkMoreThanEightHours: e.target
          .value as BreakMinutesCandidateWhenWorkMoreThanEightHours,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("休憩時間を変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "休憩時間(8時間を超える勤務)の変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDefaultChange = async (index: number) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attendanceRuleRepository.update(formState[index].id, {
        isDefault: true,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("デフォルトルールを変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "デフォルトルールの変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDelete = async (index: number) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attendanceRuleRepository.delete(formState[index].id);
      mutate();
      dispatch(mainOperations.updateSuccessMessage("勤怠設定を削除しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "勤怠設定の削除に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <Layout>
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 9999 }}
        open={isValidating}
        invisible
      >
        <CircularProgress />
      </Backdrop>

      <Typography fontWeight="bold" fontSize="24px" mb="20px">
        勤怠管理設定
      </Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginBottom: "24px" }}>
        <Typography fontWeight="bold">起算日</Typography>
        <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700] }}>
          打刻データの起算日を設定できます。
        </Typography>
        <div>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            sx={{ height: "40px", mr: "8px" }}
          >
            {[...Array(12)].map((_, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}月
              </MenuItem>
            ))}
          </Select>
          <Select value={selectedDay} onChange={handleDayChange} sx={{ height: "40px" }}>
            {[...Array(daysInMonth)].map((_, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}日
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography fontWeight="bold">勤怠ルール</Typography>
          <InfoToolTip url="https://help.gembahub.jp/130e4bd4030780b5938fe82416988ebf" />
        </div>
        <AddButton title="勤怠ルールを追加" onClick={handleAdd} />
      </div>
      {formState.map((rule, index) => (
        <React.Fragment key={index}>
          <div style={{ display: "flex", alignItems: "end", gap: "16px" }}>
            <div>
              <STypography>ルール名</STypography>
              <TextField
                name="name"
                value={rule.name}
                onChange={(e) => handleChangeName(e, index)}
                onBlur={(e) => handleBlurName(e, index)}
                sx={{
                  width: "160px",
                  "& .MuiOutlinedInput-root": {
                    height: "36px",
                  },
                }}
              />
            </div>
            <div>
              <STypography>起算曜日</STypography>
              <Select
                value={rule.startingWdayOfWeek}
                onChange={(e) => handleStartingWdayChange(e, index)}
                sx={{ height: "36px", width: "100px" }}
              >
                {startingWdayOptions.map((day) => (
                  <MenuItem key={day.value} value={day.value}>
                    {day.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <STypography>法定休日</STypography>
              <Select
                value={rule.legalHolidayWday}
                onChange={(e) => handleLegalHolidayChange(e, index)}
                sx={{ height: "36px", width: "100px" }}
              >
                {legalHolidayOptions.map((day) => (
                  <MenuItem key={day.value} value={day.value}>
                    {day.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
              <Typography fontSize={14}>休憩時間自動適用</Typography>
              <Switch
                name="isEnable"
                checked={rule.isBreakMinutesAutoApplied}
                onChange={(e) => handleToggleBreakTime(e, index)}
                color="primary"
              />
            </div>
            <div>
              <STypography>休憩時間(6時間を超える勤務)</STypography>
              <Select
                value={rule.breakMinutesWhenWorkMoreThanSixHours}
                onChange={(e) => handleChangeBreakMinutesWhenWorkMoreThanSixHours(e, index)}
                sx={{ height: "36px", width: "160px" }}
                disabled={!rule.isBreakMinutesAutoApplied}
              >
                <MenuItem value={45}>45分</MenuItem>
                <MenuItem value={60}>60分</MenuItem>
                <MenuItem value={90}>90分</MenuItem>
                <MenuItem value={120}>120分</MenuItem>
              </Select>
            </div>
            <div>
              <STypography>休憩時間(8時間を超える勤務)</STypography>
              <Select
                value={rule.breakMinutesWhenWorkMoreThanEightHours}
                onChange={(e) => handleChangeBreakMinutesWhenWorkMoreThanEightHours(e, index)}
                sx={{ height: "36px", width: "160px" }}
                disabled={!rule.isBreakMinutesAutoApplied}
              >
                <MenuItem value={60}>60分</MenuItem>
                <MenuItem value={90}>90分</MenuItem>
                <MenuItem value={120}>120分</MenuItem>
              </Select>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Radio size="small" checked={rule.isDefault} />}
                name="default-rule"
                value={index}
                label="デフォルト"
                onChange={() => handleDefaultChange(index)}
                sx={{
                  "& .MuiFormControlLabel-label": { fontSize: "14px" },
                }}
              />
            </div>
            <IconButton onClick={() => handleDelete(index)}>
              <DeleteIcon color={theme.palette.grayScale[700]} size={20} />
            </IconButton>
          </div>
          <Divider sx={{ my: "16px" }} />
        </React.Fragment>
      ))}
    </Layout>
  );
};

const baseWeekdays = [
  { value: 0, label: "日曜日" },
  { value: 1, label: "月曜日" },
  { value: 2, label: "火曜日" },
  { value: 3, label: "水曜日" },
  { value: 4, label: "木曜日" },
  { value: 5, label: "金曜日" },
  { value: 6, label: "土曜日" },
];

const startingWdayOptions = [...baseWeekdays];
const legalHolidayOptions = [{ value: -1, label: "未設定" }, ...baseWeekdays];
