import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  NoticeConfirmedScheduleAssignmentChangeIndexResponse,
  NoticeConfirmedScheduleAssignmentChangeUpdateRequest,
} from "./confirmed-schedule-assignment-change.dto";

export const confirmedScheduleAssignmentChangeRepository = {
  show(): Promise<NoticeConfirmedScheduleAssignmentChangeIndexResponse> {
    return ApiClient.get("/api/v1/notice/confirmed_schedule_assignment_changes/setting").then(
      (res) => {
        return convertKeysToCamelCase(res.data);
      },
    );
  },
  update(body: NoticeConfirmedScheduleAssignmentChangeUpdateRequest): Promise<void> {
    return ApiClient.put(
      "/api/v1/notice/confirmed_schedule_assignment_changes/setting",
      updateBody(body),
    ).then((res) => res.data);
  },
};

const updateBody = (body: NoticeConfirmedScheduleAssignmentChangeUpdateRequest) => {
  return {
    subscriber_types: body.subscriberTypes,
    is_enable: body.isEnable,
  };
};
