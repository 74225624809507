import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Layout } from "components/templates/layout";
import { buildingRepository } from "data-access/repositories/building/building.repository";
import useSWR from "swr";
import { BuildingSidebar } from "./sidebar";
import { BuildingTable } from "./table";
import { BuildingTableHeader } from "./table-header";

export const Building = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);

  const fetchIndexKey = `/api/v1/buildings?page=${page}`;
  const { data, mutate, isValidating } = useSWR(fetchIndexKey, () =>
    buildingRepository.index({
      page,
      keyword,
    }),
  );

  useEffect(() => {
    // 物件の新規作成
    if (location.pathname === "/buildings/new") {
      setIsSidebarOpen(true);
      setIsCreateNew(true);
    }
    // 物件編集
    if (/^\/buildings\/\d+$/.test(location.pathname)) {
      setIsSidebarOpen(true);
      setIsCreateNew(false);
    }
  }, [location]);

  const handleSubmit = () => {
    mutate();
  };

  const handleClickCreate = () => {
    navigate("/buildings/new");
  };

  const handleClose = () => {
    setIsSidebarOpen(false);
    navigate("/buildings");
  };

  return (
    <Layout>
      <Box>
        <BuildingTableHeader
          keyword={keyword}
          setKeyword={setKeyword}
          onClick={handleClickCreate}
          onSubmit={handleSubmit}
        />
        <BuildingTable data={data} page={page} setPage={setPage} isLoading={isValidating} />

        <BuildingSidebar
          isOpen={isSidebarOpen}
          onClose={handleClose}
          isCreateNew={isCreateNew}
          fetchIndexKey={fetchIndexKey}
        />
      </Box>
    </Layout>
  );
};
