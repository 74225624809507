import { ObjectKeys } from "types/objectKeys";
import { Brand } from "../brand";
import { PageInfo } from "../page_info/page_info.dto";
import { ProjectId } from "../project/project.dto";
import { ScheduleId } from "../schedule/schedule.dto";
import { User, UserId } from "../user/user.dto";

export type WorkReportId = Brand<string, "WorkReportId">;

export interface WorkReport extends ObjectKeys {
  id: WorkReportId;
  project_id: ProjectId;
  project_code: string;
  project_name: string;
  work_status_type: string;
  work_status_type_i18n: string;
  start_time: string;
  end_time: string;
  content: string;
  site_name: string;
  working_date: string;
  site_manager: User;
  created_by: User;
  users: User[];
  created_date: string;
  url_key: string;
  company_name: string;
}

export interface WorkReportIndexRequest {
  startedDate?: string;
  endedDate?: string;
  userIds?: UserId[];
  keyword?: string;
  page?: number;
  rowsPerPage?: number;
}

export interface WorkReportIndexResponse {
  data: WorkReport[];
  pagination: PageInfo;
}

export interface WorkReportCreateRequest extends ObjectKeys {
  projectId?: ProjectId;
  scheduleId?: ScheduleId;
  startTime?: string;
  endTime?: string;
  content?: string;
  siteManagerId?: number;
  userIds?: number[];
  userNames?: string[];
  siteName?: string;
  workStatusType?: string;
}

export type WorkReportCreateResponse = WorkReport;

export type WorkReportShowResponse = WorkReport;

export interface WorkReportUpdateRequest extends ObjectKeys {
  startTime?: string;
  endTime?: string;
  content?: string;
  siteManagerId?: number;
  userIds?: number[];
  userNames?: string[];
  siteName?: string;
  workStatusType?: string;
}

export type WorkReportUpdateResponse = WorkReport;

export interface WorkReportState {
  siteName: string;
  userIds: number[];
  users: User[];
  siteManagerId: number;
  startTime: string;
  endTime: string;
  content: string;
  workStatusType: string;
  created_date: string;
  created_by: User | undefined;
}

export const initialState = (startTime: Date, endTime: Date): WorkReportState => {
  return {
    siteName: "",
    users: [],
    userIds: [],
    siteManagerId: 0,
    startTime: startTime.toString(),
    endTime: endTime.toString(),
    content: "",
    workStatusType: "in_progress",
    created_date: "",
    created_by: undefined,
  };
};
