import { useCallback } from "react";
import { Box, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-pro";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import {
  CompanyUser,
  CompanyUserId,
  CompanyUserIndexResponse,
} from "data-access/repositories/company_user/company_user.dto";
import { companyUserRepository } from "data-access/repositories/company_user/company_user.repository";
import { Group, GroupId } from "data-access/repositories/group/group.dto";
import { groupRepository } from "data-access/repositories/group/group.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { styles } from "./styles";

const menuItemStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 24,
  width: "min-content",
  px: "10px",
  fontWeight: "bold",
  fontSize: "12px",
  borderRadius: "4px",
};

const IsDeactivateValue = {
  Activate: 0,
  Deactivate: 1,
};

export const OutsourcedMemberTable = () => {
  const dispatch = useAppDispatch();
  const classes = styles();

  const {
    mutate: membersMutate,
    data: members,
    isValidating,
  } = useSWR("/api/v1/company_users", companyUserRepository.index);
  const { mutate: groupsMutate, data: groups } = useSWR("/api/v1/groups", groupRepository.index);

  const outsourcedMembers: CompanyUserIndexResponse = members
    ? members
        .filter((member) => member.isOutsourcedMember)
        .sort((a, b) => (a.isDeactivate === b.isDeactivate ? 0 : a.isDeactivate ? 1 : -1))
    : [];

  const handleChangeGroup = async (
    e: SelectChangeEvent<number | GroupId>,
    user_id: CompanyUserId,
  ) => {
    const { value: group_id } = e.target;

    try {
      await companyUserRepository.update(user_id, {
        groupId: Number(group_id),
      });
      members && membersMutate();
      groups && groupsMutate();
      dispatch(mainOperations.updateSuccessMessage("グループを変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleChangeIsDeactivate = async (e: SelectChangeEvent<number>, user_id: CompanyUserId) => {
    try {
      const isDeactivate = e.target.value === IsDeactivateValue.Deactivate;
      await companyUserRepository.update(user_id, {
        isDeactivate: isDeactivate,
      });
      members && membersMutate();
      groups && groupsMutate();
      dispatch(mainOperations.updateSuccessMessage("利用状況を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleEdit = useCallback(async (params: GridRowModel) => {
    try {
      await companyUserRepository.update(params.id, {
        name: params.name,
        unitPricePerDay: params.unit_price_per_day,
      });
      membersMutate();
      dispatch(mainOperations.updateSuccessMessage("協力会社を更新しました"));
      dispatch(mainOperations.getUsers());
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    return params;
  }, []);

  const columns = () => {
    const headers: GridColDef[] = [];
    headers.push({
      field: "name",
      headerName: "名前",
      minWidth: 240,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "group_id",
      headerName: "グループ",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => {
        return (
          <>
            <Select
              id="group"
              name="group"
              className={classes.tableSelectBox}
              value={params.row.groupId || 0}
              variant="standard"
              onChange={(e) => handleChangeGroup(e, params.row.id)}
              displayEmpty
              sx={{
                "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
              }}
            >
              <MenuItem value={0}>
                <Box
                  sx={{
                    ...menuItemStyles,
                    border: `1px solid ${theme.palette.grayScale[900]} `,
                  }}
                >
                  未設定
                </Box>
              </MenuItem>
              {groups &&
                groups.map((group: Group, index: number) => (
                  <MenuItem value={group.id} key={index}>
                    <Box
                      sx={{
                        ...menuItemStyles,
                        backgroundColor: `#${group.color_number}`,
                      }}
                    >
                      {group.name}
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </>
        );
      },
    });
    headers.push({
      field: "unit_price_per_day",
      headerName: "人工単価",
      minWidth: 100,
      editable: true,
      sortable: false,
      align: "right",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => (
        <>
          <Typography sx={{ fontSize: "14px", mr: "2px" }}>¥</Typography>
          <Typography fontSize="14px">{params.row.unitPricePerDay.toLocaleString()}</Typography>
        </>
      ),
    });
    headers.push({
      field: "is_deactivate",
      headerName: "利用状況",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => {
        return (
          <>
            {params.row.isOutsourcedMember ? (
              <Select
                className={classes.tableSelectBox}
                value={
                  params.row.isDeactivate
                    ? IsDeactivateValue.Deactivate
                    : IsDeactivateValue.Activate
                }
                variant="standard"
                onChange={(e) => handleChangeIsDeactivate(e, params.row.id)}
                displayEmpty
                sx={{
                  "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
                }}
              >
                <MenuItem value={IsDeactivateValue.Activate}>
                  <Box
                    sx={{
                      ...menuItemStyles,
                      backgroundColor: `${theme.palette.secondary.light}`,
                      border: `1px solid ${theme.palette.secondary.main} `,
                    }}
                  >
                    利用中
                  </Box>
                </MenuItem>
                <MenuItem value={IsDeactivateValue.Deactivate}>
                  <Box
                    sx={{
                      ...menuItemStyles,
                      backgroundColor: `${theme.palette.grayScale[200]}`,
                      border: `1px solid ${theme.palette.grayScale[700]} `,
                    }}
                  >
                    停止中
                  </Box>
                </MenuItem>
              </Select>
            ) : (
              <Box
                sx={{
                  ...menuItemStyles,
                  backgroundColor: `${theme.palette.secondary.light}`,
                  border: `1px solid ${theme.palette.secondary.main} `,
                }}
              >
                利用中
              </Box>
            )}
          </>
        );
      },
    });
    headers.push({
      field: "has_email",
      headerName: "",
      minWidth: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) =>
        !params.row.hasEmail && <Typography fontSize="14px">ログイン不可</Typography>,
    });
    return headers;
  };

  return (
    <>
      <CustomizedDataGridPro
        columnHeaderHeight={40}
        columns={columns()}
        rows={outsourcedMembers || []}
        loading={isValidating}
        hideFooter
        disableRowSelectionOnClick
        processRowUpdate={handleEdit}
      />
    </>
  );
};
