import { ApiClient } from "data-access/ApiClient";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { SharableWorkReportIndexResponse } from "./sharable_work_report.dto";

export const sharableWorkReportRepository = {
  index(workReportUuid: string): Promise<SharableWorkReportIndexResponse> {
    return ApiClient.get(`/api/v1/sharable_work_reports/${workReportUuid}`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  getPhotos(workReportUuid: string): Promise<PhotoResponse[]> {
    return ApiClient.get(`/api/v1/sharable_work_reports/${workReportUuid}/photos`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};
