import { useState } from "react";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";

export const usePhotoSelect = (photos: PhotoResponse[] | undefined) => {
  const [selectedPhotoIds, setSelectedPhotoIds] = useState<Set<number>>(new Set());

  const handleSelectPhoto = (photoId: number) => {
    setSelectedPhotoIds((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(photoId)) {
        newSelected.delete(photoId);
      } else {
        newSelected.add(photoId);
      }
      return newSelected;
    });
  };

  const handleSelectPhotoAll = () => {
    if (!photos) return;
    const allPhotoIds = photos.map((photo) => photo.id);
    setSelectedPhotoIds(new Set(allPhotoIds));
  };

  const handleDeselectPhotoAll = () => {
    setSelectedPhotoIds(new Set());
  };

  return {
    selectedPhotoIds,
    setSelectedPhotoIds,
    handleSelectPhoto,
    handleSelectPhotoAll,
    handleDeselectPhotoAll,
  };
};
