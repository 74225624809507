import { theme } from "extensions/theme";

type Props = {
  size?: number;
  color?: string;
};

export const DeleteIcon = (props: Props) => {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="1 1 19 18"
      x="0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 5H4.66667H18" stroke={props.color || theme.palette.red[500]} strokeWidth="2" />
      <path
        d="M7.16797 4.99862V3.33195C7.16797 2.88992 7.34356 2.466 7.65613 2.15344C7.96869 1.84088 8.39261 1.66528 8.83464 1.66528H12.168C12.61 1.66528 13.0339 1.84088 13.3465 2.15344C13.659 2.466 13.8346 2.88992 13.8346 3.33195V4.99862M16.3346 4.99862V16.6653C16.3346 17.1073 16.159 17.5312 15.8465 17.8438C15.5339 18.1564 15.11 18.3319 14.668 18.3319H6.33464C5.89261 18.3319 5.46868 18.1564 5.15612 17.8438C4.84356 17.5312 4.66797 17.1073 4.66797 16.6653V4.99862H16.3346Z"
        stroke={props.color || theme.palette.red[500]}
        strokeWidth="2"
      />
      <path
        d="M8.83203 9.16528V14.1653"
        stroke={props.color || theme.palette.red[500]}
        strokeWidth="2"
      />
      <path
        d="M12.168 9.16528V14.1653"
        stroke={props.color || theme.palette.red[500]}
        strokeWidth="2"
      />
    </svg>
  );
};
