import React, { ChangeEvent, useEffect, useMemo } from "react";
import { Box, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { OpenInNewButton } from "components/button/open-in-new-button";
import { TagLabel } from "components/label/tag-label";
import { CustomDataGridPro } from "components/molecules/custom-data-grid-pro";
import { ScheduleId } from "data-access/repositories/schedule/schedule.dto";
import { User } from "data-access/repositories/user/user.dto";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectScheduleSearchResultHeader } from "store/schedule-search-result-header/slice";
import { scheduleSearchResultTableOperations } from "store/schedule-search-result-table/operations";
import { selectScheduleSearchResultTable } from "store/schedule-search-result-table/slice";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { openURLInNewTab } from "utils/openURLInNewTab";

export const ScheduleSearchResultTable = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectScheduleSearchResultTable);
  const headerState = useAppSelector(selectScheduleSearchResultHeader);
  const orderStorageKeyName = "scheduleSearchResultTableColumnOrder";
  const rowsPerPageLocalStorageKey = "scheduleSearchResultTableRowsPerPage";

  useEffect(() => {
    if (state.isSubmit) {
      dispatch(
        scheduleSearchResultTableOperations.search({
          scheduleRangeStart: headerState.scheduleDateRange.startDate?.toString(),
          scheduleRangeEnd: headerState.scheduleDateRange.endDate?.toString(),
          keyword: headerState.keyword,
          page: state.currentPage,
          rowsPerPage: state.rowsPerPage,
        }),
      );
    }
  }, [state.isSubmit]);

  useEffect(() => {
    dispatch(
      scheduleSearchResultTableOperations.search({
        scheduleRangeStart: headerState.scheduleDateRange.startDate?.toString(),
        scheduleRangeEnd: headerState.scheduleDateRange.endDate?.toString(),
        keyword: headerState.keyword,
        page: state.currentPage,
        rowsPerPage: state.rowsPerPage,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(scheduleSearchResultTableOperations.submit());
  }, [state.currentPage]);

  const defaultSchedulesColumns = useMemo(() => {
    const headers = [];
    headers.push({
      field: "schedule_type",
      headerName: "予定タイプ",
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => (
        <TagLabel
          tagName={params.row.schedule_type.name}
          colorNumber={params.row.schedule_type.color_number}
        />
      ),
      sortable: false,
    });
    headers.push({
      field: "name",
      headerName: "予定名",
      minWidth: 240,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            lineHeight: "inherit",
            gap: "8px",
          }}
        >
          <OpenInNewButton onClick={() => handleClick(params.row.id)} />
          <Typography>{params.value}</Typography>
        </div>
      ),
    });
    headers.push({
      field: "start_time",
      headerName: "開始日時",
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ fontSize: "0.875rem" }}>
          {params.value ? formatDateUtil(params.value, DATE_TIME_SLASH_FORMAT) : ""}
        </Typography>
      ),
    });
    headers.push({
      field: "end_time",
      headerName: "終了日時",
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ fontSize: "0.875rem" }}>
          {params.value ? formatDateUtil(params.value, DATE_TIME_SLASH_FORMAT) : ""}
        </Typography>
      ),
    });
    headers.push({
      field: "users",
      renderCell: (params: GridRenderCellParams) =>
        params.row.users.map((user: User) => user.name).join(", "),
      headerName: "参加者",
      minWidth: 200,
      sortable: false,
    });
    headers.push({
      field: "project_code",
      renderCell: (params: GridRenderCellParams) => params.row.project?.code,
      headerName: "案件番号",
      minWidth: 100,
      sortable: false,
    });
    headers.push({
      field: "project_name",
      renderCell: (params: GridRenderCellParams) => params.row.project?.name,
      headerName: "案件名",
      minWidth: 200,
      sortable: false,
    });
    headers.push({
      field: "note",
      headerName: "作業内容・メモ",
      minWidth: 370,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip
          title={params.value.split("\n").map((str: string, index: number) => {
            return (
              <React.Fragment key={index}>
                <Typography fontSize="11px">
                  {str}
                  <br />
                </Typography>
              </React.Fragment>
            );
          })}
          placement="top"
          arrow
        >
          <Typography
            sx={{
              mr: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    });
    return headers;
  }, []);

  /** 過去にカラムの並び替えがあればそれを反映する. */
  const columns = useMemo(() => {
    const localStorageItem = localStorage.getItem(orderStorageKeyName);
    if (localStorageItem) {
      const savedColumns = JSON.parse(localStorageItem);
      let resultColumns = savedColumns.map((column: { field: string }) => {
        return defaultSchedulesColumns.find(
          (defaultColumn) => defaultColumn.field === column.field,
        );
      });
      // 途中から追加されたカラムが反映されないことの対応
      const notSavedColumns = defaultSchedulesColumns.filter(
        (defaultColumn) => !resultColumns.includes(defaultColumn),
      );
      resultColumns = resultColumns.concat(notSavedColumns);
      return resultColumns;
    } else {
      return defaultSchedulesColumns;
    }
  }, [defaultSchedulesColumns]);

  const handleSelectPage = (event: SelectChangeEvent<unknown>) => {
    dispatch(scheduleSearchResultTableOperations.updateCurrentPage(event.target.value as number));
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(scheduleSearchResultTableOperations.updateCurrentPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    dispatch(scheduleSearchResultTableOperations.updateRowsPerPage(Number(event.target.value)));
    localStorage.setItem(rowsPerPageLocalStorageKey, event.target.value);
    dispatch(scheduleSearchResultTableOperations.submit());
  };

  const setColumnOrder = (allColumns: GridColDef[]) => {
    const columns = allColumns.map((column) => {
      return { field: column.field };
    });
    localStorage.setItem(orderStorageKeyName, JSON.stringify(columns));
  };

  const handleColumnOrderChange = (allColumns: GridColDef[]) => {
    setColumnOrder(allColumns);
  };

  const handleClick = (scheduleId: ScheduleId) => {
    openURLInNewTab(`calendar/${scheduleId}`);
  };

  return (
    <>
      <Box>
        <CustomDataGridPro
          type="scheduleSearchResult"
          columns={columns}
          rows={state.schedules}
          loading={state.isLoading}
          pageInfo={state.pageInfo}
          currentPage={state.currentPage}
          tableHeight="80vh"
          handleSelectPage={handleSelectPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleColumnOrderChange={handleColumnOrderChange}
        />
      </Box>
    </>
  );
};
