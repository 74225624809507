import { useEffect, useMemo, useState } from "react";
import { SelectChangeEvent, Typography } from "@mui/material";
import { AmountTextField } from "components/atoms/amount-text-field";
import { PrimaryButton } from "components/atoms/primary-button";
import { ExpandToggleLabel } from "components/label/expand-toggle-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { DepositBlock } from "components/molecules/deposit-block";
import {
  BillingDepositHistoryId,
  DepositHistoryResponse,
} from "data-access/repositories/billing/deposit_history/deposit_history.dto";
import { depositHistoryRepository } from "data-access/repositories/billing/deposit_history/deposit_history.repository";
import { theme } from "extensions/theme";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { BillingSidebarState } from "store/billing-sidebar/slice";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { BillingSidebarDepositHistoryInitialDisplayNumber } from "utils/constant";
import { handleError } from "utils/errorHandler";
import { DATE_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";

interface Props {
  state: BillingSidebarState;
  sumAmount: number;
}

export const BillingSidebarDepositBlock = (props: Props) => {
  const { state, sumAmount } = props;
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<boolean>(false);

  const { mutate, data: depositHistories } = useSWR(
    state.billing.id ? `/api/v1/billings/${state.billing.id}/deposit_histories` : null,
    () => depositHistoryRepository.show(state.billing.id),
  );

  const displayNumber = useMemo(() => {
    if (expanded && depositHistories) {
      return depositHistories.length;
    }
    return BillingSidebarDepositHistoryInitialDisplayNumber;
  }, [expanded]);

  useEffect(() => {
    setDisplayAmount({
      ...displayAmount,
      adjustedAmount: state.form.adjustedAmount as number,
    });
  }, [state.billing.id]);

  const [displayAmount, setDisplayAmount] = useState<{
    adjustedAmount: number | string;
  }>({
    adjustedAmount: 0,
  });

  const sumDepositAmount = useMemo(() => {
    const total = depositHistories
      ? depositHistories.reduce((prev, current) => prev + Number(current.deposit_amount), 0)
      : 0;
    return total;
  }, [depositHistories]);

  const accountsReceivable = useMemo(() => {
    return sumAmount + Number(state.form.adjustedAmount) - sumDepositAmount;
  }, [sumAmount, sumDepositAmount, state.form.adjustedAmount]);

  const handleChange = async (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
    idx?: number | string,
  ) => {
    switch (e.target.name) {
      case "depositAmount": {
        if (depositHistories) {
          const updatedDepositHistories = depositHistories.map((depositHistory, index) => {
            if (index === idx) {
              return {
                ...depositHistory,
                deposit_amount: e.target.value,
              };
            }
            return depositHistory;
          }) as DepositHistoryResponse[];

          mutate(updatedDepositHistories, false);
        }
        break;
      }
      case "adjustedAmount":
        // setDisplayAmount({ adjustedAmount: e.target.value as number });
        return dispatch(billingSidebarOperations.updateAdjustedAmount(e.target.value as number));
    }
  };

  const handleChangeDate = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number,
  ) => {
    if (!depositHistories) return;

    try {
      const updatedDepositHistories = depositHistories.map((depositHistory, index) => {
        if (index === idx) {
          return {
            ...depositHistory,
            deposit_date: e.target.value,
          };
        }
        return depositHistory;
      }) as DepositHistoryResponse[];
      mutate(updatedDepositHistories, false);

      await depositHistoryRepository.update(
        state.billing.id,
        depositHistories
          ? (depositHistories[idx].id as BillingDepositHistoryId)
          : (0 as BillingDepositHistoryId),
        {
          depositDate: e.target.value,
        },
      );
      dispatch(mainOperations.updateSuccessMessage("入金日を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleBlurAdjustedAmount = (_: React.FocusEvent<HTMLInputElement>, amount: number) => {
    dispatch(
      billingSidebarOperations.updateBilling(state.billing.id, {
        adjustedAmount: amount,
      }),
    );
  };

  const handleBlurDepositAmount = async (e: React.FocusEvent<HTMLInputElement>, idx: number) => {
    try {
      await depositHistoryRepository.update(
        state.billing.id,
        depositHistories
          ? (depositHistories[idx].id as BillingDepositHistoryId)
          : (0 as BillingDepositHistoryId),
        {
          depositAmount: Number(e.target.value),
        },
      );
      dispatch(mainOperations.updateSuccessMessage("入金金額を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleAddDepositHistory = async () => {
    if (accountsReceivable) {
      try {
        await depositHistoryRepository.create(state.billing.id, {
          depositDate: formatDateUtil(new Date(), DATE_SLASH_FORMAT),
          depositAmount: accountsReceivable,
        });
        mutate();
        dispatch(mainOperations.updateSuccessMessage("入金履歴を作成しました"));
        // 入金ステータスを更新するために必要
        dispatch(billingSidebarOperations.showBilling(state.billing.id));
      } catch (error) {
        handleError(
          error,
          (errorMessage: string) => {
            dispatch(mainOperations.updateErrorMessage(errorMessage));
          },
          "入金履歴の作成に失敗しました",
        );
      }
    }
  };

  const handleDelete = async (idx: number) => {
    try {
      await depositHistoryRepository.destroy(
        state.billing.id,
        depositHistories
          ? (depositHistories[idx].id as BillingDepositHistoryId)
          : (0 as BillingDepositHistoryId),
      );
      mutate();
      dispatch(mainOperations.updateSuccessMessage("入金履歴を削除しました"));
      // 入金ステータスを更新するために必要
      dispatch(billingSidebarOperations.showBilling(state.billing.id));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleChangeDepositMethodType = async (
    e: SelectChangeEvent<string | number>,
    idx: number,
  ) => {
    if (!depositHistories) return;

    try {
      const updatedDepositHistories = depositHistories.map((depositHistory, index) => {
        if (index === idx) {
          return {
            ...depositHistory,
            deposit_method_type: e.target.value,
          };
        }
        return depositHistory;
      }) as DepositHistoryResponse[];
      mutate(updatedDepositHistories, false);

      await depositHistoryRepository.update(
        state.billing.id,
        depositHistories
          ? (depositHistories[idx].id as BillingDepositHistoryId)
          : (0 as BillingDepositHistoryId),
        {
          depositMethodType: e.target.value as string,
        },
      );
      dispatch(mainOperations.updateSuccessMessage("入金方法を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const [displayDepositAmount, setDisplayDepositAmount] = useState<(string | number)[]>([]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <CustomFormLabel labelName="入金履歴" small sx={{ mb: 0 }} />
        <PrimaryButton
          name="入金履歴を追加"
          onClick={handleAddDepositHistory}
          disabled={accountsReceivable === 0}
          sx={{ ml: 0 }}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "end",
          backgroundColor: theme.palette.customPrimary[50],
          padding: "12px",
          marginBottom: "16px",
          borderRadius: "4px",
          gap: "8px",
        }}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>入金額合計</Typography>
        <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
          ¥{sumDepositAmount && sumDepositAmount.toLocaleString()}
        </Typography>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>（税込）</Typography>
      </div>

      {depositHistories && (
        <>
          <div style={{ marginBottom: "12px" }}>
            {depositHistories.slice(0, displayNumber).map((depositHistory, idx) => (
              <DepositBlock
                key={idx}
                depositHistory={depositHistory}
                index={idx}
                onChange={handleChange}
                onChangeDate={handleChangeDate}
                onChangeDepositMethodType={handleChangeDepositMethodType}
                onDelete={handleDelete}
                onBlur={handleBlurDepositAmount}
                displayDepositAmount={displayDepositAmount}
                setDisplayDepositAmount={setDisplayDepositAmount}
              />
            ))}
          </div>
          {depositHistories.length > BillingSidebarDepositHistoryInitialDisplayNumber && (
            <ExpandToggleLabel onClick={() => setExpanded(!expanded)} expanded={expanded} />
          )}
        </>
      )}

      <div style={{ marginBottom: "12px" }}>
        <CustomFormLabel labelName="売掛残" small />
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          ¥ {accountsReceivable ? accountsReceivable.toLocaleString() : 0}
        </Typography>
      </div>

      <div>
        <CustomFormLabel labelName="調整金額" small />
        <AmountTextField
          name="adjustedAmount"
          width="60%"
          value={state.form.adjustedAmount as number}
          onChange={handleChange}
          blurFunc={handleBlurAdjustedAmount}
        />
      </div>
    </>
  );
};
