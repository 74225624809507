import { useEffect, useState } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, IconButton, Typography } from "@mui/material";
import { attendanceOvertimeEmployeeRepository } from "features/attendance/api/overtime_record/attendance.overtime.employee.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { formatDateUtil } from "utils/formatDateUtil";
import { Card } from "./card";

export const Overtime = () => {
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<Date>(new Date());
  const year = formatDateUtil(date.toString(), "yyyy");

  const { data, isLoading } = useSWR(
    `/api/v1/attendances/overtime_records/${year}/employees`,
    () => attendanceOvertimeEmployeeRepository.index(year),
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    dispatch(mainOperations.updateIsLoading(isLoading));
  }, [isLoading]);

  const decrementDate = () => {
    setDate(new Date(date.setFullYear(date.getFullYear() - 1)));
  };

  const incrementDate = () => {
    setDate(new Date(date.setFullYear(date.getFullYear() + 1)));
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <IconButton onClick={decrementDate}>
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
        <Box sx={{ m: "0 8px", cursor: "default" }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", userSelect: "none" }}>
            {formatDateUtil(date, "yyyy年")}度
          </Typography>
        </Box>
        <IconButton onClick={incrementDate}>
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </div>

      {data && data.map((employee, index) => <Card key={index} employee={employee} />)}
    </>
  );
};
