import { useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AmountTextField } from "components/atoms/amount-text-field";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { TaxChangeDialog } from "components/molecules/tax-change-dialog";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import { calculateGrossProfitRate } from "utils/calculateGrossProfit";
import { halfWidthNumber } from "utils/halfWidthNumber";
import { CostBreakdownModal } from "../../../features/cost/components/breakdown-modal";

const STypography = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  color: ${theme.palette.text.secondary};
`;

interface Props {
  salesAmount: number;
  tax: number;
  totalCostAmount: number;
  decidedEstimatesTotalAmount: number;
  projectId: ProjectId;
  /** 概要、メモがTextFieldとして認識されず、Tabキーが反映されない対策 */
  onBlurSalesAmount: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const ProjectSidebarSalesBlock = (props: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectProjectSidebar);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isTaxDisplayed, setIsTaxDisplayed] = useState<boolean>(true);
  const [grossProfitAmount, setGrossProfitAmount] = useState<number>(0);
  const [isCostModalOpen, setIsCostModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (state.isOpen) {
      setGrossProfitAmount(props.salesAmount - props.totalCostAmount);
    }
  }, [state.isOpen, props.salesAmount, props.totalCostAmount]);

  const handleClick = () => {
    dispatch(projectSidebarOperations.updateSalesAmount(props.decidedEstimatesTotalAmount));
    dispatch(
      projectSidebarOperations.updateProject(props.projectId as ProjectId, {
        salesAmount: props.decidedEstimatesTotalAmount,
      }),
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case "salesAmount": {
        setIsTaxDisplayed(false);
        dispatch(
          projectSidebarOperations.updateSalesAmount(
            isNaN(Number(halfWidthNumber(String(e.target.value))))
              ? 0
              : Number(halfWidthNumber(String(e.target.value))),
          ),
        );
        break;
      }
    }
  };

  const handleChangeSalesTax = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(
      projectSidebarOperations.updateSalesTax(
        isNaN(Number(halfWidthNumber(String(e.target.value))))
          ? 0
          : Number(halfWidthNumber(String(e.target.value))),
      ),
    );
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>, amount: number) => {
    setIsTaxDisplayed(true);
    if (state.project.sales_amount === amount) return;

    switch (e.target.name) {
      case "salesAmount": {
        props.onBlurSalesAmount(e);
        if (state.isExisting) {
          return dispatch(
            projectSidebarOperations.updateProject(props.projectId, {
              salesAmount: amount,
            }),
          );
        } else {
          dispatch(
            projectSidebarOperations.createProject({
              ...state.form,
              salesAmount: amount,
            }),
          );
        }
        return;
      }
      case "tax":
        return dispatch(
          projectSidebarOperations.updateProject(props.projectId, {
            tax: props.tax,
          }),
        );
    }
  };

  return (
    <>
      <Box sx={{ my: "24px" }}>
        <CustomFormLabel labelName="売上情報" small />
        <div style={{ display: "flex", gap: "16px" }}>
          <div style={{ width: "33%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "36px",
              }}
            >
              <STypography>売上</STypography>
              <Button
                onClick={handleClick}
                size="small"
                sx={{ pl: "12px" }}
                disabled={props.decidedEstimatesTotalAmount === 0}
              >
                決定見積の金額を反映
              </Button>
            </div>
            <AmountTextField
              name="salesAmount"
              width="100%"
              value={props.salesAmount}
              onChange={handleChange}
              blurFunc={handleBlur}
            />
            {!!props.salesAmount && (
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <p style={{ fontSize: "11px" }}>
                  税込: ¥{isTaxDisplayed && ` ${(props.salesAmount + props.tax).toLocaleString()}`}
                </p>
                <IconButton
                  tabIndex={-1}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setOpen(true);
                  }}
                >
                  <Edit color="primary" sx={{ width: "16px" }} />
                </IconButton>
              </div>
            )}
          </div>

          <div style={{ width: "33%" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
              <STypography>原価</STypography>
              <IconButton
                onClick={() => setIsCostModalOpen(true)}
                color="primary"
                disabled={!state.isExisting}
              >
                <AddCircleIcon fontSize="small" />
              </IconButton>
            </div>
            <Typography>¥{props.totalCostAmount.toLocaleString() || 0}</Typography>
          </div>

          <div style={{ width: "33%" }}>
            <STypography
              sx={{
                marginBottom: "12px",
              }}
            >
              粗利
            </STypography>
            <div style={{ marginLeft: "8px" }}>
              <Typography
                sx={{
                  color:
                    grossProfitAmount >= 0 ? theme.palette.text.primary : theme.palette.error.main,
                }}
              >
                ¥ {grossProfitAmount.toLocaleString()}
              </Typography>
            </div>
            <p
              style={{
                color:
                  grossProfitAmount >= 0 ? theme.palette.text.primary : theme.palette.error.main,
                fontSize: "11px",
                marginTop: "4px",
              }}
            >
              粗利率:
              {calculateGrossProfitRate(props.salesAmount, props.totalCostAmount)}%
            </p>
          </div>
        </div>
      </Box>

      <CostBreakdownModal isOpen={isCostModalOpen} onClose={() => setIsCostModalOpen(false)} />

      <TaxChangeDialog
        onOpen={setOpen}
        open={open}
        tax={props.tax}
        onChange={handleChangeSalesTax}
        onBlur={handleBlur}
        anchorEl={anchorEl}
      />
    </>
  );
};
