import { useEffect, useState } from "react";
import { ActivityBlock } from "components/InfoBlock/activity";
import { billingActivityRepository } from "data-access/repositories/billing/activity/billing_activity.repository";
import { BillingSidebarState } from "store/billing-sidebar/slice";
import useSWR from "swr";
import { ACTIVITY_PER_PAGE } from "utils/constant";

interface Props {
  state: BillingSidebarState;
}

export const BillingSidebarActivity = (props: Props) => {
  const { state } = props;

  const [limit, setLimit] = useState<number>(ACTIVITY_PER_PAGE);
  const { data: activities, mutate } = useSWR(
    state.billing.id ? `/api/v1/billings/${state.billing.id}/activities?limit=${limit}` : null,
    () => billingActivityRepository.index(state.billing.id, limit),
  );

  useEffect(() => {
    if (state.billing.id > 0 && state.isSubmitted) {
      mutate();
    }
  }, [state.isSubmitted]);

  return (
    <ActivityBlock
      activities={activities?.data || []}
      totalCount={activities?.pagination.count || 0}
      limit={limit}
      setLimit={setLimit}
    />
  );
};
