import { useEffect, useRef } from "react";
import React from "react";
import { LinkItEmail, LinkItUrl } from "react-linkify-it";
import { Box, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { ProjectUpdateRequest } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";

interface Props {
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  projectForm: ProjectUpdateRequest;
  /** 概要、メモの管理用. */
  isNoteEditEnabled: boolean;
  setIsNoteEditEnabled: (value: boolean) => void;
}

export const ProjectSidebarContentDetailsBlock = (props: Props) => {
  const mainState = useAppSelector(selectMain);

  return (
    <>
      <Box sx={{ my: "1.7rem" }}>
        <CustomFormLabel labelName="概要・メモ" small />
        <NoteTextField
          onChange={props.onChange}
          onBlur={props.onBlur}
          note={props.projectForm.note}
          isNoteEditEnabled={props.isNoteEditEnabled}
          setIsNoteEditEnabled={props.setIsNoteEditEnabled}
          linesToDisplay={mainState.personalSetting.number_of_project_note_lines_to_display}
        />
      </Box>
      <Box sx={{ my: "1.7rem" }}>
        <CustomFormLabel labelName="請求に関する連絡事項" small />
        <TextField
          id="billingPrecaution"
          name="billingPrecaution"
          value={props.projectForm.billingPrecaution}
          onChange={props.onChange}
          onBlur={props.onBlur}
          type="string"
          placeholder="クリックして文章を入力"
          sx={{ width: "100%" }}
          multiline
          maxRows={4}
        />
      </Box>
    </>
  );
};

interface NoteTextFieldProps {
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  note?: string;
  isNoteEditEnabled: boolean;
  setIsNoteEditEnabled: (value: boolean) => void;
  linesToDisplay: number;
}

/** 概要・メモのTextField.
 *  LinkItUrlを使用したゆえに少し肥大化したので切り出した.
 */
const NoteTextField = (props: NoteTextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // Tabキーが押されたときにフォーカスが当たるようにする
  useEffect(() => {
    if (props.isNoteEditEnabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.isNoteEditEnabled]);

  // TextFieldにはLinkItUrlが効かないため、編集じゃない時はTypoGraphyとして使用
  return (
    <>
      {props.isNoteEditEnabled ? (
        <TextField
          placeholder="クリックして文章を入力"
          sx={{ width: "100%" }}
          id="note"
          name="note"
          value={props.note}
          type="string"
          onChange={props.onChange}
          onBlur={(e) => {
            props.onBlur(e);
            props.setIsNoteEditEnabled(false);
          }}
          multiline
          inputRef={inputRef}
        />
      ) : (
        <div
          style={{
            border: `1px solid ${theme.palette.grayScale[300]}`,
            borderRadius: "5px",
          }}
        >
          <Typography
            sx={{
              margin: "14px",
              maxHeight: `${props.linesToDisplay * 24}px`,
              lineHeight: "24px",
              borderRadius: "5px",
              wordWrap: "break-word",
              overflow: "auto",
            }}
            onClick={() => props.setIsNoteEditEnabled(true)}
          >
            {props.note ? (
              props.note.split("\n").map((str: string, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <LinkItUrl>
                      <LinkItEmail>
                        {str}
                        <br />
                      </LinkItEmail>
                    </LinkItUrl>
                  </React.Fragment>
                );
              })
            ) : (
              <>クリックして文章を入力</>
            )}
          </Typography>
        </div>
      )}
    </>
  );
};
