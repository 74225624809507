import { Brand } from "data-access/repositories/brand";
import { SubscriberType } from "../index.dto";

export type NoticeConfirmedScheduleCreationId = Brand<number, "NoticeConfirmedScheduleCreationId">;

export type NoticeConfirmedScheduleCreationIndexResponse = NoticeConfirmedScheduleCreation;

export interface NoticeConfirmedScheduleCreation {
  id: NoticeConfirmedScheduleCreationId;
  subscriberTypes: SubscriberType[];
  isEnable: boolean;
}

export interface NoticeConfirmedScheduleCreationUpdateRequest {
  subscriberTypes?: SubscriberType[];
  isEnable?: boolean;
}

export const initialNoticeConfirmedScheduleCreation: NoticeConfirmedScheduleCreation = {
  id: 0 as NoticeConfirmedScheduleCreationId,
  subscriberTypes: [],
  isEnable: false,
};
