import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GroupLabel } from "components/label/group-label";
import { theme } from "extensions/theme";
import {
  AttendanceEmployee,
  AttendanceIndexResponse,
} from "features/attendance/types/monthly_record/attendance.employee.dto";

interface Props {
  attendances: AttendanceIndexResponse;
  onClick: (params: AttendanceEmployee) => void;
}

export const Table = (props: Props) => {
  const { attendances, onClick } = props;

  return (
    <TableContainer
      sx={{
        border: `1px solid ${theme.palette.grayScale[300]}`,
        borderRadius: "8px",
        height: "65vh",
      }}
    >
      <MuiTable
        stickyHeader
        sx={{
          minWidth: 650,
          "& thead th": { borderRight: 1, borderColor: theme.palette.grayScale[300] },
          "& thead th:last-child": { borderRight: 0 },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>氏名</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>グループ</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>労働時間</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>出勤日数</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attendances.map((attendanceEmployee, index) => (
            <TableRow
              key={index}
              onClick={() => onClick(attendanceEmployee)}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  cursor: "pointer",
                },
              }}
            >
              <TableCell component="th" scope="row">
                {attendanceEmployee.name}
              </TableCell>
              <TableCell>
                {attendanceEmployee.group && (
                  <GroupLabel
                    name={attendanceEmployee.group.name}
                    colorNumber={attendanceEmployee.group.colorNumber}
                  />
                )}
              </TableCell>
              <TableCell>{attendanceEmployee.laborHours}</TableCell>
              <TableCell>{attendanceEmployee.numberOfWorkingDays}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
