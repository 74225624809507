import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  NoticeConfirmedScheduleChangeIndexResponse,
  NoticeConfirmedScheduleChangeUpdateRequest,
} from "./confirmed-schedule-change.dto";

export const confirmedScheduleChangeRepository = {
  show(): Promise<NoticeConfirmedScheduleChangeIndexResponse> {
    return ApiClient.get("/api/v1/notice/confirmed_schedule_changes/setting").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(body: NoticeConfirmedScheduleChangeUpdateRequest): Promise<void> {
    return ApiClient.put(
      "/api/v1/notice/confirmed_schedule_changes/setting",
      updateBody(body),
    ).then((res) => res.data);
  },
};

const updateBody = (body: NoticeConfirmedScheduleChangeUpdateRequest) => {
  return {
    subscriber_types: body.subscriberTypes,
    is_enable: body.isEnable,
  };
};
