import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRowsPerPageFromLocalStorage } from "data-access/local_storage/table_filter/local_storage";
import { Billing } from "data-access/repositories/billing/billing.dto";
import { PageInfo } from "data-access/repositories/page_info/page_info.dto";
import { index, destroy } from "./actions";
import type { RootState } from "../store";

interface BillingTableState {
  isLoading: boolean;
  isSubmitted: boolean;
  isDestroyed: boolean;
  successMessage: string;
  errorMessage: string;
  billings: Billing[];
  pageInfo: PageInfo;
  currentPage: number;
  rowsPerPage: number;
}

const initialState: BillingTableState = {
  isLoading: false,
  isSubmitted: false,
  isDestroyed: false,
  successMessage: "",
  errorMessage: "",
  billings: [],
  pageInfo: {
    page: 0,
    pages: 1,
    items: 0,
    count: 0,
  },
  currentPage: 1,
  rowsPerPage: getRowsPerPageFromLocalStorage("billingTableRowsPerPage"),
};

export const billingTableSlice = createSlice({
  name: "billingTable",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isLoading: action.payload.value,
    }),
    updateCurrentPage: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      currentPage: action.payload.value,
    }),
    updateRowsPerPage: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      rowsPerPage: action.payload.value,
    }),
    updateSuccessMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      successMessage: action.payload.value,
    }),
    updateErrorMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      errorMessage: action.payload.value,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(index.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(index.fulfilled, (state, action) => {
      state.billings = action.payload.data;
      state.pageInfo = action.payload.pagination;
      state.isLoading = false;
    });
    builder.addCase(index.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(destroy.pending, (state) => {
      state.isLoading = true;
      state.isDestroyed = false;
    });
    builder.addCase(destroy.fulfilled, (state) => {
      state.isLoading = false;
      state.successMessage = "請求を削除しました";
      state.isDestroyed = true;
    });
    builder.addCase(destroy.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
  },
});

export const selectBillingTable = (state: RootState) => state.billingTable;
export default billingTableSlice.reducer;
