type Props = {
  size?: number;
  color?: string;
};
export const CloseIcon = (props: Props) => {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox={`-4 -2 18 19`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6631 7.01662L7.00406 7.38245L7.36983 7.04143L9.07666 5.45009C9.08467 5.44263 9.09408 5.43681 9.10433 5.43297C9.11459 5.42914 9.12551 5.42736 9.13645 5.42775C9.1474 5.42813 9.15816 5.43067 9.16812 5.43521C9.17809 5.43975 9.18706 5.44621 9.19453 5.45422C9.202 5.46223 9.20782 5.47164 9.21165 5.48189C9.21549 5.49215 9.21726 5.50306 9.21688 5.51401C9.2165 5.52495 9.21396 5.53572 9.20942 5.54568C9.20487 5.55565 9.19841 5.56462 9.1904 5.57209L7.48357 7.16342L7.11793 7.50432L7.45876 7.87003L9.04951 9.57686L9.05544 9.58322L9.06158 9.58937C9.06948 9.59728 9.07572 9.60671 9.0799 9.61708C9.08409 9.62745 9.08615 9.63856 9.08595 9.64975C9.08575 9.66093 9.0833 9.67196 9.07875 9.68218C9.0742 9.6924 9.06763 9.7016 9.05945 9.70922C9.05127 9.71685 9.04164 9.72275 9.03113 9.72658C9.02061 9.7304 9.00944 9.73207 8.99827 9.73149C8.9871 9.7309 8.97616 9.72808 8.9661 9.72317C8.95605 9.71827 8.94708 9.71139 8.93974 9.70295L8.93406 9.69642L8.92816 9.69009L7.33683 7.98325L6.99593 7.61761L6.63022 7.95845L4.92339 9.54919L4.9233 9.5491L4.91499 9.55721C4.90704 9.56496 4.89763 9.57105 4.8873 9.57512C4.87696 9.57919 4.86592 9.58115 4.85483 9.58089C4.84373 9.58063 4.83279 9.57816 4.82266 9.57361C4.81253 9.56907 4.80341 9.56255 4.79584 9.55443C4.78826 9.54631 4.78239 9.53676 4.77856 9.52634C4.77473 9.51592 4.77302 9.50484 4.77353 9.49375C4.77404 9.48266 4.77676 9.47178 4.78153 9.46176C4.7863 9.45173 4.79303 9.44276 4.80132 9.43538L4.8014 9.43547L4.80952 9.4279L6.51693 7.83657L6.88277 7.49561L6.54174 7.12984L4.95594 5.42894C4.94276 5.41301 4.93595 5.39271 4.9369 5.37198C4.9379 5.35023 4.94738 5.32974 4.9633 5.31488C4.97922 5.30003 5.00032 5.292 5.02209 5.2925C5.0428 5.29299 5.06254 5.30116 5.07751 5.31536L6.6631 7.01662Z"
        stroke="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583984 7.50016C0.583984 3.95641 3.4569 1.0835 7.00065 1.0835C10.5444 1.0835 13.4173 3.95641 13.4173 7.50016C13.4173 11.0439 10.5444 13.9168 7.00065 13.9168C3.4569 13.9168 0.583984 11.0439 0.583984 7.50016ZM7.00065 12.7502C6.31121 12.7502 5.62852 12.6144 4.99156 12.3505C4.3546 12.0867 3.77585 11.7 3.28834 11.2125C2.80083 10.725 2.41412 10.1462 2.15028 9.50925C1.88645 8.87229 1.75065 8.1896 1.75065 7.50016C1.75065 6.81072 1.88645 6.12803 2.15028 5.49108C2.41412 4.85412 2.80083 4.27536 3.28834 3.78785C3.77585 3.30034 4.3546 2.91363 4.99156 2.6498C5.62852 2.38596 6.31121 2.25016 7.00065 2.25016C8.39304 2.25016 9.7284 2.80329 10.713 3.78785C11.6975 4.77242 12.2507 6.10778 12.2507 7.50016C12.2507 8.89255 11.6975 10.2279 10.713 11.2125C9.7284 12.197 8.39304 12.7502 7.00065 12.7502Z"
        fill="#666666"
      />
    </svg>
  );
};
