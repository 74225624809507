import {
  ScheduleUpdateRequest,
  ScheduleRightClickObject,
  ScheduleId,
} from "data-access/repositories/schedule/schedule.dto";
import { duplicateGridSchedule, duplicateTimelineSchedule, updateGridSchedule } from "./actions";
import { scheduleRightClickMenuSlice } from "./slice";
import { AppDispatch } from "../store";

export const scheduleRightClickMenuOperations = {
  duplicateGridSchedule: (id: ScheduleId) => (dispatch: AppDispatch) => {
    dispatch(duplicateGridSchedule(id));
  },
  duplicateTimelineSchedule: (id: ScheduleId) => (dispatch: AppDispatch) => {
    dispatch(duplicateTimelineSchedule(id));
  },
  updateGridSchedule:
    (id: ScheduleId, with_futures: boolean, body: ScheduleUpdateRequest) =>
    (dispatch: AppDispatch) => {
      dispatch(updateGridSchedule({ id, with_futures, body }));
    },
  setObject: (value: ScheduleRightClickObject) => (dispatch: AppDispatch) => {
    dispatch(scheduleRightClickMenuSlice.actions.setObject({ value }));
  },
  setAnchorEl: (value: HTMLButtonElement | null) => (dispatch: AppDispatch) => {
    dispatch(scheduleRightClickMenuSlice.actions.setAnchorEl({ value }));
  },
  deleteSnackbarMessage: () => (dispatch: AppDispatch) => {
    dispatch(scheduleRightClickMenuSlice.actions.deleteSnackbarMessage());
  },
  open: () => (dispatch: AppDispatch) => {
    dispatch(scheduleRightClickMenuSlice.actions.open());
  },
  close: () => (dispatch: AppDispatch) => {
    dispatch(scheduleRightClickMenuSlice.actions.close());
  },
};
