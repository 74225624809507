import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";

interface Props {
  title: string;
  onClick: () => void;
}
export const AddButton = (props: Props) => {
  return (
    <Button
      variant="contained"
      startIcon={<AddCircleIcon />}
      onClick={props.onClick}
      sx={{ minWidth: "12rem" }}
    >
      {props.title}
    </Button>
  );
};
