import { makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() => ({
  titleBox: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
    backgroundColor: theme.palette.customPrimary[50],
    borderRadius: "8px 8px 0 0",
    padding: "16px 12px",
    gap: "20px",
  },
  headerItem: {
    width: "120px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: `1px solid ${theme.palette.grayScale[300]}`,
  },
  headerText: {
    margin: 0,
    fontSize: "12px",
    fontWeight: "500",
  },
  headerAverageMonthItem: {
    flex: 1,
    lineHeight: "28px",
    textAlign: "center",
    borderRight: `1px solid ${theme.palette.grayScale[300]}`,
  },
  row: {
    height: "35px",
    display: "flex",
    lineHeight: "35px",
    borderTop: `1px solid ${theme.palette.grayScale[300]}`,
  },
  cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    width: "120px",
    fontSize: "12px",
    paddingRight: "12px",
  },
  cellAverageMonthItem: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    fontSize: "12px",
    paddingRight: "12px",
  },
}));
