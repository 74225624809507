import { Brand } from "data-access/repositories/brand";
import { ExpenseItem, ExpenseItemId } from "./expense_item.dto";

export type ExpenseId = Brand<number, "ExpenseId">;

export interface Expense {
  id: ExpenseId;
  amount: number;
  note: string;
  expenseItem: ExpenseItem;
}

export type ExpenseIndexResponse = Array<Expense>;

export interface ExpenseCreateRequest {
  expenseItemId?: ExpenseItemId;
  expenseItem?: {
    name?: string;
  };
  amount: number;
  note: string;
}

export interface ExpenseUpdateRequest {
  amount: number;
  note: string;
}

export const initialExpenseFormRequest: ExpenseUpdateRequest = {
  amount: 0,
  note: "",
};
