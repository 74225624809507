import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { MicrosoftIcon } from "components/icon/microsoft-icon";
import { DayOfWeek } from "data-access/repositories/company_user/company_user.dto";
import { iCalRepository } from "data-access/repositories/ical/ical.repository";
import { authorizeUrlRepository } from "data-access/repositories/microsoft/authorize_url/authorize_url.repository";
import { tokenRepository } from "data-access/repositories/microsoft/token/token.repository";
import { personalSettingRepository } from "data-access/repositories/personal_setting/personal_setting.repository";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR from "swr";

export const Calendar = () => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);

  const [startDayOfWeek, setStartDayOfWeek] = useState<DayOfWeek>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [shareUrlTip, setShareUrlTip] = useState<string>("URLをコピーします");
  const navigate = useNavigate();
  const { search: parameter } = useLocation();

  const { data: { url: iCalUrl } = { url: "" }, mutate } = useSWR(
    "/api/v1/personal_setting",
    iCalRepository.show,
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    const fetchCode = async () => {
      if (parameter) {
        const url = new URLSearchParams(parameter);
        const code = url.get("code");
        if (!code) return;

        try {
          await tokenRepository.save(code);
          dispatch(mainOperations.getPersonalSetting());
          navigate("/user-settings");
          dispatch(mainOperations.updateSuccessMessage("Microsoftと連携しました"));
        } catch (error) {
          dispatch(mainOperations.updateErrorMessage(error.response.data.message));
        }
      }
    };

    fetchCode();
  }, []);

  useEffect(() => {
    if (!mainState.personalSetting) return;
    setStartDayOfWeek(mainState.personalSetting.calendar_start_day_of_week);
  }, [mainState.personalSetting]);

  const dayOfWeeks: { id: DayOfWeek; name: string }[] = [
    { id: 0, name: "月曜日" },
    { id: 6, name: "日曜日" },
  ];

  const handleSignIn = async () => {
    try {
      const res = await authorizeUrlRepository.index("gembahub");
      window.location.href = res.authorize_url;
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleSingOut = async () => {
    try {
      await tokenRepository.destroy();
      try {
        dispatch(mainOperations.getPersonalSetting());
        dispatch(mainOperations.updateSuccessMessage("Microsoftとの連携を解除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleChange = async (event: SelectChangeEvent<number>) => {
    setIsLoading(true);
    try {
      await personalSettingRepository.update({
        calendarStartDayOfWeek: event.target.value as number,
      });
      // DatePickerの開始曜日参照元である、グローバルステートを更新させるため
      dispatch(mainOperations.getPersonalSetting());
      dispatch(mainOperations.updateSuccessMessage("週の開始曜日を更新しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(iCalUrl);
      setShareUrlTip("コピーしました");
    } catch {
      dispatch(mainOperations.updateErrorMessage("コピーに失敗しました"));
    }
  };

  const handleReset = async () => {
    try {
      await iCalRepository.reset();
      mutate();
      dispatch(mainOperations.updateSuccessMessage("URLをリセットしました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  return (
    <>
      <Box sx={{ my: "40px" }}>
        <Typography fontWeight="700" fontSize="14px" lineHeight="16.8px" marginBottom="12px">
          週の開始曜日
        </Typography>
        <Select
          value={startDayOfWeek}
          onChange={handleChange}
          sx={{ width: "373px" }}
          disabled={isLoading}
        >
          {dayOfWeeks.map((dayOfWeek) => {
            return (
              <MenuItem key={dayOfWeek.id} value={dayOfWeek.id}>
                {dayOfWeek.name}
              </MenuItem>
            );
          })}
        </Select>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "start" }}>
        <Typography fontWeight="bold">外部カレンダーの予定を現場Hubで表示する</Typography>
        <Typography fontSize="14px">
          サインインすると、Outlookに登録されている予定を現場Hubの予定に表示できます。
        </Typography>
        {mainState.personalSetting.is_microsoft_signed_in ? (
          <Button variant="contained" onClick={handleSingOut} color="error">
            <Typography sx={{ textTransform: "none" }}>Microsoft連携を解除</Typography>
          </Button>
        ) : (
          <Button variant="outlined" onClick={handleSignIn} sx={{ display: "flex", gap: "8px" }}>
            <MicrosoftIcon />
            <Typography sx={{ textTransform: "none" }}>Microsoftにサインイン</Typography>
          </Button>
        )}
      </Box>

      <Stack spacing="16px" sx={{ mt: "40px" }}>
        <Typography fontWeight="bold">
          現場Hub上で参加している予定を外部カレンダーで表示する
        </Typography>
        <Typography fontSize="14px">
          下記のURLを使うと、現場Hub上で参加している予定をOutlook、Googleカレンダー、Appleカレンダーなどに表示できます。
        </Typography>
        <Box>
          <Typography fontWeight="500" mb="8px" fontSize="14px">
            iCal形式の非公開URL
          </Typography>
          <OutlinedInput
            value={iCalUrl}
            readOnly
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                  {showPassword ? (
                    <VisibilityOff fontSize="small" />
                  ) : (
                    <Visibility fontSize="small" />
                  )}
                </IconButton>
                <Divider sx={{ height: "45px", ml: "8px" }} orientation="vertical" />
                <Tooltip
                  title={shareUrlTip}
                  onOpen={() => setShareUrlTip("URLをコピーします")}
                  placement="top"
                  arrow
                >
                  <IconButton onClick={handleCopy} edge="end">
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
            sx={{
              width: "580px",
              height: "45px",
            }}
          />
        </Box>
        <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px" }}>
          このURLは、誰にも教えないでください。
          <br />
          現在のURLを無効にし、新しいURLを生成するには「リセット」ボタンを押してください。
        </Typography>
        <Button variant="outlined" onClick={handleReset} sx={{ width: "100px", height: "40px" }}>
          リセット
        </Button>
      </Stack>
    </>
  );
};
