export const openURLInNewTab = (path: string): void => {
  let url: string;
  if (import.meta.env.MODE === "development") {
    url = `${import.meta.env.VITE_APP_LOCAL_URL}/${path}`;
  } else if (import.meta.env.MODE === "stage") {
    url = `${import.meta.env.VITE_APP_STAGING_URL}/${path}`;
  } else {
    url = `${import.meta.env.VITE_APP_PROD_URL}/${path}`;
  }
  window.open(url, "_blank");
};
