import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { ScheduleSearchResultTable } from "components/organisms/schedule-search-result-table";
import { ScheduleSearchResultTableHeader } from "components/organisms/schedule-search-result-table-header";
import { Layout } from "components/templates/layout";

export default function ScheduleSearchResult() {
  const navigate = useNavigate();

  return (
    <Layout>
      <Box>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <div
            onClick={() => navigate("/calendar")}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <ChevronLeft />
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", marginLeft: "8px" }}>
              予定検索
            </Typography>
          </div>
        </div>
        <ScheduleSearchResultTableHeader />
        <Box sx={{ mt: 2 }}>
          <ScheduleSearchResultTable />
        </Box>
      </Box>
    </Layout>
  );
}
