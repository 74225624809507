import { DEFAULT_ROWS_PER_PAGE } from "utils/constant";
import {
  DefaultBilling,
  DefaultBillingType,
  DefaultProjectFilter,
  DefaultProjectType,
  DefaultWorkReport,
  DefaultWorkReportType,
} from "./local_storage.dto";

type Key = "billing" | "project" | "workReport";

type ItemType<T> = T extends "billing"
  ? DefaultBillingType
  : T extends "project"
    ? DefaultProjectType
    : T extends "workReport"
      ? DefaultWorkReportType
      : never;

export const getFilterItem = <T extends Key>(key: T): ItemType<T> => {
  const localStorageItem = localStorage.getItem(key + "TableFilter");
  const result = () => {
    switch (key) {
      case "billing":
        return DefaultBilling as ItemType<T>;
      case "project":
        return DefaultProjectFilter as ItemType<T>;
      case "workReport":
        return DefaultWorkReport as ItemType<T>;
    }
  };

  return localStorageItem !== null ? JSON.parse(localStorageItem) : result();
};

export const setFilterItem = <T extends Key>(key: T, item: ItemType<T>) => {
  localStorage.setItem(key + "TableFilter", JSON.stringify(item));
};

export const getRowsPerPageFromLocalStorage = (key: string): number => {
  const rowsPerPage = localStorage.getItem(key);
  return rowsPerPage ? parseInt(rowsPerPage, 10) : DEFAULT_ROWS_PER_PAGE;
};
