import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRowsPerPageFromLocalStorage } from "data-access/local_storage/table_filter/local_storage";
import { PageInfo } from "data-access/repositories/page_info/page_info.dto";
import { search } from "store/schedule-search-result-table/actions";
import { ScheduleList } from "../../data-access/repositories/schedule/schedule.dto";
import type { RootState } from "store/store";

export interface SearchResultTableState {
  isSubmit: boolean;
  isLoading: boolean;
  errorMessage: string;
  schedules: ScheduleList[];
  filteredSchedules: ScheduleList[];
  keyword: string;
  pageInfo: PageInfo;
  currentPage: number;
  rowsPerPage: number;
}

const initialState: SearchResultTableState = {
  isLoading: false,
  isSubmit: false,
  errorMessage: "",
  schedules: [],
  filteredSchedules: [],
  keyword: "",
  pageInfo: {
    page: 0,
    pages: 1,
    items: 0,
    count: 0,
  },
  currentPage: 1,
  rowsPerPage: getRowsPerPageFromLocalStorage("scheduleSearchResultTableRowsPerPage"),
};

export const scheduleSearchResultTableSlice = createSlice({
  name: "scheduleSearchResultTable",
  initialState,
  reducers: {
    submit: (state) => ({
      ...state,
      isSubmit: true,
    }),
    updateCurrentPage: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      currentPage: action.payload.value,
    }),
    updateRowsPerPage: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      rowsPerPage: action.payload.value,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(search.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(search.fulfilled, (state, action) => {
      state.isSubmit = false;
      state.isLoading = false;
      state.schedules = action.payload.data;
      state.pageInfo = action.payload.pagination;
    });
    builder.addCase(search.rejected, (state, action) => {
      state.isSubmit = false;
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
  },
});

export const selectScheduleSearchResultTable = (state: RootState) =>
  state.scheduleSearchResultTable;
export default scheduleSearchResultTableSlice.reducer;
