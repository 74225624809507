import React from "react";
import { OpenInNew } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography, styled } from "@mui/material";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { BuildingProject } from "data-access/repositories/building/building.dto";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { DATE_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { openURLInNewTab } from "utils/openURLInNewTab";

const BoldInfo = styled("span")({
  fontSize: "14px",
  fontWeight: "500",
});

const InfoTypography = styled(Typography)({
  width: "150px",
  fontSize: "12px",
});

interface Props {
  projects: BuildingProject[];
}

export const ProjectsInfoBlock = (props: Props) => {
  const { projects } = props;
  const handleClick = (projectId: ProjectId) => {
    openURLInNewTab(`projects/${projectId}`);
  };
  return (
    <>
      <CustomFormLabel labelName="案件一覧" />
      {projects.length === 0 && (
        <Typography sx={{ fontWeight: "bold", ml: "8px" }}>紐づく案件はありません</Typography>
      )}
      {projects.map((project) => (
        <React.Fragment key={project.id}>
          <Box
            sx={{
              backgroundColor: theme.palette.customPrimary[50],
              border: `1px solid ${theme.palette.grayScale[300]}`,
              borderRadius: "8px",
              p: "12px",
              width: "100%",
              mb: "16px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <StatusTypeLabel
                    statusType={project.project_status_type.name}
                    color={project.project_status_type.color_number}
                    sx={{ width: "100px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "330px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {project.code}:{project.name}
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                  <InfoTypography>
                    現場責任者 <BoldInfo>{project.manager?.name}</BoldInfo>
                  </InfoTypography>
                  <InfoTypography>
                    売上 <BoldInfo>¥{project.sales_amount.toLocaleString()}</BoldInfo>
                  </InfoTypography>
                  <InfoTypography>
                    完了日{" "}
                    <BoldInfo>
                      {project.completed_date
                        ? formatDateUtil(project.completed_date, DATE_SLASH_FORMAT)
                        : ""}
                    </BoldInfo>
                  </InfoTypography>
                </div>
              </div>
              <IconButton onClick={() => handleClick(project.id)} sx={{ p: 0 }}>
                <OpenInNew fontSize="small" color="primary" />
              </IconButton>
            </div>
            <Divider sx={{ my: "12px" }} />
            <Typography
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {project.note}
            </Typography>
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};
