import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";

interface Props {
  size?: number;
  url: string;
}
export const InfoToolTip = ({ url, size = 18 }: Props) => {
  const handleInfoClick = () => {
    window.open(url, "_blank");
  };

  return (
    <Tooltip title="クリックするとヘルプページを開きます" placement="top">
      <IconButton onClick={handleInfoClick} size="small">
        <InfoOutlinedIcon sx={{ fontSize: size }} />
      </IconButton>
    </Tooltip>
  );
};
