import { AddressFromPostalCodeResponse } from "./address_from_postal_code.dto";
import { ApiClient } from "../../ApiClient";

export const addressRepository = {
  index(postalCode: string): Promise<AddressFromPostalCodeResponse> {
    const params = {
      postal_code: postalCode,
    };
    return ApiClient.get("/api/v1/postal_codes/address_from_postal_code", { params }).then(
      (res) => res.data,
    );
  },
};
