import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { formatDateUtil } from "utils/formatDateUtil";
import { SalesAggregationResponse } from "../types/common.dto";

export const salesAggregationByClientRepository = {
  index(targetMonth: Date): Promise<SalesAggregationResponse> {
    const formattedTargetMonth = formatDateUtil(targetMonth.toString(), "yyyy-MM");
    return ApiClient.get(
      `/api/v1/sales_aggregations/by_client?target_month=${formattedTargetMonth}`,
    ).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
};
