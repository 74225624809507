import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  type: "close" | "change";
  isOpen: boolean;
  handleClose?: () => void;
  handleYesChange: () => void;
  handleYesClose: () => void;
}
export const ConfirmDialog = (props: Props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      maxWidth="sm"
      sx={{
        "& .MuiPaper-root": {
          p: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 0,
          color: theme.palette.grayScale[900],
        }}
      >
        <IconButton onClick={props.handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          sx={{ color: theme.palette.grayScale[900], textAlign: "center", fontSize: "18px" }}
        >
          {props.type === "close"
            ? "内容は保存されずに破棄されますがよろしいでしょうか？"
            : "書式を変更する場合、現在の内容は破棄されますがよろしいでしょうか？"}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", pt: "20px" }}>
        <Button
          onClick={props.handleClose}
          color="primary"
          variant="outlined"
          sx={{ width: "13rem" }}
        >
          キャンセル
        </Button>
        <Button
          onClick={props.type === "close" ? props.handleYesClose : props.handleYesChange}
          color="error"
          variant="contained"
          sx={{ width: "13rem" }}
          disableElevation
        >
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};
