import {
  AttendanceRule,
  AttendanceRuleId,
} from "features/company-settings/types/attendance/rule/attendance_rule.dto";
import { Brand } from "../brand";
import { GroupId } from "../group/group.dto";
import { UserId } from "../user/user.dto";

export type CompanyUserId = Brand<number, "CompanyUserId">;

export interface CompanyUser {
  id: CompanyUserId;
  name: string;
  userId: UserId;
  groupId: GroupId;
  isOutsourcedMember: boolean;
  isSuperUser: boolean;
  hasEmail: boolean;
  isDeactivate: boolean;
  projectWrite: boolean;
  projectRead: number;
  estimateWrite: number;
  estimateRead: boolean;
  attachmentRead: number;
  billingWrite: boolean;
  billingRead: boolean;
  scheduleWrite: number;
  scheduleRead: number;
  clientWrite: boolean;
  clientRead: boolean;
  masterWrite: boolean;
  masterRead: boolean;
  unitPricePerDay: number;
  calendarStartDayOfWeek: DayOfWeek;
  numberOfProjectNoteLinesToDisplay: number;
  attendanceRule: AttendanceRule;
}

export type CompanyUserIndexResponse = Array<CompanyUser>;

export enum PermissionSetKeyName {
  OutsourcedMember = "outsourced_member",
  Other = "other",
}

export interface CompanyUserCreateRequest {
  name: string;
  email: string;
  permissionSetKeyName: PermissionSetKeyName;
  groupName?: string;
}

export interface CompanyUserUpdateRequest {
  name?: string;
  groupId?: number;
  unitPricePerDay?: number;
  displayOrderPosition?: number;
  isDeactivate?: boolean;
  attendanceRuleId?: AttendanceRuleId;
}

export type CompanyUserCreateResponse = CompanyUser;

export type CompanyUserUpdateResponse = CompanyUser;

// 月火水木金土日
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const initialCompanyUserRequest: CompanyUserCreateRequest = {
  name: "",
  email: "",
  permissionSetKeyName: PermissionSetKeyName.OutsourcedMember,
};
