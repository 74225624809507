import React, { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { CustomModal } from "components/molecules/custom-modal";
import { Client } from "data-access/repositories/client/client.dto";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectMain } from "store/main/slice";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import { reportContentPrintModalOperations } from "store/report-content-print-modal/operations";
import { selectReportContentPrintModal } from "store/report-content-print-modal/slice";
import { styles } from "./styles";

interface Props {
  client: Client;
}
export const ReportContentPrintModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectReportContentPrintModal);
  const classes = styles();

  const componentRef = useRef<HTMLButtonElement>(null);
  const handleClose = () => {
    dispatch(reportContentPrintModalOperations.close());
  };

  return (
    <CustomModal
      open={state.isOpen}
      onClose={() => handleClose()}
      title="報告内容を印刷・PDFで保存"
      footer={
        <React.Fragment>
          <Button variant="outlined" onClick={handleClose} sx={{ px: "3rem" }}>
            キャンセル
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" sx={{ px: "5rem", ml: "1rem" }}>
                印刷
              </Button>
            )}
            content={() => componentRef.current}
          />
        </React.Fragment>
      }
    >
      <Box sx={{ pt: "5rem", px: "3rem" }} className={classes.reportContent}>
        <ReportContent isColorOn={true} client={props.client} />
      </Box>

      <div style={{ display: "none" }}>
        <Box ref={componentRef} sx={{ pt: "5rem", px: "3rem" }} className={classes.reportContent}>
          <ReportContent isColorOn={false} client={props.client} />
        </Box>
      </div>
    </CustomModal>
  );
};
interface ReportContentProps {
  isColorOn: boolean;
  client: Client;
}
const ReportContent = (props: ReportContentProps) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectReportContentPrintModal);
  const mainState = useAppSelector(selectMain);
  const detailState = useAppSelector(selectProjectSidebar);
  const classes = styles();

  useEffect(() => {
    dispatch(
      reportContentPrintModalOperations.updateRequestedByName(
        `${
          detailState.form.requestedByPersonName ? detailState.form.requestedByPersonName : ""
        } 様`,
      ),
    );
    dispatch(reportContentPrintModalOperations.updateActivityType("受注"));
    dispatch(reportContentPrintModalOperations.updateBehaviorType("決定"));
    if (detailState.project.inquired_by) {
      dispatch(
        reportContentPrintModalOperations.updateReportedByName(
          detailState.project.inquired_by.name,
        ),
      );
    }
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
  ) => {
    switch (e.target.name) {
      case "requestedByName":
        dispatch(reportContentPrintModalOperations.updateRequestedByName(e.target.value));
        break;
      case "activityType":
        dispatch(reportContentPrintModalOperations.updateActivityType(e.target.value));
        break;
      case "behaviorType":
        dispatch(reportContentPrintModalOperations.updateBehaviorType(e.target.value));
        break;
      case "reportedByName":
        dispatch(reportContentPrintModalOperations.updateReportedByName(e.target.value));
        break;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography>
            {props.client?.name}{" "}
            {state.requestedByName === "" &&
              (props.client?.honorific_title === "nothing"
                ? ""
                : props.client?.honorific_title_i18n)}
          </Typography>
          <TextField
            id="requestedByName"
            name="requestedByName"
            className={props.isColorOn ? classes.canInput : ""}
            sx={{ mt: 1 }}
            value={state.requestedByName}
            inputProps={{
              style: {
                padding: 0,
              },
            }}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <Typography textAlign="right">{mainState.company.name}</Typography>
          <Typography textAlign="right">{mainState.company.address}</Typography>
          <Typography textAlign="right">TEL: {mainState.company.phone_number}</Typography>
          <Typography textAlign="right">FAX: {mainState.company.fax_number}</Typography>
        </Box>
      </Box>
      <Typography sx={{ mt: "1rem", fontSize: "1.3rem", textAlign: "center" }}>
        {detailState.project.project_type.name}受注物件報告書
      </Typography>
      <Typography sx={{ mt: "0.5rem", textAlign: "center" }}>
        ご依頼を頂き誠にありがとうございました。
        <br />
        下記の通り
        <Select
          id="activityType"
          name="activityType"
          variant="standard"
          sx={{ ml: "0.5rem" }}
          value={state.activityType}
          disableUnderline
          className={props.isColorOn ? classes.canInput : ""}
          onChange={handleChange}
        >
          <MenuItem value="受注">受注</MenuItem>
          <MenuItem value="訪問日時">訪問日時</MenuItem>
          <MenuItem value="訪問">訪問</MenuItem>
          <MenuItem value="作業">作業</MenuItem>
        </Select>
        を
        <Select
          id="behaviorType"
          name="behaviorType"
          variant="standard"
          sx={{ ml: "0.5rem" }}
          value={state.behaviorType}
          disableUnderline
          className={props.isColorOn ? classes.canInput : ""}
          onChange={handleChange}
        >
          <MenuItem value="決定">決定</MenuItem>
          <MenuItem value="完了">完了</MenuItem>
        </Select>
        いたしましたのでご報告させていただきます。
      </Typography>

      <Table sx={{ mt: "1rem" }}>
        <TableBody>
          <TableRow>
            <TableCell component="th">現場名</TableCell>
            <TableCell>
              {detailState.project.code}：{detailState.form.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">現場住所</TableCell>
            <TableCell>
              {detailState.form.address} {detailState.form.addressSecond}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table sx={{ mt: "1rem" }}>
        <TableBody>
          <TableRow>
            <TableCell component="th">FAX又はTEL ご依頼日</TableCell>
            <TableCell>{detailState.form.inquiredDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">ご依頼内容</TableCell>
            <TableCell>
              {detailState.form.note &&
                detailState.form.note.split("\n").map((str: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {str}
                      <br />
                    </React.Fragment>
                  );
                })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">受付担当者</TableCell>
            <TableCell>
              {detailState.project.inquired_by ? detailState.project.inquired_by.name : ""}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table sx={{ mt: "1rem" }}>
        <TableBody>
          {detailState.project.project_type.name !== "物販" && (
            <TableRow>
              <TableCell component="th">{detailState.project.project_type.name}訪問日</TableCell>
              <TableCell>{detailState.form.expectedCompleteDate}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell component="th">報告内容</TableCell>
            <TableCell>
              {detailState.form.reportContent &&
                detailState.form.reportContent.split("\n").map((str: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {str}
                      <br />
                    </React.Fragment>
                  );
                })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table sx={{ mt: "1rem" }}>
        <TableBody>
          <TableRow>
            <TableCell component="th">{detailState.project.project_type.name}完了日</TableCell>
            <TableCell>{detailState.form.completedDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">報告担当者</TableCell>
            <TableCell sx={{ p: 0, fontSize: "1.1rem" }}>
              <TextField
                id="reportedByName"
                name="reportedByName"
                className={props.isColorOn ? classes.canInput : ""}
                sx={{ m: 0, p: 0 }}
                fullWidth
                value={state.reportedByName}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
