import { Close } from "@mui/icons-material";
import {
  Box,
  Breakpoint,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { Flash } from "components/atoms/flash";
import { MuiButtonColor, theme } from "extensions/theme";
import { scheduleDeleteConfirmDialogOperations } from "store/schedule-delete-confirm-dialog/operations";
import { styles } from "./styles";
import { useAppDispatch } from "../../../store/hooks";

interface Props {
  title?: string;
  content?: string;
  summary?: string;
  maxWidth?: Breakpoint;
  isOpen: boolean;
  isConfirmNotShowAgain?: boolean;
  handleClose?: () => void;
  handleYes: () => void;
  handleNo: () => void;
  handleConfirmedNotShowAgain?: (e: boolean) => void;
  yesButtonColor?: MuiButtonColor;
  yesButtonText?: string;
  noButtonText?: string;
  isSubmit?: boolean;
  errorMessage?: string;
}

export const ConfirmDialog = ({
  yesButtonText = "はい",
  noButtonText = "キャンセル",
  yesButtonColor = "primary",
  ...props
}: Props) => {
  const classes = styles();
  const dispatch = useAppDispatch();

  // TODO:switch文でoperationsして動的にする
  const handleCheck = (e: any) => {
    if (props.handleConfirmedNotShowAgain) {
      props.handleConfirmedNotShowAgain(e.target.checked);
    } else {
      dispatch(
        scheduleDeleteConfirmDialogOperations.updateIsDeleteConfirmNotShowAgain(e.target.checked),
      );
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleNo}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classes.dialog}
      maxWidth={props.maxWidth ? props.maxWidth : "sm"}
    >
      <DialogTitle id="dialog-title" className={classes.title}>
        {props.title}
        <IconButton onClick={props.handleClose || props.handleNo}>
          <Close />
        </IconButton>
      </DialogTitle>

      {props.errorMessage && <Flash title="エラー" severity="error" message={props.errorMessage} />}

      {props.content && (
        <DialogContent>
          <DialogContentText
            id="dialog-description"
            sx={{ color: theme.palette.grayScale[900], textAlign: "center", fontSize: "18px" }}
          >
            {props.content}
          </DialogContentText>
        </DialogContent>
      )}

      {props.summary && (
        <DialogContent>
          <DialogContentText
            id="dialog-description"
            sx={{ color: theme.palette.grayScale[900], textAlign: "center", fontSize: "0.9rem" }}
          >
            {props.summary}
          </DialogContentText>
        </DialogContent>
      )}

      {props.isConfirmNotShowAgain && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <FormControlLabel
            control={<Checkbox onChange={handleCheck} name="isDeleteConfirmNotShowAgain" />}
            label="次回以降表示しない"
          />
        </Box>
      )}

      <DialogActions sx={{ justifyContent: "space-between", pt: "20px" }}>
        <Button onClick={props.handleNo} color="primary" variant="outlined" sx={{ width: "13rem" }}>
          {noButtonText}
        </Button>
        <Button
          onClick={props.handleYes}
          color={yesButtonColor}
          variant="contained"
          sx={{ width: "13rem" }}
          disabled={props.isSubmit}
          disableElevation
        >
          {yesButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
