import { projectTableHeaderSlice } from "./slice";
import { AppDispatch } from "../store";

export const projectTableHeaderOperations = {
  updateIsSubmitted: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(projectTableHeaderSlice.actions.updateIsSubmitted({ value }));
  },
  addCountChanges: () => (dispatch: AppDispatch) => {
    dispatch(projectTableHeaderSlice.actions.addCountChanges());
  },
};
