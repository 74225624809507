import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { CustomTabs } from "components/molecules/custom-tabs";
import { Daily } from "./daily";
import { Monthly } from "./monthly";
import { Overtime } from "./overtime";

type TabType = "daily" | "monthly" | "overtime";

export const Attendance = () => {
  const [tab, setTab] = useState<TabType>("daily");

  return (
    <>
      <Box>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: "20px" }}>勤怠管理</Typography>
        <CustomTabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: TabType) => setTab(newValue)}
          tabs={[
            { value: "daily", label: "打刻 日ごと" },
            { value: "monthly", label: "打刻 月ごと" },
            { value: "overtime", label: "残業時間" },
          ]}
        />

        <Box sx={{ mt: "20px" }}>
          {tab === "daily" && <Daily />}
          {tab === "monthly" && <Monthly />}
          {tab === "overtime" && <Overtime />}
        </Box>
      </Box>
    </>
  );
};
