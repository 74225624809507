import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  AttachmentTypeIndexResponse,
  AttachmentTypeShowResponse,
  AttachmentTypeRequest,
  AttachmentTypeId,
} from "./attachment_type.dto";
import { ApiClient } from "../../ApiClient";

export const attachmentTypeRepository = {
  index(): Promise<AttachmentTypeIndexResponse> {
    return ApiClient.get("/api/v1/attachment_types").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(body: AttachmentTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/attachment_types", createBody(body), config).then(
      (res) => res.data,
    );
  },
  show(id: AttachmentTypeId): Promise<AttachmentTypeShowResponse> {
    return ApiClient.get(`/api/v1/attachment_types/${id}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(id: AttachmentTypeId, body: AttachmentTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/attachment_types/${id}`, createBody(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: AttachmentTypeId): Promise<void> {
    return ApiClient.delete(`/api/v1/attachment_types/${id}`).then((res) => res.data);
  },
};

const createBody = (body: AttachmentTypeRequest) => {
  return {
    name: body.name,
    is_publish_to_mobile: body.isPublishToMobile,
    display_order_position: body.displayOrderPosition,
  };
};
