import { theme } from "extensions/theme";

type Props = {
  size?: number;
  color?: string;
};

export const ConfirmSchedule = (props: Props) => {
  return (
    <svg
      width={props.size || 22}
      height={props.size || 22}
      viewBox="1 0 16 16"
      x="0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.87437 15.0181L1.87452 15.0183C2.20711 15.3547 2.61082 15.525 3.07895 15.525H8.88158H9.06531L8.99785 15.3541C8.89587 15.0958 8.82612 14.8383 8.78813 14.5817C8.74954 14.321 8.73026 14.0605 8.73026 13.8V13.675H8.60526H3.20395V7.525H12.4276V9.08V9.22544L12.5714 9.20358C12.8284 9.16452 13.0853 9.145 13.3421 9.145C13.5989 9.145 13.8558 9.16452 14.1128 9.20358L14.2566 9.22544V9.08V4.2C14.2566 3.72715 14.0892 3.31907 13.7571 2.9826C13.4246 2.64559 13.0208 2.475 12.5526 2.475H11.8882V1V0.875H11.7632H10.1842H10.0592V1V2.475H5.57237V1V0.875H5.44737H3.86842H3.74342V1V2.475H3.07895C2.61075 2.475 2.20702 2.64559 1.87445 2.9826C1.54241 3.31907 1.375 3.72715 1.375 4.2V13.8C1.375 14.2728 1.5424 14.6812 1.87437 15.0181ZM12.4276 4.325V5.675H3.20395V4.325H12.4276Z"
        fill={props.color || theme.palette.grayScale[700]}
      />
      <path
        d="M15 12L12.25 15L11 13.6364"
        stroke={props.color || theme.palette.grayScale[700]}
        strokeWidth="2"
      />
    </svg>
  );
};
