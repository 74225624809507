import { ApiClient } from "data-access/ApiClient";
import { TertiaryCategoryId, TertiaryCategoryUpdateRequest } from "./tertiary_category.dto";
import { PrimaryCategoryId } from "../primary_category/primary_category.dto";
import { ProjectId } from "../project.dto";
import { SecondaryCategoryId } from "../secondary_category/secondary_category.dto";

export const tertiaryCategoryRepository = {
  create(
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ): Promise<void> {
    return ApiClient.post(
      `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}/tertiary_categories`,
    ).then((res) => res.data);
  },
  update(
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
    tertiaryCategoryId: TertiaryCategoryId,
    body: TertiaryCategoryUpdateRequest,
  ): Promise<void> {
    return ApiClient.put(
      `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}/tertiary_categories/${tertiaryCategoryId}`,
      updateBody(body),
    ).then((res) => res.data);
  },
  delete(
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
    tertiaryCategoryId: TertiaryCategoryId,
  ): Promise<void> {
    return ApiClient.delete(
      `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}/tertiary_categories/${tertiaryCategoryId}`,
    ).then((res) => res.data);
  },
};

const updateBody = (body: TertiaryCategoryUpdateRequest) => {
  return {
    name: body.name,
    display_order: body.displayOrder,
  };
};
