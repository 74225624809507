import { ElementRef, useCallback, useRef } from "react";
import { Box, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowModel,
  GridRowOrderChangeParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { DeleteIcon } from "components/icon/delete-icon";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import { CompanyUser } from "data-access/repositories/company_user/company_user.dto";
import { companyUserRepository } from "data-access/repositories/company_user/company_user.repository";
import { Group, GroupId } from "data-access/repositories/group/group.dto";
import { groupRepository } from "data-access/repositories/group/group.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { GroupColorPatterns } from "utils/constant";
import { styles } from "./styles";

export const GroupTable = () => {
  const dispatch = useAppDispatch();
  const classes = styles();

  const {
    mutate: groupsMutate,
    data,
    isValidating,
  } = useSWR("/api/v1/groups", groupRepository.index);
  const { mutate: membersMutate, data: members } = useSWR(
    "/api/v1/company_users",
    companyUserRepository.index,
  );

  const notSetId = 0;
  const groups = data && [
    ...data,
    {
      id: notSetId as GroupId,
      name: "未設定",
      color_number: "",
      user_count: 0,
      display_order_position: 0,
    },
  ];

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    id: GroupId,
  ) => {
    try {
      await groupRepository.update(id, {
        colorNumber: e.target.value,
      });
      groups &&
        groupsMutate(
          groups.map((group: Group) => {
            if (group.id === id) {
              group.color_number = e.target.value;
            }
            return group;
          }),
        );
      membersMutate();
      dispatch(mainOperations.updateSuccessMessage("カラーを変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (id: GroupId) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();

    if (res) {
      try {
        await groupRepository.destroy(id);
        groups && groupsMutate(groups.filter((group: Group) => group.id !== id));
        dispatch(mainOperations.updateSuccessMessage("グループを削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const handleEditName = useCallback(async (params: GridRowModel) => {
    try {
      await groupRepository.update(params.id, {
        name: params.name,
      });
      groups &&
        groupsMutate(
          groups.map((group: Group) => {
            if (group.id === params.id) {
              group.name = params.name;
            }
            return group;
          }),
        );
      membersMutate();
      dispatch(mainOperations.updateSuccessMessage("グループ名を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    return params;
  }, []);

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    if (params.row.id === notSetId) {
      dispatch(mainOperations.updateErrorMessage("「未設定」は並び替えることができません"));
      return;
    }

    try {
      await groupRepository.update(params.row.id, {
        displayOrderPosition: params.targetIndex,
      });
      groups && groupsMutate();
      dispatch(mainOperations.updateSuccessMessage("グループの順番を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const columns = () => {
    const headers: GridColDef[] = [];
    headers.push({
      field: "name",
      headerName: "グループ名",
      minWidth: 300,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "color_number",
      headerName: "カラー",
      minWidth: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.id !== notSetId && (
            <Select
              id="color"
              name="color"
              className={classes.tableSelectBox}
              value={`${params.row.color_number}`}
              variant="standard"
              onChange={(e) => handleChange(e, params.row.id)}
              displayEmpty
              sx={{
                "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
              }}
            >
              {GroupColorPatterns.map((color, index) => (
                <MenuItem value={color} key={index}>
                  <Box
                    sx={{
                      width: 90,
                      height: 24,
                      backgroundColor: `#${color}`,
                      borderRadius: "4px",
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      ),
    });
    headers.push({
      field: "user_count",
      headerName: "メンバー数",
      minWidth: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.id !== notSetId
            ? params.row.user_count
            : members?.filter((member: CompanyUser) => !member.groupId).length}
        </>
      ),
    });
    headers.push({
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params: GridRowParams) => [
        <>
          {params.row.id !== notSetId && (
            <GridActionsCellItem
              icon={<DeleteIcon color={theme.palette.grayScale[700]} size={20} />}
              label="Delete"
              onClick={() => handleDelete(params.row.id as GroupId)}
            />
          )}
        </>,
      ],
    });
    return headers;
  };

  // 「未設定」の行のスタイル
  const getRowClassName = (params: GridRowClassNameParams) => {
    return params.row.id === notSetId ? classes.noDrag : "";
  };

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <CustomizedDataGridPro
        columnHeaderHeight={40}
        columns={columns()}
        rows={groups || []}
        loading={isValidating}
        // 「未設定」の名前は編集不可のため
        isCellEditable={(params: GridCellParams) => params.row.id !== notSetId}
        getRowClassName={getRowClassName}
        hideFooter
        rowReordering
        disableRowSelectionOnClick
        onRowOrderChange={handleRowOrderChange}
        processRowUpdate={handleEditName}
      />
    </>
  );
};
