import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import { InfoToolTip } from "components/button/info-tooltip";
import { GroupLabel } from "components/label/group-label";
import { theme } from "extensions/theme";
import {
  AlertType,
  AttendanceOvertimeEmployee,
} from "features/attendance/types/overtime_record/attendance.employee.dto";
import { styles } from "./styles";

interface Props {
  employee: AttendanceOvertimeEmployee;
}

export const Card = (props: Props) => {
  const classes = styles();

  const getBackgroundColor = (alertType: AlertType) => {
    switch (alertType) {
      case "principle_warning":
        return theme.palette.brown[200];
      case "principle_violation":
        return theme.palette.orange[200];
      case "exception_warning":
        return theme.palette.mustard[100];
      case "exception_violation":
        return theme.palette.red[200];
      default:
        return "inherit";
    }
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grayScale[300]}`,
        borderRadius: "8px",
        mb: "24px",
      }}
    >
      <Box className={classes.titleBox}>
        <Typography sx={{ fontWeight: "bold" }}>{props.employee.name}</Typography>
        {props.employee.group && (
          <GroupLabel
            name={props.employee.group.name}
            colorNumber={props.employee.group.colorNumber}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", height: "60px" }}>
        <div
          style={{
            width: "80px",
            height: "100%",
            borderRight: `1px solid ${theme.palette.grayScale[300]}`,
          }}
        />
        <div className={classes.headerItem}>
          <p className={classes.headerText}>①法定外労働</p>
          <InfoToolTip url="https://help.gembahub.jp/322f2bf361414192a1979d60ea4e00bf" />
        </div>
        <div className={classes.headerItem}>
          <p className={classes.headerText}>②法定休日労働</p>
        </div>
        <div className={classes.headerItem}>
          <p className={classes.headerText}>①②の合計</p>
          <InfoToolTip url="https://help.gembahub.jp/322f2bf361414192a1979d60ea4e00bf" />
        </div>
        <div style={{ fontSize: "12px", fontWeight: "500", flex: 1 }}>
          <div
            style={{
              height: "32px",
              borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
            }}
          >
            <Typography
              sx={{ fontSize: "12px", lineHeight: "32px", textAlign: "center", fontWeight: "500" }}
            >
              法定外・休日労働時間の平均（当月を含む）
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", height: "28px" }}>
            <div className={classes.headerAverageMonthItem}>2ヶ月</div>
            <div className={classes.headerAverageMonthItem}>3ヶ月</div>
            <div className={classes.headerAverageMonthItem}>4ヶ月</div>
            <div className={classes.headerAverageMonthItem}>5ヶ月</div>
            <div className={classes.headerAverageMonthItem} style={{ borderRight: "none" }}>
              6ヶ月
            </div>
          </div>
        </div>
      </Box>

      {props.employee.monthlyOvertimeRecords.map((record, index) => (
        <Box key={index} className={classes.row}>
          <div style={{ width: "80px", textAlign: "center", fontSize: "12px" }}>
            {new Date(record.startingDate).getMonth() + 1}月
          </div>
          <div
            className={classes.cell}
            style={{
              backgroundColor: getBackgroundColor(record.overtimeHours.alertType),
            }}
          >
            <AlertToolTip
              target={{
                type: record.overtimeHours.alertType,
                message: record.overtimeHours.alertMessage,
              }}
            />
            {record.overtimeHours.value}
          </div>
          <div className={classes.cell}>{record.holidayHours}</div>
          <div
            className={classes.cell}
            style={{
              backgroundColor: getBackgroundColor(record.overtimeAndHolidayHours.alertType),
            }}
          >
            <AlertToolTip
              target={{
                type: record.overtimeAndHolidayHours.alertType,
                message: record.overtimeAndHolidayHours.alertMessage,
              }}
            />
            {record.overtimeAndHolidayHours.value}
          </div>

          {/* 平均 */}
          <div style={{ flex: 1, display: "flex", justifyContent: "space-between" }}>
            <div
              className={classes.cellAverageMonthItem}
              style={{
                backgroundColor: getBackgroundColor(record.twoMonthsAverage.alertType),
              }}
            >
              <AlertToolTip
                target={{
                  type: record.twoMonthsAverage.alertType,
                  message: record.twoMonthsAverage.alertMessage,
                }}
              />
              {record.twoMonthsAverage.value}
            </div>
            <div
              className={classes.cellAverageMonthItem}
              style={{
                backgroundColor: getBackgroundColor(record.threeMonthsAverage.alertType),
              }}
            >
              <AlertToolTip
                target={{
                  type: record.threeMonthsAverage.alertType,
                  message: record.threeMonthsAverage.alertMessage,
                }}
              />
              {record.threeMonthsAverage.value}
            </div>
            <div
              className={classes.cellAverageMonthItem}
              style={{
                backgroundColor: getBackgroundColor(record.fourMonthsAverage.alertType),
              }}
            >
              <AlertToolTip
                target={{
                  type: record.fourMonthsAverage.alertType,
                  message: record.fourMonthsAverage.alertMessage,
                }}
              />
              {record.fourMonthsAverage.value}
            </div>
            <div
              className={classes.cellAverageMonthItem}
              style={{
                backgroundColor: getBackgroundColor(record.fiveMonthsAverage.alertType),
              }}
            >
              <AlertToolTip
                target={{
                  type: record.fiveMonthsAverage.alertType,
                  message: record.fiveMonthsAverage.alertMessage,
                }}
              />
              {record.fiveMonthsAverage.value}
            </div>
            <div
              className={classes.cellAverageMonthItem}
              style={{
                backgroundColor: getBackgroundColor(record.sixMonthsAverage.alertType),
              }}
            >
              <AlertToolTip
                target={{
                  type: record.sixMonthsAverage.alertType,
                  message: record.sixMonthsAverage.alertMessage,
                }}
              />
              {record.sixMonthsAverage.value}
            </div>
          </div>
        </Box>
      ))}

      {/* 合計 */}
      <Box className={classes.row}>
        <div style={{ width: "80px", textAlign: "center", fontSize: "12px" }}>合計</div>
        <div
          className={classes.cell}
          style={{
            backgroundColor: getBackgroundColor(
              props.employee.annualOvertimeRecord.overtimeHours.alertType,
            ),
          }}
        >
          <AlertToolTip
            target={{
              type: props.employee.annualOvertimeRecord.overtimeHours.alertType,
              message: props.employee.annualOvertimeRecord.overtimeHours.alertMessage,
            }}
          />
          {props.employee.annualOvertimeRecord.overtimeHours.value}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            backgroundColor: getBackgroundColor(
              props.employee.annualOvertimeRecord.specialClauseOccurrences.alertType,
            ),
          }}
        >
          <AlertToolTip
            target={{
              type: props.employee.annualOvertimeRecord.specialClauseOccurrences.alertType,
              message: props.employee.annualOvertimeRecord.specialClauseOccurrences.alertMessage,
            }}
          />
          特別条項該当回数: {props.employee.annualOvertimeRecord.specialClauseOccurrences.value}
        </div>
      </Box>
    </Box>
  );
};

interface AlertToolTipProps {
  target: {
    type: AlertType;
    message: string;
  };
}
const AlertToolTip = (props: AlertToolTipProps) => {
  const getAlertColor = (type: AlertType) => {
    switch (type) {
      case "principle_warning":
        return theme.palette.brown[500];
      case "principle_violation":
        return theme.palette.orange[500];
      case "exception_warning":
        return theme.palette.mustard[500];
      case "exception_violation":
        return theme.palette.red[500];
      default:
        return theme.palette.grayScale[700];
    }
  };
  return (
    <>
      {props.target.type !== "none" && (
        <Tooltip title={props.target.message} placement="top">
          <InfoOutlinedIcon
            sx={{
              fontSize: "18px",
              mr: "8px",
              color: getAlertColor(props.target.type),
            }}
          />
        </Tooltip>
      )}
    </>
  );
};
