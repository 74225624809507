import * as Sentry from "@sentry/browser";

export const handleError = (
  error: any,
  setErrorMessage: (message: string) => void,
  defaultErrorMessage: string,
) => {
  if (error.response && error.response.data && error.response.data.message) {
    setErrorMessage(error.response.data.message);
  } else {
    setErrorMessage(defaultErrorMessage);
    Sentry.captureException(error, { extra: error.response });
  }
};
