import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, SxProps, Theme } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  sx?: SxProps<Theme>;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

// 3点リーダー
export const MoreOptionsButton = (props: Props) => {
  return (
    <IconButton
      sx={{
        width: "25px",
        height: "25px",
        padding: "4px",
        backgroundColor: theme.palette.grayScale[0],
        border: `1px solid ${theme.palette.grayScale[300]}`,
        "&:hover": {
          backgroundColor: theme.palette.grayScale[300],
        },
        top: "8px",
        right: "8px",
        position: "absolute",
      }}
      onClick={props.onClick}
    >
      <MoreHorizIcon fontSize="small" />
    </IconButton>
  );
};
