import {
  AttachmentCreateRequest,
  AttachmentUpdateRequest,
  AttachmentUpdateResponse,
  AttachmentDestroyResponse,
  ProjectAttachmentId,
} from "./attachment.dto";
import { ApiClient } from "../../../ApiClient";
import { AttachmentTypes, ProjectId } from "../project.dto";

export const attachmentRepository = {
  byAttachmentType(projectId: ProjectId): Promise<AttachmentTypes[]> {
    return ApiClient.get(`/api/v1/projects/${projectId}/attachments/by_attachment_type`).then(
      (res) => res.data,
    );
  },
  async create(projectId: ProjectId, body: AttachmentCreateRequest) {
    await Promise.all(
      body.files.map(async (file) => {
        try {
          const preSignedUrlResponse = await ApiClient.get(
            `/api/v1/projects/${projectId}/attachments/put_presigned_url?file_name=${encodeURIComponent(
              file.name,
            )}`,
          );

          const preSignedUrlData = preSignedUrlResponse.data;
          if (preSignedUrlResponse.status !== 200 || !preSignedUrlData.url) {
            throw new Error("Failed to get presigned URL");
          }

          const uploadResponse = await fetch(preSignedUrlData.url, {
            method: "PUT",
            body: file,
            // MIMEタイプが取得できない場合はバイナリとして扱う
            headers: {
              "Content-Type": file.type || "binary/octet-stream",
            },
          });

          if (uploadResponse.status !== 200) {
            throw new Error("Failed to upload file");
          }

          await ApiClient.post(`/api/v1/projects/${projectId}/attachments`, {
            file_name: file.name,
            object_key: preSignedUrlData.object_key,
            attachment_type_id: body.attachmentTypeId,
          });
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      }),
    );
  },
  update(
    projectId: ProjectId,
    id: ProjectAttachmentId,
    body: AttachmentUpdateRequest,
  ): Promise<AttachmentUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = {
      is_publish_to_mobile: body.isPublishToMobile,
    };
    return ApiClient.put(
      `/api/v1/projects/${projectId}/attachments/${id}`,
      requestBody,
      config,
    ).then((res) => res.data);
  },
  destroy(projectId: ProjectId, id: ProjectAttachmentId): Promise<AttachmentDestroyResponse> {
    return ApiClient.delete(`/api/v1/projects/${projectId}/attachments/${id}`).then(
      (res) => res.data,
    );
  },
  excelFilePreview(projectId: ProjectId, id: ProjectAttachmentId): Promise<{ url: string }> {
    return ApiClient.get(`/api/v1/projects/${projectId}/attachments/${id}/excel_file_preview`).then(
      (res) => res.data,
    );
  },
};
