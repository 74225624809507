import { useEffect, useState } from "react";
import { Autocomplete, Button, Checkbox, TextField } from "@mui/material";
import { ScheduleId } from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { User } from "data-access/repositories/user/user.dto";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { styles } from "./styles";
import { CustomModal } from "../custom-modal";

interface Props {
  isOpen: boolean;
  onClose: (isOpen: false, scheduleId: ScheduleId, assignments: User[]) => void;
  scheduleId: ScheduleId;
  assignments: User[];
  fetchIndexKey: string;
}

export const SelectAssignmentsModal = (props: Props) => {
  const { isOpen, onClose, scheduleId, assignments, fetchIndexKey } = props;
  const classes = styles();
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const [formState, setFormState] = useState<Array<User>>([]);
  const activeUsers = mainState.users.filter((user) => !user.is_deactivate);

  useEffect(() => {
    if (isOpen) {
      setFormState(assignments);
    }
  }, [isOpen]);

  const handleChange = (_: React.SyntheticEvent<Element, Event>, newValue: User[]) => {
    setFormState(newValue);
  };

  const handleSubmit = async () => {
    try {
      const userIds = formState.map((user) => user.id);
      await scheduleRepository.update(scheduleId, false, { userIds });
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("予定の参加者を変更しました"));
    } catch {
      dispatch(mainOperations.updateErrorMessage("予定の参加者を変更できませんでした"));
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose(false, 0 as ScheduleId, []);
    setFormState([]);
  };

  return (
    <CustomModal
      title="参加者を編集"
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "160px" }}>
            キャンセル
          </Button>
          <Button onClick={handleSubmit} variant="contained" sx={{ width: "160px", ml: "16px" }}>
            変更
          </Button>
        </>
      }
    >
      <Autocomplete
        multiple
        options={activeUsers || []}
        value={formState || []}
        onChange={handleChange}
        disableCloseOnSelect
        disableClearable
        fullWidth
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label="参加者を選択" variant="standard" />}
        className={classes.memberAutocomplete}
      />
    </CustomModal>
  );
};
