import { forwardRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Backdrop, CircularProgress, Box, Button, Typography } from "@mui/material";
import { projectTypeRepository } from "data-access/repositories/project_type/project_type.repository";
import { theme } from "extensions/theme";
import useSWR from "swr";
import { ProjectTypeModal } from "./form-modal";
import { ProjectTypeTable } from "./table";

const ProjectType = forwardRef<HTMLDivElement>((_, ref) => {
  const [isNew, setNew] = useState<boolean>(false);

  const projectTypesFetchIndexKey = "/api/v1/project_types";
  const { data, isLoading } = useSWR(projectTypesFetchIndexKey, projectTypeRepository.index);

  if (!data) {
    return (
      <>
        <Backdrop
          sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
          open={isLoading}
          invisible
        >
          <CircularProgress />
        </Backdrop>
      </>
    );
  }

  return (
    <>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>案件タイプ</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setNew(true)}
          sx={{ minWidth: "12rem" }}
        >
          案件タイプを新規作成
        </Button>
      </Box>

      <Box>
        <ProjectTypeTable />
      </Box>

      <ProjectTypeModal open={setNew} isOpen={isNew} />
    </>
  );
});

ProjectType.displayName = "ProjectType";

export default ProjectType;
