import { styled } from "@mui/material/styles";

interface StyleWrapperProps {
  viewType: "dayGridMonth" | "resourceTimelineWeek" | "resourceTimelineDay";
}

export const StyleWrapper = styled("div")<StyleWrapperProps>`
  ${({ viewType, theme }) => `
  // 土日のstyle変更
  .fc-day-sat {
    color: ${theme.palette.blue[500]};
  }
  
  .fc-col-header-cell.fc-day-sat,
  .fc-timeline-slot-label.fc-day-sat {
    background-color: ${theme.palette.blue[100]};
  }

  .fc-day-sun {
    color: ${theme.palette.red[500]};
  }

  .fc-col-header-cell.fc-day-sun,
  .fc-timeline-slot-label.fc-day-sun {
    background-color: ${theme.palette.red[100]};
  }

  // 祝日のstyle変更
  .holiday {
    visibility: hidden;
    height: 0;
  }
  .fc-daygrid-day-frame:has(.holiday) .fc-daygrid-day-number {
    color: ${theme.palette.red[500]};
  }

  // リソースの縦幅を変更
  .fc-resource {
    padding-bottom: 20px;
  }

  // ドラッグした時のstyleの範囲を変更(週・日表示)
  .fc-highlight {
    bottom: -20px;
  }

  // ドラッグした時のstyleの範囲を変更(月表示)
  .fc-daygrid-bg-harness .fc-highlight {
    bottom: 0px;
  }

  // 月表示の月切り替えstyle
  .fc .fc-toolbar-chunk {
    display: flex;
  }

  // 年月日
  .fc .fc-toolbar-title {
    width: ${(() => {
      switch (viewType) {
        case "resourceTimelineWeek":
          return "300px";
        case "resourceTimelineDay":
          return "250px";
        case "dayGridMonth":
          return "140px";
      }
    })()};
    text-align: center;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  /**
   * v5以降あたりから以下のような対応が必要らしい。
   * 参考: https://github.com/fullcalendar/fullcalendar/issues/6407
   */
  .fc-col-header,
  .fc-daygrid-body,
  .fc-scrollgrid-sync-table,
  .fc-timegrid-body, 
  .fc-timeline-slots table,
  .fc-timegrid-body table {
      width: 100% !important;
  }

  .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    background-color: ${theme.palette.primary.main};
    border-radius: 15px;
    color: ${theme.palette.grayScale[0]};
  }

  .fc .fc-miniCalendarOpenButton-button {
    background-color: ${theme.palette.grayScale[0]} !important;
    border-radius: 4px;
    color: ${theme.palette.primary.main};
  }

  .fc .fc-button-primary {
    background-color: ${theme.palette.primary.main};
  }
  
  // prev,nextボタン
  .fc .fc-next-button,
  .fc .fc-prev-button {
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.grayScale[0]};
    border: none;
    font-size: 20px;
    margin: 0;
  }

  .fc .fc-next-button:focus,
  .fc .fc-prev-button:focus {
    box-shadow: none;
  }

  .fc .fc-todayButton-button {
    background-color: ${theme.palette.grayScale[0]};
    color: ${theme.palette.primary.main};
  }

  .fc .fc-eventSearchButton-button {
    background-color: ${theme.palette.grayScale[0]};
    color: ${theme.palette.primary.main};
    height: 40px;
  }

  .fc .fc-button-group > .fc-button {
    background-color: ${theme.palette.grayScale[0]};
    color: ${theme.palette.primary.main};
  }
  .fc .fc-button-group > .fc-button.fc-button-active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.grayScale[0]};
  }

  // 予定作成ボタンclass
  .fc .fc-createScheduleButton-button {
    padding: 0 20px;
  }

  // 参加者未定リソースのclass
  .not_assigned {
    color: red;
  }

  .fc-button-group .fc-button {
    width: 70px;
  }

  .fc-h-event .fc-event-time {
    overflow: visible;
  }

  .fc-event-title {
    white-space: normal;
  }

  .fc-event-main-frame {
    flex-direction: column;
  }

  .fc-header-toolbar {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    z-index: 99;
    height: 75px;
    background-color: ${theme.palette.grayScale[0]};
  }

  .fc .fc-toolbar.fc-header-toolbar {
    padding-bottom: 100px;
    padding-top: 50px;
    margin-bottom: 0;
  }

  /* 固定 */
  thead[role='rowgroup'] {
    position: -webkit-sticky;
    position: sticky;
    top: 200px;
    z-index: 99;
  }
`}
`;
