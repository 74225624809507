import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    modal: {
      "& .MuiDialogContent-root": {
        border: "none",
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grayScale[0],
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      "& .MuiSvgIcon-root": {
        color: theme.palette.grayScale[0],
      },
    },
    header: {
      position: "sticky",
      top: 0,
      zIndex: 1100,
      display: "flex",
      alignItems: "baseline",
      gap: "8px",
      backgroundColor: theme.palette.customPrimary[50],
      padding: "12px",
      margin: "16px 0",
    },
  }),
);
