import { ApiClient } from "data-access/ApiClient";

export const participationHoursByWorkReportRepository = {
  csvDownload(startDate: Date, endDate: Date): Promise<Blob> {
    const params = {
      start_date: startDate.toString(),
      end_date: endDate.toString(),
    };
    return ApiClient.get("/api/v1/work_report_aggregations/participation_hours", {
      params,
      responseType: "blob",
    }).then((res) => res.data);
  },
};
