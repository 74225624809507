import { Close } from "@mui/icons-material";
import { Dialog, Box, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { styles } from "./styles";

type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  subTitle?: string;
  scroll?: "body" | "paper";
  fullWidth?: boolean;
  maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl";
  maxHeight?: string;
  minHeight?: string;
  footer?: React.ReactNode;
};

export const CustomModal = ({
  fullWidth = true,
  maxWidth = "md",
  scroll = "paper",
  ...props
}: Props) => {
  const classes = styles({ ...props });

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={scroll}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={classes.modal}
    >
      <DialogTitle id="custom-modal-title" className={classes.title}>
        {props.title}
        {props.subTitle && <br />}
        {props.subTitle}

        <IconButton className={classes.closeButton} onClick={props.onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers className={classes.content}>
        {props.children}
      </DialogContent>

      <Box className={classes.footer}>{props.footer}</Box>
    </Dialog>
  );
};
