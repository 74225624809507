import { Dispatch } from "@reduxjs/toolkit";
import { BillingUpdateRequest, BillingId } from "data-access/repositories/billing/billing.dto";
import {
  InvoiceCreateRequest,
  BillingInvoiceId,
} from "data-access/repositories/billing/invoice/invoice.dto";
import { WarrantyCreateRequest } from "data-access/repositories/billing/warranty/warranty.dto";
import {
  showBilling,
  downloadTemplateBilling,
  updateBilling,
  showInvoice,
  createInvoice,
  destroyInvoice,
  showWarranty,
  createWarranty,
  destroyWarranty,
} from "./actions";
import { billingSidebarSlice } from "./slice";
import { AppDispatch } from "../store";
export const billingSidebarOperations = {
  showBilling: (id: BillingId) => (dispatch: AppDispatch) => {
    dispatch(showBilling(id));
  },
  downloadTemplateBilling: (id: BillingId, fileName: string) => (dispatch: AppDispatch) => {
    dispatch(downloadTemplateBilling({ id, fileName }));
  },
  showInvoice: (billingId: BillingId) => (dispatch: AppDispatch) => {
    dispatch(showInvoice({ billingId }));
  },
  createInvoice: (billingId: BillingId, body: InvoiceCreateRequest) => (dispatch: AppDispatch) => {
    dispatch(createInvoice({ billingId, body }));
  },
  destroyInvoice: (billingId: BillingId, id: BillingInvoiceId) => (dispatch: AppDispatch) => {
    dispatch(destroyInvoice({ billingId, id }));
  },
  showWarranty: (billingId: BillingId) => (dispatch: AppDispatch) => {
    dispatch(showWarranty({ billingId }));
  },
  createWarranty:
    (billingId: BillingId, body: WarrantyCreateRequest) => (dispatch: AppDispatch) => {
      dispatch(createWarranty({ billingId, body }));
    },
  destroyWarranty: (billingId: BillingId, id: number) => (dispatch: AppDispatch) => {
    dispatch(destroyWarranty({ billingId, id }));
  },
  updateBilling: (id: BillingId, body: BillingUpdateRequest) => (dispatch: AppDispatch) => {
    dispatch(updateBilling({ id, body }));
  },
  updateStatusType: (value: string) => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.updateStatusType({ value }));
  },
  updateBillingDate: (value: string) => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.updateBillingDate({ value }));
  },
  updateCode: (value: string) => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.updateCode({ value }));
  },
  updateSubject: (value: string) => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.updateSubject({ value }));
  },
  updateMailingName: (value: string) => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.updateMailingName({ value }));
  },
  updateMailingAddress: (value: string) => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.updateMailingAddress({ value }));
  },
  updateAdjustedAmount: (value: number) => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.updateAdjustedAmount({ value }));
  },
  updateNote: (value: string) => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.updateNote({ value }));
  },
  updateIsWarrantyAttach: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.updateIsWarrantyAttach({ value }));
  },
  setUploadedInvoices:
    (files: File[]) =>
    (
      dispatch: Dispatch<{
        payload: { files: File[] };
        type: string;
      }>,
    ) => {
      dispatch(billingSidebarSlice.actions.setUploadedInvoices({ files }));
    },
  setUploadedWarranties:
    (files: File[]) =>
    (
      dispatch: Dispatch<{
        payload: { files: File[] };
        type: string;
      }>,
    ) => {
      dispatch(billingSidebarSlice.actions.setUploadedWarranties({ files }));
    },
  updateSuccessMessage:
    (value: string) =>
    (
      dispatch: Dispatch<{
        payload: {
          value: string;
        };
        type: string;
      }>,
    ) => {
      dispatch(billingSidebarSlice.actions.updateSuccessMessage({ value }));
    },
  updateErrorMessage:
    (value: string) =>
    (
      dispatch: Dispatch<{
        payload: {
          value: string;
        };
        type: string;
      }>,
    ) => {
      dispatch(billingSidebarSlice.actions.updateErrorMessage({ value }));
    },
  open: () => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.open());
  },
  close: () => (dispatch: AppDispatch) => {
    dispatch(billingSidebarSlice.actions.close());
  },
};
