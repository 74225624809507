import { ApiClient } from "data-access/ApiClient";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";

export const sharablePhotoRepository = {
  index(projectUuid: string): Promise<PhotoResponse[]> {
    return ApiClient.get(`/api/v1/sharable_photos/${projectUuid}/photos`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};
