import { Typography } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  onClick: () => void;
  expanded: boolean;
}

export const ExpandToggleLabel = (props: Props) => {
  return (
    <Typography
      onClick={props.onClick}
      sx={{
        fontSize: "14px",
        mt: "16px",
        lineHeight: "20.27px",
        textAlign: "center",
        color: theme.palette.grayScale[700],
        cursor: "pointer",
      }}
    >
      {props.expanded ? "少なく表示" : "すべて表示"}
    </Typography>
  );
};
