import { createStyles, makeStyles } from "@mui/styles";

export const styles = makeStyles(() =>
  createStyles({
    sideBarDrawer: {
      "& .MuiDrawer-paper": {
        maxWidth: "1020px",
        padding: "0 1rem",
        boxShadow: "-1px 0px 15px 0px rgba(0,0,0,0.3)",
      },
    },
  }),
);
