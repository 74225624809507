import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRowsPerPageFromLocalStorage } from "data-access/local_storage/table_filter/local_storage";
import { PageInfo } from "data-access/repositories/page_info/page_info.dto";
import { ProjectCollection } from "data-access/repositories/project/project.dto";
import { getIndexProject, destroyProject, downloadProject } from "./actions";
import type { RootState } from "../store";

export interface ProjectTableState {
  isLoading: boolean;
  isSubmitted: boolean;
  isDownloaded: boolean;
  isDestroyed: boolean;
  loadingMessage: string;
  successMessage: string;
  errorMessage: string;
  projects: ProjectCollection[];
  pageInfo: PageInfo;
  currentPage: number;
  rowsPerPage: number;
  downloadProjects?: ProjectCollection[];
}

const initialState: ProjectTableState = {
  isLoading: false,
  isSubmitted: false,
  isDownloaded: false,
  isDestroyed: false,
  loadingMessage: "",
  successMessage: "",
  errorMessage: "",
  projects: [],
  pageInfo: {
    page: 0,
    pages: 1,
    items: 0,
    count: 0,
  },
  currentPage: 1,
  rowsPerPage: getRowsPerPageFromLocalStorage("projectTableRowsPerPage"),
};

export const projectTableSlice = createSlice({
  name: "projectTable",
  initialState,
  reducers: {
    updateCurrentPage: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      currentPage: action.payload.value,
    }),
    updateRowsPerPage: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      rowsPerPage: action.payload.value,
    }),
    updateSuccessMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      successMessage: action.payload.value,
    }),
    updateErrorMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      errorMessage: action.payload.value,
    }),
    updateIsDownloaded: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isDownloaded: action.payload.value,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getIndexProject.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIndexProject.fulfilled, (state, action) => {
      state.projects = action.payload.data;
      state.pageInfo = action.payload.pagination;
      state.isLoading = false;
    });
    builder.addCase(getIndexProject.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(destroyProject.pending, (state) => {
      state.isLoading = true;
      state.loadingMessage = "案件を削除中です";
      state.isDestroyed = false;
    });
    builder.addCase(destroyProject.fulfilled, (state) => {
      state.isLoading = false;
      state.loadingMessage = "";
      state.isDestroyed = true;
      state.successMessage = "案件を削除しました";
    });
    builder.addCase(destroyProject.rejected, (state, action) => {
      state.isLoading = false;
      state.loadingMessage = "";
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(downloadProject.pending, (state) => {
      state.isLoading = true;
      state.isDownloaded = false;
      state.loadingMessage = "案件をダウンロード中です";
    });
    builder.addCase(downloadProject.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isDownloaded = true;
      state.downloadProjects = action.payload;
      state.loadingMessage = "";
      state.successMessage = "案件をダウンロードしました";
    });
    builder.addCase(downloadProject.rejected, (state, action) => {
      state.isLoading = false;
      state.loadingMessage = "";
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
  },
});

export const selectProjectTable = (state: RootState) => state.projectTable;
export default projectTableSlice.reducer;
