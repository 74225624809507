import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const styles = makeStyles(() =>
  createStyles({
    textField: {
      width: "30rem",
      "& .MuiInputBase-input": {
        fontWeight: "bold",
      },
    },
  }),
);
