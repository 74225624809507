import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialProjectResponse, Project } from "data-access/repositories/project/project.dto";
import type { RootState } from "../store";

export type ProjectTableHeaderState = {
  isSubmit: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  respondProject: Project;
  countChanges: number;
  successMessage: string;
  errorMessage: string;
};

const initialState: ProjectTableHeaderState = {
  isSubmit: false,
  isLoading: false,
  isSubmitted: false,
  respondProject: initialProjectResponse,
  countChanges: 0,
  successMessage: "",
  errorMessage: "",
};

export const projectTableHeaderSlice = createSlice({
  name: "projectTableHeader",
  initialState,
  reducers: {
    updateIsSubmitted: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isSubmitted: action.payload.value,
    }),
    addCountChanges: (state) => ({
      ...state,
      countChanges: state.countChanges + 1,
    }),
  },
});

export const selectProjectTableHeader = (state: RootState) => state.projectTableHeader;
export default projectTableHeaderSlice.reducer;
