import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { PrimaryCategoryIndexResponse } from "./primary_category.dto";
import { WorkReportId } from "../work_report.dto";

export const primaryCategoryRepository = {
  index(workReportId: WorkReportId): Promise<PrimaryCategoryIndexResponse> {
    return ApiClient.get(`/api/v1/work_reports/${workReportId}/primary_categories`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};
