export type SubscriberType = "fcm" | "email";

export const subscriberTypes = [
  {
    value: "fcm" as SubscriberType,
    name: "プッシュ",
  },
  {
    value: "email" as SubscriberType,
    name: "メール",
  },
];
