import { CalenderMain } from "components/organisms/calendar";
import { Layout } from "components/templates/layout";

export default function Calender() {
  return (
    <Layout isOverflowAllowed>
      <CalenderMain />
    </Layout>
  );
}
