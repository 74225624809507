import { theme } from "extensions/theme";

export const SiteManagerIcon = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.main,
        width: "18px",
        height: "18px",
        borderRadius: "9px",
        fontWeight: "500",
        color: theme.palette.grayScale[0],
        marginLeft: "4px",
      }}
    >
      責
    </div>
  );
};
