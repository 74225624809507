import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "extensions/theme";

export const styles = makeStyles(() =>
  createStyles({
    whole: {
      position: "sticky",
      top: 0,
      paddingTop: "5rem",
      paddingBottom: "1rem",
      backgroundColor: theme.palette.grayScale[0],
      zIndex: "999",
      boxShadow: "0px 5px 5px -5px rgba(0,0,0,0.5)",
    },
    subjectField: {
      width: "30rem",
      "& .MuiInputBase-input": {
        fontWeight: "bold",
      },
    },
    codeBox: {
      width: "150px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    codeField: {
      "& .MuiInputBase-input": {
        padding: "0.5rem 1rem",
      },
    },
    dateBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
  }),
);
