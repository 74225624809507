import { ApiClient } from "data-access/ApiClient";
import { ProjectScheduleRequest, ProjectScheduleResponse } from "./schedule.dto";

export const projectScheduleRepository = {
  index(body: ProjectScheduleRequest): Promise<ProjectScheduleResponse> {
    const params = {
      page: body.page,
      limit: body.limit,
    };
    return ApiClient.get(`/api/v2/projects/${body.id}/schedules`, {
      params,
    }).then((res) => res.data);
  },
};
