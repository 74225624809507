import React, { ElementRef, useEffect, useRef, useState } from "react";
import { LinkItEmail, LinkItUrl /**linkIt, UrlComponent*/ } from "react-linkify-it";
import { useNavigate, useParams } from "react-router-dom";
import { Close, Edit } from "@mui/icons-material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import RepeatIcon from "@mui/icons-material/Repeat";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Dialog,
  Box,
  Divider,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  Chip,
  Button,
} from "@mui/material";
import { TitleBadge } from "components/badge/title-badge";
import { ConfirmSchedule } from "components/icon/confirm-schedule-icon";
import { DeleteIcon } from "components/icon/delete-icon";
import { LinkIcon } from "components/icon/link-icon";
import { UnconfirmSchedule } from "components/icon/unconfirm-schedule-icon";
import { TagLabel } from "components/label/tag-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { ProjectBlock } from "components/molecules/project-block";
import { WorkReportBlock } from "components/molecules/work-report-block";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { ClientId } from "data-access/repositories/client/client.dto";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { ProjectCreateRequest, ProjectId } from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import {
  ProjectInfo,
  Schedule,
  ScheduleId,
  ScheduleList,
  initialScheduleResponse,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import {
  WorkReport,
  WorkReportCreateRequest,
  WorkReportId,
  WorkReportState,
} from "data-access/repositories/work_report/work_report.dto";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { theme } from "extensions/theme";
import { useScrollToPosition } from "hooks/useScrollToPosition";
import { calendarOperations } from "store/calendar/operations";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { scheduleDeleteConfirmDialogOperations } from "store/schedule-delete-confirm-dialog/operations";
import useSWR, { mutate } from "swr";
import Cookies from "universal-cookie";
import { DEFAULT_ID } from "utils/constant";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { timeRange } from "utils/timeRange";
import { styles } from "./styles";
import { formatDateUtil } from "../../../utils/formatDateUtil";
import { LinkProjectFormModal } from "../schedule-link-project-modal";
import { WorkReportCreateModal } from "../work-report/create-modal";
import { WorkReportDetailModal } from "../work-report/detail-modal";

interface Props {
  setIsLoading: (isLoading: boolean) => void;
  onDelete: (isOpen: boolean, id: ScheduleId) => void;
  noDatedEvents: ScheduleList[];
  fetchIndexKey: string;
  fetchNoDatedEventKey: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsEdit: (isOpen: boolean) => void;
  setRecurrenceDialog: (
    isOpen: boolean,
    id: ScheduleId,
    type: "delete" | "update",
    onUpdateFunc?: (withFutures: boolean) => Promise<void>,
  ) => void;
}

export const ScheduleDetailModal = (props: Props) => {
  const {
    setIsLoading,
    onDelete,
    setRecurrenceDialog,
    noDatedEvents,
    fetchIndexKey,
    fetchNoDatedEventKey,
    isOpen,
    setIsOpen,
    setIsEdit,
  } = props;
  const classes = styles();
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const navigate = useNavigate();
  const { id } = useParams();
  const scheduleId: ScheduleId = Number(id) as ScheduleId;
  const { scrollToSavedPosition } = useScrollToPosition("calendar");
  const [state, setState] = useState<Schedule>(initialScheduleResponse);
  const [isOpenWorkReportModal, setIsOpenWorkReportModal] = useState<boolean>(false);
  const [isOpenLinkProjectModal, setIsOpenLinkProjectModal] = useState<boolean>(false);

  const {
    data,
    mutate: showMutate,
    isLoading,
  } = useSWR(isOpen ? `/api/v1/schedules/${scheduleId}` : null, () =>
    scheduleRepository.show(scheduleId),
  );

  const [workReportShow, setWorkReportShow] = useState<{
    isOpen: boolean;
    id: WorkReportId | undefined;
  }>({
    isOpen: false,
    id: undefined,
  });

  const [workReport, setWorkReport] = useState<WorkReport | undefined>(state.work_report);

  useEffect(() => {
    if (!isOpen || !data) return;
    setState(data);
    setWorkReport(data.work_report);
  }, [data]);

  const scheduleAssignMembers = state.users.filter(
    (user, index, self) => self.findIndex((e) => e.id === user.id) === index,
  );

  const handleClickDelete = () => {
    if (state.recurrence_rule) {
      setRecurrenceDialog(true, state.id as ScheduleId, "delete");
      return;
    }

    const cookies = new Cookies();
    const isDeleteConfirmNotShowAgain = cookies.get("isScheduleDeleteConfirmNotShowAgain");
    if (isDeleteConfirmNotShowAgain) {
      dispatch(calendarOperations.destroy(state.id as ScheduleId, !!state.recurrence_rule));
      mutate(
        fetchNoDatedEventKey,
        noDatedEvents?.filter((event) => event.id !== Number(state.id)),
        false,
      );
      setIsOpen(false);
      navigate("/calendar");
    } else {
      onDelete(true, state.id);
      dispatch(
        scheduleDeleteConfirmDialogOperations.setObject({
          id: state.id,
          name: state.name,
        }),
      );
      dispatch(scheduleDeleteConfirmDialogOperations.open());
    }
  };

  const handleClose = () => {
    navigate("/calendar");
    setWorkReport(undefined);
    setState(initialScheduleResponse);
    setIsOpen(false);
  };

  const handleClickEdit = () => {
    navigate(`/calendar/${state.id}`);
    setIsEdit(true);
  };

  const handleIsConfirm = async (value: boolean) => {
    const updateSchedule = async (withFutures: boolean) => {
      setIsLoading(true);
      try {
        await scheduleRepository.update(scheduleId, withFutures, { isConfirmed: value });
        mutate(fetchIndexKey);
        dispatch(
          mainOperations.updateSuccessMessage(
            value ? "予定を確定しました" : "予定を未確定にしました",
          ),
        );
      } catch {
        dispatch(mainOperations.updateErrorMessage("予定を変更できませんでした"));
      } finally {
        setIsLoading(false);
        scrollToSavedPosition();
        handleClose();
      }
    };

    if (state.recurrence_rule) {
      setRecurrenceDialog(true, state.id as ScheduleId, "update", updateSchedule);
    } else {
      await updateSchedule(false);
    }
  };

  const handleProjectUnlink = async () => {
    const updateSchedule = async (withFutures: boolean) => {
      try {
        await scheduleRepository.update(scheduleId, withFutures, { projectId: 0 });
        showMutate();
        dispatch(mainOperations.updateSuccessMessage("案件を解除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    };

    if (state.recurrence_rule) {
      setRecurrenceDialog(true, state.id as ScheduleId, "update", updateSchedule);
    } else {
      await updateSchedule(false);
    }
  };

  const switchProjectLinkModal = (isOpen: boolean) => {
    setIsOpenLinkProjectModal(isOpen);
  };

  const handleClickProject = () => {
    if (!state.project) return;
    openURLInNewTab(`projects/${state.project.id}`);
  };

  /**
   * ここで案件紐付けする
   * @param projectId あるときは既存のプロジェクト紐付け
   * @param projectInfo projectIdがないときはprojectInfoで新規作成
   */
  const handleLinkProject = async (projectId: ProjectId, projectInfo: ProjectInfo) => {
    const updateSchedule = async (withFutures: boolean) => {
      try {
        let linkProjectId: ProjectId;
        if (projectId != DEFAULT_ID) {
          linkProjectId = projectId;
        } else {
          // 顧客の新規作成があれば行う
          let clientId: ClientId | undefined;
          if (projectInfo.client && projectInfo.client.name) {
            const createClientRes = await clientRepository.create({
              name: projectInfo.client.name,
            });
            clientId = createClientRes.id;
          } else {
            clientId = projectInfo?.clientId;
          }
          // 案件の新規作成
          const projectCreateRes = await projectRepository.create({
            ...projectInfo,
            projectStatusTypeId: mainState.projectStatusTypes[0].id,
            projectTypeId: projectInfo?.projectTypeId,
            clientId: clientId,
          } as ProjectCreateRequest);
          linkProjectId = projectCreateRes.id;
        }
        await scheduleRepository.update(scheduleId, withFutures, {
          projectId: linkProjectId,
        });
        setIsOpenLinkProjectModal(false);
        await showMutate();
        dispatch(mainOperations.updateSuccessMessage("案件を紐づけました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    };
    if (state.recurrence_rule) {
      setRecurrenceDialog(true, state.id as ScheduleId, "update", updateSchedule);
    } else {
      await updateSchedule(false);
    }
  };

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDeleteForWorkReport = async (id: WorkReportId) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();

    if (res) {
      try {
        await workReportRepository.destroy(id as WorkReportId);
        showMutate();
        setWorkReportShow({ isOpen: false, id: undefined });
        dispatch(mainOperations.updateSuccessMessage("作業日報を削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const handleClickWorkReport = () => {
    setIsOpenWorkReportModal(true);
  };

  const handleSubmitWorkReport = async (body: WorkReportState): Promise<WorkReport> => {
    try {
      const res: WorkReport = await workReportRepository.create({
        ...body,
        scheduleId: scheduleId,
      } as WorkReportCreateRequest);
      showMutate();
      dispatch(mainOperations.updateSuccessMessage("作業日報を作成しました"));
      return res;
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      throw error;
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <Dialog open={isOpen} onClose={handleClose}>
        <Backdrop
          sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
          open={isLoading}
          invisible
        >
          <CircularProgress />
        </Backdrop>
        <Box
          sx={{
            p: "0 1rem 1rem",
            width: "30rem",
          }}
        >
          {/*ヘッダー*/}
          <Box className={classes.headerFixed}>
            <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "12px",
                  width: "100%",
                  pt: "16px",
                }}
              >
                <div>
                  <IconButton onClick={handleClickDelete} sx={{ color: theme.palette.red[500] }}>
                    <DeleteIcon />
                  </IconButton>
                  <Typography
                    sx={{ color: theme.palette.red[500], fontSize: "0.8rem" }}
                    align="center"
                  >
                    削除
                  </Typography>
                </div>
                {state.is_confirmed ? (
                  <div>
                    <IconButton onClick={() => handleIsConfirm(false)}>
                      <UnconfirmSchedule size={24} />
                    </IconButton>
                    <Typography sx={{ fontSize: "0.8rem" }} align="center">
                      未確定
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <IconButton onClick={() => handleIsConfirm(true)}>
                      <ConfirmSchedule size={24} />
                    </IconButton>
                    <Typography sx={{ fontSize: "0.8rem" }} align="center">
                      確定
                    </Typography>
                  </div>
                )}
                <div>
                  <IconButton onClick={handleClickEdit}>
                    <Edit />
                  </IconButton>
                  <Typography sx={{ fontSize: "0.8rem" }} align="center">
                    編集
                  </Typography>
                </div>
                <div>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                  <Typography sx={{ fontSize: "0.8rem" }} align="center">
                    閉じる
                  </Typography>
                </div>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <TagLabel
                tagName={state.schedule_type.name}
                colorNumber={state.schedule_type.color_number}
              />
              <Typography className={classes.scheduleName}>{state.name}</Typography>
            </Box>
            <Divider sx={{ mt: 2, mb: 3 }} />
          </Box>

          <div style={{ display: "flex", alignItems: "center", marginBottom: "28px", gap: "16px" }}>
            <ScheduleIcon fontSize="small" />
            <Stack spacing="8px">
              <Typography sx={{ fontWeight: "500" }}>
                {timeRange("yyyy年MM月dd日 HH:mm", state.start_time, state.end_time)}
              </Typography>
              {state.recurrence_rule && (
                <div style={{ display: "flex", gap: "4px", color: theme.palette.grayScale[700] }}>
                  <RepeatIcon fontSize="small" />
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {state.recurrence_rule.label}
                  </Typography>
                </div>
              )}
            </Stack>
          </div>

          <div style={{ display: "flex", alignItems: "center", marginBottom: "28px", gap: "16px" }}>
            <Person2OutlinedIcon fontSize="small" />
            <Stack spacing={2}>
              {state.site_manager && (
                <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                  <Typography sx={{ fontWeight: "500" }}>{state.site_manager.name}</Typography>
                  <TitleBadge title="当日現場責任者" />
                </div>
              )}
              <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
                {scheduleAssignMembers.length > 0
                  ? scheduleAssignMembers.map((user, index) => {
                      return (
                        <Chip
                          key={index}
                          label={user.name}
                          variant="outlined"
                          color="primary"
                          sx={{ fontWeight: "500" }}
                        />
                      );
                    })
                  : "参加者なし"}
              </Box>
            </Stack>
          </div>

          <div style={{ display: "flex", marginBottom: "28px", gap: "16px" }}>
            <FormatAlignLeftIcon fontSize="small" />
            <Typography sx={{ wordWrap: "break-word", width: "90%" }}>
              {state.note ? (
                state.note.split("\n").map((str: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <LinkItUrl>
                        <LinkItEmail>
                          {str}
                          <br />
                        </LinkItEmail>
                      </LinkItUrl>
                    </React.Fragment>
                  );
                })
              ) : (
                <Typography sx={{ fontWeight: "500", lineHeight: "16px" }}>メモなし</Typography>
              )}
            </Typography>
          </div>

          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mb: "12px" }} className={classes.blockTitle}>
              案件
            </Typography>
            {!state.project ? (
              <>
                <Typography>案件はありません</Typography>
                <p>
                  <Button
                    variant="contained"
                    startIcon={<LinkIcon size={16} color="white" />}
                    onClick={() => switchProjectLinkModal(true)}
                    sx={{ minWidth: "12rem" }}
                  >
                    案件を紐づける
                  </Button>
                </p>
              </>
            ) : (
              <ProjectBlock
                project={state.project}
                onClick={handleClickProject}
                onClickUnlink={handleProjectUnlink}
              />
            )}
          </Box>

          {state.project && (
            <>
              <Box sx={{ mt: 3 }}>
                <CustomFormLabel labelName="作業日報" />
                {workReport != undefined ? (
                  <WorkReportBlock workReport={workReport} setShow={setWorkReportShow} />
                ) : (
                  <>
                    <Typography>作業日報はありません</Typography>
                    <p>
                      <Button
                        variant="contained"
                        startIcon={<AssignmentOutlinedIcon />}
                        onClick={handleClickWorkReport}
                        sx={{ minWidth: "12rem" }}
                      >
                        作業日報を作成する
                      </Button>
                    </p>
                  </>
                )}
              </Box>

              <WorkReportDetailModal
                show={workReportShow}
                setShow={setWorkReportShow}
                onDelete={handleDeleteForWorkReport}
              />
              <WorkReportCreateModal
                isOpen={isOpenWorkReportModal}
                setIsOpen={setIsOpenWorkReportModal}
                handleSubmit={handleSubmitWorkReport}
                projectId={state.project.id}
                fetchIndexKeys={[fetchIndexKey]}
              />
            </>
          )}
          <LinkProjectFormModal
            isOpen={isOpenLinkProjectModal}
            scheduleName={state.name}
            onClose={() => switchProjectLinkModal(false)}
            onClickSubmit={handleLinkProject}
          />

          <Box sx={{ mt: 3, display: "flex", flexDirection: "row" }}>
            <Box>
              <Typography component="span" className={classes.blockTitle}>
                作成
              </Typography>
              <Box sx={{ mt: "0.5rem" }}>
                <Typography component="span">{state.created_by.name}</Typography>
                <Typography component="span" sx={{ ml: "0.5rem" }}>
                  {formatDateUtil(state.created_at, "yyyy/MM/dd(E) HH:mm")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 3, display: "flex", flexDirection: "row" }}>
            <Box>
              <Typography component="span" className={classes.blockTitle}>
                最終更新
              </Typography>
              <Box sx={{ mt: "0.5rem" }}>
                <Typography component="span">{state.updated_by.name}</Typography>
                <Typography component="span" sx={{ ml: "0.5rem" }}>
                  {formatDateUtil(state.updated_at, "yyyy/MM/dd(E) HH:mm")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
