import { DayOfWeek } from "data-access/repositories/company_user/company_user.dto";

// DatePicker・calendarStartDayの曜日はDayOfWeekの数字とは異なる（日月火水木金土）
export const convertToCalendarStartDay = (n: DayOfWeek): DayOfWeek => {
  switch (n) {
    case 6:
      return 0;
    default:
      return (n + 1) as DayOfWeek;
  }
};
