import { Box } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  tagName: string;
  colorNumber: string;
  sx?: object;
}

export const TagLabel = (props: Props) => {
  return (
    <Box
      component="span"
      sx={{
        fontSize: "14px",
        borderRadius: "4px",
        p: "6px 10px",
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: `#${props.colorNumber}`,
        color: theme.palette.grayScale[0],
        ...props.sx,
      }}
    >
      {props.tagName}
    </Box>
  );
};
