import { Range } from "react-date-range";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store/store";

export interface ScheduleSearchResultHeaderState {
  isLoading: boolean;
  errorMessage: string;
  scheduleDateRange: Range;
  scheduleDateRangeLabel: string;
  keyword: string;
}

const initialState: ScheduleSearchResultHeaderState = {
  isLoading: false,
  errorMessage: "",
  scheduleDateRange: {
    startDate: undefined,
    endDate: undefined,
    key: "selection",
  },
  scheduleDateRangeLabel: "すべての期間",
  keyword: "",
};

export const scheduleSearchResultHeaderSlice = createSlice({
  name: "scheduleSearchResultHeader",
  initialState,
  reducers: {
    updateScheduleDateRange: (state, action: PayloadAction<{ value: Range }>) => ({
      ...state,
      scheduleDateRange: action.payload.value,
    }),
    updateScheduleDateRangeLabel: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      scheduleDateRangeLabel: action.payload.value,
    }),
    updateKeyword: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      keyword: action.payload.value,
    }),
  },
  extraReducers: () => {},
});

export const selectScheduleSearchResultHeader = (state: RootState) =>
  state.scheduleSearchResultHeader;
export default scheduleSearchResultHeaderSlice.reducer;
