import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { AmountTextField } from "components/atoms/amount-text-field";
import { DeleteIcon } from "components/icon/delete-icon";
import { theme } from "extensions/theme";
import { OutsourcingCost, OutsourcingCostId } from "features/cost/types/outsourcing_cost.dto";
import { styles } from "./styles";

interface Props {
  outsourcingCost: OutsourcingCost;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: OutsourcingCostId,
  ) => void;
  onBlur: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    id: OutsourcingCostId,
  ) => void;
  onSelect: (e: SelectChangeEvent<string>, id: OutsourcingCostId) => void;
  onDelete: (id: OutsourcingCostId) => void;
}
export const OutsourcingCostCard = (props: Props) => {
  const { outsourcingCost, onChange, onBlur, onSelect, onDelete } = props;
  const classes = styles();

  return (
    <Box className={classes.card}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          {outsourcingCost.employee.name}
        </Typography>
        <IconButton onClick={() => onDelete(outsourcingCost.id)}>
          <DeleteIcon size={14} color={theme.palette.grayScale[700]} />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "end", gap: "8px" }}>
          <div>
            <Typography className={classes.label} fontSize="12px">
              単価
            </Typography>
            <AmountTextField
              name="unitPrice"
              width="140px"
              value={outsourcingCost.unitPrice}
              onChange={(e) => onChange(e, outsourcingCost.id)}
              blurFunc={(e) => onBlur(e, outsourcingCost.id)}
              step={100}
              isBgWhite
            />
          </div>
          <div>
            <Typography className={classes.label} fontSize="12px">
              単位
            </Typography>
            <TextField
              name="quantity"
              value={outsourcingCost.quantity}
              type="number"
              onChange={(e) => onChange(e, outsourcingCost.id)}
              onBlur={(e) => onBlur(e, outsourcingCost.id)}
              inputProps={{ step: 0.5 }}
              sx={{
                width: "72px",
                bgcolor: theme.palette.grayScale[0],
                "& .MuiOutlinedInput-root": {
                  height: "38px",
                },
              }}
              disabled={outsourcingCost.unitType.value === "set"}
            />
          </div>
          <Select
            name="unitType"
            value={outsourcingCost.unitType.value}
            onChange={(e) => onSelect(e, outsourcingCost.id)}
            sx={{ height: "38px" }}
          >
            <MenuItem value="manpower">人工</MenuItem>
            <MenuItem value="set">式</MenuItem>
          </Select>
        </Box>
        <Typography sx={{ fontSize: "14px", mr: "10px" }}>
          ¥{(outsourcingCost.unitPrice * outsourcingCost.quantity).toLocaleString()}
        </Typography>
      </Box>
    </Box>
  );
};
