import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Billing,
  BillingUpdateRequest,
  initialBilling,
  initialBillingUpdateRequest,
} from "data-access/repositories/billing/billing.dto";
import { InvoiceResponse } from "data-access/repositories/billing/invoice/invoice.dto";
import { WarrantyShowResponse } from "data-access/repositories/billing/warranty/warranty.dto";
import { ObjectKeys } from "types/objectKeys";
import {
  showBilling,
  downloadTemplateBilling,
  updateBilling,
  showInvoice,
  createInvoice,
  destroyInvoice,
  showWarranty,
  createWarranty,
  destroyWarranty,
} from "./actions";
import type { RootState } from "../store";

export interface BillingSidebarState extends ObjectKeys {
  isOpen: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  isInvoiceSubmitted: boolean;
  isWarrantySubmitted: boolean;
  successMessage: string;
  errorMessage: string;
  form: BillingUpdateRequest;
  billing: Billing;
  invoices: InvoiceResponse[];
  warranties: WarrantyShowResponse[];
  uploadedInvoices: File[];
  uploadedWarranties: File[];
}

const initialState: BillingSidebarState = {
  isOpen: false,
  isLoading: false,
  isSubmitted: false,
  isInvoiceSubmitted: false,
  isWarrantySubmitted: false,
  successMessage: "",
  errorMessage: "",
  form: initialBillingUpdateRequest,
  billing: initialBilling,
  invoices: [],
  warranties: [],
  uploadedInvoices: [],
  uploadedWarranties: [],
};

export const billingSidebarSlice = createSlice({
  name: "billingSidebar",
  initialState,
  reducers: {
    updateStatusType: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        statusType: action.payload.value,
      },
    }),
    updateBillingDate: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        billingDate: action.payload.value,
      },
    }),
    updateCode: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        code: action.payload.value,
      },
    }),
    updateSubject: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        subject: action.payload.value,
      },
    }),
    updateMailingName: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        mailingName: action.payload.value,
      },
    }),
    updateMailingAddress: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        mailingAddress: action.payload.value,
      },
    }),
    updateAdjustedAmount: (state, action: PayloadAction<{ value: number }>) => ({
      ...state,
      form: {
        ...state.form,
        adjustedAmount: action.payload.value,
      },
    }),
    updateNote: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      form: {
        ...state.form,
        note: action.payload.value,
      },
    }),
    updateIsWarrantyAttach: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      form: {
        ...state.form,
        isWarrantyAttach: action.payload.value,
      },
    }),
    setUploadedInvoices: (state, action: PayloadAction<{ files: File[] }>) => {
      if (action.payload.files) {
        state.uploadedInvoices = action.payload.files;
      }
      return state;
    },
    setUploadedWarranties: (state, action: PayloadAction<{ files: File[] }>) => {
      if (action.payload.files) {
        state.uploadedWarranties = action.payload.files;
      }
      return state;
    },
    updateSuccessMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      successMessage: action.payload.value,
    }),
    updateErrorMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      errorMessage: action.payload.value,
    }),
    open: (state) => ({
      ...state,
      isOpen: true,
    }),
    close: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(showBilling.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(showBilling.fulfilled, (state, action) => {
      state.isLoading = false;
      state.uploadedInvoices = [];
      state.uploadedWarranties = [];
      const billing = action.payload;
      state.billing = billing;

      state.form.subject = billing.subject;
      state.form.code = billing.code;
      state.form.mailingName = billing.mailing_name;
      state.form.mailingAddress = billing.mailing_address;
      state.form.billingDate = billing.billing_date;
      state.form.adjustedAmount = billing.adjusted_amount;
      state.form.note = billing.note;
      state.form.statusType = billing.status_type;
      state.form.isWarrantyAttach = billing.is_warranty_attach;
    });
    builder.addCase(showBilling.rejected, (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(downloadTemplateBilling.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadTemplateBilling.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadTemplateBilling.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(updateBilling.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(updateBilling.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.billing = action.payload;
      state.successMessage = "請求を更新しました";
    });
    builder.addCase(updateBilling.rejected, (state, action) => {
      state.isLoading = false;
      state.uploadedInvoices = [];
      state.uploadedWarranties = [];
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(showInvoice.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(showInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invoices = action.payload;
    });
    builder.addCase(showInvoice.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(createInvoice.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
      state.isInvoiceSubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(createInvoice.fulfilled, (state) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.isInvoiceSubmitted = true;
      state.successMessage = "請求書をアップロードしました";
    });
    builder.addCase(createInvoice.rejected, (state, action) => {
      state.isLoading = false;
      state.uploadedInvoices = [];
      state.uploadedWarranties = [];
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(destroyInvoice.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
      state.isInvoiceSubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(destroyInvoice.fulfilled, (state) => {
      state.isSubmitted = true;
      state.isInvoiceSubmitted = true;
      state.successMessage = "請求書を削除しました";
    });
    builder.addCase(destroyInvoice.rejected, (state, action) => {
      state.isLoading = false;
      state.uploadedInvoices = [];
      state.uploadedWarranties = [];
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(showWarranty.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(showWarranty.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.warranties = action.payload;
    });
    builder.addCase(showWarranty.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(createWarranty.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
      state.isWarrantySubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(createWarranty.fulfilled, (state) => {
      state.isLoading = false;
      state.isSubmitted = true;
      state.isWarrantySubmitted = true;
      state.successMessage = "保証書をアップロードしました";
    });
    builder.addCase(createWarranty.rejected, (state, action) => {
      state.isLoading = false;
      state.uploadedInvoices = [];
      state.uploadedWarranties = [];
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(destroyWarranty.pending, (state) => {
      state.isLoading = true;
      state.isSubmitted = false;
      state.isWarrantySubmitted = false;
      state.errorMessage = "";
    });
    builder.addCase(destroyWarranty.fulfilled, (state) => {
      state.isSubmitted = true;
      state.isWarrantySubmitted = true;
      state.successMessage = "保証書を削除しました";
    });
    builder.addCase(destroyWarranty.rejected, (state, action) => {
      state.isLoading = false;
      state.uploadedInvoices = [];
      state.uploadedWarranties = [];
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
  },
});

export const selectBillingSidebar = (state: RootState) => state.billingSidebar;
export default billingSidebarSlice.reducer;
