import { ObjectKeys } from "types/objectKeys";
import { Brand } from "../brand";
import { PageInfo } from "../page_info/page_info.dto";
import { RelatedProject } from "../project/project.dto";

export type ClientId = Brand<number, "ClientId">;

export interface Client extends ObjectKeys {
  id: ClientId;
  code: string;
  name: string;
  honorific_title: string;
  honorific_title_i18n: string;
  name_kana: string;
  postal_code: string;
  address: string;
  address_second: string;
  billing_person_name: string;
  billing_person_position: string;
  billing_person_section: string;
  phone_number: string;
  phone_number_second: string;
  email: string;
  fax_number: string;
  note: string;
  billing_closing_date_type: string;
  billing_closing_date_type_i18n: string;
  deposit_method_type: string;
  deposit_method_type_i18n: string;
  deposit_month_type: string;
  deposit_month_type_i18n: string;
  deposit_date_type: string;
  deposit_date_type_i18n: string;
}

export interface ClientIndexRequest {
  ids?: ClientId[];
  keyword?: string;
  page?: number;
  limit?: number;
  orderBy?: "recently_created";
}

export interface ClientIndexResponse {
  data: Client[];
  pagination: PageInfo;
}

export interface ClientRequest extends ObjectKeys {
  code?: string;
  name?: string;
  honorificTitle?: string;
  nameKana?: string;
  postalCode?: string;
  address?: string;
  addressSecond?: string;
  billingPersonName?: string;
  billingPersonPosition?: string;
  billingPersonSection?: string;
  phoneNumber?: string;
  phoneNumberSecond?: string;
  email?: string;
  faxNumber?: string;
  note?: string;
  billingClosingDateType?: string;
  depositMethodType?: string;
  depositMonthType?: string;
  depositDateType?: string;
}

export type ClientCreateResponse = Client;

export type ClientShowResponse = Client;

export type ClientUpdateResponse = Client;

export type ClientProject = RelatedProject;

export const initialClientResponse: Client = {
  id: 0 as ClientId,
  code: "",
  name: "",
  honorific_title: "",
  honorific_title_i18n: "",
  name_kana: "",
  postal_code: "",
  address: "",
  address_second: "",
  billing_person_name: "",
  billing_person_position: "",
  billing_person_section: "",
  phone_number: "",
  phone_number_second: "",
  email: "",
  fax_number: "",
  note: "",
  billing_closing_date_type: "",
  billing_closing_date_type_i18n: "",
  deposit_method_type: "",
  deposit_method_type_i18n: "",
  deposit_month_type: "",
  deposit_month_type_i18n: "",
  deposit_date_type: "",
  deposit_date_type_i18n: "",
};
