import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface ImagePreviewModalState {
  isOpen: boolean;
  image: {
    name: string;
    url: string;
  };
}

const initialState: ImagePreviewModalState = {
  isOpen: false,
  image: {
    name: "",
    url: "",
  },
};

export const imagePreviewModalSlice = createSlice({
  name: "imagePreviewModal",
  initialState,
  reducers: {
    setImage: (state, action: PayloadAction<{ image: { name: string; url: string } }>) => ({
      ...state,
      image: action.payload.image,
    }),
    open: (state) => ({
      ...state,
      isOpen: true,
    }),
    close: () => initialState,
  },
  extraReducers: () => {},
});

export const selectImagePreviewModal = (state: RootState) => state.imagePreviewModal;
export default imagePreviewModalSlice.reducer;
