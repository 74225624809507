import { ApiClient } from "data-access/ApiClient";

interface SalesTaxCalculatorResult {
  amount: number;
  tax: number;
  tax_rate: number;
}

export const salesTaxCalculator = async (amount: number): Promise<SalesTaxCalculatorResult> => {
  const res = await ApiClient.get<SalesTaxCalculatorResult>("/api/v1/sales_tax_calculator/tax", {
    params: { amount },
  });
  return res.data;
};
