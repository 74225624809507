import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Layout } from "components/templates/layout";
import { theme } from "extensions/theme";
import { ScheduleTypeFormModal } from "features/company-settings/schedule-settings/form-modal";
import { ScheduleTypeTable } from "features/company-settings/schedule-settings/table";

const TableHeader = styled(Box)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.palette.primary.main};
    opacity: 0.5;
  }
`;

export const ScheduleSettings = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <Layout>
      <ScheduleTypeFormModal open={setModalOpen} isOpen={isModalOpen} />
      <TableHeader
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>予定タイプ</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setModalOpen(true)}
        >
          予定タイプを新規作成
        </Button>
      </TableHeader>

      <Box sx={{ height: "70vh" }}>
        <ScheduleTypeTable />
      </Box>
    </Layout>
  );
};
