import { ElementRef, useCallback, useMemo, useRef } from "react";
import { Box, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowOrderChangeParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { DeleteIcon } from "components/icon/delete-icon";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import {
  ProjectStatusTypeId,
  ProjectStatusType,
} from "data-access/repositories/project_status_type/project_status_type.dto";
import { projectStatusTypeRepository } from "data-access/repositories/project_status_type/project_status_type.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { BaseColorPatterns } from "utils/constant";
import { styles } from "./styles";

export const ProjectStatusTypeTable = () => {
  const dispatch = useAppDispatch();
  const classes = styles();

  const { data, mutate, isValidating } = useSWR(
    "/api/v2/project_status_types",
    projectStatusTypeRepository.index,
  );
  const projectStatusTypes = data && [...data];

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    id: ProjectStatusTypeId,
  ) => {
    try {
      await projectStatusTypeRepository.update(id, {
        colorNumber: e.target.value,
      });
      projectStatusTypes && mutate();
      dispatch(mainOperations.getProjectStatusTypes());
      dispatch(mainOperations.updateSuccessMessage("カラーを変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const handleDelete = async (id: ProjectStatusTypeId) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();

    if (res) {
      try {
        await projectStatusTypeRepository.destroy(id);
        projectStatusTypes &&
          mutate(
            projectStatusTypes.filter(
              (projectStatusType: ProjectStatusType) => projectStatusType.id !== id,
            ),
          );
        dispatch(mainOperations.getProjectStatusTypes());
        dispatch(mainOperations.updateSuccessMessage("案件ステータスを削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    try {
      await projectStatusTypeRepository.update(params.row.id, {
        displayOrderPosition: params.targetIndex,
      });
      projectStatusTypes && mutate();
      dispatch(mainOperations.getProjectStatusTypes());
      dispatch(mainOperations.updateSuccessMessage("案件ステータスの順番を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleEditName = useCallback(async (params: GridRowModel) => {
    try {
      await projectStatusTypeRepository.update(params.id, {
        name: params.name,
      });
      projectStatusTypes &&
        mutate(
          projectStatusTypes.map((projectStatusType: ProjectStatusType) => {
            if (projectStatusType.id === params.id) {
              projectStatusType.name = params.name;
            }
            return projectStatusType;
          }),
        );
      dispatch(mainOperations.getProjectStatusTypes());
      dispatch(mainOperations.updateSuccessMessage("案件ステータス名を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    return params;
  }, []);

  const columns = useMemo(() => {
    const headers: GridColDef[] = [];
    headers.push({
      field: "name",
      headerName: "案件ステータス名",
      minWidth: 300,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "color_number",
      headerName: "カラー",
      minWidth: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Select
            id="color"
            name="color"
            className={classes.tableSelectBox}
            value={`${params.row.color_number}`}
            variant="standard"
            onChange={(e) => handleChange(e, params.row.id)}
            displayEmpty
            sx={{
              "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
            }}
          >
            {BaseColorPatterns.map((color, index) => (
              <MenuItem value={color} key={index}>
                <Box
                  sx={{
                    width: 90,
                    height: 24,
                    backgroundColor: `#${color}`,
                    borderRadius: "4px",
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </>
      ),
    });
    headers.push({
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<DeleteIcon color={theme.palette.grayScale[700]} size={20} />}
          label="Delete"
          onClick={() => handleDelete(Number(params.row.id) as ProjectStatusTypeId)}
        />,
      ],
    });
    return headers;
  }, []);

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <CustomizedDataGridPro
        columnHeaderHeight={40}
        columns={columns}
        rows={projectStatusTypes || []}
        loading={isValidating}
        hideFooter
        rowReordering
        disableRowSelectionOnClick
        onRowOrderChange={handleRowOrderChange}
        processRowUpdate={handleEditName}
      />
    </>
  );
};
