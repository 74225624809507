import { ChangeEventHandler, useCallback } from "react";
import { Clear, Search } from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";
import { theme } from "extensions/theme";
import { useStyles } from "./styles";

interface Props {
  id: string;
  name: string;
  placeholder?: string;
  variant?: "outlined" | "filled" | "standard" | undefined;
  backgroundColor?: string;
  width?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onSubmit?: () => void;
  sx?: object;
}

export const SearchTextField = ({
  backgroundColor = theme.palette.grayScale[0],
  variant = "outlined",
  placeholder = "キーワードから探す",
  ...props
}: Props) => {
  const classes = useStyles({
    width: props.width,
    backgroundColor,
  });
  const handleChange = useCallback(
    (e: any) => {
      props.onChange(e);
    },
    [props.onChange],
  );

  const handleClear = async () => {
    const event = {
      target: {
        name: props.name,
        value: "",
      },
    } as React.ChangeEvent<HTMLInputElement>;
    await props.onChange(event);
    props.onSubmit && props.onSubmit();
  };

  return (
    <TextField
      id={props.id}
      name={props.name}
      className={classes.root}
      value={props.value}
      onChange={handleChange}
      variant={variant}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Button
              startIcon={<Clear fontSize="small" />}
              onClick={handleClear}
              color="primary"
              sx={{ p: 0, minWidth: "auto" }}
              disabled={!props.value}
            />
          </InputAdornment>
        ),
      }}
      sx={{
        width: props.width,
        backgroundColor,
        ...props.sx,
        "& .MuiInputBase-root": { pr: "0px" },
      }}
    />
  );
};
