import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { ArrowForwardIos } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import ja from "date-fns/locale/ja";
import { theme } from "extensions/theme";
import { attendanceEmployeeRepository } from "features/attendance/api/monthly_record/attendance.employee.repository";
import { attendanceRepository } from "features/attendance/api/monthly_record/attendance.repository";
import { EmployeeId } from "features/attendance/types/index.dto";
import { AttendanceEmployee } from "features/attendance/types/monthly_record/attendance.employee.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { downloadBlob } from "utils/downloadBlob";
import { handleError } from "utils/errorHandler";
import { formatDateUtil } from "utils/formatDateUtil";
import { Sidebar } from "./sidebar";
import { Table } from "./table";
registerLocale("ja", { ...ja, options: { ...ja.options, weekStartsOn: 1 } });

export const Monthly = () => {
  const dispatch = useAppDispatch();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [sidebarState, setSidebarState] = useState<{
    isOpen: boolean;
    id: EmployeeId;
  }>({ isOpen: false, id: 0 as EmployeeId });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [date, setDate] = useState<Date>(new Date());
  const yearMonth = formatDateUtil(date.toString(), "yyyy-MM");

  const { data: attendances, isValidating: isAttendancesValidating } = useSWR(
    `/api/v1/attendances/monthly_records/${yearMonth}/employees/employees.csv`,
    () => attendanceEmployeeRepository.index(yearMonth),
    { revalidateOnFocus: false },
  );

  const { data, isValidating, mutate } = useSWR(
    `/api/v1/attendances/monthly_records/${yearMonth}`,
    () => attendanceRepository.show(yearMonth),
    { revalidateOnFocus: false },
  );

  const decrementDate = () => {
    setDate(new Date(date.setMonth(date.getMonth() - 1)));
  };

  const incrementDate = () => {
    setDate(new Date(date.setMonth(date.getMonth() + 1)));
  };

  const handleClick = (params: AttendanceEmployee) => {
    setSidebarState({ isOpen: true, id: params.id });
  };

  type DownloadType = "by_day" | "by_month";
  const handleDownload = async (downloadType: DownloadType) => {
    try {
      let blob: Blob;
      let fileName: string;

      dispatch(mainOperations.updateSuccessMessage("CSVをダウンロード中..."));
      if (downloadType === "by_day") {
        blob = await attendanceEmployeeRepository.csvDownloadByDay(yearMonth);
        fileName = `日次集計_${yearMonth}.csv`;
      } else {
        blob = await attendanceEmployeeRepository.csvDownload(yearMonth);
        fileName = `月次集計_${yearMonth}.csv`;
      }

      downloadBlob(blob, fileName);
      dispatch(mainOperations.updateSuccessMessage("CSVをダウンロードしました"));
    } catch {
      dispatch(mainOperations.updateErrorMessage("CSVダウンロードに失敗しました"));
    } finally {
      setIsOpen(false);
    }
  };

  const handleAggregateClose = async () => {
    try {
      await attendanceRepository.update(yearMonth, !data?.isClosed);
      mutate();
      dispatch(
        mainOperations.updateSuccessMessage(
          data?.isClosed ? "締め処理を解除しました" : "締め処理を実行しました",
        ),
      );
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "締め処理に失敗しました",
      );
    }
  };

  const handleCloseSidebar = () => {
    setSidebarState({ isOpen: false, id: 0 as EmployeeId });
  };

  const handleClickExportButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
        open={isAttendancesValidating || isValidating}
        invisible
      >
        <CircularProgress />
      </Backdrop>

      <Popover
        open={isDatePickerOpen}
        onClose={() => setIsDatePickerOpen(false)}
        sx={{ position: "fixed", left: "550px", top: "100px" }}
      >
        <DatePicker
          locale="ja"
          selected={date}
          onChange={(date: Date | null) => {
            if (date) {
              setDate(date);
              setIsDatePickerOpen(false);
            }
          }}
          inline
          showMonthYearPicker
        />
      </Popover>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={decrementDate}>
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
          <Box sx={{ m: "0 8px", cursor: "default" }}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", userSelect: "none" }}>
              {formatDateUtil(date, "yyyy年MM月")}
            </Typography>
          </Box>
          <IconButton onClick={incrementDate}>
            <ArrowForwardIos fontSize="small" />
          </IconButton>
          <Box
            sx={{
              ml: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: data?.isClosed ? theme.palette.secondary.main : theme.palette.primary.main,
              color: theme.palette.grayScale[0],
              borderRadius: "100px",
              width: "80px",
              fontSize: "12px",
              fontWeight: "500",
              p: "6px 12px",
            }}
          >
            {data?.isClosed ? "締め完了" : "記録中"}
          </Box>
          <IconButton onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
            <CalendarTodayIcon color="primary" />
          </IconButton>
        </div>
        <div>
          <Button
            onClick={handleAggregateClose}
            variant="contained"
            color={data?.isClosed ? "error" : "primary"}
            sx={{ height: "36px", mr: "16px" }}
          >
            {data?.isClosed ? "締めを解除" : "記録を締める"}
          </Button>
          <Button
            variant="outlined"
            endIcon={<ExpandMoreIcon />}
            onClick={handleClickExportButton}
            sx={{ height: "36px", width: "170px" }}
          >
            CSVエクスポート
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  width: "170px",
                },
              },
            }}
          >
            <MenuItem onClick={() => handleDownload("by_day")}>日次集計</MenuItem>
            <MenuItem onClick={() => handleDownload("by_month")}>月次集計</MenuItem>
          </Menu>
        </div>
      </div>

      <Table attendances={attendances || []} onClick={handleClick} />

      <Sidebar
        isOpen={sidebarState.isOpen}
        onClose={handleCloseSidebar}
        id={sidebarState.id}
        date={date}
      />
    </>
  );
};
