import { forwardRef } from "react";
import { Box, Typography } from "@mui/material";
import { EmployeeTable } from "./table";

const Employee = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>自社メンバー</Typography>
      </Box>
      <Box>
        <EmployeeTable />
      </Box>
    </>
  );
});

Employee.displayName = "Employee";

export default Employee;
