import {
  ProjectDownloadRequest,
  ProjectId,
  ProjectIndexRequest,
} from "data-access/repositories/project/project.dto";
import { getIndexProject, destroyProject, downloadProject } from "./actions";
import { projectTableSlice } from "./slice";
import { AppDispatch } from "../store";

export const projectTableOperations = {
  getIndex: (query: ProjectIndexRequest) => (dispatch: AppDispatch) => {
    dispatch(getIndexProject(query));
  },
  destroy: (id: ProjectId) => (dispatch: AppDispatch) => {
    dispatch(destroyProject(id));
  },
  updateCurrentPage: (value: number) => (dispatch: AppDispatch) => {
    dispatch(projectTableSlice.actions.updateCurrentPage({ value }));
  },
  updateRowsPerPage: (value: number) => (dispatch: AppDispatch) => {
    dispatch(projectTableSlice.actions.updateRowsPerPage({ value }));
  },
  updateSuccessMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectTableSlice.actions.updateSuccessMessage({ value }));
  },
  updateErrorMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectTableSlice.actions.updateErrorMessage({ value }));
  },
  downloadProject: (query: ProjectDownloadRequest) => (dispatch: AppDispatch) => {
    dispatch(downloadProject(query));
  },
  updateIsDownloaded: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(projectTableSlice.actions.updateIsDownloaded({ value }));
  },
};
