import { Dispatch } from "@reduxjs/toolkit";
import {
  AttachmentCreateRequest,
  AttachmentUpdateRequest,
  ProjectAttachmentId,
} from "data-access/repositories/project/attachment/attachment.dto";
import { EstimateCreateRequest } from "data-access/repositories/project/estimate/estimate.dto";
import {
  ProjectCreateRequest,
  ProjectUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { ProjectTodoRequest, ProjectTodoId } from "data-access/repositories/project/todo/todo.dto";
import { ProjectStatusTypeId } from "data-access/repositories/project_status_type/project_status_type.dto";
import {
  showProject,
  updateProject,
  getPostalCode,
  createProjectAttachment,
  updateProjectAttachment,
  destroyProjectAttachment,
  createProjectEstimate,
  createProject,
  duplicateProject,
  getAddress,
  showProjectTodo,
  createProjectTodo,
  updateTodoDone,
  updateTodoUndone,
  deleteProjectTodo,
  updateProjectTodo,
} from "./actions";
import { projectSidebarSlice } from "./slice";
import { AppDispatch } from "../store";

export const projectSidebarOperations = {
  createProject: (body: ProjectCreateRequest) => (dispatch: AppDispatch) => {
    dispatch(createProject({ body }));
  },
  showProject: (id: ProjectId) => (dispatch: AppDispatch) => {
    dispatch(showProject(id));
  },
  showProjectTodo: (id: ProjectId) => (dispatch: AppDispatch) => {
    dispatch(showProjectTodo(id));
  },
  createProjectTodo:
    (projectId: ProjectId, body: ProjectTodoRequest) => (dispatch: AppDispatch) => {
      dispatch(createProjectTodo({ projectId, body }));
    },
  updateProjectTodo:
    (projectId: ProjectId, todoId: ProjectTodoId, body: ProjectTodoRequest) =>
    (dispatch: AppDispatch) => {
      dispatch(updateProjectTodo({ projectId, todoId, body }));
    },
  deleteProjectTodo: (projectId: ProjectId, todoId: ProjectTodoId) => (dispatch: AppDispatch) => {
    dispatch(deleteProjectTodo({ projectId, todoId }));
  },
  updateTodoDone: (projectId: ProjectId, todoId: ProjectTodoId) => (dispatch: AppDispatch) => {
    dispatch(updateTodoDone({ projectId, todoId }));
  },
  updateTodoUndone: (projectId: ProjectId, todoId: ProjectTodoId) => (dispatch: AppDispatch) => {
    dispatch(updateTodoUndone({ projectId, todoId }));
  },
  duplicate: (id: ProjectId) => (dispatch: AppDispatch) => {
    dispatch(duplicateProject(id));
  },
  createProjectAttachment:
    (projectId: ProjectId, body: AttachmentCreateRequest) => (dispatch: AppDispatch) => {
      dispatch(createProjectAttachment({ projectId, body }));
    },
  updateProjectAttachment:
    (projectId: ProjectId, id: ProjectAttachmentId, body: AttachmentUpdateRequest) =>
    (dispatch: AppDispatch) => {
      dispatch(updateProjectAttachment({ projectId, id, body }));
    },
  destroyProjectAttachment:
    (projectId: ProjectId, id: ProjectAttachmentId) => (dispatch: AppDispatch) => {
      dispatch(destroyProjectAttachment({ projectId, id }));
    },
  createProjectEstimate:
    (projectId: ProjectId, body: EstimateCreateRequest) => (dispatch: AppDispatch) => {
      dispatch(createProjectEstimate({ projectId, body }));
    },
  updateSalesAmount: (value: number) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateSalesAmount({ value }));
  },
  updateSalesTax: (value: number) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateSalesTax({ value }));
  },
  updateName: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateName({ value }));
  },
  updatePersonName: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updatePersonName({ value }));
  },
  updateRequester: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateRequester({ value }));
  },
  updateRequestedByPersonName: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateRequestedByPersonName({ value }));
  },
  updateBillingPrecaution: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateBillingPrecaution({ value }));
  },
  updatePostalCode: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updatePostalCode({ value }));
  },
  updateAddress: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateAddress({ value }));
  },
  updateAddressSecond: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateAddressSecond({ value }));
  },
  updatePhoneNumber: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updatePhoneNumber({ value }));
  },
  updatePhoneNumberSecond: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updatePhoneNumberSecond({ value }));
  },
  updateFaxNumber: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateFaxNumber({ value }));
  },
  updateEmail: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateEmail({ value }));
  },
  updateManagerId: (value: number) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateManagerId({ value }));
  },
  updateNote: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateNote({ value }));
  },
  updateReportContent: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateReportContent({ value }));
  },
  updateIsEditMode: (id: number, value: boolean) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateIsEditMode({ id, value }));
  },
  updateIsCreated: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateIsCreated({ value }));
  },
  updateTodoTagType: (id: number, value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateTodoTagType({ id, value }));
  },
  updateTodoContent: (id: number, value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateTodoContent({ id, value }));
  },
  updateProjectTypeId: (value: number) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateProjectTypeId({ value }));
  },
  updateProjectStatusTypeId: (value: ProjectStatusTypeId) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateProjectStatusTypeId({ value }));
  },
  updateInquiredById: (value: number) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateInquiredById({ value }));
  },
  updateCode: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateCode({ value }));
  },
  updateInquiryNumber: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateInquiryNumber({ value }));
  },
  updateIsManageBillingOnAnotherSystem: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateIsManageBillingOnAnotherSystem({ value }));
  },
  updateIsNoBill: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateIsNoBill({ value }));
  },
  updateIsSupportedByMaker: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateIsSupportedByMaker({ value }));
  },
  updateInquiredDate: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateInquiredDate({ value }));
  },
  updateOrderedDate: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateOrderedDate({ value }));
  },
  updateExpectedCompleteDate: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateExpectedCompleteDate({ value }));
  },
  updateCompletedDate: (value: string) => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.updateCompletedDate({ value }));
  },
  getPostalCode: (address: string) => (dispatch: Dispatch<any>) => {
    dispatch(getPostalCode(address));
  },
  getAddress: (postalCode: string) => (dispatch: AppDispatch) => {
    dispatch(getAddress(postalCode));
  },
  updateProject: (id: ProjectId, body: ProjectUpdateRequest) => (dispatch: AppDispatch) => {
    dispatch(updateProject({ id, body }));
  },
  setUploadedEstimates:
    (files: File[]) =>
    (
      dispatch: Dispatch<{
        payload: { files: File[] };
        type: string;
      }>,
    ) => {
      dispatch(projectSidebarSlice.actions.setUploadedEstimates({ files }));
    },
  setUploadedAttachments:
    (files: File[], attachmentTypeId: string) =>
    (
      dispatch: Dispatch<{
        payload: { files: File[]; attachmentTypeId: string };
        type: string;
      }>,
    ) => {
      dispatch(
        projectSidebarSlice.actions.setUploadedAttachments({
          files,
          attachmentTypeId,
        }),
      );
    },
  updateSuccessMessage:
    (value: string) =>
    (
      dispatch: Dispatch<{
        payload: {
          value: string;
        };
        type: string;
      }>,
    ) => {
      dispatch(projectSidebarSlice.actions.updateSuccessMessage({ value }));
    },
  updateErrorMessage:
    (value: string) =>
    (
      dispatch: Dispatch<{
        payload: {
          value: string;
        };
        type: string;
      }>,
    ) => {
      dispatch(projectSidebarSlice.actions.updateErrorMessage({ value }));
    },
  open: () => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.open());
  },
  close: () => (dispatch: AppDispatch) => {
    dispatch(projectSidebarSlice.actions.close());
  },
};
