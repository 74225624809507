import { GridValidRowModel } from "@mui/x-data-grid";
import { CSVGridColDef } from "pages/projects";

type CsvEntityName = "billing" | "project";

export const downloadDataGridRowsCsv = <RowModel extends GridValidRowModel>(
  entityName: CsvEntityName,
  rowModels: RowModel[],
  headers: CSVGridColDef[],
) => {
  const header = headers.map((header) => header.headerName).join(",");
  const rows = rowModels.map((model) =>
    headers
      .map((header) => {
        if (header.csvRender) {
          return header.csvRender(model[header.field], model);
        } else {
          return model[header.field];
        }
      })
      .join(","),
  );
  const csvData = [header, ...rows].join("\n");
  // BOMを付与することでExcelで開いた時の文字化けを防止
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csvData], { type: "text/csv;charset=utf-8;" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const filename = `${entityName}_${year}${month}${day}.csv`;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};
