import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Button, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { theme } from "extensions/theme";

interface Props {
  sx?: SxProps;
  onUnlink: () => void;
}

export const UnlinkButton = (props: Props) => {
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        props.onUnlink();
      }}
      sx={{
        ...props.sx,
        display: "flex",
        alignItems: "center",
        border: `solid 1px ${theme.palette.grayScale[700]}`,
        borderRadius: "4px",
        p: "4px",
        width: "100px",
      }}
    >
      <LinkOffIcon
        sx={{
          color: theme.palette.grayScale[700],
          mr: "6px",
          width: "14.43px",
          height: "14.43px",
        }}
      />
      <Typography
        sx={{
          color: theme.palette.grayScale[700],
          fontSize: "12px",
        }}
      >
        紐付け解除
      </Typography>
    </Button>
  );
};
