import { Box } from "@mui/material";

interface Props {
  name: string;
  colorNumber: string;
  sx?: object;
}

export const GroupLabel = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 24,
        width: "fit-content",
        px: "10px",
        fontWeight: "bold",
        fontSize: "14px",
        borderRadius: "4px",
        backgroundColor: `#${props.colorNumber}`,
        ...props.sx,
      }}
    >
      {props.name}
    </Box>
  );
};
