interface ClientInfo {
  name: string;
  billing_person_name: string;
  honorific_title: string;
  billing_person_section: string;
  billing_person_position: string;
}
export const mailingName = (client: ClientInfo): string => {
  let here = `${client.name} ${
    client.billing_person_name === "" ? changeHonorificTitle(client.honorific_title) : ""
  }`;
  if (client.billing_person_section !== "") {
    here += `\n${client.billing_person_section}`;
  }
  if (client.billing_person_position !== "") {
    here += `\n${client.billing_person_position}`;
  }
  if (client.billing_person_name !== "") {
    here += `\n${client.billing_person_name} 様`;
  }
  return here;
};

const changeHonorificTitle = (honorific_title: string) => {
  switch (honorific_title) {
    case "様":
    case "御中":
    case "なし":
      return honorific_title;
    case "to_person":
      return "様";
    case "to_group":
      return "御中";
    case "nothing":
      return "";
  }
};
