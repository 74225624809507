import { createBrowserRouter } from "react-router-dom";
import { AggregationDownload } from "features/company-settings/aggregation-download";
import { AttendanceSetting } from "features/company-settings/attendance-setting";
import { CostSetting } from "features/company-settings/cost-setting";
import { MemberSetting } from "features/company-settings/member-setting";
import { NoticeSetting } from "features/company-settings/notice-setting";
import { ProjectSetting } from "features/company-settings/project-setting";
import { ScheduleSettings } from "features/company-settings/schedule-settings";
import { TemplateSetting } from "features/company-settings/template-setting";
import Attendances from "pages/attendances";
import Billings from "pages/billings";
import { Building } from "pages/building";
import Calendar from "pages/calendar";
import { Client } from "pages/client";
import { ErrorPage404 } from "pages/error/error-page-404";
import { ErrorPage500 } from "pages/error/error-page-500";
import Projects from "pages/projects";
import SalesAggregations from "pages/sales-aggregations";
import ScheduleSearchResult from "pages/schedule-search-result";
import UserSettings from "pages/user-settings";
import WorkReports from "pages/work-reports";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Calendar />,
    errorElement: <ErrorPage404 />,
  },
  {
    path: "/error",
    element: <ErrorPage500 />,
  },
  {
    path: "/calendar",
    element: <Calendar />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/calendar/:id",
    element: <Calendar />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/billings",
    element: <Billings />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/billings/:id",
    element: <Billings />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/projects",
    element: <Projects />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/projects/new",
    element: <Projects />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/projects/:id",
    element: <Projects />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/buildings",
    element: <Building />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/buildings/new",
    element: <Building />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/buildings/:id",
    element: <Building />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/clients",
    element: <Client />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/clients/:id",
    element: <Client />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/clients/new",
    element: <Client />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/clients/new/project/:id",
    element: <Client />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/sales-aggregations",
    element: <SalesAggregations />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/work-reports",
    element: <WorkReports />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/work-reports/:id",
    element: <WorkReports />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/attendances",
    element: <Attendances />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "schedule-search-result",
    element: <ScheduleSearchResult />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/company-settings/schedule-settings",
    element: <ScheduleSettings />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/company-settings/project-settings",
    element: <ProjectSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/company-settings/member-settings",
    element: <MemberSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/company-settings/attendance-settings",
    element: <AttendanceSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/company-settings/cost-settings",
    element: <CostSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/company-settings/notice-settings",
    element: <NoticeSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/company-settings/template-settings",
    element: <TemplateSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/company-settings/aggregation-output",
    element: <AggregationDownload />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/user-settings",
    element: <UserSettings />,
    errorElement: <ErrorPage500 />,
  },
]);
