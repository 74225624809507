import { useState, useImperativeHandle, forwardRef } from "react";
import { ConfirmDialog } from "components/molecules/confirm-dialog";

export type Handle = {
  confirm: () => Promise<boolean>;
};

type Props = { ref: React.RefObject<Handle> };

export const AsyncConfirmDialog = forwardRef<Handle, Props>((_, ref) => {
  const [open, setOpen] = useState(false);

  const [handleAgree, setHandleAgree] = useState<any>(() => {});
  const [handleDisagree, setHandleDisagree] = useState<any>(() => {});
  const [handleClose, setHandleClose] = useState<any>(() => {});
  const resetHandle = () => {
    setHandleAgree(() => {});
    setHandleDisagree(() => {});
    setHandleClose(() => {});
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    confirm: () =>
      new Promise((resolve) => {
        setHandleAgree(() => () => {
          resetHandle();
          resolve(true);
        });
        setHandleDisagree(() => () => {
          resetHandle();
          resolve(false);
        });
        setHandleDisagree(() => () => {
          resetHandle();
          resolve(false);
        });
        setOpen(true);
      }),
  }));
  return (
    <ConfirmDialog
      content="削除しますか？"
      yesButtonText="削除"
      handleNo={handleDisagree}
      handleYes={handleAgree}
      isOpen={open}
      yesButtonColor="error"
      handleClose={handleClose}
    />
  );
});

AsyncConfirmDialog.displayName = "AsyncConfirmDialog";
