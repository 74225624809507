import { theme } from "extensions/theme";

type Props = {
  size?: number;
  color?: string;
};
export const LinkIcon = (props: Props) => {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4459 11.6244L8.08884 13.9814C7.2737 14.7966 6.29141 15.2041 5.14197 15.2041C3.99253 15.2041 3.01063 14.7966 2.19628 13.9814C1.38114 13.1663 0.973573 12.184 0.973574 11.0346C0.973573 9.88513 1.38114 8.90323 2.19628 8.08888L4.5533 5.73186L5.73181 6.91037L3.37479 9.26739C2.88374 9.75844 2.63822 10.3477 2.63822 11.0352C2.63822 11.7226 2.88374 12.3119 3.37479 12.8029C3.86584 13.294 4.45509 13.5395 5.14256 13.5395C5.83002 13.5395 6.41928 13.294 6.91032 12.8029L9.26735 10.4459L10.4459 11.6244ZM6.32107 11.0352L5.14256 9.85664L9.8566 5.1426L11.0351 6.32111L6.32107 11.0352ZM11.6244 10.4459L10.4459 9.26739L12.8029 6.91037C13.2939 6.41932 13.5394 5.83006 13.5394 5.1426C13.5394 4.45514 13.2939 3.86588 12.8029 3.37483C12.3118 2.88379 11.7226 2.63826 11.0351 2.63826C10.3476 2.63826 9.75839 2.88379 9.26735 3.37483L6.91032 5.73186L5.73181 4.55334L8.08884 2.19632C8.90397 1.38118 9.88626 0.973617 11.0357 0.973617C12.1851 0.973617 13.167 1.38119 13.9814 2.19632C14.7965 3.01146 15.2041 3.99375 15.2041 5.14319C15.2041 6.29263 14.7965 7.27453 13.9814 8.08888L11.6244 10.4459Z"
        fill={props.color || theme.palette.primary.main}
      />
    </svg>
  );
};
