import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  NoticeScheduleSettingId,
  NoticeScheduleSettingIndexResponse,
  NoticeScheduleSettingUpdateRequest,
} from "./schedule.dto";

export const scheduleSettingRepository = {
  index(): Promise<NoticeScheduleSettingIndexResponse> {
    return ApiClient.get("/api/v1/notice/schedules/settings").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(id: NoticeScheduleSettingId, body: NoticeScheduleSettingUpdateRequest): Promise<void> {
    return ApiClient.put(`/api/v1/notice/schedules/settings/${id}`, updateBody(body)).then(
      (res) => res.data,
    );
  },
};

const updateBody = (body: NoticeScheduleSettingUpdateRequest) => {
  return {
    schedule_type_ids_for_filter: body.scheduleTypeIdsForFilter,
    display_lead_number: body.displayLeadNumber === undefined ? 1 : body.displayLeadNumber,
    display_lead_unit_type: body.displayLeadUnitType,
    subscriber_types: body.subscriberTypes,
    is_enable: body.isEnable,
  };
};
