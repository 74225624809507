import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ClientServiceReportId } from "../types/client_service_report.dto";
import { ClientServiceReportPageRequest } from "../types/client_service_report_page.dto";
import { ClientServiceReportPageResponse } from "../types/client_service_report_page.dto";
import { ClientServiceReportPageId } from "../types/client_service_report_page.dto";

export const clientServiceReportPageRepository = {
  create(
    id: ClientServiceReportId,
    body: ClientServiceReportPageRequest,
  ): Promise<ClientServiceReportPageResponse> {
    const params = {
      client_service_report_format_page_id: body.clientServiceReportFormatPageId,
    };
    return ApiClient.post(`/api/v1/client_service_reports/${id}/pages`, params).then((res) => {
      return convertKeysToCamelCase(res.data) as ClientServiceReportPageResponse;
    });
  },
  destroy(id: ClientServiceReportId, pageId: ClientServiceReportPageId): Promise<void> {
    return ApiClient.delete(`/api/v1/client_service_reports/${id}/pages/${pageId}`).then(
      (res) => res.data,
    );
  },
};
