import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface deleteConfirmDialogState {
  isOpen: boolean;
  object: {
    id: number | string;
    type: string;
    recordType?: string;
  };
}

const initialState: deleteConfirmDialogState = {
  isOpen: false,
  object: {
    id: 0,
    type: "",
  },
};

export const deleteConfirmDialogSlice = createSlice({
  name: "deleteConfirmDialog",
  initialState,
  reducers: {
    setObject: (
      state,
      action: PayloadAction<{ value: { id: number | string; type: string; recordType?: string } }>,
    ) => ({
      ...state,
      object: action.payload.value,
    }),
    open: (state) => ({
      ...state,
      isOpen: true,
    }),
    close: () => initialState,
  },
  extraReducers: () => {},
});

export const selectDeleteConfirmDialog = (state: RootState) => state.deleteConfirmDialog;
export default deleteConfirmDialogSlice.reducer;
