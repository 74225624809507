import { createStyles, makeStyles } from "@mui/styles";

export const styles = makeStyles(() =>
  createStyles({
    memberAutocomplete: {
      overflow: "scroll",
      "& .MuiInputBase-root": {
        flexWrap: "nowrap !important",
      },
      "& .MuiAutocomplete-inputRoot": {
        paddingRight: "0 !important",
      },
      "& .MuiAutocomplete-endAdornment": {
        position: "inherit",
      },
    },
  }),
);
