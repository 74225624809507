import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  PrimaryCategoryId,
  PrimaryCategoryIndexResponse,
  PrimaryCategoryUpdateRequest,
} from "./primary_category.dto";
import { ProjectId } from "../project.dto";

export const primaryCategoryRepository = {
  index(projectId: ProjectId): Promise<PrimaryCategoryIndexResponse> {
    return ApiClient.get(`/api/v1/projects/${projectId}/primary_categories`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  create(projectId: ProjectId): Promise<void> {
    return ApiClient.post(`/api/v1/projects/${projectId}/primary_categories`).then(
      (res) => res.data,
    );
  },
  update(
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    body: PrimaryCategoryUpdateRequest,
  ): Promise<void> {
    return ApiClient.put(
      `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}`,
      updateBody(body),
    ).then((res) => res.data);
  },
  delete(projectId: ProjectId, primaryCategoryId: PrimaryCategoryId): Promise<void> {
    return ApiClient.delete(
      `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}`,
    ).then((res) => res.data);
  },
  sharedIndex(projectUuid: string): Promise<{
    companyName: string;
    projectName: string;
    primaryCategories: PrimaryCategoryIndexResponse;
  }> {
    return ApiClient.get(`/api/v1/sharable_photos/${projectUuid}/primary_categories`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};

const updateBody = (body: PrimaryCategoryUpdateRequest) => {
  return {
    name: body.name,
    display_order: body.displayOrder,
  };
};
