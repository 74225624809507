import styled from "@emotion/styled";
import { Box, IconButton } from "@mui/material";
import { theme } from "extensions/theme";

export const CustomTextTitleBox = styled(Box)({
  lineHeight: "14px",
  letterSpacing: "0.2px",
  marginBottom: "18px",
  fontWeight: "500",
});

export const CustomImageBlockBox = styled(Box)({
  backgroundColor: theme.palette.customPrimary[50],
  border: `1px solid ${theme.palette.grayScale[300]}`,
  borderRadius: "5px",
  marginTop: "1rem",
  padding: "1rem",
  "& .MuiIconButton-root": {
    position: "absolute",
    right: 0,
  },
  alignItems: "center",
});

export const CustomPhotoBox = styled(Box)({
  cursor: "pointer",
  position: "relative",
  overflow: "hidden",
  margin: "0.7rem",
  borderRadius: "4px",
  height: "8.5rem",
  width: "13rem",
  textAlign: "center",
  border: `1px solid ${theme.palette.grayScale[300]}`,
});

export const CustomIconButton = styled(IconButton)({
  m: "5px",
  backgroundColor: theme.palette.grayScale[0],
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.grayScale[300]}`,
  width: "24px",
  height: "24px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});
