import { FileDownloadOutlined, Visibility } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { FileIcon } from "components/icon/file-icon";
import { theme } from "extensions/theme";
import { fileDownload } from "utils/fileDownload";
import { formatDateUtil } from "utils/formatDateUtil";
import { isContainExtensions } from "utils/isContainExtensions";

interface Props {
  sx?: object;
  pdfFile: {
    id: number;
    name: string;
    url: string;
    uploadedDate: string;
  };
  onDelete: (id: number, type: string) => void;
  handleClickPreview: (file: { id: number; name: string; url: string }) => void;
  availableExtensions: string[];
}
// TODO: 他のファイルのブロックと共通化する
export const PdfFileBlock = (props: Props) => {
  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grayScale[300]}`,
        borderRadius: "5px",
        backgroundColor: theme.palette.customPrimary[50],
        p: "1rem",
        mt: "1rem",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <FileIcon fileName={props.pdfFile.name} sx={{ width: "1.8rem" }} />
          <Box sx={{ ml: "0.5rem", width: "8rem", wordWrap: "break-word" }}>
            <Typography>{props.pdfFile.name}</Typography>
            <Typography
              fontSize="0.85rem"
              sx={{ mt: "0.5rem", color: theme.palette.grayScale[700] }}
            >
              {formatDateUtil(new Date(props.pdfFile.uploadedDate), "yyyy年MM月dd日")}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              ml: "0.5rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {isContainExtensions(props.pdfFile.name, props.availableExtensions) && (
              <IconButton
                size="small"
                onClick={() =>
                  props.handleClickPreview({
                    ...props.pdfFile,
                  })
                }
              >
                <Visibility />
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() => fileDownload(props.pdfFile.url, props.pdfFile.name)}
            >
              <FileDownloadOutlined />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => props.onDelete(props.pdfFile.id, "projectPdfFile")}
            >
              <DeleteIcon color={theme.palette.grayScale[700]} size={20} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
