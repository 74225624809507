import { useCallback } from "react";
import { Box, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-pro";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import { CompanyUser, CompanyUserId } from "data-access/repositories/company_user/company_user.dto";
import { companyUserRepository } from "data-access/repositories/company_user/company_user.repository";
import { Group, GroupId } from "data-access/repositories/group/group.dto";
import { groupRepository } from "data-access/repositories/group/group.repository";
import { theme } from "extensions/theme";
import { attendanceRuleRepository } from "features/company-settings/api/attendance/rule/attendance-rule.repository";
import {
  AttendanceRule,
  AttendanceRuleId,
} from "features/company-settings/types/attendance/rule/attendance_rule.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { handleError } from "utils/errorHandler";
import { styles } from "./styles";

const menuItemStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 24,
  width: "min-content",
  px: "10px",
  fontWeight: "bold",
  fontSize: "12px",
  borderRadius: "4px",
};

export const EmployeeTable = () => {
  const dispatch = useAppDispatch();
  const classes = styles();

  const {
    mutate: membersMutate,
    data: members,
    isValidating,
  } = useSWR("/api/v1/company_users", companyUserRepository.index);
  const { mutate: groupsMutate, data: groups } = useSWR("/api/v1/groups", groupRepository.index);
  const { data: attendanceRules } = useSWR(
    "/api/v1/attendance/rules",
    attendanceRuleRepository.index,
    { revalidateOnFocus: false },
  );

  const activeEmployees = members?.filter((member) => {
    const isInHouseDeactivateMember = !member.isOutsourcedMember && member.isDeactivate;
    const isOutsourcedMember = member.isOutsourcedMember;
    return !(isInHouseDeactivateMember || isOutsourcedMember);
  });

  const handleChangeGroup = async (
    e: SelectChangeEvent<number | GroupId>,
    companyUserId: CompanyUserId,
  ) => {
    const { value: groupId } = e.target;

    try {
      await companyUserRepository.update(companyUserId, {
        groupId: Number(groupId),
      });
      members && membersMutate();
      groups && groupsMutate();
      dispatch(mainOperations.updateSuccessMessage("グループを変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleEdit = useCallback(async (params: GridRowModel) => {
    try {
      await companyUserRepository.update(params.id, {
        name: params.name,
        unitPricePerDay: params.unit_price_per_day,
      });
      membersMutate();
      dispatch(mainOperations.updateSuccessMessage("メンバーを更新しました"));
      dispatch(mainOperations.getUsers());
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    return params;
  }, []);

  const handleChangeAttendanceRule = async (
    e: SelectChangeEvent<number | AttendanceRule>,
    companyUserId: CompanyUserId,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    const { value: attendanceRuleId } = e.target;

    try {
      await companyUserRepository.update(companyUserId, {
        attendanceRuleId: attendanceRuleId as AttendanceRuleId,
      });
      members && membersMutate();
      dispatch(mainOperations.updateSuccessMessage("勤怠ルールを変更しました"));
    } catch (error) {
      handleError(
        error,
        (errorMessage: string) => {
          dispatch(mainOperations.updateErrorMessage(errorMessage));
        },
        "勤怠ルールの変更に失敗しました",
      );
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const columns = () => {
    const headers: GridColDef[] = [];
    headers.push({
      field: "name",
      headerName: "名前",
      minWidth: 240,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "group_id",
      headerName: "グループ",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => {
        return (
          <>
            <Select
              id="group"
              name="group"
              className={classes.tableSelectBox}
              value={params.row.groupId || 0}
              variant="standard"
              onChange={(e) => handleChangeGroup(e, params.row.id)}
              displayEmpty
              sx={{
                "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
              }}
            >
              <MenuItem value={0}>
                <Box
                  sx={{
                    ...menuItemStyles,
                    border: `1px solid ${theme.palette.grayScale[900]} `,
                  }}
                >
                  未設定
                </Box>
              </MenuItem>
              {groups &&
                groups.map((group: Group, index: number) => (
                  <MenuItem value={group.id} key={index}>
                    <Box
                      sx={{
                        ...menuItemStyles,
                        backgroundColor: `#${group.color_number}`,
                      }}
                    >
                      {group.name}
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </>
        );
      },
    });
    headers.push({
      field: "unit_price_per_day",
      headerName: "人工単価",
      minWidth: 100,
      editable: true,
      sortable: false,
      align: "right",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => (
        <>
          <Typography sx={{ fontSize: "14px", mr: "2px" }}>¥</Typography>
          <Typography fontSize="14px">{params.row.unitPricePerDay.toLocaleString()}</Typography>
        </>
      ),
    });
    headers.push({
      field: "attendance_rule",
      headerName: "勤怠ルール",
      minWidth: 240,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<CompanyUser>) => (
        <>
          <Select
            name="attendanceRule"
            className={classes.tableSelectBox}
            value={params.row.attendanceRule.id || 0}
            variant="standard"
            onChange={(e) => handleChangeAttendanceRule(e, params.row.id)}
            displayEmpty
            sx={{
              "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
            }}
          >
            {attendanceRules &&
              attendanceRules.map((rule: AttendanceRule, index: number) => (
                <MenuItem value={rule.id} key={index}>
                  <Box sx={{ ...menuItemStyles }}>{rule.name}</Box>
                </MenuItem>
              ))}
          </Select>
        </>
      ),
    });
    return headers;
  };

  return (
    <>
      <CustomizedDataGridPro
        columnHeaderHeight={40}
        columns={columns()}
        rows={activeEmployees || []}
        loading={isValidating}
        hideFooter
        disableRowSelectionOnClick
        processRowUpdate={handleEdit}
      />
    </>
  );
};
