import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Typography } from "@mui/material";

interface Props {
  onOpen: () => void;
  entityName: string;
  width: string;
  height: string;
}
export const AddButton = ({ onOpen, entityName, width, height }: Props) => {
  return (
    <Button
      onClick={onOpen}
      variant="outlined"
      sx={{
        width,
        height,
      }}
    >
      <AddCircleIcon fontSize="small" sx={{ mr: "12px" }} />
      <Typography color="primary" sx={{ fontSize: "14px", fontWeight: "bold" }}>
        {entityName}を追加
      </Typography>
    </Button>
  );
};
