import { ApiClient } from "data-access/ApiClient";
import { AuthClient } from "data-access/AuthClient";

export const userPasswordRepository = {
  update(email: string, password: string, confirmation_code: string): Promise<void> {
    return AuthClient.put("/api/v1/users/password", { email, password, confirmation_code }).then(
      (res) => res.data,
    );
  },
  reset(email: string): Promise<void> {
    return AuthClient.put("/api/v1/users/password/reset", { email }).then((res) => res.data);
  },
  updateWithAuth(password: string): Promise<void> {
    return ApiClient.put("/api/v1/users/password/with_auth", { password }).then((res) => res.data);
  },
};
