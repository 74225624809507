import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  RecurrenceRuleLabelRequest,
  RecurrenceRuleLabelResponse,
} from "./recurrence_rule_label.dto";
import { RecurrenceRule } from "../recurrence_rule/recurrence_rule.dto";

export const RecurrenceRuleLabelRepository = {
  index(body: RecurrenceRuleLabelRequest): Promise<RecurrenceRuleLabelResponse> {
    const params = {
      original_start_time: body.originalStartTime,
      interval: body.interval,
      frequency_type: body.frequencyType,
      end_type: body.endType,
      end_date: body.endDate,
      count: body.count,
      days_of_week: body.daysOfWeek?.join(","),
    };
    return ApiClient.get("/api/v1/recurrence_rule_label", { params }).then((res) => {
      return convertKeysToCamelCase(res.data) as RecurrenceRule;
    });
  },
};
