import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  CompanyUserIndexResponse,
  CompanyUserUpdateRequest,
  CompanyUserCreateRequest,
  CompanyUserCreateResponse,
  CompanyUserUpdateResponse,
  CompanyUserId,
} from "./company_user.dto";
import { ApiClient } from "../../ApiClient";

export const companyUserRepository = {
  index(): Promise<CompanyUserIndexResponse> {
    return ApiClient.get("/api/v1/company_users/").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(body: CompanyUserCreateRequest): Promise<CompanyUserCreateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/company_users", createBody(body), config).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(id: CompanyUserId, body: CompanyUserUpdateRequest): Promise<CompanyUserUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/company_users/${id}`, updateBody(body), config).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
};

const createBody = (body: CompanyUserCreateRequest) => {
  return {
    name: body.name,
    email: body.email,
    permission_set_key_name: body.permissionSetKeyName,
    group_name: body.groupName,
  };
};

const updateBody = (body: CompanyUserUpdateRequest) => {
  return {
    name: body.name,
    group_id: body.groupId !== 0 ? body.groupId : null,
    unit_price_per_day: body.unitPricePerDay,
    display_order_position: body.displayOrderPosition && body.displayOrderPosition,
    is_deactivate: body.isDeactivate,
    attendance_rule_id: body.attendanceRuleId,
  };
};
