import { CompanySettingUpdateRequest } from "./company_setting.dto";
import { ApiClient } from "../../ApiClient";

export const companySettingRepository = {
  update(body: CompanySettingUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put("/api/v1/companies/company_setting", requestBody(body), config).then(
      (res) => res.data,
    );
  },
};
const requestBody = (body: CompanySettingUpdateRequest) => {
  return {
    schedule_note_template: body.scheduleNoteTemplate,
    work_report_content_template: body.workReportContentTemplate,
    project_note_template: body.projectNoteTemplate,
  };
};
