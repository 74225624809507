import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  NoticeConfirmedScheduleDeletionIndexResponse,
  NoticeConfirmedScheduleDeletionUpdateRequest,
} from "./confirmed-schedule-deletion.dto";

export const confirmedScheduleDeletionRepository = {
  show(): Promise<NoticeConfirmedScheduleDeletionIndexResponse> {
    return ApiClient.get("/api/v1/notice/confirmed_schedule_deletions/setting").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(body: NoticeConfirmedScheduleDeletionUpdateRequest): Promise<void> {
    return ApiClient.put(
      "/api/v1/notice/confirmed_schedule_deletions/setting",
      updateBody(body),
    ).then((res) => res.data);
  },
};

const updateBody = (body: NoticeConfirmedScheduleDeletionUpdateRequest) => {
  return {
    subscriber_types: body.subscriberTypes,
    is_enable: body.isEnable,
  };
};
