import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { theme } from "extensions/theme";
import { styles } from "./styles";

export type Handle = {
  confirm: () => Promise<boolean>;
};

type Props = {
  ref: React.RefObject<Handle>;
};

export const AsyncScheduleNoticeDialog = forwardRef<Handle, Props>((_, ref) => {
  const [open, setOpen] = useState<boolean>(false);

  const [handleAgree, setHandleAgree] = useState<any>(() => {});
  const [handleDisagree, setHandleDisagree] = useState<any>(() => {});
  const resetHandle = () => {
    setHandleAgree(() => {});
    setHandleDisagree(() => {});
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    confirm: () =>
      new Promise((resolve) => {
        setHandleAgree(() => () => {
          resetHandle();
          resolve(true);
        });
        setHandleDisagree(() => () => {
          resetHandle();
          resolve(false);
        });
        setHandleDisagree(() => () => {
          resetHandle();
          resolve(false);
        });
        setOpen(true);
      }),
  }));
  return <ScheduleNoticeDialog handleNo={handleDisagree} handleYes={handleAgree} isOpen={open} />;
});

AsyncScheduleNoticeDialog.displayName = "AsyncConfirmDialog";

interface ScheduleNoticeDialogProps {
  isOpen: boolean;
  handleYes: () => void;
  handleNo: () => void;
}

const ScheduleNoticeDialog = ({ ...props }: ScheduleNoticeDialogProps) => {
  const classes = styles();

  return (
    <Dialog
      open={props.isOpen}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classes.dialog}
      maxWidth="sm"
    >
      <DialogContent>
        <DialogContentText
          id="dialog-description"
          sx={{ color: theme.palette.grayScale[900], textAlign: "center", fontSize: "18px" }}
        >
          予定の変更を通知しますか？
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", pt: "20px" }}>
        <Button onClick={props.handleNo} color="primary" variant="outlined" sx={{ width: "13rem" }}>
          いいえ
        </Button>
        <Button
          onClick={props.handleYes}
          color="primary"
          variant="contained"
          sx={{ width: "13rem" }}
          disableElevation
        >
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};
