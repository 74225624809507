import { ScheduleUpdateRequest, ScheduleId } from "data-access/repositories/schedule/schedule.dto";
import { update, updateGridSchedule, dropGridSchedule, destroy } from "./actions";
import { calendarSlice } from "./slice";
import { AppDispatch } from "../store";

export const calendarOperations = {
  destroy: (id: ScheduleId, with_futures: boolean) => (dispatch: AppDispatch) => {
    dispatch(destroy({ id, with_futures }));
  },
  update:
    (id: ScheduleId, with_futures: boolean, body: ScheduleUpdateRequest) =>
    (dispatch: AppDispatch) => {
      dispatch(update({ id, with_futures, body }));
    },
  updateGridSchedule:
    (id: ScheduleId, with_futures: boolean, body: ScheduleUpdateRequest) =>
    (dispatch: AppDispatch) => {
      dispatch(updateGridSchedule({ id, with_futures, body }));
    },
  dropGridSchedule:
    (id: ScheduleId, with_futures: boolean, body: ScheduleUpdateRequest) =>
    (dispatch: AppDispatch) => {
      dispatch(dropGridSchedule({ id, with_futures, body }));
    },
  updateSuccessMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(calendarSlice.actions.updateSuccessMessage({ value }));
  },
  updateErrorMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(calendarSlice.actions.updateErrorMessage({ value }));
  },
  deleteSnackbarMessage: () => (dispatch: AppDispatch) => {
    dispatch(calendarSlice.actions.deleteSnackbarMessage());
  },
};
