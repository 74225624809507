import { Popover } from "@mui/material";
import { AmountTextField } from "components/atoms/amount-text-field";

interface Props {
  onOpen: (v: boolean) => void;
  open: boolean;
  tax: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, amount: number) => void;
  anchorEl: HTMLButtonElement | null;
}

export const TaxChangeDialog = (props: Props) => {
  const { onOpen, open, tax, onChange, onBlur, anchorEl } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => onOpen(false)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ "& .MuiPaper-root": { p: "20px 16px" } }}
    >
      <p style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "16px", marginTop: 0 }}>
        消費税の手動設定
      </p>
      <AmountTextField name="tax" width="208px" value={tax} onChange={onChange} blurFunc={onBlur} />
    </Popover>
  );
};
