import { createStyles, makeStyles } from "@mui/styles";

export const styles = makeStyles(() => {
  return createStyles({
    checkAllNone: {
      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
        display: "none",
      },
    },
    table: () => ({
      "& .MuiDataGrid-columnHeaderDraggableContainer": {
        cursor: "move",
      },
      "& .MuiDataGrid-cell": {
        display: "flex",
        alignItems: "center",
        lineHeight: "inherit !important",
      },
      "& .MuiDataGrid-cell:focus": {
        outline: "inherit !important",
      },
      ".MuiDataGrid-columnHeader:focus": {
        outline: "inherit !important",
      },
      "& .MuiTypography-root": {
        lineHeight: "inherit",
      },
    }),
  });
});
