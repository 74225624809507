import { ViewType } from "components/organisms/calendar";
import { ScheduleList } from "data-access/repositories/schedule/schedule.dto";
import { User } from "data-access/repositories/user/user.dto";
import { theme } from "extensions/theme";
import { CalendarEvent } from "types/calendarEvent";
import { usersName } from "./usersName";

export const scheduleTitle = (
  name: string,
  displayName: string,
  users: User[],
  viewType: ViewType,
): string => {
  // 月表示以外の場合は予定名が空でも参加者名を表示しない
  if (viewType !== "dayGridMonth") return displayName;

  // 予定の名前がない場合は、参加者名を表示
  if (!name) {
    return `${displayName} ${usersName(users)}`;
  } else {
    return displayName;
  }
};

export const convertScheduleToEvent = (
  schedule: ScheduleList,
  viewType: ViewType,
): CalendarEvent => {
  return {
    id: schedule.id.toString(),
    resourceIds: !schedule.users.length
      ? ["0"]
      : schedule.users.map((user) => {
          return user.id.toString();
        }),
    title: scheduleTitle(schedule.name, schedule.display_name, schedule.users, viewType),
    note: schedule.note,
    start: schedule.start_time,
    end: schedule.end_time,
    textColor: schedule.is_confirmed
      ? theme.palette.grayScale[0]
      : `#${schedule.schedule_type.color_number}`,
    display: "block",
    isConfirmed: schedule.is_confirmed,
    backgroundColor: schedule.is_confirmed
      ? `#${schedule.schedule_type.color_number}`
      : theme.palette.grayScale[0],
    borderColor: `#${schedule.schedule_type.color_number}`,
    isHoliday: schedule.schedule_type.is_holiday,
    recurrenceRule: schedule.recurrence_rule,
  };
};
