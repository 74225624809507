import { BilledStatusType } from "./type";

export const convertBilledStatusToJP = (type: BilledStatusType) => {
  switch (type) {
    case "before_billing_file_created":
      return "未作成";
    case "billing_file_created":
      return "作成済み";
    case "warranty_not_attach":
      return "ー";
    case "before_warranty_created":
      return "未作成";
    case "warranty_created":
      return "作成済み";
    case "before_billed":
      return "未送付";
    case "billed":
      return "送付済み";
    case "before_deposited":
      return "未入金";
    case "partial_deposited":
      return "一部入金済み";
    case "all_deposited":
      return "全て入金済み";
  }
};
