import { createStyles, makeStyles } from "@mui/styles";

export const styles = makeStyles(() =>
  createStyles({
    checkbox: {
      justifyContent: "center",
      "&.MuiFormControlLabel-root": {
        margin: 0,
      },
    },
    selectBox: {
      "&.MuiInput-underline:before": {
        borderBottom: "none",
      },
    },
    contentField: {
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },
  }),
);
