interface Props {
  color: string;
  size?: number;
}
export const ChartIcon = ({ color, size = 20 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6673 8.35333L13.494 3.46667L14.6473 4.13333L11.1607 10.1667L6.82065 7.66667L3.64065 13.1667H14.6673V14.5H1.33398V2.5H2.66732V12.1933L6.33398 5.83333L10.6673 8.35333Z"
        fill={color}
      />
    </svg>
  );
};
