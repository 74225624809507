import { ApiClient } from "data-access/ApiClient";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  ClientServiceReportId,
  ClientServiceReportRequest,
  ClientServiceReportResponse,
  ClientServiceReportIndexResponse,
} from "../types/client_service_report.dto";
import { ClientServiceReportFormatId } from "../types/client_service_report_format.dto";

type Format = ".xlsx" | ".pdf";
export const clientServiceReportRepository = {
  index(projectId: ProjectId): Promise<ClientServiceReportIndexResponse> {
    return ApiClient.get(`/api/v1/projects/${projectId}/client_service_reports`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  show(id: ClientServiceReportId): Promise<ClientServiceReportResponse> {
    return ApiClient.get(`/api/v1/client_service_reports/${id}`).then((res) => {
      return convertKeysToCamelCase(res.data) as ClientServiceReportResponse;
    });
  },
  create(body: ClientServiceReportRequest): Promise<ClientServiceReportResponse> {
    const params = {
      client_service_report_format_id: body.ClientServiceReportFormatId,
      project_id: body.projectId,
    };
    return ApiClient.post("/api/v1/client_service_reports", params).then((res) => {
      return convertKeysToCamelCase(res.data) as ClientServiceReportResponse;
    });
  },
  update(
    id: ClientServiceReportId,
    formatId: ClientServiceReportFormatId,
  ): Promise<ClientServiceReportResponse> {
    return ApiClient.put(`/api/v1/client_service_reports/${id}`, {
      client_service_report_format_id: formatId,
    }).then((res) => {
      return convertKeysToCamelCase(res.data) as ClientServiceReportResponse;
    });
  },
  destroy(id: ClientServiceReportId): Promise<void> {
    return ApiClient.delete(`/api/v1/client_service_reports/${id}`).then((res) => res.data);
  },
  download(urlKey: string, format: Format): Promise<Blob> {
    return ApiClient.get(`/api/v1/client_service_reports/${urlKey}/download${format}`, {
      responseType: "blob",
    }).then((res) => res.data);
  },
};
