import { Typography } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  title: string;
  sx?: object;
}

export const TitleBadge = (props: Props) => {
  return (
    <Typography
      sx={{
        width: "fit-content",
        backgroundColor: theme.palette.customPrimary[100],
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "4px",
        padding: "4px 10px",
        fontSize: "12px",
        fontWeight: "bold",
        ...props.sx,
      }}
    >
      {props.title}
    </Typography>
  );
};
