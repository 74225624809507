/**
 * 全角数字を半角数字に変換する
 * ０１２３ => 0123
 */

export const halfWidthNumber = (str: string): string => {
  return str.replace(/[０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};
