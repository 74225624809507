import { useEffect, useRef } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { ClientId, ClientRequest } from "data-access/repositories/client/client.dto";
import { theme } from "extensions/theme";

interface Props {
  onClose: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (e: SelectChangeEvent<string>) => void;
  onBlur: () => void;
  onDelete: (id: ClientId) => void;
  isCreateNew: boolean;
  formState: ClientRequest;
  setFormState: React.Dispatch<React.SetStateAction<ClientRequest>>;
  clientId: ClientId;
}
export const ClientSidebarHeader = (props: Props) => {
  const { onClose, onChange, onSelect, onBlur, onDelete, isCreateNew, formState, clientId } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // DOMの更新が終わった後にフォーカスを当てるため遅延させる
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 10);
  }, []);

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        pt: "70px",
        backgroundColor: theme.palette.grayScale[0],
        zIndex: "2",
        boxShadow: "0px 5px 5px -5px rgba(0,0,0,0.5)",
        mb: "20px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <Button
          startIcon={<Close />}
          onClick={onClose}
          sx={{
            color: theme.palette.grayScale[700],
          }}
        >
          とじる
        </Button>
        <Button
          startIcon={
            <DeleteIcon size={20} color={isCreateNew ? theme.palette.grayScale[700] : ""} />
          }
          onClick={() => onDelete(clientId)}
          color="error"
          disabled={isCreateNew}
        >
          削除する
        </Button>
      </div>
      <div style={{ display: "flex", marginBottom: "20px", gap: "16px" }}>
        <div style={{ width: "450px" }}>
          <Typography>顧客名</Typography>
          <TextField
            inputRef={inputRef}
            id="name"
            name="name"
            value={formState.name || ""}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
          />
        </div>
        <div style={{ width: "100px" }}>
          <Typography>敬称</Typography>
          <Select
            id="honorificTitle"
            name="honorificTitle"
            value={formState.honorificTitle || ""}
            onChange={onSelect}
            fullWidth
          >
            <MenuItem value="nothing">なし</MenuItem>
            <MenuItem value="to_person">様</MenuItem>
            <MenuItem value="to_group">御中</MenuItem>
          </Select>
        </div>
      </div>
    </Box>
  );
};
