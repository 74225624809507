import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import UTIF from "utif";
import styles from "./styles.module.css";

type Props = {
  tiff: string;
};

export const TIFFViewer = forwardRef(function TiffFileViewer({ tiff, ...rest }: Props, ref: any) {
  // states
  const [_tiff] = useState<string>(tiff);
  const [, setTiffs] = useState<HTMLCanvasElement[]>([]);
  const [pages, setPages] = useState<HTMLCanvasElement[]>([]);
  const [page, setPage] = useState(0);

  // refs
  const canvasRef = useRef(null);
  const paginateBottomRef = useRef(null);

  const imgLoaded = (e: any) => {
    console.log(e);
    const ifds = UTIF.decode(e.target.response);
    const _tiffs = ifds.map((ifd, index) => {
      UTIF.decodeImage(e.target.response, ifd);
      const rgba = UTIF.toRGBA8(ifd);
      const canvas = document.createElement("canvas");
      canvas.width = ifd.width;
      canvas.height = ifd.height;
      const ctx = canvas.getContext("2d");
      const img = ctx?.createImageData(ifd.width, ifd.height);
      img?.data.set(rgba);
      ctx?.putImageData(img as ImageData, 0, 0);
      if (index === 0) document.getElementById("tiff-inner-container")?.appendChild(canvas);
      return canvas;
    });
    setPages(_tiffs);
    setTiffs(_tiffs);
  };

  async function displayTIFF(tiffUrl: string) {
    console.log(tiffUrl);
    const response = await axios.get(tiffUrl, {
      responseType: "arraybuffer",
      withCredentials: false,
    });
    imgLoaded({ target: { response: response.data } });
  }

  const handlePreviousClick = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < pages.length - 1) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    displayTIFF(_tiff);
  }, [_tiff]);

  useEffect(() => {
    if (pages.length > 0 && canvasRef.current !== null) {
      (canvasRef.current as HTMLElement).innerHTML = "";
      (canvasRef.current as HTMLElement).appendChild(pages[page]);
    }
  }, [page, pages]);

  // ref all page print
  useImperativeHandle(ref, () => ({
    context: () => {
      pages.forEach((page, index) => {
        if (index > 0 && canvasRef.current !== null) {
          (canvasRef.current as HTMLElement).style.display = "block";
          (canvasRef.current as HTMLElement).appendChild(page);
        }
      });
      return canvasRef.current;
    },
  }));

  return (
    <div className={styles.container} id="tiff-container" ref={ref} {...rest}>
      {pages.length > 1 && (
        <div id="footer" ref={paginateBottomRef}>
          <button disabled={page === 0} onClick={handlePreviousClick} className={styles.button}>
            戻る
          </button>
          <span className={styles.span}>{`${page + 1}/${pages.length}`}</span>
          <button
            disabled={page === pages.length - 1}
            onClick={handleNextClick}
            className={styles.button}
          >
            次へ
          </button>
        </div>
      )}

      <div className={styles.arrow}>
        <div id="tiff-inner-container" className={styles.inner} ref={canvasRef} />
      </div>
    </div>
  );
});

TIFFViewer.propTypes = {
  tiff: PropTypes.string.isRequired,
};
