import { OpenInNew } from "@mui/icons-material";
import { IconButton, SxProps, Theme } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  sx?: SxProps<Theme>;
  onClick: () => void;
}

export const OpenInNewButton = (props: Props) => {
  return (
    <IconButton
      sx={{
        width: "20px",
        height: "20px",
        padding: "4px",
        "&:hover": {
          backgroundColor: "transparent",
        },
        ...props.sx,
      }}
      onClick={props.onClick}
    >
      <OpenInNew style={{ fontSize: "20px", color: `${theme.palette.blue[500]}` }} />
    </IconButton>
  );
};
