import { useState } from "react";
import { ConfirmDialog } from "components/molecules/confirm-dialog";
import { cookieRepository } from "data-access/cookie/cookie.repository";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { useSWRConfig } from "swr";

interface Props {
  isOpen: boolean;
  scheduleIds: number[];
  onClose: () => void;
  fetchIndexKey: string;
}

export const SchedulesConfirmedDialog = (props: Props) => {
  const [isConfirmedNotShowAgain, setIsConfirmedNotShowAgain] = useState<boolean>(false);
  const { mutate: IndexMutate } = useSWRConfig();

  const handleYes = async () => {
    try {
      await scheduleRepository.confirm({ schedule_ids: props.scheduleIds });
      await IndexMutate(props.fetchIndexKey);
      props.onClose();
      if (isConfirmedNotShowAgain) {
        cookieRepository.set("isSchedulesConfirmedNotShowAgain", isConfirmedNotShowAgain);
      }
    } catch (error) {
      console.log(error);
      // TODO: 失敗時の処理は後で考える
    }
  };

  const handlerNo = () => {
    props.onClose();
  };

  const handleConfirmNotShowAgain = (e: boolean) => {
    setIsConfirmedNotShowAgain(e);
  };

  return (
    <ConfirmDialog
      content="この日の予定を一括確定しますか？"
      maxWidth="xs"
      yesButtonText="確定する"
      handleYes={handleYes}
      handleNo={handlerNo}
      handleConfirmedNotShowAgain={handleConfirmNotShowAgain}
      isOpen={props.isOpen}
      isConfirmNotShowAgain={true}
      yesButtonColor="primary"
    />
  );
};
