import { Alert, AlertTitle, AlertColor } from "@mui/material";

interface Props {
  title: string;
  message: string;
  severity: AlertColor;
  sx?: object;
}

export const Flash = ({ sx = {}, ...props }: Props) => {
  return (
    <Alert severity={props.severity} sx={{ ...sx }}>
      {props.title && <AlertTitle>{props.title}</AlertTitle>}
      {props.message}
    </Alert>
  );
};
