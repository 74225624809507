import Axios, { AxiosInstance, AxiosResponse } from "axios";
import { getBaseUrl } from "utils/getBaseUrl";

const axiosInstance: AxiosInstance = Axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
  responseType: "json",
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

export const AuthClient = axiosInstance;
