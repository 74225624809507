import { useRef, useState } from "react";
import { RefObject } from "@fullcalendar/core/preact";
import { Backdrop, Box, CircularProgress, Stack, Typography } from "@mui/material";
import { confirmedScheduleAssignmentChangeRepository } from "data-access/repositories/notice/schedule/confirmed-schedule-assignment-change.repository";
import { confirmedScheduleChangeRepository } from "data-access/repositories/notice/schedule/confirmed-schedule-change.repository";
import { confirmedScheduleCreationRepository } from "data-access/repositories/notice/schedule/confirmed-schedule-creation.repository";
import { confirmedScheduleDeletionRepository } from "data-access/repositories/notice/schedule/confirmed-schedule-deletion.repository";
import { nextDayScheduleSummaryRepository } from "data-access/repositories/notice/schedule/next_day_schedule_summary.repository";
import { scheduleSettingRepository } from "data-access/repositories/notice/schedule/schedule.repository";
import { theme } from "extensions/theme";
import useSWR from "swr";
import { ConfirmedScheduleAssignmentChange } from "./confirmed-schedule-assignment-change";
import { ConfirmedScheduleChange } from "./confirmed-schedule-change";
import { ConfirmedScheduleCreation } from "./confirmed-schedule-creation";
import { ConfirmedScheduleDeletion } from "./confirmed_schedule_deletion";
import { NextDayScheduleSummary } from "./next-day-schedule-summary";
import { ScheduleSetting } from "./schedule-setting";
import { styles } from "./styles";

export const Schedule = () => {
  const classes = styles();

  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);
  const fetchIndexKeyForNextDayScheduleSummary =
    "/api/v1/notice/next_day_schedule_summaries/settings";
  const fetchIndexKeyForScheduleSetting = "/api/v1/notice/schedules/settings";
  const fetchIndexKeyForConfirmedScheduleChangeSetting =
    "/api/v1/notice/confirmed_schedule_changes/setting";
  const fetchIndexKeyForConfirmedScheduleCreationSetting =
    "/api/v1/notice/confirmed_schedule_creations/setting";
  const fetchIndexKeyForConfirmedScheduleAssignmentChangeSetting =
    "/api/v1/notice/confirmed_schedule_assignment_changes/setting";
  const fetchIndexKeyForConfirmedScheduleDeletionSetting =
    "/api/v1/notice/confirmed_schedule_deletions/setting";

  const { data: nextDayScheduleSummaries, isLoading } = useSWR(
    fetchIndexKeyForNextDayScheduleSummary,
    nextDayScheduleSummaryRepository.index,
    {
      revalidateOnFocus: false,
    },
  );
  const { data: scheduleSettings, isLoading: isLoadingForScheduleSetting } = useSWR(
    fetchIndexKeyForScheduleSetting,
    scheduleSettingRepository.index,
    {
      revalidateOnFocus: false,
    },
  );
  const {
    data: confirmedScheduleChangeSetting,
    isLoading: isLoadingForConfirmedScheduleChangeSetting,
  } = useSWR(
    fetchIndexKeyForConfirmedScheduleChangeSetting,
    confirmedScheduleChangeRepository.show,
    {
      revalidateOnFocus: false,
    },
  );
  const {
    data: confirmedScheduleCreationSetting,
    isLoading: isLoadingForConfirmedScheduleCreationSetting,
  } = useSWR(
    fetchIndexKeyForConfirmedScheduleCreationSetting,
    confirmedScheduleCreationRepository.show,
    {
      revalidateOnFocus: false,
    },
  );
  const {
    data: confirmedScheduleAssignmentChangeSetting,
    isLoading: isLoadingForConfirmedScheduleAssignmentChangeSetting,
  } = useSWR(
    fetchIndexKeyForConfirmedScheduleAssignmentChangeSetting,
    confirmedScheduleAssignmentChangeRepository.show,
    {
      revalidateOnFocus: false,
    },
  );
  const {
    data: confirmedScheduleDeletionSetting,
    isLoading: isLoadingForConfirmedScheduleDeletionSetting,
  } = useSWR(
    fetchIndexKeyForConfirmedScheduleDeletionSetting,
    confirmedScheduleDeletionRepository.show,
    {
      revalidateOnFocus: false,
    },
  );

  const priorScheduleNoticeHeaderRef = useRef<HTMLDivElement>(null);
  const confirmedScheduleNoticeHeaderRef = useRef<HTMLDivElement>(null);

  // スクロールを実行する関数
  const scrollToSection = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
        open={
          isFetchLoading ||
          isLoading ||
          isLoadingForScheduleSetting ||
          isLoadingForConfirmedScheduleChangeSetting ||
          isLoadingForConfirmedScheduleAssignmentChangeSetting ||
          isLoadingForConfirmedScheduleCreationSetting ||
          isLoadingForConfirmedScheduleDeletionSetting
        }
        invisible
      >
        <CircularProgress />
      </Backdrop>

      <div style={{ display: "flex" }}>
        <div style={{ width: "180px" }}>
          <Stack spacing={2} sx={{ mr: "20px" }}>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(priorScheduleNoticeHeaderRef)}
              fontWeight="500"
            >
              予定の事前通知
            </Typography>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(confirmedScheduleNoticeHeaderRef)}
              fontWeight="500"
            >
              確定ステータス
              <br />
              の予定
            </Typography>
          </Stack>
        </div>
        <div style={{ overflow: "scroll", height: "70vh", flex: 1 }}>
          <Box ref={priorScheduleNoticeHeaderRef}>
            <div style={{ marginBottom: "16px" }}>
              <Typography fontWeight="bold" fontSize="20px" color="primary">
                予定の事前通知
              </Typography>
            </div>
            <ScheduleSetting
              data={scheduleSettings || []}
              fetchIndexKey={fetchIndexKeyForScheduleSetting}
              setIsLoading={setIsFetchLoading}
            />
            <NextDayScheduleSummary
              data={nextDayScheduleSummaries || []}
              fetchIndexKey={fetchIndexKeyForNextDayScheduleSummary}
              setIsLoading={setIsFetchLoading}
            />
          </Box>
          <Box ref={confirmedScheduleNoticeHeaderRef}>
            <div
              style={{
                marginBottom: "16px",
              }}
            >
              <Typography fontWeight="bold" fontSize="20px" color="primary" mr="16px">
                確定ステータスの予定
              </Typography>
            </div>
            <ConfirmedScheduleCreation
              data={confirmedScheduleCreationSetting}
              fetchIndexKey={fetchIndexKeyForConfirmedScheduleCreationSetting}
              setIsLoading={setIsFetchLoading}
            />
            <ConfirmedScheduleAssignmentChange
              data={confirmedScheduleAssignmentChangeSetting}
              fetchIndexKey={fetchIndexKeyForConfirmedScheduleAssignmentChangeSetting}
              setIsLoading={setIsFetchLoading}
            />
            <ConfirmedScheduleChange
              data={confirmedScheduleChangeSetting}
              fetchIndexKey={fetchIndexKeyForConfirmedScheduleChangeSetting}
              setIsLoading={setIsFetchLoading}
            />
            <ConfirmedScheduleDeletion
              data={confirmedScheduleDeletionSetting}
              fetchIndexKey={fetchIndexKeyForConfirmedScheduleDeletionSetting}
              setIsLoading={setIsFetchLoading}
            />
          </Box>
        </div>
      </div>
    </>
  );
};
