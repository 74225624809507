import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Flash } from "components/atoms/flash";
import { userPasswordRepository } from "data-access/repositories/user/password/password.repository";
import { theme } from "extensions/theme";
import { PageState } from ".";

interface Props {
  setPage: (page: PageState) => void;
  sendEmail: string;
}
export const ResetPassword = (props: Props) => {
  const { setPage, sendEmail } = props;
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await userPasswordRepository.update(sendEmail, password, confirmationCode);
      setPage("confirmPasswordReset");
    } catch (error) {
      setErrorMessage("パスワードの再設定に失敗しました");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
        open={isLoading}
        invisible
      >
        <CircularProgress />
      </Backdrop>

      {errorMessage && <Flash message={errorMessage} severity="error" title="エラー" />}
      <Typography sx={{ fontSize: "28px", fontWeight: "bold", mb: "43px" }}>
        パスワードの再設定
      </Typography>
      <Typography sx={{ fontSize: "14px", mb: "43px" }}>
        パスワード再設定用コードと、新しいパスワードを入力してください。
        <br />
        新しいパスワードは6文字以上で入力してください。
      </Typography>
      <TextField
        id="confirmationCode"
        name="confirmationCode"
        type="number"
        label="パスワード再設定用コード"
        variant="outlined"
        onChange={(e) => setConfirmationCode(e.target.value)}
        value={confirmationCode}
        fullWidth
        placeholder="000000"
        sx={{ mb: "20px" }}
      />
      <TextField
        id="password"
        name="password"
        label="新しいパスワード"
        type={showPassword ? "text" : "password"}
        variant="outlined"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        fullWidth
        autoComplete="off"
        sx={{ mb: "43px" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        onClick={handleSubmit}
        variant="contained"
        fullWidth
        disabled={password.length < 6 || confirmationCode.length === 0}
      >
        パスワード再設定
      </Button>
    </>
  );
};
