import { useState } from "react";
import { DateRangePicker, DefinedRangeProps, Preview, Range } from "react-date-range";
import { Box, ClickAwayListener, Typography } from "@mui/material";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; //{ theme }css file
import { addMonths, addWeeks, isSameDay, startOfDay } from "date-fns";
import { ja } from "date-fns/locale";
import { styles } from "./styles";

interface Props {
  dateName: string;
  dateRangeLabel: string;
  dateRange: Range;
  handleChangeDateRange: (item: Range) => void;
  sx?: object;
}

export const CustomDateRangePicker = (props: Props) => {
  const classes = styles();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const staticRangeHandler = {
    range: {},
    isSelected(range: Range) {
      const definedRange = range;
      return (
        isSameDay(range.startDate!, definedRange.startDate!) &&
        isSameDay(range.endDate!, definedRange.endDate!)
      );
    },
  };
  const createStaticRanges = (
    ranges: { label?: string; range: (props?: DefinedRangeProps) => Preview }[],
  ) => {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  };
  const startOfToday = startOfDay(new Date());
  const defaultStaticRanges = createStaticRanges([
    {
      label: props.dateName,
      range: () => ({
        startDate: undefined,
        endDate: undefined,
      }),
    },
    {
      label: "過去7日間",
      range: () => ({
        startDate: startOfDay(addWeeks(new Date(), -1)),
        endDate: startOfToday,
      }),
    },

    {
      label: "過去14日間",
      range: () => ({
        startDate: startOfDay(addWeeks(new Date(), -2)),
        endDate: startOfToday,
      }),
    },
    {
      label: "過去30日間",
      range: () => ({
        startDate: startOfDay(addMonths(new Date(), -1)),
        endDate: startOfToday,
      }),
    },
    {
      label: "過去90日間",
      range: () => ({
        startDate: startOfDay(addMonths(new Date(), -3)),
        endDate: startOfToday,
      }),
    },
    {
      label: "過去1年間",
      range: () => ({
        startDate: startOfDay(addMonths(new Date(), -12)),
        endDate: startOfToday,
      }),
    },
  ]);

  return (
    <Box sx={{ ...props.sx, cursor: "pointer" }}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box sx={{ position: "relative" }}>
          <Typography
            onClick={handleClick}
            color="primary"
            sx={{
              border: "1px solid rgba(4, 74, 88, 0.5)",
              borderRadius: "0.2rem",
              pl: "0.5rem",
              py: "0.25rem",
              fontSize: "0.857rem",
              width: "12.4rem",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {props.dateRangeLabel}
          </Typography>
          {open && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 9,
              }}
            >
              <DateRangePicker
                locale={ja}
                ranges={[props.dateRange]}
                weekStartsOn={1}
                onChange={(item) => props.handleChangeDateRange(item.selection)}
                className={classes.dateRangePicker}
                staticRanges={defaultStaticRanges}
                showDateDisplay={false}
                showMonthArrow={false}
                scroll={{ enabled: true }}
              />
            </Box>
          )}
        </Box>
      </ClickAwayListener>
    </Box>
  );
};
