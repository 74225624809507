import { ElementRef, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { expenseRepository } from "features/cost/api/expense.repository";
import { laborCostRepository } from "features/cost/api/labor_cost.repository";
import { materialCostRepository } from "features/cost/api/material_cost.repository";
import { outsourcingCostRepository } from "features/cost/api/outsourcing_cost.repository";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { projectSidebarOperations } from "store/project-sidebar/operations";
import { selectProjectSidebar } from "store/project-sidebar/slice";
import useSWR from "swr";
import { ExpenseList } from "./expense";
import { LaborCostList } from "./labor";
import { MaterialCostList } from "./material";
import { OutsourcingCostList } from "./outsourcing";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const CostBreakdownModal = (props: Props) => {
  const { isOpen, onClose } = props;
  const dispatch = useAppDispatch();
  const projectState = useAppSelector(selectProjectSidebar);
  const classes = styles();
  const params = useParams();
  const projectId = Number(params.id) as ProjectId;
  const confirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const fetchIndexKeyLabor = `/api/v1/projects/${projectId}/labor_costs`;
  const fetchIndexKeyOutsourcing = `/api/v1/projects/${projectId}/outsourcing_costs`;
  const fetchIndexKeyExpense = `/api/v1/projects/${projectId}/expenses`;
  const fetchIndexKeyMaterial = `/api/v1/projects/${projectId}/material_costs`;

  const { data: laborCosts, isLoading: isLoadingLabor } = useSWR(
    isOpen ? fetchIndexKeyLabor : null,
    () => laborCostRepository.index(projectId),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: outsourcingCosts, isLoading: isLoadingOutsourcing } = useSWR(
    isOpen ? fetchIndexKeyOutsourcing : null,
    () => outsourcingCostRepository.index(projectId),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: expenses, isLoading: isLoadingExpense } = useSWR(
    isOpen ? fetchIndexKeyExpense : null,
    () => expenseRepository.index(projectId),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: materialCosts, isLoading: isLoadingMaterial } = useSWR(
    isOpen ? fetchIndexKeyMaterial : null,
    () => materialCostRepository.index(projectId),
    {
      revalidateOnFocus: false,
    },
  );

  const totalCost = useMemo(() => {
    const laborCost = laborCosts?.reduce((acc, cur) => acc + cur.amount, 0) || 0;
    const outsourcingCost = outsourcingCosts?.reduce((acc, cur) => acc + cur.amount, 0) || 0;
    const materialCost = materialCosts?.reduce((acc, cur) => acc + cur.amount, 0) || 0;
    const expense = expenses?.reduce((acc, cur) => acc + cur.amount, 0) || 0;
    return laborCost + outsourcingCost + materialCost + expense;
  }, [laborCosts, outsourcingCosts, materialCosts, expenses]);

  const handleClose = () => {
    onClose();
    // 原価モーダルを閉じた時に原価に変更があれば、原価合計を案件詳細に反映させる
    if (totalCost === projectState.project.total_cost_amount) return;
    dispatch(projectSidebarOperations.showProject(projectId));
  };

  return (
    <>
      <AsyncConfirmDialog ref={confirmRef} />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        scroll="paper"
        className={classes.modal}
      >
        <DialogTitle className={classes.title}>
          原価の内訳
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box className={classes.header}>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>実績原価 合計</Typography>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              ¥{totalCost.toLocaleString()}
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>(税抜)</Typography>
          </Box>

          {/* 労務費 */}
          <Box sx={{ mb: "12px" }}>
            <LaborCostList
              projectId={projectId}
              fetchIndexKey={fetchIndexKeyLabor}
              data={laborCosts || []}
            />
          </Box>

          {/* 外注費 */}
          <Box sx={{ mb: "12px" }}>
            <OutsourcingCostList
              projectId={projectId}
              fetchIndexKey={fetchIndexKeyOutsourcing}
              data={outsourcingCosts || []}
            />
          </Box>

          {/* 材料費 */}
          <Box sx={{ mb: "12px" }}>
            <MaterialCostList
              projectId={projectId}
              fetchIndexKey={fetchIndexKeyMaterial}
              data={materialCosts || []}
              confirmRef={confirmRef}
            />
          </Box>

          {/* 経費 */}
          <Box sx={{ mb: "12px" }}>
            <ExpenseList
              projectId={projectId}
              fetchIndexKey={fetchIndexKeyExpense}
              data={expenses || []}
              confirmRef={confirmRef}
            />
          </Box>

          <Backdrop
            sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
            open={isLoadingLabor || isLoadingOutsourcing || isLoadingExpense || isLoadingMaterial}
            invisible
          >
            <CircularProgress />
          </Backdrop>
        </DialogContent>
      </Dialog>
    </>
  );
};
